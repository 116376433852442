
import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../portalCarrier.actions';
import { Page } from "../../../../../components/page/Page";
import Toolbar from '../../../../../components/toolbar/Toolbar';
import { routes } from '../../../../../constants';
import { Column } from 'primereact/column';
import { TableRowTreeview } from './TableRowTreeview';
import { CargoShipmentDeliveredList } from './CargoShipmentDeliveredList';
import { numberToCurrency } from '../../../../../utils';
import storage from '../../../../../services/stoage.service'
import { AppService, COMPANY_CHANGE_EVENT_NAME } from '../../../../../services/app.service';
import { CARGO_SHIP_DELIVERED } from '../../../constantes';
import moment from 'moment';
import "./cargoShipDelivered.scss"
import UpdateDataEntregaPortaCarrierl from '../../cargoShipmentInStatusTrasito/components/updateDataEntregaPortaCarrierl';
import { InputText } from 'primereact/inputtext';

const mapStateToProps = state => {
  const records = state.crudPortalCarrierState.cargoShipStatusDelivereds;
  let columns = [];
  const columnsRowTreeviewTemplate = state.crudPortalCarrierState.columnsRowTreeviewTemplate;
  const columnsRowTreeviewTransfTemplate = state.crudPortalCarrierState.columnsRowTreeviewTransfTemplate;
  const columnsRowTreeviewInTransitoTemplate = state.crudPortalCarrierState.columnsRowTreeviewInTransitoTemplate;

  const user = storage.getUser();

  if (!user.carrier) {
    columns = [...state.crudPortalCarrierState.columnsCargoShipmentDelivered, {
      field: "collectionOrder.carrier.name",
      header: "Transportadora",
      columnKey: "collectionOrder.carrier.name",
      sortable: false,
      filter: false
    }]
  } else {
    columns = [...state.crudPortalCarrierState.columnsCargoShipmentDelivered]
  }

  return {
    records,
    columns,
    //devera ser limpo a cada troca de tela
    first: state.crudPortalCarrierState.index,
    rows: state.crudPortalCarrierState.max,
    totalRecords: state.crudPortalCarrierState.count,
    sortField: state.crudPortalCarrierState.sortField,
    order: state.crudPortalCarrierState.sortOrder,


    expandedRows: state.crudPortalCarrierState.expandedRows,
    columnsRowTreeviewTemplate,
    openDialogIframeVisible: state.crudPortalCarrierState.openDialogIframeVisible,
    link: state.crudPortalCarrierState.link,
    carrier: state.crudPortalCarrierState.carrier,
    columnsRowTreeviewTransfTemplate,
    columnsRowTreeviewInTransitoTemplate,
    showModalUploadImg: state.crudPortalCarrierState.showModalUploadImg,
    delivery: state.crudPortalCarrierState.delivery,
    nroNfe: state.crudPortalCarrierState.nroNfe,
    delivery: state.crudPortalCarrierState.delivery,
    nroNfe: state.crudPortalCarrierState.nroNfe,
    codeShipment: state.crudPortalCarrierState.codeShipment,
    nroEmbarque: state.crudPortalCarrierState.nroEmbarque,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => {
      if (paginationParams.index !== undefined) {
        dispatch(actions.setIndex(paginationParams.index));
      }
      if (paginationParams.page !== undefined) {
        dispatch(actions.setPage(paginationParams.page));
      }
      if (paginationParams.max !== undefined) {
        dispatch(actions.setMax(paginationParams.max));
      }
    },
    cleanPaginate: () => {
      dispatch(actions.setIndex(0));
      dispatch(actions.setPage(0));
      dispatch(actions.setMax(50));
    },
    getCargoShipmentDelivered: () => {
      dispatch(actions.loadCargoShipmentDelivered())
    },
    setCurrentScreen: (currentScreen) => dispatch(actions.setCurrentScreen(currentScreen)),
    setExpandedRows: (expandedRows) => {
      dispatch(actions.setExpandedRows(expandedRows));
    },

    setShowModalUploadImg: visible => dispatch(actions.setShowModalUploadImg(visible)),
    setCodeOrder: order => dispatch(actions.setCodeOrder(order)),
    setLinkAddCanhoto: linkCanhoto => dispatch(actions.setLinkAddCanhoto(linkCanhoto)),


    setDeliveryFilter: delivery => dispatch(actions.setDeliveryFilter(delivery)),
    setNroNfeFilter: nroNfe => dispatch(actions.setNroNfeFilter(nroNfe)),
    setCodeShipmentFilter: codeShipment => dispatch(actions.setCodeShipmentFilter(codeShipment)),
    setNroEmbarqueFilter: nroEmbarque => dispatch(actions.setNroEmbarqueFilter(nroEmbarque)),
  }
}

class CargoShipmentDeliveredPageContainer extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.cleanPaginate();
    this.props.setCurrentScreen(CARGO_SHIP_DELIVERED);
    this.props.getCargoShipmentDelivered();
    this.clearFilter();
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.cleanPaginate();
    this.clearFilter();
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME) {
      this.props.cleanPaginate();
      this.props.setCurrentScreen(CARGO_SHIP_DELIVERED);
      this.props.getCargoShipmentDelivered();
      this.clearFilter();
    }
  };
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}`)
  }

  setExpandedRowsByRowClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else if (e.data && e.data.length == 1) {
      this.props.setExpandedRows([e.data[0]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  setExpandedRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  onRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  onSetCodeOrder = (order) => {
    this.props.setLinkAddCanhoto(this.props.expandedRows[0].link);
    this.props.setCodeOrder(order);
  }
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
    this.props.getCargoShipmentDelivered();
  }

  setDeliveryFilter = (e) => {
    let resul = String(e.target.value).trim();
    this.props.setDeliveryFilter(resul);
  }
  setNroNfeFilter = (e) => {
    let resul = String(e.target.value).trim();
    this.props.setNroNfeFilter(resul);
  }
  setCodeShipmentFilter = (e) => {
    let resul = String(e.target.value).trim();
    this.props.setCodeShipmentFilter(resul);
  }
  setNroEmbarqueFilter = (e) => {
    let resul = String(e.target.value).trim();
    if (resul.length > 0) {
      try {
        let nroEmb = parseInt(resul)
        this.props.setNroEmbarqueFilter(nroEmb);
      } catch (error) {
        this.props.setNroEmbarqueFilter("");
      }
    } else {
      this.props.setNroEmbarqueFilter("");
    }
  }

  clearFilter = () => {
    this.props.setNroEmbarqueFilter("");
    this.props.setCodeShipmentFilter("");
    this.props.setNroNfeFilter("");
    this.props.setDeliveryFilter("");
  }

  render() {

    const title = `Embarques já entregues`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onSearch={this.searchHandler} />
        </div>
      </div>
    );


    const numberTemplate = (rowData, column) => {
      try {
        const valor = rowData[column.field];
        let numero = "";
        if (valor) {
          numero = !valor ? 0.0 : parseFloat(valor).toFixed(3);
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {numero}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };


    const pesoTemplate = (rowData, column) => {
      try {
        const valor = rowData.collectionOrder.cargo.weight;
        let numero = "";
        if (valor) {
          numero = !valor ? 0.0 : parseFloat(valor).toFixed(3);
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {numero}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };


    const valorCustoTemplate = (rowData, column) => {
      try {
        const valor = Number(rowData[column.field]);
        let formattedDate = "";
        if (valor) {
          formattedDate = numberToCurrency(valor, "R$");
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {formattedDate}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const formatPesoTemplate = (rowData, column) => {
      try {
        const valor = Number(rowData.collectionOrder.cargo.weight);
        let formattedDate = "";
        if (valor) {
          formattedDate = valor.toLocaleString('pt-br', { style: 'decimal', maximumFractionDigits: 3, minimumFractionDigits: 1 })
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {formattedDate}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).format("DD/MM/YYYY");
      return <div style={{ textAlign: "center", color: "black" }}>{formattedDate}</div>;
    };

    const dateEntregaTemplate = (rowData, column) => {
      let className = "no-pendencia-ocorren";
      if (!possuiOcorrenEntrega(rowData)) {
        className = "pendencia-ocorren"
      }

      if (rowData.occurrence && rowData.occurrence.dateOccurrence) {
        const date = rowData.occurrence.dateOccurrence;
        const formattedDate = moment(date).format("DD/MM/YYYY");
        return <span style={{ textAlign: "center" }} className={className}>{formattedDate}</span>;
      } else {
        return <div></div>
      }
    };


    const possuiCanhotoTemplate = (rowData, column) => {
      try {

        if (rowData.occurrence && rowData.occurrence.image) {
          return (
            <span className="no-pendencia-ocorren">
              Sim
            </span>
          );
        }
        return <span className="pendencia-ocorren" >Não</span>;
      } catch (error) {
        return <span>Não</span>;
      }
    };
    const bodyDefault = (rowData, column) => {
      let className = "no-pendencia-ocorren";
      if (!possuiOcorrenEntrega(rowData)) {
        className = "pendencia-ocorren"
      }
      return (
        <span className={className}>{rowData[`${column.field}`]}</span>
      );
    };
    const bodyDefaultParent = (rowData, column) => {
      let className = "no-delivered";
      if (rowData.allDelivered) {
        className = "delivered"
      }
      return (
        <span className={className}>{rowData[`${column.field}`]}</span>
      );
    };

    const ocorrenceDescriptTemplate = (rowData, column) => {
      let className = "no-pendencia-ocorren";
      if (!possuiOcorrenEntrega(rowData)) {
        className = "pendencia-ocorren"
      }
      if (rowData["occurrence"] && rowData["occurrence"].description) {
        return (
          <span className={className}>{rowData["occurrence"].description}</span>
        );
      } else {
        return <div></div>
      }
    };

    const possuiOcorrenEntrega = (rowData) => {
      return rowData.occurrence && rowData.occurrence.image;
    }

    const btnIncludeCanhotoTemplate = (rowData, column) => {
      try {

        return (
          <div style={{ textAlign: "center" }}>
            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.onSetCodeOrder(rowData)
                this.props.setShowModalUploadImg(true);
              }}

              title={"Incluir data da entrega e canhoto"}
            >
              <i className="pi pi-upload"></i>
            </button>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const templateExpandedRow = (rowData, e) => {
      const records = [];
      try {
        let sequencia = 0;
        rowData.destination.forEach((dest) => {
          sequencia++;
          records.push({
            index: sequencia,
            ...dest
          });
        });
      } catch (error) {
        console.log("Erro templateExpandedRow=", error);
      }

      let transferenceRecord = []
      let transferenceColumns = []
      if (rowData.ordersByTransfer && rowData.ordersByTransfer.length > 0) {
        transferenceRecord = rowData.ordersByTransfer;
        transferenceColumns = this.props.columnsRowTreeviewTransfTemplate.map((col) => {

          if (col.field === "type") {
            return <Column {...col} key={col.field} style={{ width: "130px" }} />;
          }
          if (col.field === "orderCodeTransfer") {
            return <Column {...col} key={col.field} style={{ width: "130px" }} />;
          }
          if (col.field === "code") {
            return <Column {...col} key={col.field} style={{ width: "130px" }} />;
          }
          if (col.field === "apportionmentValue") {
            return <Column {...col} key={col.field} style={{ width: "130px" }} body={valorCustoTemplate} />;
          }
          return <Column {...col} key={col.field} />;
        })
      }

      let columnsTreeview = this.props.columnsRowTreeviewTemplate.map((col) => {
        if (col.field === "type") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} />;
        }
        if (col.field === "code") {
          return <Column {...col} key={col.field} style={{ width: "100px" }} />;
        }
        if (col.field === "situation") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} />;
        }
        if (col.field === "previsionDate") {
          return <Column {...col} key={col.field} style={{ width: "120px" }} />;
        }
        if (col.field === "qtdeSacos") {
          return <Column {...col} key={col.field} style={{ width: "70px" }} />;
        }
        if (col.field === "qtdePallets") {
          return <Column {...col} key={col.field} style={{ width: "70px" }} />;
        }
        if (col.field === "apportionmentValue") {
          return <Column {...col} key={col.field} style={{ width: "130px" }} body={valorCustoTemplate} />;
        }
        if (col.field === "cd") {
          return <Column {...col} key={col.field} style={{ width: "180px" }} />;
        }
        return <Column {...col} key={col.field} />;
      });

      //canhoto 
      let columnsCanhoto = this.props.columnsRowTreeviewInTransitoTemplate.map((col) => {

        if (col.field === "code") {
          return <Column {...col} key={col.field} style={{ width: "100px" }} body={bodyDefault} />;
        }
        if (col.field === "nroNfe") {
          return <Column {...col} key={col.field} style={{ width: "100px" }} body={bodyDefault} />;
        }
        if (col.field === "shipmentCode") {
          return <Column {...col} key={col.field} style={{ width: "130px" }} body={bodyDefault} />;
        }
      
        if (col.field === "occurrence.image") {
          return <Column {...col} key={col.field} style={{ width: "150px" }} body={possuiCanhotoTemplate} />;
        }

        if (col.field === "btnCanhoto") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} body={btnIncludeCanhotoTemplate} />;
        }

        if (col.field === "occurrence.dateOccurrence") {
          return <Column {...col} key={col.field} style={{ width: "130px" }} body={dateEntregaTemplate} />;
        }
        if (col.field === "occurrence.description") {
          return <Column {...col} key={col.field} style={{ width: "130px" }} body={ocorrenceDescriptTemplate} />;
        }
        return <Column {...col} key={col.field} body={bodyDefault} />;
      });

      const orders = rowData.orders;

      let className = "no-delivered";
      if (rowData.allDelivered) {
        className = ""
      }


      return (
        <div>
          <br />
          <h3 className={className}>Comprovante de entrega</h3>
          <TableRowTreeview records={orders} columns={columnsCanhoto} />
          <br />
          <br />
          <h3>Entregas</h3>
          <TableRowTreeview records={records} columns={columnsTreeview} />
          {transferenceRecord.length > 0 &&
            <div>
              <h3>Pedidos de Transferência</h3>
              <TableRowTreeview records={transferenceRecord} columns={transferenceColumns} />
            </div>
          }

        </div>
      );
    };

    const columns = this.props.columns.map((col) => {
      if (col.field === "freightValue") {
        return <Column {...col} key={col.field} style={{ width: "110px" }} />;
      }
      if (col.field === "oid") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} body={bodyDefaultParent} />;
      }

      if (col.field === "distance") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} />;
      }

      if (col.field === "collectionOrder.cargo.weight") {
        return <Column {...col} key={col.field} style={{ width: "120px" }} body={formatPesoTemplate} />;
      }
      if (col.field === "qtdeTotalSacos") {
        return <Column {...col} key={col.field} style={{ width: "60px" }} />;
      }
      if (col.field === "qtdeTotalPallets") {
        return <Column {...col} key={col.field} style={{ width: "60px" }} />;
      }
      if (col.field === "collectionOrder.pickupDate") {
        return <Column {...col} key={col.field} style={{ width: "90px" }} body={dateTemplate} />;
      }
      if (col.field === "collectionOrder.acceptAt") {
        return <Column {...col} key={col.field} style={{ width: "90px" }} body={dateTemplate} />;
      }
      if (col.field === "collectionOrder.vehicleType") {
        return <Column {...col} key={col.field} style={{ width: "130px" }} />;
      }
      if (col.field === "collectionOrder.origin.name") {
        return <Column {...col} key={col.field} style={{ width: "160px" }} />;
      }
      if (col.field === "collectionOrder.destination.name") {
        return <Column {...col} key={col.field} style={{ width: "160px" }} />;
      }


      return <Column {...col} key={col.field} />;
    });

    const content = (
      <div>
        {this.props.showModalUploadImg &&
          <UpdateDataEntregaPortaCarrierl />
        }
        <div className="p-grid-filter">

          <div className="form-field-container field-filter">
            <label>Número pedido</label>
            <InputText maxlength="15" style={{ width: "120px" }} value={this.props.delivery} onChange={this.setDeliveryFilter} />
          </div>

          <div className="form-field-container field-filter">
            <label>Número NFe</label>
            <InputText maxlength="15" style={{ width: "120px" }} value={this.props.nroNfe} onChange={this.setNroNfeFilter} />
          </div>

          <div className="form-field-container field-filter">
            <label>Código Shipment</label>
            <InputText maxlength="15" style={{ width: "120px" }} value={this.props.codeShipment} onChange={this.setCodeShipmentFilter} />
          </div>

          <div className="form-field-container field-filter">
            <label>Número Embarque</label>
            <InputText maxlength="15" style={{ width: "120px" }} value={this.props.nroEmbarque} onChange={this.setNroEmbarqueFilter} />
          </div>

          <div className="form-field-container field-filter">
            <button
              title="Filtrar"
              onClick={this.props.getCargoShipmentDelivered}
              style={{ marginRight: ".25em" }}
              className="lognex-btn-highlighted btl-filtrar">
              {"Filtrar"}
            </button>
          </div>

        </div>
        <CargoShipmentDeliveredList
          records={this.props.records}
          rowToggleHandler={this.setExpandedRowsClick}
          rowExpandButtonOnClick={this.setExpandedRowsByRowClick}
          expandedRows={this.props.expandedRows}
          templateExpandedRow={templateExpandedRow}
          onRowsClick={this.onRowsClick}
          onPage={this.onPage}
          first={this.props.first}
          rows={this.props.rows}
          totalRecords={this.props.totalRecords}
          sortField={this.props.sortField}
          order={this.props.order}
          columns={columns} />
      </div>
    );

    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CargoShipmentDeliveredPageContainer);