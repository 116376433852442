import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";
import { RouteManualTable } from "./RouteManualTable";
import "./editroute.scss";
export default class RouteManualTablePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      virtualCD: {
        cd: "Automático",
        documentNumber:"",
        label:"",
        location: [],
      },
      vehicle: { oid: -1, name: "Automático" },
    }; 
  }
  onChangeSelectedCD = (e) => {
    let virtualCD = e.value;
    this.setState({ virtualCD });
  };
  onChangeSelectedVehicle = (e) => {
    let vehicle = e.value;
    this.setState({ vehicle }); 
  };

  onConfirm = () => {
    const vehicle = this.state.vehicle.oid != -1 ? this.state.vehicle : null;
    const orderCodes = this.props.records.map((order) => {
      return order.code;
    });
    const pointStart = this.state.virtualCD.location.length > 0 ? this.state.virtualCD : null;
    // console.log("onConfirm vehicle=", vehicle);
    const payload = { pointStart, vehicle, orderCodes };
    this.props.onConfirm(payload);
  };

  render() {
    const footer = (
      <div>
        <ButtonDialog
          onClick={this.onConfirm}
          button={{
            title: "Confirmar",
            className: "lognex-btn-highlighted",
            iconClassName: "pi pi-check",
          }}
          dialog={{
            header: "Confirmação",
            modal: false,
            message: "Deseja realmente incluir as ordens da rota?",
          }}
        ></ButtonDialog>

        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onHide}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    const columns = this.props.columns
      .filter((col) => col.field == "code" || 
      col.field == "pickup.virtualCD" ||  
      col.field == "pickup.description" || 
      col.field == "delivery.description")
      .map((col) => {
        if (col.field == "code") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", width: "80px" }} />;
        } else if (col.field == "pickup.virtualCD") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", width: "80px" }} />;
        }
        return <Column {...col} key={col.field} style={{ fontSize: "11px" }} />;
      });

    const virtualCDs = [
      {
        cd: "Automático",
        location: [],
        label:"Automático"
      },
    ]; 

    if (this.props.records) {
      //adicionar os CDs das novas ordens, q serão inclusas
      this.props.records.forEach((ship) => {
        if (ship.type !== "pontoApoio") {
        if (virtualCDs.filter((vcd) => vcd.cd === String(ship.pickup.virtualCD).toUpperCase() && vcd.documentNumber === ship.pickup.documentNumber).length == 0) {
          virtualCDs.push({
            cd: String(ship.pickup.virtualCD).toUpperCase(),
            location: ship.pickup.location,
            documentNumber: ship.pickup.documentNumber,
            key:ship.pickup.virtualCD + "-" + ship.pickup.documentNumber,
            label:(ship.sender && ship.sender.label) ||   String(ship.pickup.virtualCD).toUpperCase()+"-"+ship.pickup.documentNumber
          }); 
        }
      }
      });
    }

    let vehicles = []; //this.props.vehicles;

    //mostrar somente veiculos que são dos CDs das Ordens

    //filtrar veiculos por CD
    this.props.vehicles.forEach((ve) => {
      for (let index = 0; index < virtualCDs.length; index++) {
        if (String(virtualCDs[index].documentNumber).toString() === String(ve.businessUnit).toUpperCase()) {
          vehicles.push(ve);
        }
      }
    });
    vehicles = vehicles.sort((a, b) => {
      if (a.cd < b.cd) {
        return -1;
      }
      if (a.cd > b.cd) {
        return 1;
      }
      return 0;
    });
    // vehicles = vehicles.filter((v) => virtualCDs.indexOf(String(v.cd).trim().toUpperCase()) > -1);
    let vehiclesAux = [];
    vehicles.forEach((v) => {
      if (vehiclesAux.filter((h) => h.code === v.code && h.cd == v.cd && h.businessUnit == v.businessUnit).length == 0) {
        vehiclesAux.push({ ...v, label: v.name + " - " + v.label });
      }
    });



    vehicles = [{ oid: -1, name: "Automático", label: "Automático" }, ...vehiclesAux];

    return (
      <Dialog
        className="content"
        header={"Criar uma rota manual"}
        footer={footer}
        visible={this.props.visible}
        style={this.props.style || { width: "55vw"  }}  
        modal={true}
        onHide={this.props.onHide}
      >
        <div style={{minHeight:"300px"}}>
          <div className="p-grid">
            <div className="p-col-12 form-field-container">
              <h3>Criar rota manual com as ordens abaixo</h3>
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-6 form-field-container">
              <label>Selecione o ponto inicial(CD)</label>
              <Dropdown
                placeholder="Selecione"
                value={this.state.virtualCD}
                dataKey={"key"}
                key={Math.random()}
                optionLabel="label"
                options={virtualCDs}
                onChange={this.onChangeSelectedCD}
              />
            </div>
            <div className="p-col-6 form-field-container">
              <label>Selecione o tipo de Veículo</label>
              <Dropdown
                placeholder="Selecione"
                value={this.state.vehicle}
                dataKey={"oid"}
                key={Math.random()}
                optionLabel="label"
                options={vehicles}
                onChange={this.onChangeSelectedVehicle}
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-12 form-field-container">
              <label>Ordens</label>
              <RouteManualTable records={this.props.records} columns={columns} />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
