import moment from "moment";
import { types } from "./order-management.actions";

const initialState = {
    pagination: {
        index: 0,
        max: 50,
        page: 1,
        count: 0
    },
    toolbarFilters: {
        number: null,
        initialDate: moment().startOf('month').toDate(),
        finalDate: moment().toDate(),
        carrier: {
            tradeName: "",
            documentNumber: "",
        },
        lookupCarrier: {
            visible: false,
            modal: false,
            header: "Transportadora"
        },
        lookupOrigin: {
            visible: false,
            modal: false,
            header: "Origem"
        },
        lookupDestination: {
            visible: false,
            modal: false,
            header: "Destino"
        },
        selectedBusinessUnitGroup: null,
        selectedGrouperList: [],
        selectedBusinessUnits: [],
        selectedMainBusinessUnit: null,
        selectedBusinessUnitGroupLabel: "",
        modalGrouperVisible: false,
        modalSearchBusinessUnitVisible: false,
        businessUnitGroup: null,
        currentBusinessUnit: null,
        label: [],
        labelList: [],
    },
    orderFilters: [],
    shipmentFilters: [],
    orders: [],
    currentOrder: {},
    selectedOrders: [],
    shipments: [],
    currentShipment: {},
    currentView: "ordersWithoutStock",
    selectedShipments: [],
    countOrders: {
        withStock: 0,
        outOfStock: 0,
        inScripting: 0
    },
    countCargoShipmentOrders: {
        new: 0,             //* box Embarques
        confirmed: 0,       //* box Ordem de Coleta
        approved: 0,        //* box Para Coletar
        traveling: 0,       //* box Em trânsito
        delivered: 0,       //* box Entregues
    },
    pendenceEmail: false,
    pendenceQuote: false,
    pendenceConfirmeShipmentWebhook: false,

}



export function CRUDOrderManagementReducer(state = initialState, action) {
    return execute(state, action);
}

function execute(state, action) {
    switch (action.type) {
        case types.SET_INDEX:
            return setIndex(state, action);
        case types.SET_COUNT:
            return setCount(state, action);
        case types.SET_MAX:
            return setMax(state, action);
        case types.SET_PAGE:
            return setPage(state, action);
        case types.SET_ORDERS:
            return setOrders(state, action);
        case types.ADD_ORDERS:
            return addOrders(state, action);
        case types.SET_ORDER:
            return setOrder(state, action);
        case types.SET_COUNT_ORDERS:
            return setCountOrders(state, action);
        case types.SET_NUMBER_FILTER:
            return setNumberFilter(state, action)
        case types.SET_INITIAL_DATE_FILTER:
            return setInitialDateFilter(state, action)
        case types.SET_FINAL_DATE_FILTER:
            return setFinalDateFilter(state, action)
        case types.SET_CARRIER_FILTER:
            return setCarrierFilter(state, action)
        case types.SET_LOOKUP_CARRIER_VISIBLE:
            return setLookupCarrierVisible(state, action)
        case types.SET_COUNT_CARGO_SHIPMENT_ORDERS:
            return setCountCargoShipmentOrders(state, action)
        case types.SET_SELECTED_ODERS:
            return setSelectedOrders(state, action)
        case types.SET_SHIPMENTS:
            return setShipments(state, action)
        case types.SET_SHIPMENT:
            return setShipment(state, action)
        case types.ADD_SHIPMENTS:
            return addShipments(state, action)
        case types.SET_SELECTED_SHIPMENTS:
            return setSelectedShipments(state, action)
        case types.SET_ORIGIN_FILTER:
            return setOriginFilter(state, action)
        case types.SET_LOOKUP_ORIGIN_VISIBLE:
            return setLookupOriginVisible(state, action)
        case types.SET_DESTINATION_FILTER:
            return setDestinationFilter(state, action)
        case types.SET_LOOKUP_DESTINATION_VISIBLE:
            return setLookupDestinationVisible(state, action)
        case types.APPLY_ORDER_FILTERS:
            return applyOrderFilters(state, action);
        case types.APPLY_SHIPMENT_FILTERS:
            return applyShipmentFilters(state, action);
        case types.SET_CURRENT_VIEW:
            return setCurrentView(state, action);
        case types.CHECK_PENDENCE:
            return hasCargoShipmentPendences(state, action);
        case types.SET_PENDENCE_EMAIL:
            return setPendenceEmail(state, action);
        case types.SET_PENDENCE_QUOTE:
            return setPendenceQuote(state, action);
        case types.SET_PENDENCE_CONFIRM_SHIPMENT_WEBHOOK:
            return setPendenceConfirmShipmentWebhook(state, action);
        case types.SET_GROUP_FILTER:
            return setGroupFilter(state, action);
        case types.SET_VISIBLE_MODAL_WIZARD_FILTER:
            return setVisibleModalGrouperFilter(state, action);
        case types.SET_VISIBLE_MODAL_SEARCH_FILTER:
            return setVisibleModalSearchBusinessUnitFilter(state, action);
        case types.SET_SELECTED_UNITS_LIST_GROUPER:
            return setSelectedUnitsListGrouper(state, action);
        case types.SET_BUSINESS_UNIT_GROUP:
            return setBusinessUnitGroup(state, action);
        case types.SET_SELECTED_GROUPER_RECORDS:
            return setSelectedListGrouper(state, action);
        case types.SET_SELECTED_BUSINESS_UNIT_RECORD:
            return setSelectedBusinessUnit(state, action);
        case types.SET_SELECTED_BUSINESS_UNITS:
            return setSelectedBusinessUnits(state, action);
        case types.SET_SELECTED_MAIN_BUSINESS_UNIT:
            return setSelectedMainBusinessUnit(state, action);
        case types.SET_SELECTED_BUSINESS_UNIT_GROUP_LABEL:
            return setSelectedBusinessUnitGroupLabel(state, action);
        case types.SET_BUSINESS_UNIT_GROUP_FILTER:
            return setBusinessUnitGroupFilter(state, action);
        case types.SET_BUSINESS_UNIT_FILTER:
            return setBusinessUnitFilter(state, action);
        case types.SET_LABEL:
            return setLabel(state, action);
        case types.SET_LABEL_LIST:
            return setLabelList(state, action);
        case types.ON_SELECTED_URGENT:
            return onSelectUrgent(state, action);
        case types.ON_SELECTED_AG:
            return onSelectAG(state, action);
        case types.ON_SELECTED_ZORC:
            return onSelectZorc(state, action);
        default:
            return state;
    }
}
 
function onSelectZorc(state, action) {
    const { shipment, selected } = action.value;

    let shipments = [...state.shipments] || [];
    for (let index = 0; index < shipments.length; index++) {
        if (shipments[index].oid == shipment.oid) {
            shipments[index].zorc = selected
        }
    }

    return { ...state, shipments }
}
function onSelectAG(state, action) {
    const { shipment, selected } = action.value;

    let shipments = [...state.shipments] || [];
    for (let index = 0; index < shipments.length; index++) {
        if (shipments[index].oid == shipment.oid) {
            shipments[index].ag = selected
        }
    }

    return { ...state, shipments }
}
function onSelectUrgent(state, action) {
    const { shipment, selected } = action.value;

    let shipments = [...state.shipments] || [];
    for (let index = 0; index < shipments.length; index++) {
        if (shipments[index].oid == shipment.oid) {
            shipments[index].urgent = selected
        }
    }

    return { ...state, shipments }
}
function setLabelList(state, action) {
    let toolbarFilters = state.toolbarFilters;
    toolbarFilters.labelList = action.labelList;
    return { ...state, toolbarFilters }
}

function setLabel(state, action) {
    let toolbarFilters = state.toolbarFilters;
    toolbarFilters.label = action.value
    return { ...state, toolbarFilters }
}

function setBusinessUnitFilter(state, action) {
    let toolbarFilters = state.toolbarFilters;
    let currentBusinessUnit = action.currentBusinessUnit;
    toolbarFilters = { ...toolbarFilters, currentBusinessUnit };
    return { ...state, toolbarFilters }
}
function setBusinessUnitGroupFilter(state, action) {
    let toolbarFilters = state.toolbarFilters;
    let businessUnitGroup = action.businessUnitGroup;
    toolbarFilters = { ...toolbarFilters, businessUnitGroup };
    return { ...state, toolbarFilters }
}

function setSelectedBusinessUnitGroupLabel(state, action) {
    let toolbarFilters = { ...state.toolbarFilters };
    toolbarFilters.selectedBusinessUnitGroupLabel = action.value;
    return { ...state, toolbarFilters }
}

function setSelectedListGrouper(state, action) {
    let toolbarFilters = state.toolbarFilters;
    toolbarFilters.selectedGrouperList = [...action.records];
    return { ...state, toolbarFilters }
}

function setSelectedBusinessUnit(state, action) {
    let toolbarFilters = state.toolbarFilters;
    toolbarFilters.selectedBusinessUnitGroup = action.record;
    return { ...state, toolbarFilters }
}
function setSelectedBusinessUnits(state, action) {
    let toolbarFilters = { ...state.toolbarFilters };
    toolbarFilters.selectedBusinessUnits = action.records;
    return { ...state, toolbarFilters }
}
function setSelectedMainBusinessUnit(state, action) {
    let toolbarFilters = { ...state.toolbarFilters };
    toolbarFilters.selectedMainBusinessUnit = action.record;
    return { ...state, toolbarFilters }
}

function setSelectedUnitsListGrouper(state, action) {
    let toolbarFilters = state.toolbarFilters;
    toolbarFilters.listGrouper.units = action.value;
    return { ...state, toolbarFilters }
}

function setBusinessUnitGroup(state, action) {
    let toolbarFilters = state.toolbarFilters;
    toolbarFilters.businessUnitGroup = action.value
    return { ...state, toolbarFilters }
}

function setCurrentView(state, action) {
    return { ...state, currentView: action.view }
}


function applyOrderFilters(state, action) {
    const orderFilters = action.filters
    return { ...state, orderFilters }
}
function applyShipmentFilters(state, action) {
    const shipmentFilters = action.filters
    return { ...state, shipmentFilters }
}

function setCountCargoShipmentOrders(state, action) {
    const countCargoShipmentOrders = action.countCargoShipmentOrders
    return { ...state, countCargoShipmentOrders }
}

function setIndex(state, action) {
    const pagination = { ...state.pagination, index: action.index };
    return { ...state, pagination };
}
function setCount(state, action) {
    const pagination = { ...state.pagination, count: action.count };
    return { ...state, pagination };
}
function setMax(state, action) {
    const pagination = { ...state.pagination, max: action.max };
    return { ...state, pagination };
}
function setPage(state, action) {
    const pagination = { ...state.pagination, page: action.page };
    return { ...state, pagination };
}
function setOrders(state, action) {
    return { ...state, orders: action.orders || [] };
}

function addOrders(state, action) {
    const orders = [...state.orders, ...action.orders]
    return { ...state, orders };
}

function setOrder(state, action) {
    return { ...state, order: action.order };
}

function setCountOrders(state, action) {
    const countOrders = action.countOrders
    return { ...state, countOrders }
}

function setNumberFilter(state, action) {
    let toolbarFilters = state.toolbarFilters
    toolbarFilters[action.field] = action.value
    return { ...state, toolbarFilters }
}


function setGroupFilter(state, action) {
    let toolbarFilters = state.toolbarFilters;
    toolbarFilters[action.field] = action.value;
    return { ...state, toolbarFilters }
}

function setVisibleModalGrouperFilter(state, action) {
    let toolbarFilters = state.toolbarFilters;
    toolbarFilters.modalGrouperVisible = action.visible;
    return { ...state, toolbarFilters }
}

function setVisibleModalSearchBusinessUnitFilter(state, action) {
    let toolbarFilters = state.toolbarFilters;
    toolbarFilters.modalSearchBusinessUnitVisible = action.visible;
    return { ...state, toolbarFilters }
}

function setInitialDateFilter(state, action) {
    let toolbarFilters = state.toolbarFilters
    toolbarFilters.initialDate = action.initialDateFilter
    return { ...state, toolbarFilters }
}

function setFinalDateFilter(state, action) {
    let toolbarFilters = state.toolbarFilters
    toolbarFilters.finalDate = action.finalDateFilter
    return { ...state, toolbarFilters }
}

function setCarrierFilter(state, action) {
    let toolbarFilters = state.toolbarFilters
    toolbarFilters.carrier = action.carrier
    return { ...state, toolbarFilters }
}

function setLookupCarrierVisible(state, action) {
    let lookupCarrier = state.toolbarFilters.lookupCarrier
    lookupCarrier.visible = action.visible
    return { ...state, lookupCarrier }
}

function setOriginFilter(state, action) {
    let toolbarFilters = state.toolbarFilters
    toolbarFilters.origin = action.origin
    return { ...state, toolbarFilters }
}

function setLookupOriginVisible(state, action) {
    let lookupOrigin = state.toolbarFilters.lookupOrigin
    lookupOrigin.visible = action.visible
    return { ...state, lookupOrigin }
}

function setDestinationFilter(state, action) {
    let toolbarFilters = state.toolbarFilters
    toolbarFilters.destination = action.destination
    return { ...state, toolbarFilters }
}

function setLookupDestinationVisible(state, action) {
    let lookupDestination = state.toolbarFilters.lookupDestination
    lookupDestination.visible = action.visible
    return { ...state, lookupDestination }
}


function setSelectedOrders(state, action) {
    let selectedOrders = [...action.orders]
    return { ...state, selectedOrders }
}

function setShipments(state, action) {
    return { ...state, shipments: action.shipments || [] };
}

function setShipment(state, action) {
    return { ...state, shipment: action.shipment };
}

function addShipments(state, action) {
    const shipments = [...state.shipments, ...action.shipments]
    return { ...state, shipments };
}


function setSelectedShipments(state, action) {

    let selectedShipments = [...action.shipments]
    return { ...state, selectedShipments }
}


function hasCargoShipmentPendences(state, action) {

    let type = action.pendenceType || "EMAIL"
    let hasPendence = false;
    const listShipments = state.shipments;
    listShipments.forEach((shipment) => {
        if (shipment.pendencies && shipment.pendencies.length > 0) {
            shipment.pendencies.forEach(pendence => {
                if (pendence.type === type && pendence.status === "OPEN") {
                    hasPendence = true;
                }
            });
        }
    });
    if (type === "EMAIL") {
        return { ...state, pendenceEmail: hasPendence }
    } else if (type === "QUOTE") {
        return { ...state, pendenceQuote: hasPendence }
    } else if (type === "CONFIRM_SHIPMENT_WEBHOOK") {
        return { ...state, pendenceConfirmeShipmentWebhook: hasPendence }
    }

}

function setPendenceEmail(state, action) {
    return { ...state, pendenceEmail: action.value }
}

function setPendenceQuote(state, action) {
    return { ...state, pendenceQuote: action.value }
}

function setPendenceConfirmShipmentWebhook(state, action) {
    return { ...state, pendenceConfirmeShipmentWebhook: action.value }
}