
import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../portalCarrier.actions';
import { Page } from "../../../../../components/page/Page";
import Toolbar from '../../../../../components/toolbar/Toolbar';
import { routes } from '../../../../../constants';
import { Column } from 'primereact/column';
import { CargoShipmentInStatusColetaList } from './cargoShipmentInStatusColetaList';
import { numberToCurrency } from '../../../../../utils';
import storage from '../../../../../services/stoage.service'
import { AppService, COMPANY_CHANGE_EVENT_NAME } from '../../../../../services/app.service';
import { CARGO_IN_COLETA } from '../../../constantes';
import moment from 'moment';
import { ButtonDialog } from '../../../../../components/button-dialog/ButtonDialog';

const mapStateToProps = state => {
  const records = state.crudPortalCarrierState.cargoShipStatusInOrderColeta;

  let columns = [];

  const user = storage.getUser();

  if (!user.carrier) {
    columns = [...state.crudPortalCarrierState.columnsShipStatusInOrderColeta, {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.name",
      sortable: false,
      filter: false
    }]
  } else {
    columns = [...state.crudPortalCarrierState.columnsShipStatusInOrderColeta]
  }

  return {
    records,
    columns,
    //devera ser limpo a cada troca de tela
    first: state.crudPortalCarrierState.index,
    rows: state.crudPortalCarrierState.max,
    totalRecords: state.crudPortalCarrierState.count,
    sortField: state.crudPortalCarrierState.sortField,
    order: state.crudPortalCarrierState.sortOrder,

  }
}

const mapDispatchToProps = dispatch => {
  return {

    paginate: paginationParams => {
      if (paginationParams.index !== undefined) {
        dispatch(actions.setIndex(paginationParams.index));
      }
      if (paginationParams.page !== undefined) {
        dispatch(actions.setPage(paginationParams.page));
      }
      if (paginationParams.max !== undefined) {
        dispatch(actions.setMax(paginationParams.max));
      } 
    },
    cleanPaginate: () => {
      dispatch(actions.setIndex(0));
      dispatch(actions.setPage(0));
      dispatch(actions.setMax(50)); 
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    getAllInCollectionOrder: () => dispatch(actions.getAllInCollectionOrder()),


    setCurrentScreen: (currentScreen) => {
      dispatch(actions.setCurrentScreen(currentScreen));
    },

    changeStatusCargoShipmentToTransito: (oid) => dispatch(actions.changeStatusCargoShipmentToTransito(oid)),
    openDialogIframeCollectionOrder: (visible, link) => {
      dispatch(actions.openDialogIframeCollectionOrder({ visible, link }));
    },
  }
}

class CargoShipmentInStatusColetaPageContainer extends React.Component {

  constructor(props) {
    super(props);

  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.setCurrentScreen(CARGO_IN_COLETA);
    this.props.getAllInCollectionOrder();
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.cleanPaginate();
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME) {
      this.props.setCurrentScreen(CARGO_IN_COLETA);
      this.props.getAllInCollectionOrder();
    }
  };
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}`)
  }
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
    this.props.getAllInCollectionOrder();
  }

  render() {

    const header = (
      <div>
        <h1>Embarques em coletas</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onSearch={this.searchHandler} />
        </div>
      </div>
    );

    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).format("DD/MM/YYYY");
      return <div style={{ textAlign: "center", color: "black" }}>{formattedDate}</div>;
    };
    
    const agTemplate = (rowData, column) => {
      let situacao = "";
      if (rowData.isAg){
        situacao="AG"
      } 
      return <div style={{ textAlign: "center", color: "black" }}>{situacao}</div>;
    };

    const docMotTemplate = (rowData, column) => {
      let documentMot = rowData[column.field];      
      return <div style={{ textAlign: "center", overflow:"hidden" }} title={documentMot}>{documentMot}</div>;
    };

    const valorCustoTemplate = (rowData, column) => {
      try {
        const valor = Number(rowData[column.field]);
        let formattedDate = "";
        if (valor) {
          formattedDate = numberToCurrency(valor, "R$");
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {formattedDate}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const btnToTransitoTemplate = (rowData, column) => {
      try {
        const isChangeStatus = rowData.isChangeStatus
        let titulo="Altere o status do embarque para em trânsito, obrigado!"
        if (!isChangeStatus){
          titulo= `Ainda não chegou todas as Nfe do embarque`
        }
        return (
          <div style={{ textAlign: "center" }}>
            <ButtonDialog
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.changeStatusCargoShipmentToTransito(rowData.oid)
                //  
              }}
              button={{
                title: titulo,
                className: "lognex-btn-highlighted",
                iconClassName: "pi pi-check",
                disabled:!isChangeStatus
              }}
              dialog={{
                header: "Confirmação",
                modal: false,
                message: "Deseja realmente alterar o status do embarque para em Trânsito ?",
              }}
            ></ButtonDialog>
 
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const btnAceiteTemplate = (rowData, column) => {
      try {

        return (
          <div style={{ textAlign: "center" }}>
            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.openDialogIframeCollectionOrder(true, rowData.link);
                // 
                this.props.history.push(`${routes.PATH_CARRIER_PORTAL_COLLECTION_ORDER}`)
              }}

              title={"Abrir pagina para dar o aceite"}
            >
              <i className="pi pi-pencil"></i>
            </button>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const columns = this.props.columns.map((col) => {
      if (col.field === "freightValue") {
        return <Column {...col} key={col.field} style={{ width: "120px" }} body={valorCustoTemplate} />;
      }
      if (col.field === "oid") {
        return <Column {...col} key={col.field} style={{ width: "70px" }} />;
      }

      if (col.field === "btnToTransito") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} body={btnToTransitoTemplate} />;
      }

      if (col.field === "pickupDate") {
        return <Column {...col} key={col.field} style={{ width: "110px" }} body={dateTemplate} />;
      }
      if (col.field === "deliveryForeCastDate") {
        return <Column {...col} key={col.field} style={{ width: "110px" }} body={dateTemplate} />;
      }
      if (col.field === "isAg") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} body={agTemplate} />;
      }

      if (col.field === "status") {
        return <Column {...col} key={col.field} style={{ width: "85px" }} />;
      }

      if (col.field === "plate") {
        return <Column {...col} key={col.field} style={{ width: "85px" }} />;
      }
      if (col.field === "driverDocumentNumber") {
        return <Column {...col} key={col.field} style={{ width: "120px" }}  body={docMotTemplate}  />;
      }
      if (col.field === "collectionOrder.vehicleType") {
        return <Column {...col} key={col.field} style={{ width: "130px" }} />;
      }
      if (col.field === "collectionOrder.origin.name") {
        return <Column {...col} key={col.field} style={{ width: "160px" }} />;
      }
      if (col.field === "collectionOrder.destination.name") {
        return <Column {...col} key={col.field} style={{ width: "160px" }} />;
      }
      if (col.field === "darAceite") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} body={btnAceiteTemplate} />;
      }

      return <Column {...col} key={col.field} />;
    });

    const content = (
      <div>
        <h3 style={{ marginLeft: "5px" }}>Altere os embarque que já foram carregados para em trânsito</h3>
        <CargoShipmentInStatusColetaList
          records={this.props.records}
          onPage={this.onPage} 
          first={this.props.first}
          rows={this.props.rows}
          totalRecords={this.props.totalRecords}
          sortField={this.props.sortField}
          order={this.props.order}
          columns={columns} />
      </div>
    );

    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CargoShipmentInStatusColetaPageContainer);