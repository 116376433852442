import React from "react";
import { DataTable } from "primereact/datatable";

export const RouteRowTreeview = (props) => (
  <DataTable selectionMode="multiple" 
   onRowClick={props.rowClickHandler}
   value={props.records}
   emptyMessage="Não foram encontrados registros"
   onSelectionChange={props.onSelectionChange}
   selection={props.selectedOrdersDivideRoute}
   >
    {props.columns}
  </DataTable>
);
