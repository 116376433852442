
import { validate } from './validations';
import { build } from '../../../services/crudFactory';
import { Action } from '../../../services/actions.factory';
import Api from '../../../services/api.service';

const BASE_PATH = '/lognex/cargo-shipment';
const prefix = 'crudCargoShipment';


const crud = build(validate, BASE_PATH);

const newTypes = {
    LOOKUP_CARRIER_SET_VISIBLE: `${prefix}setLookupCarrierVisible`,
    LOOKUP_DRIVER_SET_VISIBLE: `${prefix}setLookupDriverVisible`,
    LOOKUP_VEHICLE_TYPE_SET_VISIBLE: `${prefix}setLookupVehicleTypeVisible`,
    SET_SELECTED_ORDERS: `${prefix}setSelectedOrders`,
    REMOVE_SELECTED_ORDERS: `${prefix}removeSelectedOrders`,
    LOOKUP_ORDER_SET_VISIBLE: `${prefix}setLookupOrderVisible`,
    LOOKUP_ORDER_SET_INDEX: `${prefix}setLookupOrderIndex`,
    LOOKUP_ORDER_SET_MAX: `${prefix}setLookupOrderMax`,
    LOOKUP_ORDER_SET_COUNT: `${prefix}setLookupOrderCount`,
    LOOKUP_ORDER_SET_SORT_FIELD: `${prefix}setLookupOrderSortField`,
    LOOKUP_ORDER_SET_SORT_ORDER: `${prefix}setLookupOrderSortOrder`,
    SET_ORDER_FILTERS: `${prefix}setOrderFilters`,
    SET_ORDERS: `${prefix}setOrders`,
    ADD_ORDERS: `${prefix}addOrders`,
    FETCH_ORDERS: `${prefix}fetchOrders`,
    SET_CARD_VALUES: `${prefix}calcCards`,
    SET_CARGO_SHIPMENT: `${prefix}setCargoShipment`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.loadQuotes = (quotes) => {
    return {
        type: types.LOAD_QUOTES,
        quotes
    }
}
actions.lookupCarrierSetVisible = (visible) => {
    return {
        type: types.LOOKUP_CARRIER_SET_VISIBLE,
        visible
    }
}
actions.lookupDriverSetVisible = (visible) => {
    return {
        type: types.LOOKUP_DRIVER_SET_VISIBLE,
        visible
    }
}
actions.lookupVehicleTypeSetVisible = (visible) => {
    return {
        type: types.LOOKUP_VEHICLE_TYPE_SET_VISIBLE,
        visible
    }
}
actions.setSelectedOrders = (orders) => {
    return {
        type: types.SET_SELECTED_ORDERS,
        orders
    }
}

actions.removeSelectedOrders = () => {
    return {
        type: types.REMOVE_SELECTED_ORDERS,
    }
}

actions.setLookupOrderVisible = (visible) => {
    return {
        type: types.LOOKUP_ORDER_SET_VISIBLE,
        visible
    }
}

actions.setLookupOrderIndex = (index) => {
    return {
        type: types.LOOKUP_ORDER_SET_INDEX,
        index
    }
}

actions.setLookupOrderMax = (max) => {
    return {
        type: types.LOOKUP_ORDER_SET_MAX,
        max
    }
}
actions.setLookupOrderCount = (count) => {
    return {
        type: types.LOOKUP_ORDER_SET_COUNT,
        count
    }
}
actions.setLookupOrderSortField = (sortField) => {
    return {
        type: types.LOOKUP_ORDER_SET_SORT_FIELD,
        sortField
    }
}
actions.setLookupOrderSortOrder = (sortOrder) => {
    return {
        type: types.LOOKUP_ORDER_SET_SORT_ORDER,
        sortOrder
    }
}
actions.setOrderFilters = (filters) => {
    return {
        type: types.SET_ORDER_FILTERS,
        filters
    }
}
actions.setOrders = (orders) => {
    return {
        type: types.SET_ORDERS,
        async: false,
        orders
    }
}
actions.addOrders = (orders) => {
    return {
        type: types.ADD_ORDERS,
        orders
    }
}

actions.fetchOrders = () => {
    return {
        type: types.FETCH_ORDERS,
        async: true
    }
}

actions.setCargoShipment = (cargoShipment) => {
    return {
        type: types.SET_CARGO_SHIPMENT,
        cargoShipment
    }
}

actions.loadOrders = () => {
    return (dispatch, getState) => {
        const state = getState()[`${prefix}State`];
        const filters = state.orderFilters || []
        let sortFields = [];

        const params = {
            index: state.lookupOrder.index,
            max: state.lookupOrder.max,
            permissions: null,
            _filters: filters,
            get filters() {
                return this._filters;
            },
            set filters(value) {
                this._filters = value;
            },
            sortFields
        };
        dispatch(actions.fetchOrders());
        Api.post('/api/v1/lognex/shipment-order/all', params)
            .then((response) => {
                const data = response.data || {};
                dispatch(actions.setLookupOrderIndex(data.index || 0));
                dispatch(actions.setLookupOrderMax(data.size || 0));
                dispatch(actions.setLookupOrderCount(data.count || 0));
                dispatch(actions.setOrders(data.records || []));
            })
            .catch((error) => {
                dispatch(actions.setOrders([]))
            })
    }
}

actions.calcCards = () => {
    return {
        type: types.SET_CARD_VALUES,
    }
}


actions.loadCargoShipmentById = (oid) => {
    return (dispatch, getState) => {
        Api.post(`/api/v1/lognex/cargo-shipment/${oid}`)
            .then((response) => {
                const data = response.data || {}; 
                dispatch(actions.setCargoShipment(data))
                dispatch(actions.calcCards());
            })
            .catch((error) => {
                console.log(error)
                dispatch(actions.setOrders([]))
                dispatch(actions.calcCards());
            })
    }
}



export { types, actions }