import React from 'react';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CardAcess from '../../../components/dashboard/CardAccess';
import CardChart from '../../../components/dashboard/CardChart'
import CardIndicator from '../../../components/dashboard/CardIndicator';
import NeuralRoutes from '../../../components/NeuralRoutes/NeuralRoutes'
import LineChart from '../../../components/charts/line-chart/lineChart'
import { GridBox } from "../../../components/grid-box/GridBox";
import { TextSelector } from "../../../components/text-selector/TextSelector";
import  calendar from '../../../assets/images/icons/calendar_blue.png';
import  truck from '../../../assets/images/icons/truck_blue.png';
import './Dashboard.scss';
import { IndicatorWithCarrierAndPeriod } from './IndicatorWithCarrierAndPeriod';
import { IndicatorWithLocationAndPeriod } from './IndicatorWithLocationAndPeriod';

class Dashboard extends React.Component {
    
    render() {

        const { props } = this;

        const favoriteCards = props.dashboardParams && !props.dashboardParams.favoriteRecords ? [] : props.dashboardParams.favoriteRecords.filter(e=>e!=null).map((favorite,index) => {
            return <CardAcess key={index} icon={favorite.iconName} onClick={favorite.command} title={favorite.title} isFavorite={true} ></CardAcess>
        })


        const footerIndicator = (
            <div>
                <TextSelector 
                    icon={truck} 
                    text={props.dashboardParams.efficiency.carrier} 
                    onActivate={props.dashboardParams.efficiency.btnCarrierOnClick}
                />
                <TextSelector 
                    icon={calendar} 
                    text={props.dashboardParams.efficiency.period} 
                    onActivate={props.dashboardParams.efficiency.btnPeriodoOnClick}
                />
            </div>
        )

        const efficiency = (
            <IndicatorWithCarrierAndPeriod
            carrier={props.dashboardParams.efficiency.carrier}
            onCarrierClick={props.dashboardParams.efficiency.btnCarrierOnClick}
            period={props.dashboardParams.efficiency.period}
            onPeriodClick={props.dashboardParams.efficiency.btnPeriodoOnClick}
            title={props.dashboardParams.efficiency.title}
            value={props.dashboardParams.efficiency.value}
            lookup={props.dashboardParams.efficiency.lookup} 
        />
        )

        const divergence = (
            <IndicatorWithCarrierAndPeriod
            carrier={props.dashboardParams.divergence.carrier}
            onCarrierClick={props.dashboardParams.divergence.btnCarrierOnClick}
            period={props.dashboardParams.divergence.period}
            onPeriodClick={props.dashboardParams.divergence.btnPeriodoOnClick}
            title={props.dashboardParams.divergence.title}
            value={props.dashboardParams.divergence.value}
            lookup={props.dashboardParams.divergence.lookup}
        />
        )
        
        const relationWeightByFreight = (
            <IndicatorWithCarrierAndPeriod
                carrier={props.dashboardParams.relationWeightByFreight.carrier}
                onCarrierClick={props.dashboardParams.relationWeightByFreight.btnCarrierOnClick}
                period={props.dashboardParams.relationWeightByFreight.period}
                onPeriodClick={props.dashboardParams.relationWeightByFreight.btnPeriodoOnClick}
                title={props.dashboardParams.relationWeightByFreight.title}
                value={props.dashboardParams.relationWeightByFreight.value}
                lookup={props.dashboardParams.relationWeightByFreight.lookup}
            />
        )

        const percentFreightOnBill = (
            <IndicatorWithCarrierAndPeriod
                carrier={props.dashboardParams.percentFreightOnBill.carrier}
                onCarrierClick={props.dashboardParams.percentFreightOnBill.btnCarrierOnClick}
                period={props.dashboardParams.percentFreightOnBill.period}
                onPeriodClick={props.dashboardParams.percentFreightOnBill.btnPeriodoOnClick}
                title={props.dashboardParams.percentFreightOnBill.title}
                value={props.dashboardParams.percentFreightOnBill.value}
                lookup={props.dashboardParams.percentFreightOnBill.lookup}
            />
        )
              
        const freightValueByLocation = (
            <IndicatorWithLocationAndPeriod
                location={props.dashboardParams.freightValueByLocation.location}
                onLocationClick={props.dashboardParams.freightValueByLocation.btnLocationFreightValueOnClick}
                period={props.dashboardParams.freightValueByLocation.period}
                onPeriodClick={props.dashboardParams.freightValueByLocation.btnPeriodFreightValueOnClick}
                title={props.dashboardParams.freightValueByLocation.title}
                value={props.dashboardParams.freightValueByLocation.value}
                lookup={props.dashboardParams.freightValueByLocation.lookup}
            />
        )


        return (
            <div>
                   
                <div className="p-grid lx-dashboard">
                   
                     <div className="p-grid lx-dashboard__panel">
                        <div className="lx-dashboard__panel-cards">
                           
                            {props.dashboardParams.efficiency.visible && efficiency}  
                            {props.dashboardParams.divergence.visible && divergence}      
                            {props.dashboardParams.percentFreightOnBill.visible && percentFreightOnBill}      
                            {props.dashboardParams.relationWeightByFreight.visible && relationWeightByFreight} 
                            {props.dashboardParams.freightValueByLocation.visible && freightValueByLocation}
                       
                        </div>
                    </div>
                    
                    <div className="p-grid lx-dashboard__panel">
                        <div className="lx-dashboard__panel-header">
                            Favoritos
                    </div>
                        <div className="lx-dashboard__panel-cards">
                            {favoriteCards}
                        </div>
                    </div>
                   
                    <NeuralRoutes></NeuralRoutes>
                </div >


            </div>

        )
    }
};

export default Dashboard;