import { validate } from "./validations";
import { Action } from "../../services/actions.factory";
import { build } from "../../services/crudFactory";
import api from "../../services/api.service";
const BASE_PATH = "/lognex/shipment-order";

const prefix = "crudOrderDelivery";
const crud = build(validate, BASE_PATH);

const newTypes = {
  SET_BUSINESS_UNIT_FILTER: `${prefix}setBusinessUnitFilter`,
  SET_BUSINESS_UNIT_GROUP_FILTER: `${prefix}setBusinessUnitGroupFilter`,
  SET_VISIBLE_MODAL_SEARCH_FILTER: `${prefix}setVisibleModalSearchBusinessUnitFilter`,
  SET_VISIBLE_MODAL_WIZARD_FILTER: `${prefix}setVisibleModalGrouperFilter`,
  SET_SELECTED_BUSINESS_UNIT_RECORD: `${prefix}setSelectedBusinessUnit`,
  SET_SELECTED_BUSINESS_UNITS: `${prefix}setSelectedBusinessUnits`,
  SET_SELECTED_MAIN_BUSINESS_UNIT: `${prefix}setSelectMainBusinessUnit`,
  SET_SELECTED_BUSINESS_UNIT_GROUP_LABEL: `${prefix}setSelectedBusinessUnitGroupLabel`,
  SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
  SET_CARRIER_FILTER: `${prefix}setCarrierFilter`,
  SET_LOOKUP_DESTINATION_VISIBLE: `${prefix}setLookupDestinationVisible`,
  SET_DESTINATION_FILTER: `${prefix}setDestinationFilter`,
  SET_LOOKUP_ORIGIN_VISIBLE: `${prefix}setLookupOriginVisible`,
  SET_ORIGIN_FILTER: `${prefix}setOriginFilter`,
  SET_INITIAL_DATE_FILTER: `${prefix}setInitialDateFilter`,
  SET_FINAL_DATE_FILTER: `${prefix}setFinalDateFilter`,
  SET_NUMBER_FILTER: `${prefix}setNumberFilter`,
  SET_EXPANDED_PRODUCT_ROW: `${prefix}setExpandedProductRows`,
  DOWNLOAD_CSV_DELIVERYS: `${prefix}downloadDelivery`,
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.downloadDelivery = (filters) => {
  return (dispatch, getState) => {
    //  const state = getState().crudOrderDeliveryState
    //
    dispatch(actions.showLoader());
    
    api
      .post("/api/v1/lognex/shipment-order/export/delivery", { filters })
      .then((response) => {
        const data = response.data;
        // dispatch(actions.load());
        
        const anchor = document.createElement("a");
        anchor.href =data.presigned_url;
        anchor.target = "_blank";         
        document.body.append(anchor);
        anchor.click();
        anchor.remove();
        
      }).finally(()=>{
        dispatch(actions.hideLoader());
      })
      .catch((err) => {
        console.log(err); 
        dispatch(actions.hideLoader());
      });
  };
};

actions.setExpandedProductRows = (productRowSelected) => {
  return {
    type: types.SET_EXPANDED_PRODUCT_ROW,
    productRowSelected,
  };
};

actions.setOrders = (orders) => {
  return {
    type: types.SET_ORDERS,
    orders,
  };
};

actions.setNumberFilter = (field, value) => {
  return {
    type: types.SET_NUMBER_FILTER,
    field,
    value,
  };
};

actions.setInitialDateFilter = (initialDateFilter) => {
  return {
    type: types.SET_INITIAL_DATE_FILTER,
    initialDateFilter,
  };
};

actions.setFinalDateFilter = (finalDateFilter) => {
  return {
    type: types.SET_FINAL_DATE_FILTER,
    finalDateFilter,
  };
};

actions.setOriginFilter = (origin) => {
  return {
    type: types.SET_ORIGIN_FILTER,
    origin,
  };
};

actions.setLookupOriginVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_ORIGIN_VISIBLE,
    visible,
  };
};

actions.setDestinationFilter = (destination) => {
  return {
    type: types.SET_DESTINATION_FILTER,
    destination,
  };
};

actions.setLookupDestinationVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_DESTINATION_VISIBLE,
    visible,
  };
};
actions.setCarrierFilter = (carrier) => {
  return {
    type: types.SET_CARRIER_FILTER,
    carrier,
  };
};
actions.setLookupCarrierVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CARRIER_VISIBLE,
    visible,
  };
};

actions.setSelectedBusinessUnitGroupLabel = (value) => {
  return {
    type: types.SET_SELECTED_BUSINESS_UNIT_GROUP_LABEL,
    value,
  };
};

actions.setBusinessUnitFilter = (currentBusinessUnit) => {
  return {
    type: types.SET_BUSINESS_UNIT_FILTER,
    currentBusinessUnit,
  };
};

actions.setSelectedBusinessUnits = (records) => {
  return {
    type: types.SET_SELECTED_BUSINESS_UNITS,
    records,
  };
};
actions.setSelectMainBusinessUnit = (record) => {
  return {
    type: types.SET_SELECTED_MAIN_BUSINESS_UNIT,
    record,
  };
};

actions.setSelectedBusinessUnit = (record) => {
  return {
    type: types.SET_SELECTED_BUSINESS_UNIT_RECORD,
    record,
  };
};

actions.setVisibleModalGrouperFilter = (visible) => {
  return {
    type: types.SET_VISIBLE_MODAL_WIZARD_FILTER,
    visible,
  };
};

actions.setBusinessUnitFilter = (currentBusinessUnit) => {
  return {
    type: types.SET_BUSINESS_UNIT_FILTER,
    currentBusinessUnit,
  };
};

actions.setBusinessUnitGroupFilter = (businessUnitGroup) => {
  return {
    type: types.SET_BUSINESS_UNIT_GROUP_FILTER,
    businessUnitGroup,
  };
};

actions.setVisibleModalSearchBusinessUnitFilter = (visible) => {
  return {
    type: types.SET_VISIBLE_MODAL_SEARCH_FILTER,
    visible,
  };
};

export { types, actions };
