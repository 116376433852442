import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";
import { makeMessages, messageActions } from "../../store/message";

const BASE_PATH = "/lognex/towertravel";
const prefix = "crudTowerTravelVisualizeMap";
const crud = build(validate, BASE_PATH);

const newTypes = {
  SET_FILTER_MAP: `${prefix}setFilterMap`,
  SET_DELIVERY_DATETIME: `${prefix}deliveryDateChangeHandler`,
  SET_DELIVERY_DATE_DIALOG_VISIBLE: `${prefix}changeDialogDeliveryDateVisible`,
  SET_TRAVEL_CHANGE_DELIVERUDATE: `${prefix}setTavelDeliveryDate`,
  FINDALL_BUSINESS_UNITS: `${prefix}findAllBusinessunits`,
  SET_ALL_BUSINESUNITS: `${prefix}setAllBusinessunits`,
  SET_ALL_TRAVELS: `${prefix}setTravels`,
  GET_ALL_TRAVELS: `${prefix}getAllTravels`,
  GET_ALL_TRAVELS_PORTAL: `${prefix}getAllTravelsPortal`, 
  SET_TOTALIZADORES_PORTAL: `${prefix}setTotalizadoresPortal`,
  LOAD_TOTALIZADORES_PORTAL: `${prefix}loadTotalizersPortal`, 
  SET_SELECTEC_TYPE_FILTER: `${prefix}setSelectedTypeFilter`,   


};
const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

export { types, actions };
actions.setSelectedTypeFilter = (selectedTypeFilter) => {
  return {
    type: types.SET_SELECTEC_TYPE_FILTER,
    selectedTypeFilter,
  };
};
 

actions.setTotalizadoresPortal = (totalizers) => {
  return {
    type: types.SET_TOTALIZADORES_PORTAL,
    totalizers,
  };
};



actions.setAllBusinessunits = (records) => {
  return {
    type: types.SET_ALL_BUSINESUNITS,
    records,
  };
};
actions.setTavelDeliveryDate = (travelData) => {
  return {
    type: types.SET_TRAVEL_CHANGE_DELIVERUDATE,
    travelData,
  };
};

actions.deliveryDateChangeHandler = (dateTime) => {
  return {
    type: types.SET_DELIVERY_DATETIME,
    dateTime,
  };
};

actions.changeDialogDeliveryDateVisible = (visible) => {
  return {
    type: types.SET_DELIVERY_DATE_DIALOG_VISIBLE,
    visible,
  };
};
actions.onConfirmInputDeliveryDate = () => {
  return (dispatch, getState) => {
    const state = getState().crudTowerTravelVisualizeMapState;
    const deliveryDate = state.deliveryChange.delaveryDatetime;
    const oid = state.deliveryChange.oid;
    const destinOid = state.deliveryChange.destinOid;

    if (deliveryDate && oid && destinOid) {
      const params = { deliveryDate: deliveryDate, oid: oid, destinOid };

      api
        .post("/api/v1/lognex/towertravel/saveDeliveryDate", params)
        .then((result) => {
          dispatch(actions.deliveryDateChangeHandler(null));
          dispatch(actions.changeDialogDeliveryDateVisible(false));
          dispatch(messageActions.messageShowMessages(makeMessages(["Registro realizado com sucesso"], "success")));
        })
        .catch((err) => {
          dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível atualizar o registro"], "error")));
        });
    } else {
      dispatch(messageActions.messageShowMessages(makeMessages(["Informe a data da entrega!"], "error")));
    }
  };
};
actions.findAllBusinessunits = () => {

  return (dispatch, getState) => {
    api.post("/api/v1/lognex/businessunits/findAllbusinessunits").then(({ data }) => {
      dispatch(actions.setAllBusinessunits(data));
    }).catch((err) => {
      console.log(err)
    });

  };
};

actions.setTravels = (travels) => {
  return {
    type: types.SET_ALL_TRAVELS,
    travels,
  };
};


actions.getAllTravels = (filters) => {
  return (dispatch, getState) => {
    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/towertravel/getAll", { filters })
      .then((result) => {
        dispatch(actions.setTravels(result.data));
        dispatch(actions.hideLoader());
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.setTravels([]));
        dispatch(actions.hideLoader());
      });
  };
};

 
actions.loadTotalizersPortal = (filters) => {
  return (dispatch, getState) => {
    api
      .post("/api/v1/lognex/towertravel/findTotalizers", { filters })
      .then((totalizers) => {
        dispatch(actions.setTotalizadoresPortal(totalizers.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.setTotalizadoresPortal(null));
      });
  };
};

actions.getAllTravelsPortal = (filters) => {
  return (dispatch, getState) => {
    api
      .post("/api/v1/lognex/towertravel/getAll", { filters })
      .then((result) => {
        dispatch(actions.setTravels(result.data));
        // dispatch(actions.hideLoader());
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.setTravels([]));
        // dispatch(actions.hideLoader());
      });
  };
};