import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const TableRoutesList = (props) => (
  <DataTable
    selectionMode="multiple"
    value={props.records}
    rowExpansionTemplate={props.templateExpandedRow}
    expandedRows={props.expandedRows}
    onRowToggle={props.rowExpandButtonOnClick}
    onRowClick={props.rowToggleHandler}
    // scrollable={true}
    // scrollHeight={"320px"}
    onSelectionChange={props.onSelectedEventsChange}
    selection={props.selectedRoutes}
  >
    {props.columns}
  </DataTable>
);
