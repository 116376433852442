import React from "react";
import { InputText } from "primereact/inputtext"; 

import "./patiomanagerdocaForm.scss"; 

export const PatioManagerDocaForm = ({ record, changeHandler,businessUnit }) => { 
 
  return (
    <div className="patio-dock-manager-form">
      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-3">
              <div className="form-field-container">
                <label>Código</label>
                <InputText value={record.oid} onChange={changeHandler} disabled={true} />
              </div>
            </div>
            <div className="p-col-3">
              <div className="form-field-container">
                <label>Unidade</label>
                <InputText value={record.sigla||""} onChange={(e)=>{}} disabled={true} />
              </div>
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Nome</label>
              <InputText value={record.name} onChange={changeHandler} name="name" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
