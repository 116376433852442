import { applyMiddleware, combineReducers,createStore } from "redux";
import ReduxThunk from 'redux-thunk';
import {loaderReducer, loaderMiddleware} from "./loader";
import {menuReducer} from "./menu";
import {messageReducer} from "./message";
import { loginReducer } from "../pages/login/login.reducer";
import { CRUDAcaoReducer} from '../pages/acao';
import { CRUDPessoaReducer } from "../pages/pessoa";
import { CRUDEmpresaReducer } from "../pages/empresa";
import { CRUDUsuarioReducer } from "../pages/usuario";
import { crudPerfilReducer } from "../pages/perfil";
import {CRUDModuloReducer} from "../pages/modulo";
import {CRUDPermissaoReducer} from "../pages/permissao";
import {CRUDUnidadeReducer} from "../pages/unidade";
import {appReducer} from './app';
import {CRUDMenuReducer} from '../pages/menu';
import {CRUDZipCodeRangeReducer} from '../pages/zipCodeRange';
import {CRUDCarrierReducer} from '../pages/carrier';
import {CRUDRegionReducer} from '../pages/region';
import {CRUDLocationReducer} from '../pages/location';
import {CRUDRouteReducer} from '../pages/route';
import {CRUDSegmentReducer} from '../pages/segment';
import {CRUDPalletStandardReducer} from '../pages/palletStandard';
import {CRUDPalletReducer} from '../pages/pallet';
import {CRUDPackingReducer} from '../pages/packing';
import {CRUDProductReducer} from '../pages/product';
import {CRUDModalReducer} from '../pages/modal';
import {CRUDVehicleReducer} from '../pages/vehicle';
import {CRUDEdiReducer} from '../pages/edi'
import { CRUDFreightComponentReducer } from "../pages/freightComponent";
import { CRUDFreightTableReducer } from "../pages/freightTable";
import { CRUDComponentRangeTypeReducer} from "../pages/componentRangeType";
import { CRUDVehicleGroupReducer} from "../pages/vehicleGroup";
import { CRUDVehicleTypeReducer} from "../pages/vehicleType";
import { CRUDImportFilesReducer} from "../pages/importfiles";
import { AuditReducer} from "../pages/audit";
import { FreightCalculationReducer} from "../pages/freightCalculation";
import { TrackingReducer} from "../pages/tracking";
import { CRUDOccurrenceTypeReducer} from "../pages/occurrenceType";
import { DashboardReducer} from "../pages/dashboard"
import { CRUDClientReducer} from "../pages/client";
import {reportAuditExpectedAndRealizedReducer} from "../reports";
import { CRUDDeliveryDeadlineReducer } from "../pages/deliveryDeadline";
import { AccountConfigurationReducer } from "../pages/accountConfiguration";
import { reducerCarrierEfficiency } from "./reports/carrierefficiency";
import { reducerDivergence } from "./reports/divergence";
import { reducerRelationWeightByFreight } from "./reports/relationweightbyfreight";
import { reducerPercentFreightOnBill } from  "./reports/percentfreightonbill";
import { reducerValueFreightByLocation } from "./reports/freightvaluebylocation";
import { reducerOrderLog } from "../pages/order-log";
import { reducerOrderManagement } from "../pages/order-management";
import { CRUDUserDocumentTypeReducer } from "../pages/userDocumentType";
import { CRUDPackingConverterReducer } from "../pages/packingConverter";
import { CRUDCargoShipmentReducer } from "../pages/order-management/cargo-shipment";
import { CRUDDriverReducer } from "../pages/driver";
import { CollectionOrderReducer } from "../pages/collectionOrder";
import { CRUDBusinessUnitGroupFilterReducer } from "../pages/businessUnitGroupFilter";
import { LogNFeReducer } from "../pages/log-nfe";
import { CRUDHistoryLogReducer } from "../pages/history-log-component";
import { CRUDOrderDeliveryReducer } from "../pages/detailDeliveryOrder";
import { CRUDTowerEventControlReducer } from "../pages/towerEventControl";
import { CRUDTowerTravelReducer } from "../pages/towerTravel";
import { CRUDTowerEquipmentReducer } from "../pages/towerEquipment";
import { CRUDTowerEquipmentTypeReducer } from "../pages/towerEquipmentType";
import { CRUDTowerTravelVisualizeMapReducer } from "../pages/towerTravelVisualizeMap";
import { CRUDTowerDashboardReducer } from "../pages/towerDashboard";
import { CRUDTowerObservationTypeReducer } from "../pages/towerObservationType";
import { AuditRecordsReducer } from "../pages/auditRecords"; 
import { CRUDDockmanagerReducer } from "../pages/patioManager";
import { CRUDPatioManagerDocaReducer } from "../pages/patioManagerDoca";
import { CRUDTransferRouteReducer} from "../pages/transferRoute"
import { CRUDScriptwriterReducer } from "../pages/scriptwriterMap";
import { CRUDPendenceDocReducer} from "../pages/pendenceDoc";
import { RastrearDeliveryReducer} from "../pages/rastrearDelivery";
import { CRUDPolicesReducer } from "../pages/polices";
import { CRUDTesteReducer } from "../pages/teste";
import { CRUDPolicesGroupReducer } from "../pages/policeGroup";
import { CRUDPolicesResourcesReducer } from "../pages/policeResources";
import { CRUDCfopReducer } from "../pages/cfop";
import { CRUDCargoShipmentReportReducer } from "../pages/cargoShipmentReport";
import { CRUDCampanhaAnymarketReducer } from "../pages/campanhaAnymarket";
import { CRUDHolidayReducer } from "../pages/holiday";
import {CRUDPortalCarrierReducer} from "../pages/portalCarrier";
import {CollectionOrderPortalReducer} from "../pages/collectionOrderPortalCarrier"
import {CRUDCargoShipmentReportKmReducer} from "../pages/cargoShipmentReportKm"

const reducers = combineReducers({
    testeState: CRUDTesteReducer,
    appState: appReducer,
    loaderState:loaderReducer,
    loginState:loginReducer,
    menuState:menuReducer,
    messageState:messageReducer,
    crudPessoaState: CRUDPessoaReducer,
    crudAcaoState : CRUDAcaoReducer,
    crudEmpresaState:CRUDEmpresaReducer,
    crudUsuarioState:CRUDUsuarioReducer,
    crudPerfilState:crudPerfilReducer,
    crudModuloState:CRUDModuloReducer,
    crudPermissaoState:CRUDPermissaoReducer,
    crudUnidadeState:CRUDUnidadeReducer,
    crudMenuState : CRUDMenuReducer,
    crudZipCodeRangeState : CRUDZipCodeRangeReducer,
    crudCarrierState : CRUDCarrierReducer,
    crudRegionState : CRUDRegionReducer,
    crudLocationState : CRUDLocationReducer,
    crudRouteState : CRUDRouteReducer,
    crudSegmentState : CRUDSegmentReducer,
    crudPalletStandardState : CRUDPalletStandardReducer,
    crudPalletState : CRUDPalletReducer,
    crudPackingState : CRUDPackingReducer,
    crudProductState : CRUDProductReducer,
    crudModalState : CRUDModalReducer,
    crudVehicleState : CRUDVehicleReducer,
    crudEdiState : CRUDEdiReducer,
    crudFreightComponentState:CRUDFreightComponentReducer,
    crudFreightTableState : CRUDFreightTableReducer,
    crudComponentRangeTypeState : CRUDComponentRangeTypeReducer,
    crudVehicleGroupState : CRUDVehicleGroupReducer,
    crudImportFilesState : CRUDImportFilesReducer,
    auditState : AuditReducer,
    freightCalculationState : FreightCalculationReducer,
    trackingState : TrackingReducer,
    crudOccurrenceTypeState: CRUDOccurrenceTypeReducer,
    dashboardState : DashboardReducer,
    crudClientState: CRUDClientReducer,
    reportAuditExpectedAndRealizedState:reportAuditExpectedAndRealizedReducer,
    crudDeliveryDeadlineState: CRUDDeliveryDeadlineReducer,
    crudAccountConfigurationState: AccountConfigurationReducer,
    carrierEfficiencyReportState: reducerCarrierEfficiency,
    divergenceReportState: reducerDivergence,
    relationWeightByFreightState: reducerRelationWeightByFreight,
    percentFreightOnBillReportState: reducerPercentFreightOnBill,
    freightValueByLocationState: reducerValueFreightByLocation,
    orderLogState : reducerOrderLog,
    crudVehicleTypeState: CRUDVehicleTypeReducer,
    orderManagementState:reducerOrderManagement,
    crudUserDocumentTypeState: CRUDUserDocumentTypeReducer,
    crudPackingConverterState: CRUDPackingConverterReducer,
    crudCargoShipmentState: CRUDCargoShipmentReducer,
    crudDriverState:CRUDDriverReducer,
    collectionOrderState: CollectionOrderReducer,
    crudBusinessUnitGroupState: CRUDBusinessUnitGroupFilterReducer,
    logNFeState: LogNFeReducer,
    crudHistorylogState:CRUDHistoryLogReducer,
    crudOrderDeliveryState :CRUDOrderDeliveryReducer,
    crudTowerTravelState:CRUDTowerTravelReducer,
    crudTowerEventControlState:CRUDTowerEventControlReducer,
    crudTowerEquipmentState:CRUDTowerEquipmentReducer,
    crudTowerEquipmentTypeState:CRUDTowerEquipmentTypeReducer,
    crudTowerTravelVisualizeMapState:CRUDTowerTravelVisualizeMapReducer,
    crudTowerDashboardState:CRUDTowerDashboardReducer,
    crudTowerObservationTypeState:CRUDTowerObservationTypeReducer,
    auditRecordsState:AuditRecordsReducer,
    crudDockmanagerState:CRUDDockmanagerReducer,
    crudPatioManagerDocaState:CRUDPatioManagerDocaReducer,
    crudTransferRouteState:CRUDTransferRouteReducer,
    crudScriptwriterState:CRUDScriptwriterReducer,
    crudPendenceDocState:CRUDPendenceDocReducer,
    rastrearDeliveryState:RastrearDeliveryReducer,
    crudPolicesState: CRUDPolicesReducer,
    crudPolicesGroupState: CRUDPolicesGroupReducer,
    crudPolicesResourcesState: CRUDPolicesResourcesReducer,
    crudCfopState:CRUDCfopReducer,
    crudCargoShipmentReportState:CRUDCargoShipmentReportReducer,
    crudCampanhaAnymarketState:CRUDCampanhaAnymarketReducer,
    crudHolidayState:CRUDHolidayReducer,
    crudPortalCarrierState:CRUDPortalCarrierReducer,
    crudCollectionOrderPortalState:CollectionOrderPortalReducer,
    crudCargoShipmentReportKmState:CRUDCargoShipmentReportKmReducer,
    
})

const store = createStore(reducers,applyMiddleware(ReduxThunk,loaderMiddleware));
export default store;