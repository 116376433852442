import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import moment from "moment";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { DockmanagerLeftComponet } from "../components/DockmanagerLeftComponet";
import { actions as accountConfigurationActions } from "../../accountConfiguration/accountConfiguration.actions";
import { actions as patioManagerDocksctions } from "../../patioManagerDoca/patiomanagerdoca.actions";
import { actions } from "../patiomanager.actions";
import { actions as actionsUnidades } from "../../unidade/unidade.actions";

import "./DockmanagerActionForm.scss";
import { Column } from "primereact/column";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { DialogEditSchedule } from "../components/DialogEditSchedule/DialogEditSchedule";
import { RenderInfoSchedule } from "../components/RenderInfoSchedule";
import { heightBoxSchedule } from "..";
import GrowlMessenger from "../../../components/growl/GrowlMessenger";

const mapStateToProps = (state) => {
  const { businessUnitConfigurations = [] } = state.crudAccountConfigurationState.currentRecord;
  let company = null;
  let businessUnit = null;
  if (state.appState.currentCompany && state.appState.currentCompany.CNPJ) {
    company = state.appState.currentCompany.CNPJ;
  }
  if (state.crudDockmanagerState.currentBusinessUnit && state.crudDockmanagerState.currentBusinessUnit.businessUnit) {
    businessUnit = state.crudDockmanagerState.currentBusinessUnit.businessUnit;
  }

  let docas = [];
  if (businessUnit) {
    docas = state.crudPatioManagerDocaState.records;
  }

  const businessUnits = state.crudUnidadeState.records;
  if(businessUnits && businessUnits.length >0){
    for (let index = 0; index < businessUnitConfigurations.length; index++) {
     const bunit = businessUnits.filter(b=>b.documentNumber === businessUnitConfigurations[index].businessUnit && b.code === businessUnitConfigurations[index].sigla)[0] 
      if(bunit){
        businessUnitConfigurations[index].label= bunit.label
      }else{
        let label = businessUnitConfigurations[index].businessUnit
        if(businessUnitConfigurations[index].sigla && String(businessUnitConfigurations[index].sigla).trim().length>0){
          label =  String(businessUnitConfigurations[index].sigla).trim()+" ("+businessUnitConfigurations[index].businessUnit+")";
        }
        businessUnitConfigurations[index].label= label
      }
    }
  }

  return {
    currentBusinessUnit: state.crudDockmanagerState.currentBusinessUnit,
    businessUnitConfigurations,
    selectedCarriers: state.crudDockmanagerState.selectedCarriers,
    dateSelected: state.crudDockmanagerState.dateSelected,
    docaWorkDayTime: state.crudDockmanagerState.docaWorkDayTime,
    docks: docas,
    schedulingRecords: state.crudDockmanagerState.schedulingRecords,
    scheduleEdit: state.crudDockmanagerState.scheduleEdit,
    visibleEditSchedule: state.crudDockmanagerState.visibleEditSchedule,
    horaSelected: state.crudDockmanagerState.horaSelected,
    dockSelected: state.crudDockmanagerState.dockSelected,
    company,
    businessUnit,
    isLoadingShedulesHours: state.crudDockmanagerState.isLoadingShedulesHours,
    scheduleLimite: state.crudDockmanagerState.scheduleLimite,
    intervaloMin: state.crudDockmanagerState.intervaloMin,
    businessUnits
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),

    loadDocks: (businessUnit, sigla) => {
      dispatch(patioManagerDocksctions.findDoksByOwner(businessUnit, sigla));
    },

    setCurrentBusinessUnit: (businessUnit) => {
      if (businessUnit) {
        dispatch(actions.setCurrentBusinessUnit(businessUnit));
      } else {
        dispatch(actions.setCurrentBusinessUnit(null));
      }
    },

    accountConfigurationLoad: () => dispatch(accountConfigurationActions.loadAccountConfigurationsByCompany()),
    setSelectedCarriers: (carriers) => dispatch(actions.setSelectedCarriers(carriers)),
    setSelectedDate: (date) => dispatch(actions.setSelectedDate(date)),
    findScheduleByOwnerFilters: () => dispatch(actions.findScheduleByOwnerFilters()),

    setEditSelectedSchedule: (schedule) => dispatch(actions.setEditSelectedSchedule(schedule)),
    setVisibleEditSchedule: (visible) => dispatch(actions.setVisibleEditSchedule(visible)),

    setEditDockSelected: (dockSelected) => dispatch(actions.setEditDockSelected(dockSelected)),
    setEditHoraSelected: (horaSelected) => dispatch(actions.setEditHoraSelected(horaSelected)),

    onConfirmScheduleDock: (dockSchedule) => dispatch(actions.onConfirmScheduleDock(dockSchedule)),
    getHorasDisponiveisDoca: (dadoDockHour) => dispatch(actions.getHorasDisponiveisDoca(dadoDockHour)),
    setLoaderHoursScheduleDoca: (visible) => dispatch(actions.setLoaderHoursScheduleDoca(visible)),
    showMessages: (messages, type) => dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, type))),
    removeScheduleById: (oid) => dispatch(actions.removeScheduleById(oid)),

    //load unidades
    loadUnidades:()=>{
      dispatch(actionsUnidades.load());
    }
  };
};

class DockmanagerFormPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { scrollX: 0, scrollY: 0 };
    this.scheduleElements = [];
    this.countUpdate = 0;
    this.lastDifTime = 0;
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.loadUnidades()
    this.props.setSelectedCarriers([]);
    this.props.setCurrentBusinessUnit(null);
    this.props.accountConfigurationLoad();
    // this.props.loadDocks();
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
  }

  componentDidUpdate() {
    //renderizar os dados do agendamento na posição, após a rendereização das docas
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME) {
      this.props.setSelectedCarriers([]);
      this.props.loadUnidades();
      this.props.setCurrentBusinessUnit(null);
      this.props.accountConfigurationLoad();
      // this.props.loadDocks();
    }
  };

  applyFilter = (businessUnitSelect) => {
    if (businessUnitSelect) {
      const { businessUnit, sigla } = businessUnitSelect;
      this.props.loadDocks(businessUnit, sigla);
    }
  };
  onDropdownBusinessUnitChange = (e) => {
    // this.props.setSelectedCarriers([]);
    this.props.setCurrentBusinessUnit(e.target.value);
    //
    var carriers = e.target.value.carrierConfigurations || [];

    carriers = carriers.map((c) => {
      return { carrier: c.carrier, name: c.name || `CNPJ:${c.carrier}` };
    });

    if (carriers.length > 0) carriers = [{ carrier: "0", name: `SEM TRANSPORTADORA` }, ...carriers];

    this.props.setSelectedCarriers(carriers);
    this.applyFilter(e.target.value);
    this.props.findScheduleByOwnerFilters();
  };

  onSelectionCarrierChange = (e) => {
    this.props.setSelectedCarriers(e.value);
  };
  onDateChange = (e) => {
    this.props.setSelectedDate(e.value);
    this.props.findScheduleByOwnerFilters();
  };

  getHorarios = () => {
    //caso não informado o intervalo de agendamento, por default é 1 hora em minutos
    let { docaRangeTime = [],timeNextOperation=0 } = this.props.docaWorkDayTime;
    // configurations.businessUnitConfigurations.13.docaWorkDayTime.timeNextOperation//
    let intervaloMin =timeNextOperation>0?timeNextOperation: this.props.intervaloMin;
    const format = "HH:mm";
    let listRangeTimes = [];

    docaRangeTime.forEach((startEnd) => {
      //considerado 24hrs de 0 à 23:59
      let dateSelected = moment(this.props.dateSelected).startOf("day");
      let timeStartFormt = moment(startEnd.start, format);
      let timeStart = dateSelected.set({ hour: timeStartFormt.hour(), minute: timeStartFormt.minute() });

      //hora end
      // let timeEnd = moment(startEnd.end, format);
      let dateSelectedEnd = moment(this.props.dateSelected).startOf("day");
      let timeEndFormt = moment(startEnd.end, format);
      let timeEnd = dateSelectedEnd.set({ hour: timeEndFormt.hour(), minute: timeEndFormt.minute() });
      let timeStartOld = timeStart.toDate();

      if (timeStart.isBefore(timeEnd)) {
        listRangeTimes.push({ label: timeStart.format(format), dateTime: timeStart.toDate(), startTime: timeStartOld, difMin: moment(timeStart.toDate()).diff(moment(timeStartOld), "minute") });
        while (timeStart.isBefore(timeEnd)) {
          timeStartOld = timeStart.toDate();
          timeStart = timeStart.add(intervaloMin, "minute");
          if (timeStart.isBefore(timeEnd)) {
            listRangeTimes.push({ label: timeStart.format(format), dateTime: timeStart.toDate(), startTime: timeStartOld, difMin: moment(timeStart.toDate()).diff(moment(timeStartOld), "minute") });
          } else {
            listRangeTimes.push({ label: timeEnd.format(format), dateTime: timeEnd.toDate(), startTime: timeStartOld, difMin: moment(timeEnd.toDate()).diff(moment(timeStartOld), "minute") });
          }
        }
      }
    });

    return listRangeTimes.map((h, index) => {
      const nextDif = index + 1 < listRangeTimes.length ? listRangeTimes[index + 1].difMin : moment(h.dateTime).diff(moment(h.startTime), "minute");

      // intervaloMin
      const percent = (nextDif / intervaloMin) * 100;
      const percentHeight = percent > 100 ? 100 : percent;
      let height = heightBoxSchedule; //
      if (nextDif > 0 && percentHeight < 100) {
        height = heightBoxSchedule * (percentHeight / 100);
      }

      return (
        <div key={index} className="col-hour" style={{ height: `${height}px` }}>
          <div className={`label_hour ${moment(h.dateTime).minute() == 0 ? "label_destaque" : ""}`}>{h.label}</div>
        </div>
      );
    });
  };

  onClickRemoveSchedule = (scheduleEdit) => {
    console.log("onClickRemoveSchedule=", scheduleEdit);
    if (scheduleEdit && scheduleEdit.oid) {
      this.props.removeScheduleById(scheduleEdit.oid);
    }
  };
  getHorariosDocks = () => {
    //caso não informado o intervalo de agendamento, por default é 1 hora em minutos
    let { docaRangeTime = [],timeNextOperation=0 } = this.props.docaWorkDayTime;
    // configurations.businessUnitConfigurations.13.docaWorkDayTime.timeNextOperation//
    let intervaloMin =timeNextOperation>0?timeNextOperation: this.props.intervaloMin;

    // let intervaloMin = this.props.intervaloMin;
    const format = "HH:mm";
    let listRangeTimes = [];
    let listRangeDateValidos = [];

    docaRangeTime.forEach((startEnd) => {
      //considerado 24hrs de 0 à 23:59
      let dateSelected = moment(this.props.dateSelected).startOf("day");
      let timeStartFormt = moment(startEnd.start, format);
      let timeStart = dateSelected.set({ hour: timeStartFormt.hour(), minute: timeStartFormt.minute() });

      //hora end
      // let timeEnd = moment(startEnd.end, format);
      let dateSelectedEnd = moment(this.props.dateSelected).startOf("day");
      let timeEndFormt = moment(startEnd.end, format);
      let timeEnd = dateSelectedEnd.set({ hour: timeEndFormt.hour(), minute: timeEndFormt.minute() });
      listRangeDateValidos.push({ timeStart: timeStart.toDate(), timeEnd: timeEnd.toDate(), labelStart: startEnd.start, labelEnd: startEnd.end });

      let timeStartOld = timeStart.toDate();

      if (timeStart.isBefore(timeEnd)) {
        listRangeTimes.push({ label: timeStart.format(format), dateTime: timeStart.toDate(), startTime: timeStartOld, difMin: moment(timeStart.toDate()).diff(moment(timeStartOld), "minute") });
        while (timeStart.isBefore(timeEnd)) {
          timeStartOld = timeStart.toDate();
          timeStart = timeStart.add(intervaloMin, "minute");
          if (timeStart.isBefore(timeEnd)) {
            listRangeTimes.push({ label: timeStart.format(format), dateTime: timeStart.toDate(), startTime: timeStartOld, difMin: moment(timeStart.toDate()).diff(moment(timeStartOld), "minute") });
          } else {
            listRangeTimes.push({ label: timeEnd.format(format), dateTime: timeEnd.toDate(), startTime: timeStartOld, difMin: moment(timeEnd.toDate()).diff(moment(timeStartOld), "minute") });
          }
        }
      }
    });

    let docks = [];

    let selectedCarriers = this.props.selectedCarriers || [];
    let carrierConfigurations = []
    if (this.props.currentBusinessUnit && this.props.currentBusinessUnit.carrierConfigurations) {
      carrierConfigurations = this.props.currentBusinessUnit.carrierConfigurations
    }

    let schedulingRecords = this.props.schedulingRecords || [];

    for (let dock of this.props.docks) {
      dock.schedules = [];
      schedulingRecords.map((item) => {
        if (item.dockId == dock.oid) {
          //desativar agendas de transportadoras desabilitadas
          //validar a transportadora esta selecionada e é a mesma do agendamento

          let newItem = { ...item, active: item.carrier && item.carrier.carrier ? selectedCarriers.filter((c) => c.carrier == item.carrier.carrier).length > 0 : false };

          //se essa transportadora ainda nao tiver sido cadastrado na unidade(tela de configuration) e nao ta na lista, entao deixa ela sempre ativa
          if (!newItem.active) {
            if (schedulingRecords.filter(c => c.carrier == item.carrier.carrier).length == 0) {
              newItem.active = true;
            }
          }
          dock.schedules.push(newItem);
        }
      });
      docks.push(dock);
    }

    this.scheduleElements = [];
    this.lastDifTime = 0;
    //incluir
    const listSchedElements = listRangeTimes.map((h, index) => {
      //identifica os intervalos cadastrados no range de time

      const nextDif = index + 1 < listRangeTimes.length ? listRangeTimes[index + 1].difMin : 0;

      // intervaloMin
      const percent = (nextDif / intervaloMin) * 100;
      const percentHeight = percent > 100 ? 100 : percent;
      let height = heightBoxSchedule; //
      if (nextDif > 0 && percentHeight < 100) {
        height = heightBoxSchedule * (percentHeight / 100);
      }
      const isHorarioValido = nextDif != 0;
      return (
        <div className="row" key={index}>
          {docks.map((dock, indexDoc) => {
            return (
              <div
                className={`cell-agenda cell-width  ${!isHorarioValido ? "invalid-horario" : ""}`}
                key={index + "-" + indexDoc}
                style={{ height: `${height}px` }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isHorarioValido) {
                    this.onClickToSchedule(dock, h);
                  }
                }}
              >
                {dock.schedules.map((schedule, indexSchedule) => {
                  // console.log("-------------OID:", schedule.oid);
                  const timeInicioAgenda = moment(schedule.startAt);

                  const endAt = moment(schedule.endAt);
                  const dateTimeDock = moment(h.dateTime);
                  const minutsBetween = endAt.diff(dateTimeDock, "minute");

                  // intervaloMin
                  const percent = (minutsBetween / intervaloMin) * 100;
                  const percentHeight = percent > 100 ? 100 : percent;

                  //para identificar igual
                  if (dateTimeDock.isBetween(timeInicioAgenda, endAt, undefined, "[]") && dateTimeDock.isBefore(endAt)) {
                    const elementID = index + "-" + indexDoc + "-" + indexSchedule;

                    //agrupar os elementos/div do mesmo agendamento
                    //para criar outra div por cima para listar os detalhes do agendamento
                    let newEditElement;

                    for (let eleIndex = 0; eleIndex < this.scheduleElements.length; eleIndex++) {
                      if (this.scheduleElements[eleIndex].oid == schedule.oid) {
                        newEditElement = this.scheduleElements[eleIndex];
                      }
                    }

                    if (!newEditElement) {
                      newEditElement = { oid: schedule.oid, active: schedule.active, elements: [{ oid: schedule.oid, elementID, percentHeight, schedule, dock, horario: h }] };
                      this.scheduleElements.push(newEditElement);
                    } else {
                      newEditElement.elements.push({ oid: schedule.oid, active: schedule.active, elementID, percentHeight, schedule, dock, horario: h });
                    }

                    return (
                      <div
                        id={elementID}
                        key={elementID}
                        style={{ height: `${percentHeight}%` }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.onClickEditSchedule(schedule, dock, h);
                        }}
                      >
                        <RenderInfoSchedule currentParentID={elementID} elementParents={newEditElement} />
                      </div>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      );
    });

    return listSchedElements;
  };

  onClickToSchedule = (dock, horaSelected) => {
    this.props.getHorasDisponiveisDoca({ dockId: dock.oid, horaSelected });
    this.props.setEditSelectedSchedule(null);
    this.props.setEditDockSelected(dock);
    this.props.setEditHoraSelected(horaSelected.dateTime);
    // this.props.setVisibleEditSchedule(true);
  };
  onClickEditSchedule = (schedule, dockSelected, horaSelected) => {
    if (schedule.active) {
      this.props.setEditSelectedSchedule(schedule);
      this.props.setEditDockSelected(dockSelected);
      this.props.setEditHoraSelected(horaSelected);
      this.props.setVisibleEditSchedule(true);
      this.props.setLoaderHoursScheduleDoca(false);
    }
  };

  getRenderedDock = () => {
    let docks = this.props.docks;
    return docks.map((dock, index) => (
      <div className="col-docks cell-width cell-height" key={dock.oid}>
        <div>{String(dock.name).toUpperCase()}</div>
      </div>
    ));
  };

  onHideDialogEditSchedule = () => {
    this.props.setEditSelectedSchedule(null);
    this.props.setVisibleEditSchedule(false);
  };

  isDadosValidos = (dockSchedule) => {
    try {
      if (
        (dockSchedule.oid || (dockSchedule.shipmentCode && dockSchedule.shipmentCode.length > 0)) &&
        dockSchedule.packagingType &&
        dockSchedule.vehicleType &&
        dockSchedule.operationType &&
        dockSchedule.operationTime > 0
      ) {
        return dockSchedule;
      } else {
        this.props.showMessages(["Atenção, preencha todos os campos obrigatórios (*)"], "error");
      }
    } catch (error) {
      console.log("error", error);
      this.props.showMessages(["Houve um problema ao salvar o agendamento, contate o suporte!"], "error");
    }
    return false;
  };

  onConfirmDialogEditSchedule = (dockSchedule) => {
    if (!dockSchedule.oid && dockSchedule.operationTime > this.props.scheduleLimite) {
      this.props.showMessages(["O tempo da operação ultrapassa o limite de " + this.props.scheduleLimite + " minutos"], "error");
    } else {
      const newSchedule = this.isDadosValidos(dockSchedule);
      if (newSchedule) {
        this.props.onConfirmScheduleDock(newSchedule);
      }
    }
  };
  onCancelDialogEditSchedule = () => {
    this.props.setEditSelectedSchedule(null);
    this.props.setVisibleEditSchedule(false);
  };
  render() {
    //

    return (
      <React.Fragment>
        <GrowlMessenger />
        <div className="dock-manager-form">
          <div className="container-fixed-lateral">
            <DockmanagerLeftComponet
              businessUnits={this.props.businessUnitConfigurations}
              currentBusinessUnit={this.props.currentBusinessUnit}
              onDropdownBusinessUnitChange={this.onDropdownBusinessUnitChange}
              onSelectionCarrierChange={this.onSelectionCarrierChange}
              onSelectionDateChange={this.onDateChange}
              selectedCarriers={this.props.selectedCarriers}
              dateSelected={this.props.dateSelected}
              docaWorkDayTime={this.props.docaWorkDayTime}
            />
          </div>

          <div
            className="content-docas-agend"
            onScroll={(e) => {
              this.setState({ scrollX: e.target.scrollTop, scrollY: e.target.scrollLeft });
            }}
          >
            <div className="container-headers" style={{ marginTop: this.state.scrollX }}>
              <div style={{ width: "40px" }}></div>
              {this.getRenderedDock()}
            </div>

            <div className="content-docas-hours" style={{ marginLeft: this.state.scrollY }}>
              {this.getHorarios()}
            </div>
            <div className="content-docas">{this.getHorariosDocks()}</div>
          </div>

          {this.props.visibleEditSchedule && (
            <DialogEditSchedule
              onConfirm={this.onConfirmDialogEditSchedule}
              onCancel={this.onCancelDialogEditSchedule}
              onHide={this.onHideDialogEditSchedule}
              {...this.props}
              horaSelected={this.props.horaSelected}
              scheduleLimite={this.props.scheduleLimite}
              onClickRemoveSchedule={this.onClickRemoveSchedule}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DockmanagerFormPageContainer);
