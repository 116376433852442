import { types } from "./cargoshipment-report.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";

const initialState = {

  columns: [
    {
      field: "doca",
      header: "Doca",
      columnKey: "doca",
      sortable: false,
      filter: false,
    },
     
    {
      field: "dataAgendamento",
      header: "Data Agendam",
      columnKey: "dataAgendamento",
      sortable: false,
      filter: false,
    }, 
    {
      field: "statusAgendamento",
      header: "Status Agendam",
      columnKey: "statusAgendamento",
      sortable: false,
      filter: false,
    },
    {
      field: "dataCriacaoEmbarque",
      header: "Criação Shipment",
      columnKey: "dataCriacaoEmbarque",
      sortable: false,
      filter: false,
    },
    {
      field: "shipmentTransf",
      header: "Shipment Transf",
      columnKey: "shipmentTransf",
      sortable: false,
      filter: false,
    },
    {
      field: "remessaTransf",
      header: "Remessa Transf",
      columnKey: "remessaTransf",
      sortable: false,
      filter: false,
    },
    {
      field: "shipmentVenda",
      header: "Shipment Venda",
      columnKey: "shipmentVenda",
      sortable: false,
      filter: false,
    },
    {
      field: "remessaVenda",
      header: "Remessa Venda",
      columnKey: "remessaVenda",
      sortable: false,
      filter: false,
    },
    {
      field: "centro",
      header: "Centro",
      columnKey: "centro",
      sortable: false,
      filter: false,
    },
      
    {
      field: "estado",
      header: "UF",
      columnKey: "estado",
      sortable: false,
      filter: false,
    }, 
    {
      field: "tipoVeiculo",
      header: "Tipo Veículo",
      columnKey: "tipoVeiculo",
      sortable: false,
      filter: false,
    },
    {
      field: "placa",
      header: "Placa",
      columnKey: "placa",
      sortable: false,
      filter: false,
    }, 
    {
      field: "qutdeEmbalagem",
      header: "Qutde Embal",
      columnKey: "qutdeEmbalagem",
      sortable: false,
      filter: false,
    },
    {
      field: "embalagem",
      header: "Embal",
      columnKey: "embalagem",
      sortable: false,
      filter: false,
    },

    {
      field: "urgente",
      header: "Urgente",
      columnKey: "urgente",
      sortable: false,
      filter: false,
    },
     
    {
      field: "transportadora",
      header: "Transp",
      columnKey: "transportadora",
      sortable: false,
      filter: false,
    },
  ],                     

  filterFields:{
    initialDate:  moment().subtract(2,"days").toDate(),
    finalDate: moment().toDate(),
  }
};
 
const reducer = new Reducer(initialState, types);

export function CRUDCargoShipmentReportReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    
    case types.SET_INITIAL_DATE:
      return initialDateChangeHandler(state, action);
      case types.SET_FINAL_DATE:
        return finalDateChangeHandler(state, action);
  }
  return result;
}
  

function finalDateChangeHandler(state, action) {
  let filterFields = state.filterFields;
  filterFields.finalDate = action.finalDate;
  return Object.assign({}, state, { filterFields });
}
  

function initialDateChangeHandler(state, action) {
  let filterFields = state.filterFields;
  filterFields.initialDate = action.initialDate;
  return Object.assign({}, state, { filterFields });
}
  
