import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from "primereact/column";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { actions } from "../patiomanagerdoca.actions";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { PatioManagerDocaList } from "./PatioManagerDocaList";
import { DocaRowTreeview } from "../components/DocaRowTreeview";
import "./patiomanagerdock.scss";
import moment from "moment";

const mapStateToProps = (state) => {
  const currentUnit = state.appState.currentUnit;
  return {
    columns: state.crudPatioManagerDocaState.columns,
    records: state.crudPatioManagerDocaState.records,
    first: state.crudPatioManagerDocaState.index,
    rows: state.crudPatioManagerDocaState.max,
    totalRecords: state.crudPatioManagerDocaState.count,
    sortField: state.crudPatioManagerDocaState.sortField,
    order: state.crudPatioManagerDocaState.sortOrder,
    expandedRows: state.crudPatioManagerDocaState.expandedRows,
    columnsRowTreeviewTemplateDocas: state.crudPatioManagerDocaState.columnsRowTreeviewTemplateDocas,
    currentUnit
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    load: () => {
      dispatch(actions.load());
    },
    setSelectedRecords: (selectedRecords) => {
      dispatch(actions.setSelectedRecords(selectedRecords));
    },
    setExpandedRows: (expandedRows) => {
      dispatch(actions.setExpandedRows(expandedRows));
    },
  };
};

class PatioManagerDocaListPageContainer extends React.Component {
  newHandler = () => {
    this.props.history.push(`${routes.PATH_PATIO_MANAGER_DOCK}/novo`);
  };

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    this.props.load();
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {

      const filters = []
      if (this.props.currentUnit && this.props.currentUnit.documentNumber!="0" && this.props.currentUnit.documentNumber!="-1") {
        filters.push({
          campo: "sigla",
          valor: this.props.currentUnit.code,
          tipoFiltro: 'EQUALS'
        })
      }
      this.props.applyFilter(filters);
    }
  };
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.applyFilter(filters);
  };

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_PATIO_MANAGER_DOCK}/${oid}`);
    }
  };

  setExpandedRowsByRowClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else if (e.data && e.data.length == 1) {
      this.props.setExpandedRows([e.data[0]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  setExpandedRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };

  render() {
    const header = (
      <div>
        <h1>Gerenciamento de Docas</h1>
        <hr className="lxSplitter"></hr>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle="highlighted"></Toolbar>
        </div>
      </div>
    );
    const packagingTypeTemplate = (rowData, col) => {
      try {
        const packagingTypes = rowData.packagingType;
        let listNames = [];
        if (packagingTypes) {
          listNames = packagingTypes.map((tp) => {
            return tp.name;
          });
        }
        return <div>{listNames.join(",")}</div>;
      } catch (error) {
        return <></>;
      }
    };
    const operationTypeTemplate = (rowData, col) => {
      try {
        const operationType = rowData.operationType;
        let listlabel = [];
        if (operationType) {
          listlabel = operationType.map((tp) => {
            return tp.label;
          });
        }
        return <div>{listlabel.join(",")}</div>;
      } catch (error) {
        return <></>;
      }
    };

    const vehicleTypeTemplate = (rowData, col) => {
      try {
        return <div>{rowData.vehicleType.name}</div>;
      } catch (error) {
        return <></>;
      }
    };
    const createdAtTemplate = (rowData, col) => {
      try {
        let formattedDate = "";
        formattedDate = moment(rowData.eventRecord.createdAt).format("DD/MM/YYYY HH:mm:ss");
        return <div style={{ textAlign: "center", color: "black" }}>{formattedDate}</div>;
      } catch (error) {
        return <></>;
      }
    };

    const operationTimeTemplate = (rowData, col) => {
      try {
        return <div>{rowData.operationTime}{" minutos"}</div>;
      } catch (error) {
        return <></>;
      }
    };

    const columns = this.props.columns.map((col) => {
      if (col.field == "eventRecord.createdAt") {
        return <Column {...col} key={col.field} className="col-head-table" body={createdAtTemplate} />;
      }

      return <Column {...col} className="col-head-table" key={col.field} />;
    });

    const templateExpandedRow = (rowData, e) => {

      let columnsTreeview = this.props.columnsRowTreeviewTemplateDocas.map((col) => {
        if (col.field == "packagingType") {
          return <Column {...col} key={col.field} className="col-head-table" body={packagingTypeTemplate} />;
        }
        if (col.field == "operationType") {
          return <Column {...col} key={col.field} className="col-head-table" body={operationTypeTemplate} />;
        }
        if (col.field == "vehicleType") {
          return <Column {...col} key={col.field} className="col-head-table" body={vehicleTypeTemplate} />;
        }
        if (col.field == "operationTime") {
          return <Column {...col} key={col.field} className="col-head-table" body={operationTimeTemplate} />;
        }

        return <Column {...col} className="col-head-table" key={col.field} />;
      });

      return <DocaRowTreeview records={rowData.docaVehicleOperation} columns={columnsTreeview} />;
    };

    const content = (
      <PatioManagerDocaList
        {...this.props}
        onRowClick={this.onRowClick}
        onFilter={this.onFilter}
        onPage={this.onPage}
        onSort={this.onSort}
        rowToggleHandler={this.setExpandedRowsClick}
        rowExpandButtonOnClick={this.setExpandedRowsByRowClick}
        expandedRows={this.props.expandedRows}
        templateExpandedRow={templateExpandedRow}
        columns={columns}
      />
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatioManagerDocaListPageContainer);
