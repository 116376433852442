import { messageActions } from "../store/message/index";
import { errorTypes } from '../constants/errorTypes';
import { exportToJSONFile } from '../file-handler'
const makeMessages = (messages, type) => {
  return messages.map(message => {
    return { sticky: true, severity: type, detail: message };
  });
};

export class Action {
  timer = null;
  constructor(prefix, crud,beforeSave) {
    this.beforeSave = beforeSave || null; 
    this.messageActions = messageActions;
    this.makeMessages = makeMessages;
    this.crud = crud;
    this.prefix = prefix;
    this.types = {
      FETCH_RECORDS: `${prefix}FetchRecords`,
      FETCH_RECORD: `${prefix}FetchRecord`,
      FETCH_RECORDS_SUCCESS: `${prefix}FetchRecordsSuccess`,
      FETCH_RECORD_SUCCESS: `${prefix}FetchRecordSuccess`,
      FETCH_RECORDS_ERROR: `${prefix}FetchRecordsError`,
      FETCH_RECORD_ERROR: `${prefix}FetchRecordError`,
      SET_RECORDS: `${prefix}.setRecords`,
      SET_RECORD: `${prefix}.setRecord`,
      SET_INDEX: `${prefix}.setIndex`,
      SET_PAGE: `${prefix}.setPage`,
      SET_MAX: `${prefix}.setMax`,
      SET_COUNT: `${prefix}.setCount`,
      HIDE_LOADER: `${prefix}.hideLoader`,
      SHOW_LOADER: `${prefix}.showLoader`,
      SET_SORT_ORDER: `${prefix}.setSortOrder`,
      SET_SORT_FIELD: `${prefix}.setSortField`,
      SET_FILTERS: `${prefix}.setFilters`,
      SET_BASE_FILTERS: `${prefix}.setBaseFilters`,
      SET_TYPE: `${prefix}.setType`,
      SET_FIELD: `${prefix}.setField`,
      ADD_ERRORS: `${prefix}.addErrors`,
      REMOVE_ERRORS: `${prefix}.removeErrors`,
      CLEAR_ERRORS: `${prefix}.clearErrors`,
      MAKE_NEW: `${prefix}.new`,
      SET_SELECTED_RECORDS: `${prefix}.setSelectedRecords`
    };
  }

  addErrors(errors = []) {
    return {
      type: this.types.ADD_ERRORS,
      errors
    };
  }

  removeErrors(errors = []) {
    return {
      type: this.types.REMOVE_ERRORS,
      errors
    };
  }

  clearErrors() {
    return {
      type: this.types.CLEAR_ERRORS
    };
  }

  fetchRecords() {
    return {
      type: this.types.FETCH_RECORDS,
      async: true
    };
  }
  fetchRecord() {
    return {
      type: this.types.FETCH_RECORD,
      async: true
    };
  }
  setRecords(records) {
    return {
      type: this.types.SET_RECORDS,
      records
    };
  }

  setIndex(index) {
    return {
      type: this.types.SET_INDEX,
      index
    };
  }
  setPage(page) {
    return {
      type: this.types.SET_PAGE,
      page
    }
  }
  setMax(max) {
    return {
      type: this.types.SET_MAX,
      max
    };
  }
  setCount(count) {
    return {
      type: this.types.SET_COUNT,
      count
    };
  }
  setSortOrder(sortOrder) {
    return {
      type: this.types.SET_SORT_ORDER,
      sortOrder
    };
  }

  setSortField(sortField) {
    return {
      type: this.types.SET_SORT_FIELD,
      sortField
    };
  }

  hideLoader() {
    return {
      type: this.types.HIDE_LOADER,
      async: false
    };
  }
  showLoader() {
    return {
      type: this.types.SHOW_LOADER,
      async: true
    };
  }
  baseFilter(filters) {
    return {
      type: this.types.SET_BASE_FILTERS,
      filters
    };
  }
  filter(filters) {
    return {
      type: this.types.SET_FILTERS,
      filters
    };
  }
  setType(filterType) {
    return {
      type: this.types.SET_TYPE,
      filterType
    };
  }

  setRecord(record) {
    return {
      type: this.types.SET_RECORD,
      record: this.normalize ? this.normalize(record) : record
    };
  }

  setField(field, value) {
    return {
      type: this.types.SET_FIELD,
      field,
      value
    };
  }
  setSelectedRecords(selectedRecords) {
    return {
      type: this.types.SET_SELECTED_RECORDS,
      selectedRecords
    };
  }
  fetchRecordsSuccess(result) {
    return dispatch => {
      dispatch(this.setRecords(result.records));
      dispatch(this.setIndex(result.index));
      dispatch(this.setMax(result.size));
      dispatch(this.setCount(result.count));
      dispatch(this.hideLoader());
    };
  }

  fetchRecordSuccess(result) {
    return dispatch => {
      dispatch(this.setRecord(result));
      dispatch(this.hideLoader());
    };
  }

  fetchRecordsError(err) {
    return dispatch => {
      dispatch(this.hideLoader());
    };
  }

  loadRecord(oid, dispatch) {
    this.crud
      .getByOid(oid)
      .then(({ data }) => {
        dispatch(this.fetchRecordSuccess(data));
      })
      .catch(err => {
        const messages = [
          "Não foi possível carregar os dados do registro selecionado"
        ];
        dispatch(
          messageActions.messageShowMessages(makeMessages(messages, "error"))
        );
        dispatch(this.hideLoader());
      });
  }

  loadRecords(params, dispatch, callback) {
    this.crud
      .getAll(params)
      .then(({ data }) => {
        dispatch(this.fetchRecordsSuccess(data));
        callback && callback(null, data)
      })
      .catch(err => {
        dispatch(this.fetchRecordsError(err));
        callback && callback(err, null)
      });
  }

  getIDs = (records) => {
    return records.map(record => record.oid)
  }

  import(record, messageHandler = () => { "messageHandler not informed" }) {

    return (dispatch) => {
      dispatch(this.fetchRecords());
      this.crud
        .import(record)
        .then((result) => {
          dispatch(messageActions.messageClearMessages());
          const messages = result && result.data && result.data.length > 0 && result.data[0].md5 === false
            ? [{ message: "Arquivo alterado indevidamente", type: 'error' }]
            : messageHandler(result);
          messages.forEach(message => {
            dispatch(
              messageActions.messageShowMessages(
                makeMessages([message.message], message.type)
              )
            );
          });


          dispatch(this.hideLoader());
          dispatch(this.load())
        })
        .catch(err => {
          console.log(err)
          dispatch(this.hideLoader());
        });
    }
  }

  export(fileName) {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`];
      dispatch(this.fetchRecords());

      this.crud
        .export(this.getIDs(state.selectedRecords))
        .then(({ data }) => {
          exportToJSONFile(data, fileName) //TODO File name must be a parameter
          dispatch(this.hideLoader());
        })
        .catch(err => {
          console.log(err)
          dispatch(this.hideLoader());
        })

    };
  }
  load(params = {}) {
    const {callback,fields = [],mode="lazy"} = params
    const prefix = this.prefix;
    return (dispatch, getState) => {      
      const state = getState()[`${prefix}State`];
      dispatch(this.fetchRecords());
      let sortFields = [];
      if (state.sortField) {
        sortFields = [
          {
            fieldName: state.sortField,
            order: state.sortOrder > -1 ? `ASC` : `DESC`
          }
        ];
      }
      const params = {
        index: state.index,
        max: state.max,
        permissions: state.permissions,
        _filters: state.filters,
        get filters() {
          return this._filters;
        },
        set filters(value) {
          this._filters = value;
        },
        sortFields,
        fields,
        mode

      };
      this.loadRecords(params, dispatch, callback);
    };
  }

  loadById(oid) {
    return (dispatch, getState) => {
      dispatch(this.fetchRecord());
      this.loadRecord(oid, dispatch);
    };
  }

  paginate(paginationParams, reload = true) {
    return (dispatch, getState) => {
      if (paginationParams.index !== undefined) {
        dispatch(this.setIndex(paginationParams.index));
      }
      if (paginationParams.page !== undefined) {
        dispatch(this.setPage(paginationParams.page));
      }
      if (paginationParams.max !== undefined) {
        dispatch(this.setMax(paginationParams.max));
      }
      //dispatch(this.setMax(paginationParams.max));
      if (reload) {
        dispatch(this.load());
      }
    };
  }

  sortOrder(sortParams, reload = true) {
    return (dispatch, getState) => {
      dispatch(this.setSortOrder(sortParams.sortOrder));
      dispatch(this.setSortField(sortParams.sortField));
      if (reload) {
        dispatch(this.load());
      }

    };
  }

  applyBaseFilters(filters) {
    return (dispatch, getState) => {
      dispatch(this.baseFilter(filters));
      if (this.timer) {
        window.clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        dispatch(this.setIndex(0));
        dispatch(this.load())
      }, 300);
    };
  }

  applyFilter(filters, params = {}) {
    const {callback, reload = true,fields=[]} = params;
    return (dispatch, getState) => {
      dispatch(this.filter(filters));
      if (this.timer) {
        window.clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        dispatch(this.setIndex(0));
        if (reload) {
          dispatch(this.load({callback,fields}))
        }
      }, 300);
    };
  }

  save(newRecord) {

    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`];
      let record = newRecord || state.currentRecord;

      if (this.beforeSave) {
        record = this.beforeSave(record);
      }
      if (!record.oid) {
        if (this.timer) {
          window.clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          dispatch(this.showLoader())
          this.crud
            .insert(record)
            .then(({ data }) => {
              dispatch(messageActions.messageClearMessages());
              if (data.errorType && data.errorType === errorTypes.VALIDATIONS) {
                const errorMessages = data.errors ? data.errors.map(error => error.message) : ['Validações falharam no servidor!'];
                dispatch(
                  messageActions.messageShowMessages(
                    makeMessages(errorMessages, "error")
                  )
                );
              } else {
                const { oid } = data;
                const messages = ["Registro criado com sucesso"];
                dispatch(this.setField("oid", oid));
                dispatch(this.hideLoader())
                dispatch(
                  messageActions.messageShowMessages(
                    makeMessages(messages, "success")
                  )
                );
                if (this.afterSave) {
                  this.afterSave(data);
                }
              }
            })
            .catch(err => {
              const { errors = [] } = err;
              if (errors.length > 0) {
                dispatch(this.clearErrors());
                dispatch(this.addErrors(errors));
                dispatch(messageActions.messageClearMessages());
                dispatch(this.hideLoader())
                dispatch(
                  messageActions.messageShowMessages(
                    makeMessages(errors.map(erro => erro.message), "error")
                  )
                );
              }
            });
        }, 500);

      } else {
        dispatch(this.showLoader())
        this.crud
          .update(record)
          .then(({ data }) => {
            dispatch(this.hideLoader())
            dispatch(messageActions.messageClearMessages());
            if (data.errorType && data.errorType === errorTypes.VALIDATIONS) {
              const errorMessages = data.errors ? data.errors.map(error => error.message) : ['Validações falharam no servidor!'];
              dispatch(
                messageActions.messageShowMessages(
                  makeMessages(errorMessages, "error")
                )
              );
            } else {
              const messages = ["Registro atualizado com sucesso"];
              dispatch(
                messageActions.messageShowMessages(
                  makeMessages(messages, "success")
                )
              );
              if (this.afterSave) {
                this.afterSave(data);
              }
            }

          })
          .catch(err => {
            const { errors = [] } = err;
            if (errors.length > 0) {
              dispatch(this.hideLoader())
              dispatch(this.clearErrors());
              dispatch(this.addErrors(errors));
              dispatch(messageActions.messageClearMessages());
              dispatch(
                messageActions.messageShowMessages(
                  makeMessages(errors.map(erro => erro.message), "error")
                )
              );
            }
          });
      }
    };
  }
  makeNew() {
    return dispatch => {
      dispatch({
        type: this.types.MAKE_NEW
      });
      if (this.afterNew) {
        this.afterNew();
      }
    };
  }

  setSelectedRecords(selectedRecords) {
    return {
      type: this.types.SET_SELECTED_RECORDS,
      selectedRecords
    }
  }

  remove() {
    return (dispatch, getState) => {
      const state = getState()[`${this.prefix}State`];
      const record = state.currentRecord;
      this.crud
        .remove(record.oid)
        .then(() => {
          if (this.afterRemove) {
            this.afterRemove();
          }
          const messages = ["Registro removido com sucesso"];
          dispatch(messageActions.messageClearMessages());
          dispatch(
            messageActions.messageShowMessages(
              makeMessages(messages, "success")
            )
          );
        })
        .catch(err => {
          const messages = ["Não foi possível remover o registro"];
          dispatch(messageActions.messageClearMessages());
          dispatch(
            messageActions.messageShowMessages(
              makeMessages(messages, "error")
            )
          );
        });
    };
  }
}
