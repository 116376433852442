import React from 'react';
import './CardAccess.scss'
import * as IconFactory from '../../services/icons.factory' 

const CardAccess = props => {
    const starClasses = props.isFavorite ? `p-col-1 pi pi-star favorite` : '';
    const divInfo = !props.info ? '' :
        <div className="p-col-10 lx-dashboard__card-access__info">
            {props.info && props.info}
        </div>
    const icon = IconFactory.getIcon(props.icon ? props.icon : 'truck', false)
    return (
        <div className="p-grid lx-dashboard__card-access" onClick={props.onClick}>
            <div className="p-col-9">
                <img src={icon} className=" lx-dashboard__card__icon" alt="Logo" />
            </div>
            <div className={starClasses}>

            </div>
            <div className="p-col-10 lx-dashboard__card-access__title">
                {props.title && props.title}
            </div>

            {divInfo}
        </div>

    )
};


export default CardAccess;