
import moment from 'moment';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';
import { build } from '../../services/crudFactory';
import storage from '../../services/stoage.service';
import { makeMessages, messageActions } from '../../store/message';
import { validate } from './validations';
const BASE_PATH = '/lognex/portalcarrier';
const prefix = 'crudPortalCarrier';
const crud = build(validate, BASE_PATH);

const newTypes = {
    // GET_COUNT_COLLECTION_ORDERS: `${prefix}getCountCollectionOrders`,
    SET_COUNT_COLLECTION_ORDERS: `${prefix}setCountCollectionOrders`,
    SET_FILTERS: `${prefix}setFilters`,
    LOAD_COLLECTION_ORDERS: `${prefix}loadCollectionOrders`,
    SET_RECORDS_COLLECTION_ORDERS: `${prefix}setRecordsCollectionOrders`,
    SET_EXPANDED_ROW: `${prefix}setExpandedRows`,
    OPEN_DIOLOG_IFRAME_COLLECTION_ORDER: `${prefix}openDialogIframeCollectionOrder`,
    SET_COUNT_COLLECTION_ORDERS_CONFIRMED: `${prefix}setCountCollectionOrdersConfirmadas`,
    SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
    SET_CARRIER_FILTER: `${prefix}setCarrierFilter`,
    COUNT_COLLECTION_ORDER_WITH_PENDENCES: `${prefix}countCollectionOrderWithPendences`,
    SET_COUNT_COLLECTION_ORDERS_WITH_PENDENCES: `${prefix}setCountCollectionOrderWithPendences`,
    GET_ALL_COLLECTION_ORDERS_WITH_PENDENCES: `${prefix}findAllCollectionOrderWithPendences`,
    SET_COLLECTION_ORDERS_WITH_PENDENCES: `${prefix}setCollectionOrderWithPendences`,
    SET_CURRENT_SCREEN: `${prefix}setCurrentScreen`,
    SET_COUNT_IN_COLLECTION_ORDERS: `${prefix}setCountInCollectionOrder`,
    SET_COUNT_IN_TRAVEL: `${prefix}setCountInTravel`,
    SET_SHIP_STATUS_IN_COLLECTION_ORDERS: `${prefix}setAllInCollectionOrder`,
    SET_STATUS_TRANSITO_CARGO_SHIPMENT: `${prefix}changeStatusCargoShipmentToTransito`,
    SET_SHIP_STATUS_IN_TRANSITO: `${prefix}setRecordsCargoShiptmentInTrasito`,
    SET_DELIVERY_DATE: `${prefix}onChangeDeliveryDate`,
    SET_OBSERVATION: `${prefix}onChangeObs`,
    ON_CHANGE_HANDLE_IMAGE: `${prefix}changeHandlerImage`,
    SET_SHOW_MODAL_UPLOAD_IMG: `${prefix}setShowModalUploadImg`,
    SET_CODE_ORDER: `${prefix}setCodeOrder`,
    SET_LINK_CANHOTO: `${prefix}setLinkAddCanhoto`,
    SET_UPDATE_INFO_PEDIDO: `${prefix}updatePedidoInfo`,
    SET_SHIP_STATUS_DELIVERED: `${prefix}setRecordsCargoShipmentDelivered`,
    SET_COUNT_SHIP_STATUS_DELIVERED: `${prefix}setCountCargoShipmentDelivered`,
    SET_DELIVERY_FILTER: `${prefix}setDeliveryFilter`,
    SET_NRO_NFE_FILTER: `${prefix}setNroNfeFilter`,
    SET_CODE_SHIPMENT_FILTER: `${prefix}setCodeShipmentFilter`,
    SET_NRO_EMBARQUE_FILTER: `${prefix}setNroEmbarqueFilter`,
    SET_CAD_VEICULO_PLATE: `${prefix}setCadVeiculoPlate`,
    SALVAR_NOVA_PLACA: `${prefix}salvarNovaPlaca`,
    SET_CAD_VEICULO_DESCRICAO: `${prefix}setCadVeiculoDescricao`,
    SET_RECORD_CAD_VEICULOS: `${prefix}setRecordsVehicles`,
    // LOAD_ALL_CARGOSHIMENT_DELIVERED: `${prefix}loadCargoShipmentDelivered`,
}


const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);
 
actions.setRecordsVehicles = (cadastroVeiculos) => {
    return {
        type: types.SET_RECORD_CAD_VEICULOS,
        cadastroVeiculos,
    };
}

actions.setCadVeiculoDescricao = (description) => {
    return {
        type: types.SET_CAD_VEICULO_DESCRICAO,
        description
    }
}

actions.setCadVeiculoPlate = (plate) => {
    return {
        type: types.SET_CAD_VEICULO_PLATE,
        plate
    }
}
actions.setNroEmbarqueFilter = (nroEmbarque) => {
    return {
        type: types.SET_NRO_EMBARQUE_FILTER,
        nroEmbarque
    }
}
actions.setCodeShipmentFilter = (codeShipment) => {
    return {
        type: types.SET_CODE_SHIPMENT_FILTER,
        codeShipment
    }
}

actions.setNroNfeFilter = (nroNfe) => {
    return {
        type: types.SET_NRO_NFE_FILTER,
        nroNfe
    }
}
actions.setDeliveryFilter = (delivery) => {
    return {
        type: types.SET_DELIVERY_FILTER,
        delivery
    }
}

actions.updatePedidoInfo = (dados) => {
    return {
        type: types.SET_UPDATE_INFO_PEDIDO,
        dados
    }
}
actions.setLinkAddCanhoto = (linkCanhoto) => {
    return {
        type: types.SET_LINK_CANHOTO,
        linkCanhoto
    }
}

actions.setCodeOrder = (order) => {
    return {
        type: types.SET_CODE_ORDER,
        order
    }
}

actions.setShowModalUploadImg = (visible) => {
    return {
        type: types.SET_SHOW_MODAL_UPLOAD_IMG,
        visible
    };
}

actions.onChangeObs = (obs) => {
    return {
        type: types.SET_OBSERVATION,
        obs
    };
}
actions.changeHandlerImage = (fileImg) => {
    return {
        type: types.ON_CHANGE_HANDLE_IMAGE,
        fileImg
    };
}
actions.onChangeDeliveryDate = (deliveryDate) => {
    return {
        type: types.SET_DELIVERY_DATE,
        deliveryDate
    };
}


actions.setCurrentScreen = (currentScreen) => {
    return {
        type: types.SET_CURRENT_SCREEN,
        currentScreen,
    };
};

actions.setCarrierFilter = (carrier) => {
    return {
        type: types.SET_CARRIER_FILTER,
        carrier,
    };
};

actions.setLookupCarrierVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_CARRIER_VISIBLE,
        visible,
    };
};
actions.openDialogIframeCollectionOrder = (openDialogIframe) => {
    return {
        type: types.OPEN_DIOLOG_IFRAME_COLLECTION_ORDER,
        openDialogIframe,
    };
};

actions.setExpandedRows = (expandedRows) => {
    return {
        type: types.SET_EXPANDED_ROW,
        expandedRows,
    };
};

actions.setRecordsCollectionOrders = (records) => {
    return {
        type: types.SET_RECORDS_COLLECTION_ORDERS,
        records,
    };
}

actions.setFilters = (filters) => {
    return {
        type: types.SET_FILTERS,
        filters,
    };
}

actions.setCountCollectionOrders = (total) => {
    return {
        type: types.SET_COUNT_COLLECTION_ORDERS,
        total,
    };
}

actions.setCountCollectionOrdersConfirmadas = (total) => {
    return {
        type: types.SET_COUNT_COLLECTION_ORDERS_CONFIRMED,
        total,
    };
}
actions.setCountCollectionOrderWithPendences = (total) => {
    return {
        type: types.SET_COUNT_COLLECTION_ORDERS_WITH_PENDENCES,
        total,
    };
}
actions.setCountInCollectionOrder = (total) => {
    return {
        type: types.SET_COUNT_IN_COLLECTION_ORDERS,
        total,
    };
}
actions.setCountInTravel = (total) => {
    return {
        type: types.SET_COUNT_IN_TRAVEL,
        total,
    };
}
actions.setCollectionOrderWithPendences = (records) => {
    return {
        type: types.SET_COLLECTION_ORDERS_WITH_PENDENCES,
        records,
    };
}
actions.setAllInCollectionOrder = (records) => {
    return {
        type: types.SET_SHIP_STATUS_IN_COLLECTION_ORDERS,
        records,
    };
}
actions.setRecordsCargoShiptmentInTrasito = (records) => {
    return {
        type: types.SET_SHIP_STATUS_IN_TRANSITO,
        records,
    };
}
actions.setRecordsCargoShipmentDelivered = (records) => {
    return {
        type: types.SET_SHIP_STATUS_DELIVERED,
        records,
    };
}
actions.setCountCargoShipmentDelivered = (total) => {
    return {
        type: types.SET_COUNT_SHIP_STATUS_DELIVERED,
        total,
    };
}

actions.getCountCollectionOrders = () => {
    return (dispatch, getState) => {
        const filters = [...getState().crudPortalCarrierState.filters];

        if (!filters) {
            filters = []
        }

        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = getState().crudPortalCarrierState.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }
        api.post("/api/v1/lognex/portalcarrier/countCollectOrderPendentes", { filters })
            .then((response) => {
                dispatch(actions.setCountCollectionOrders(response.data.total));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setCountCollectionOrders(0));
            });
    };
}


actions.countCollectionOrderWithPendences = () => {
    return (dispatch, getState) => {
        const filters = [...getState().crudPortalCarrierState.filters];

        if (!filters) {
            filters = []
        }

        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = getState().crudPortalCarrierState.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }

        api.post("/api/v1/lognex/portalcarrier/countCollectionOrderWithPendences", { filters })
            .then((response) => {
                dispatch(actions.setCountCollectionOrderWithPendences(response.data.total));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setCountCollectionOrderWithPendences(0));
            });
    };
}
//busca todas as que estão em ordem de coleta
actions.countInCollectionOrder = () => {
    return (dispatch, getState) => {
        const filters = [...getState().crudPortalCarrierState.filters];

        if (!filters) {
            filters = []
        }

        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = getState().crudPortalCarrierState.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }

        api.post("/api/v1/lognex/portalcarrier/countInCollectionOrder", { filters })
            .then((response) => {
                dispatch(actions.setCountInCollectionOrder(response.data.total));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setCountInCollectionOrder(0));
            });
    };
}
//busca todas as que estão em viagem
actions.countInTravel = () => {
    return (dispatch, getState) => {
        const filters = [...getState().crudPortalCarrierState.filters];

        if (!filters) {
            filters = []
        }

        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = getState().crudPortalCarrierState.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }

        api.post("/api/v1/lognex/portalcarrier/countInTravel", { filters })
            .then((response) => {
                dispatch(actions.setCountInTravel(response.data.total));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setCountInTravel(0));
            });
    };
}
//recupera os embarques que estão com status de aguardando carregamento finalizar
actions.getAllInCollectionOrder = () => {
    return (dispatch, getState) => {
        const state = getState().crudPortalCarrierState;
        const filters = [...state.filters];

        if (!filters) {
            filters = []
        }

        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = state.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }

        let sortFields = [];
        if (state.sortField) {
            sortFields = [
                {
                    fieldName: state.sortField,
                    order: state.sortOrder > -1 ? `ASC` : `DESC`
                }
            ];
        }

        const params = {
            index: state.index,
            max: state.max,
            permissions: state.permissions,
            filters: filters,
            sortFields,
            fields: [],
            mode: "lazy"
        };
        dispatch(actions.fetchRecordsSuccess([]))
        dispatch(actions.showLoader());
        api.post("/api/v1/lognex/portalcarrier/getAllInCollectionOrder", params)
            .then((response) => {
                dispatch(actions.hideLoader());
                dispatch(actions.fetchRecordsSuccess(response.data))
                dispatch(actions.setAllInCollectionOrder(response.data.records));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.hideLoader());
                dispatch(actions.setAllInCollectionOrder([]));
            });
    };
}
actions.findAllCollectionOrderWithPendences = () => {
    return (dispatch, getState) => {

        const state = getState().crudPortalCarrierState;

        const filters = [...state.filters];

        if (!filters) {
            filters = []
        }

        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = state.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }
        let sortFields = [];
        if (state.sortField) {
            sortFields = [
                {
                    fieldName: state.sortField,
                    order: state.sortOrder > -1 ? `ASC` : `DESC`
                }
            ];
        }

        const params = {
            index: state.index,
            max: state.max,
            permissions: state.permissions,
            filters: filters,
            sortFields,
            fields: [],
            mode: "lazy"
        };

        dispatch(actions.fetchRecordsSuccess([]))
        dispatch(actions.showLoader());
        dispatch(actions.setCollectionOrderWithPendences([]));
        api.post("/api/v1/lognex/portalcarrier/findAllCollectionOrderWithPendences", params)
            .then((response) => {
                dispatch(actions.fetchRecordsSuccess(response.data))
                dispatch(actions.setCollectionOrderWithPendences(response.data.records));
                dispatch(actions.hideLoader());
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setCollectionOrderWithPendences([]));
                dispatch(actions.hideLoader());
            });
    };
}


actions.getCountCollectionOrdersConfirmadas = () => {
    return (dispatch, getState) => {
        const filters = [...getState().crudPortalCarrierState.filters];
        if (!filters) {
            filters = []
        }
        const initialDateFilter = moment().subtract(1, "months").startOf("day").toDate();
        const finalDateFilter = moment().endOf("day").toDate();
        //filtro ultimos 90 dias
        filters.push({
            campo: "eventRecord.createdAt",
            campoComplementar: "eventRecord.createdAt",
            valor: initialDateFilter,
            valorComplementar: finalDateFilter,
            tipoFiltro: "ENTRE",
        });

        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = getState().crudPortalCarrierState.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }

        // dispatch(actions.setCountCollectionOrdersConfirmadas(0));

        api.post("/api/v1/lognex/portalcarrier/countCollectOrderConfirmadas", { filters })
            .then((response) => {
                dispatch(actions.setCountCollectionOrdersConfirmadas(response.data.total));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setCountCollectionOrdersConfirmadas(0));
            });
    };
}

actions.loadCollectionOrders = () => {
    return (dispatch, getState) => {
        const state = getState().crudPortalCarrierState;

        let filters = [...state.filters];
        if (!filters) {
            filters = []
        }

        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = state.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }
        let sortFields = [];
        if (state.sortField) {
            sortFields = [
                {
                    fieldName: state.sortField,
                    order: state.sortOrder > -1 ? `ASC` : `DESC`
                }
            ];
        }

        const params = {
            index: state.index,
            max: state.max,
            permissions: state.permissions,
            filters: filters,
            sortFields,
            fields: [],
            mode: "lazy"
        };

        dispatch(actions.fetchRecordsSuccess([]))
        dispatch(actions.showLoader());
        dispatch(actions.setRecordsCollectionOrders([]));
        api.post("/api/v1/lognex/portalcarrier/findAllCollectionOrders", params)
            .then((response) => {
                dispatch(actions.hideLoader());
                dispatch(actions.fetchRecordsSuccess(response.data))
                dispatch(actions.setRecordsCollectionOrders(response.data.records));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setRecordsCollectionOrders([]));
                dispatch(actions.hideLoader());
            });
    };
}

actions.getAllCargoShipmentInTransito = (paginationParams) => {
    return (dispatch, getState) => {

        const state = getState().crudPortalCarrierState
        let filters = [...state.filters];
        if (!filters) {
            filters = []
        }

        //campos filtros
        let delivery = String(state.delivery).trim()
        let nroNfe = String(state.nroNfe).trim()
        let codeShipment = String(state.codeShipment).trim()
        let nroEmbarque = String(state.nroEmbarque).trim()
        if (delivery != "") {
            filters.push({
                campo: "delivery",
                valor: delivery,
                tipoFiltro: 'EQUALS'
            })
        }
        if (nroNfe != "") {
            try {
                const nfe = parseInt(nroNfe)
                filters.push({
                    campo: "nroNfe",
                    valor: nfe,
                    tipoFiltro: 'EQUALS'
                })
            } catch (error) {

            }
        }
        if (codeShipment != "") {
            filters.push({
                campo: "codeShipment",
                valor: codeShipment,
                tipoFiltro: 'EQUALS'
            })
        }
        if (nroEmbarque != "") {
            try {
                let nroEmb = parseInt(nroEmbarque)
                filters.push({
                    campo: "nroEmbarque",
                    valor: nroEmb,
                    tipoFiltro: 'EQUALS'
                })
            } catch (error) {
                console.log("nroEmbarque:", nroEmbarque, " error:", error)
            }
        }
        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = state.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }

        let sortFields = [];
        if (state.sortField) {
            sortFields = [
                {
                    fieldName: state.sortField,
                    order: state.sortOrder > -1 ? `ASC` : `DESC`
                }
            ];
        }

        const params = {
            index: state.index,
            max: state.max,
            permissions: state.permissions,
            filters: filters,
            sortFields,
            fields: [],
            mode: "lazy"
        };
        dispatch(actions.fetchRecordsSuccess([]))
        dispatch(actions.showLoader());
        dispatch(actions.setRecordsCollectionOrders([]));
        api.post("/api/v1/lognex/portalcarrier/get-cargo-shipment-in-transito", params)
            .then((response) => {
                dispatch(actions.hideLoader());
                dispatch(actions.fetchRecordsSuccess(response.data))
                dispatch(actions.setRecordsCargoShiptmentInTrasito(response.data.records));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setRecordsCargoShiptmentInTrasito([]));
                dispatch(actions.hideLoader());
            });
    };
}

actions.loadCargoShipmentDelivered = () => {
    return (dispatch, getState) => {
        const state = getState().crudPortalCarrierState;

        let filters = [...state.filters];
        if (!filters) {
            filters = []
        }
        const initialDateFilter = moment().subtract(1, "months").startOf("day").toDate();
        const finalDateFilter = moment().endOf("day").toDate();
        //filtro ultimos 90 dias
        filters.push({
            campo: "eventRecord.createdAt",
            campoComplementar: "eventRecord.createdAt",
            valor: initialDateFilter,
            valorComplementar: finalDateFilter,
            tipoFiltro: "ENTRE",
        });

        //campos filtros
        let delivery = String(state.delivery).trim()
        let nroNfe = String(state.nroNfe).trim()
        let codeShipment = String(state.codeShipment).trim()
        let nroEmbarque = String(state.nroEmbarque).trim()
        if (delivery != "") {
            filters.push({
                campo: "delivery",
                valor: delivery,
                tipoFiltro: 'EQUALS'
            })
        }
        if (nroNfe != "") {
            try {
                const nfe = parseInt(nroNfe)
                filters.push({
                    campo: "nroNfe",
                    valor: nfe,
                    tipoFiltro: 'EQUALS'
                })
            } catch (error) {

            }
        }
        if (codeShipment != "") {
            filters.push({
                campo: "codeShipment",
                valor: codeShipment,
                tipoFiltro: 'EQUALS'
            })
        }
        if (nroEmbarque != "") {
            try {
                let nroEmb = parseInt(nroEmbarque)
                filters.push({
                    campo: "nroEmbarque",
                    valor: nroEmb,
                    tipoFiltro: 'EQUALS'
                })
            } catch (error) {
                console.log("nroEmbarque:", nroEmbarque, " error:", error)
            }
        }


        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = state.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }

        let sortFields = [];
        if (state.sortField) {
            sortFields = [
                {
                    fieldName: state.sortField,
                    order: state.sortOrder > -1 ? `ASC` : `DESC`
                }
            ];
        }

        const params = {
            index: state.index,
            max: state.max,
            permissions: state.permissions,
            filters: filters,
            sortFields,
            fields: [],
            mode: "lazy"
        };

        dispatch(actions.fetchRecordsSuccess([]))
        dispatch(actions.showLoader());
        dispatch(actions.setRecordsCargoShipmentDelivered([]));
        api.post("/api/v1/lognex/portalcarrier/get-cargo-shipment-delivered", params)
            .then((response) => {
                dispatch(actions.hideLoader());
                dispatch(actions.fetchRecordsSuccess(response.data))
                dispatch(actions.setRecordsCargoShipmentDelivered(response.data.records));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setRecordsCargoShipmentDelivered([]));
                dispatch(actions.hideLoader());
            });

    };
}

actions.countCargoShipmentDelivered = () => {
    return (dispatch, getState) => {
        let filters = [...getState().crudPortalCarrierState.filters];
        if (!filters) {
            filters = []
        }
        const initialDateFilter = moment().subtract(1, "months").startOf("day").toDate();
        const finalDateFilter = moment().endOf("day").toDate();
        //filtro ultimos 90 dias
        filters.push({
            campo: "eventRecord.createdAt",
            campoComplementar: "eventRecord.createdAt",
            valor: initialDateFilter,
            valorComplementar: finalDateFilter,
            tipoFiltro: "ENTRE",
        });

        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = getState().crudPortalCarrierState.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }

        api.post("/api/v1/lognex/portalcarrier/count-cargo-shipment-delivered", { filters, max: 10000 })
            .then((response) => {
                dispatch(actions.setCountCargoShipmentDelivered(response.data.total));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setCountCargoShipmentDelivered(0));
            });

    };
}

actions.changeStatusCargoShipmentToTransito = (oid) => {
    return (dispatch, getState) => {

        const user = storage.getUser();

        if (user.carrier) {

            dispatch(actions.showLoader());
            api.post('/api/v1/lognex/cargo-shipment/set-status-in-transit-shipments', { oids: [oid] })
                .then(response => {
                    dispatch(actions.hideLoader());
                    dispatch(actions.getAllInCollectionOrder());
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(actions.hideLoader());
                    dispatch(actions.setAllInCollectionOrder([]));
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages(["Ops! Falha ao tentar alterar o status para Trânsito"], "error")
                        )
                    );
                })
        } else {
            dispatch(
                messageActions.messageShowMessages(
                    makeMessages(["Somente a transportadora pode alterar o status do embarque"], "error")
                )
            );
        }
    };
}

actions.salvarNovaPlaca = () => {
    return (dispatch, getState) => {

        const user = storage.getUser();

        if (user.carrier) {
            const plate = getState().crudPortalCarrierState.cadVeiculoPlate
            const description = getState().crudPortalCarrierState.cadVeiculoDescription
            let carrierDocument = ""
            if (user.carrier && user.carrier.cnpj) {
                carrierDocument = user.carrier.cnpj
            }

            dispatch(actions.showLoader());
            api.post('/api/v1/lognex/portalcarrier/save-new-plate', { plate, carrierDocument,description })
                .then(response => {
                    dispatch(actions.hideLoader());
                    dispatch(actions.getAllVehicles());
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(actions.hideLoader()); 
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages(["Ops! Falha ao tentar cadastrar uma nova placa, verifique se ela ja existe"], "error")
                        )
                    );
                })
        } else {
            dispatch(
                messageActions.messageShowMessages(
                    makeMessages(["Somente a transportadora pode cadastrar placas de veículos"], "error")
                )
            );
        }
    };
}
actions.getAllVehicles = () => {
    return (dispatch, getState) => {

        const state = getState().crudPortalCarrierState
        let filters = [...state.filters];
        if (!filters) {
            filters = []
        }
        const user = storage.getUser();

        if (user.carrier) {
            filters.push({
                campo: "carrier",
                valor: user.carrier.cnpj,
                tipoFiltro: 'EQUALS'
            })
        } else {
            const carrier = state.carrier;
            if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
                filters.push({
                    campo: "carrier",
                    valor: carrier.documentNumber,
                    tipoFiltro: 'EQUALS'
                })
            }
        }

        let sortFields = [];
        if (state.sortField) {
            sortFields = [
                {
                    fieldName: state.sortField,
                    order: state.sortOrder > -1 ? `ASC` : `DESC`
                }
            ];
        }

        const params = {
            index: state.index,
            max: state.max,
            permissions: state.permissions,
            filters: filters,
            sortFields,
            fields: [],
            mode: "lazy"
        };


        dispatch(actions.fetchRecordsSuccess([]))
        dispatch(actions.showLoader());
        dispatch(actions.setRecordsVehicles([]));
        api.post("/api/v1/lognex/portalcarrier/get-vehicles-carrier", params)
            .then((response) => {
                console.log("response.data:",response.data)
                dispatch(actions.hideLoader());
                dispatch(actions.fetchRecordsSuccess(response.data))
                dispatch(actions.setRecordsVehicles(response.data.records));
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.setRecordsVehicles([]));
                dispatch(actions.hideLoader());
            });

    };
}





export { actions, types };
