import { types } from "./unidade.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
    booleanFilter: {
        ativo: ''
      },
  columns: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "name",
      header: "Razão Social",
      columnKey: "name",
      sortable: true,
      filter: true
    },
    {
      field: "tradeName",
      header: "Nome Fantasia",
      columnKey: "tradeName",
      sortable: true,
      filter: true
    },
    {
      field: "companyName",
      header: "Empresa",
      columnKey: "companyName",
      sortable: false,
      filter: false
    },
    {
      field: "code",
      header: "Código",
      columnKey: "code",
      sortable: true,
      filter: true
    },
    {
      field: "label",
      header: "Label",
      columnKey: "label",
      sortable: true,
      filter: true
    },
  ],
  currentRecord: {
    oid:"",
    name:"",
    tradeName:"",
    documentNumber:"",
    label:"",
    code:"",
    address:{
      street:"",
      complement:"",
      number:"",
      district:"",
      zipCode:"",
      city:"",
      state:"",
      georeference:{
        latitude:"",
        longitude:""
      }
    },
    email:"",
    site:"",
    contact:"",
    phone:"",
    companyName:"",
    oidCompany:"",
    customFields:{},
    stateRegistration:""
  },
  lookupPessoa: {
    visible: false,
    modal: true,
    header: 'Pessoas'
  },
  lookupEmpresa: {
    visible: false,
    modal: true,
    header: 'Empresas'
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDUnidadeReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_PESSOA_VISIBLE:
      return setLoockupPessoaVisible(state, action);
    case types.SET_LOOKUP_EMPRESA_VISIBLE:
      return setLoockupEmpresaVisible(state, action);
      case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action);
    default:
      return result
  }

}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter);
  booleanFilter[action.field] = action.value;
  return Object.assign({}, state, { booleanFilter });
}

function setLoockupPessoaVisible(state, action) {
  let lookupPessoa = state.lookupPessoa;
  lookupPessoa = Object.assign({}, lookupPessoa, { visible: action.visible });
  return Object.assign({}, state, { lookupPessoa })
}
function setLoockupEmpresaVisible(state, action) {
  let lookupEmpresa = state.lookupEmpresa;
  lookupEmpresa = Object.assign({}, lookupEmpresa, { visible: action.visible });
  return Object.assign({}, state, { lookupEmpresa })
}