import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { actions } from "../scriptwriter.actions";
import "./tableRoutes.scss";
import { TableRoutesList } from "./TableRoutesList";
import { RouteRowTreeview } from "./RouteRowTreeview";
import { RouteRowSteps } from "./RouteRowSteps";
import { ButtonDialog } from "../../../components/button-dialog/ButtonDialog";
import { formatNumber, formatNumberDefault, numberToCurrency, segParaHora } from "../../../utils";
import ModalRemoveDelivery from "./selectecVirtualCdRemoveOrder/ModalRemoveDelivery";
import ModalChangeVehicle from "./changeVehicleType/ModalChangeVehicle";
import { MultiSelect } from "primereact/multiselect";
import ModalGroupByClients from "./modalGroupByClients/ModalChangeByClients";

const mapStateToProps = (state) => {
  let recordsOrder = state.crudScriptwriterState.records || [];

  const records = []
  recordsOrder.forEach(o => {
    if (state.crudScriptwriterState.usersSelected && state.crudScriptwriterState.usersSelected.filter(u => String(u.user).toUpperCase() == String(o.owner.user).toUpperCase()).length > 0) {
      records.push(o);
    }
  })



  return {
    columns: state.crudScriptwriterState.routesColumns,
    columnsStep: state.crudScriptwriterState.columnsStep,
    records,
    expandedRows: state.crudScriptwriterState.expandedRows,
    selectedRoutes: state.crudScriptwriterState.selectedRoutes,
    usersSelected: state.crudScriptwriterState.usersSelected,
    columnsShipment: state.crudScriptwriterState.columnsShipment,
    deliveryCDremove: state.crudScriptwriterState.deliveryCDremove,
    startLocation: state.crudScriptwriterState.startLocation,
    routeEditVehicle: state.crudScriptwriterState.routeEditVehicle,
    vehicles: state.crudScriptwriterState.vehicles,
    vehicleTypeColumns: state.crudScriptwriterState.vehicleTypeColumns,
    vehicleSelected: state.crudScriptwriterState.vehicleSelected,
    selectedOrdersDivideRoute: state.crudScriptwriterState.selectedOrdersDivideRoute,
    filterCDSelected: state.crudScriptwriterState.filterCDSelected,
    typeView: state.crudScriptwriterState.typeView,
    allOrders: state.crudScriptwriterState.orders,
    onModalGroupByClientIsVisible: state.crudScriptwriterState.onModalGroupByClientIsVisible,
    deliveryGroupByClientsColumns: state.crudScriptwriterState.deliveryGroupByClientsColumns,
    listClientsDelivery: state.crudScriptwriterState.listClientsDelivery
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setExpandedRows: (expandedRows) => {
      dispatch(actions.setExpandedRows(expandedRows));
    },
    setSelectedRoutes: (selectedRoutes) => {
      dispatch(actions.setSelectedRoutes(selectedRoutes));
    },
    onRemoveRoute: (id) => {
      dispatch(actions.onRemoveRoute(id));
    },
    onRemoveDeliveryRoute: (code, routeId) => {
      dispatch(actions.onRemoveDeliveryRoute(code, routeId));
    },
    showBoxInfoDeliveryMap: (delivery, operationType = null) => {
      dispatch(actions.showBoxInfoDeliveryMap(delivery, operationType));
    },
    setDeliveryToRemoveChangeCD: (deliveryCDremove) => {
      dispatch(actions.setDeliveryToRemoveChangeCD(deliveryCDremove));
    },
    setSelectecVirtualCD: (value) => dispatch(actions.setSelectecVirtualCD(value)),
    setRouteChangeVehicle: (routeEditVehicle) => dispatch(actions.setRouteChangeVehicle(routeEditVehicle)),
    onLoadVehicles: () => dispatch(actions.onLoadVehicles()),
    setChangeVehicleTypeRoute: () => dispatch(actions.setChangeVehicleTypeRoute()),
    setVehicleToChange: (vehicleType) => dispatch(actions.setVehicleToChange(vehicleType)),

    //divide rota
    setSelectedOrdersDivideRoute: (orders) => dispatch(actions.setSelectedOrdersDivideRoute(orders)),
    onClickQuebrarNovaRota: (routeId) => dispatch(actions.onClickQuebrarNovaRota(routeId)),
    onSetSelectedCdFilter: (filterCDSelected) => dispatch(actions.onSetSelectedCdFilter(filterCDSelected)),

    //criar embarque
    createRomaneio: (routeId) => dispatch(actions.createRomaneio(routeId)),

    setTypeView: (typeView) => dispatch(actions.setTypeView(typeView)),
    setOpenModalGrupoByClients: (show) => dispatch(actions.setOpenModalGrupoByClients(show)),
    onConfirmOptmizeRouteByClient: () => dispatch(actions.onConfirmOptmizeRouteByClient()),
    setTypeSelectedVeicleGroupClient: (params) => dispatch(actions.setTypeSelectedVeicleGroupClient(params)),
    setTypeSelectedCDGroupClient: (params) => dispatch(actions.setTypeSelectedCDGroupClient(params)),
    onRemoveAllRoutes: (routeIds) => dispatch(actions.onRemoveAllRoutes(routeIds)),
    onEnableVehicle: (param) => dispatch(actions.onEnableVehicle(param)), //({enable:e.value,indexG,index:indexRem})
  };
};

class TableRoutesListPageContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  setExpandedRowsByRowClick = (e) => {
    if (e.data && e.data.length > 1) {
      if (this.checkOrderAllowed(e.data[1].owner)) {
        this.props.setExpandedRows([e.data[1]]);
      }
    } else if (e.data && e.data.length == 1) {
      if (this.checkOrderAllowed(e.data[0].owner)) {
        this.props.setExpandedRows([e.data[0]]);
      }
    } else {
      if (e.data.length == 0 || this.checkOrderAllowed(e.data.owner)) {
        this.props.setExpandedRows([e.data]);
      }
    }
    this.props.showBoxInfoDeliveryMap(null);
    this.props.setSelectedOrdersDivideRoute([]);
  };

  setExpandedRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      if (this.checkOrderAllowed(e.data[1].owner)) {
        this.props.setExpandedRows([e.data[1]]);
      }
    } else {
      if (this.checkOrderAllowed(e.data.owner)) {
        this.props.setExpandedRows([e.data]);
      }
    }
    this.props.showBoxInfoDeliveryMap(null);
    this.props.setSelectedOrdersDivideRoute([]);
  };
  onSelectedEventsChangeHandler = (e) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    const newList = [];
    e.value.forEach((element) => {
      if (this.checkOrderAllowed(element.owner)) {
        newList.push(element);
      }
    });
    this.props.setSelectedRoutes(newList);
    this.props.showBoxInfoDeliveryMap(null);
    this.props.setSelectedOrdersDivideRoute([]);
  };
  onRemoveRoute = (rowData) => {
    this.props.onRemoveRoute(rowData.oid);
  };

  onRemoveDeliveryRoute = (rowData, oid) => {
    this.props.onRemoveDeliveryRoute(rowData.code, oid);
  };

  checkOrderAllowed = (owner) => {
    for (let index = 0; index < this.props.usersSelected.length; index++) {
      if (this.props.usersSelected[index].user == String(owner.user).toUpperCase()) {
        return true;
      }
    }
    return false;
  };

  onShowChangeCDtoRemoveRoute = (rota, rowData) => {
    let virtualCDs = [];
    if (rota) {
      let pointStart = null;
      const deliverys = []
      rota.shipments.forEach((ship) => {
        if (ship.code != rowData.code) {
          deliverys.push(ship)
        }
      })
      deliverys.forEach((ship) => {
        if (ship.type !== "pontoApoio") {
          if (virtualCDs.filter((vcd) => vcd.cd === ship.pickup.virtualCD && vcd.documentNumber === ship.pickup.documentNumber).length == 0) {
            const cd = {
              cd: ship.pickup.virtualCD,
              location: ship.pickup.location,
              documentNumber: ship.pickup.documentNumber,
              key:ship.pickup.virtualCD + "-" + ship.pickup.documentNumber,
              label: (ship.sender && ship.sender.label) || String(ship.pickup.virtualCD).toUpperCase() + "-" + ship.pickup.documentNumber
            }
            virtualCDs.push(cd);
          }
        }
      });

      this.props.setDeliveryToRemoveChangeCD({ virtualCDs, code: rowData.code, routeId: rota.oid });

      if (virtualCDs.filter((vcd) => vcd.cd === rota.pointStart.cd && vcd.documentNumber === rota.pointStart.documentNumber).length > 0) {
        pointStart = virtualCDs.filter((vcd) => vcd.cd === rota.pointStart.cd && vcd.documentNumber === rota.pointStart.documentNumber)[0];
      } else {
        if (virtualCDs.length > 0) {
          pointStart = virtualCDs[0];
        }
      }
      this.props.setSelectecVirtualCD(pointStart);
    }
  };
  findRotaByOrderCode = (code) => {
    if (this.props.records) {
      for (let index = 0; index < this.props.records.length; index++) {
        if (this.props.records[index].codes.indexOf(code) > -1) {
          return this.props.records[index];
        }
      }
    }
    return null;
  };
  onClickChangeVehicle = (rowData) => {
    this.props.setVehicleToChange(rowData.vehicle);

    this.props.onLoadVehicles();
    this.props.setRouteChangeVehicle(rowData);
  };
  onSelectOrdersRoute = (e) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    this.props.setSelectedOrdersDivideRoute(e.value);
  };

  getOrderShowBoxInfoDeliveryMap = (rowData) => {
    const route = this.findRotaByOrderCode(rowData.code);
    if (route) {
      for (const order of route.shipments) {
        if (order.code == rowData.code) {
          this.props.showBoxInfoDeliveryMap(order, rowData.type);
          break
        }
      }
    }
  }

  onClickOpenModalApplyOtmizeByClient = (show) => {
    this.props.onLoadVehicles();
    this.props.setOpenModalGrupoByClients(show)
  }

  onRemoveAllRoutes = () => {
    let routeIds = this.props.selectedRoutes.map(r => {
      return r.oid
    })
    this.props.onRemoveAllRoutes(routeIds);
  }

  render() {
    const templateDuration = (rowData, e) => {
      if (rowData.invalid) {
        return <span style={{ color: "red" }} title={"Rota inválida"}>{segParaHora(rowData.duration)}</span>;
      }
      return <span>{segParaHora(rowData.duration)}</span>;
    };

    const templateDistanceKm = (rowData, e) => {
      if (rowData.invalid) {
        return <span style={{ color: "red" }} title={"Rota inválida"}>{rowData.distanceKm} Km</span>;
      }
      return <span>{rowData.distanceKm} Km</span>;
    };

    const templateBodyCd = (rowData, e) => {
     try {
      if (rowData.invalid) {
        return <span style={{ color: "red" }} title={"Rota inválida"}>{rowData.pointStart.cd}</span>;
      }
      return <span>{rowData.pointStart.cd}</span>;
     } catch (error) {
      console.error(error);
     }
    };

    const templateBodyVehicle = (rowData, e) => {
      try {

        if (rowData.invalid) {
          return <span style={{ color: "red" }} title={"Rota inválida"}>{rowData.vehicle.name}</span>;
        }
        return <span>{rowData.vehicle.name}</span>;

      } catch (error) {
        return <></>
      }
    };

    const templateRouteColor = (rowData, e) => {
      return <div style={{ backgroundColor: rowData.routeColor, height: "20px" }}></div>;
    };

    const templateTypeStep = (rowData, e) => {
      switch (rowData.type) {
        case "pickup":
          return <span>{"COLETA"}</span>;
        case "delivery":
          return <span>{"ENTREGA"}</span>;
        default:
          return <span>{"-"}</span>;
      }
    };

    const templateRouteRemove = (rowData, e) => {
      const isDisable = !this.checkOrderAllowed(rowData.owner);

      return (
        <div>
          <ButtonDialog
            onClick={(e) => {
              this.onRemoveRoute(rowData);
            }}
            button={{
              title: "Remover",
              disabled: isDisable,
              className: "lognex-btn-danger-inverse",
            }}
            dialog={{
              header: "Confirmação",
              modal: true,
              message: "Deseja realmente remover essa rota?",
            }}
          ></ButtonDialog>
        </div>
      );
    };

    const templateGerarEmbarqueRoute = (rowData, e) => {
      const isDisable = rowData.createEmbarque || rowData.invalid;//!this.checkOrderAllowed(rowData.owner);

      return (
        <div>
          <ButtonDialog
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              //createRomaneio
              this.props.createRomaneio(rowData.oid);
            }}
            button={{
              title: "Gerar romaneio",
              disabled: isDisable,
              className: "lognex-btn-highlighted-inverse",
              iconClassName: "pi pi-check",
            }}
            dialog={{
              header: "Confirmação",
              modal: true,
              message: "Deseja gerar o romaneio ?",
            }}
          ></ButtonDialog>
        </div>
      );
    };

    const templateBtnViewMap = (rowData, e) => {
      return (
        <div>
          <button
            className="lognex-btn-highlighted-inverse"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.showBoxInfoDeliveryMap(rowData);
            }}
          >
            <i className={"pi pi-eye"}></i>
          </button>
        </div>
      );
    };

    const templateBtnViewMapTypeView = (rowData, e) => {
      return (
        <div>
          <button
            className="lognex-btn-highlighted-inverse"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.getOrderShowBoxInfoDeliveryMap(rowData);
            }}
          >
            <i className={"pi pi-eye"}></i>
          </button>
        </div>
      );
    };
    const templateRemoveDeliveryRoute = (rowData, e) => {
      // const isDisable = !this.checkOrderAllowed(rowData.owner);
      const rota = this.findRotaByOrderCode(rowData.code);
      if (rota && rota.shipments.length == 1) {
        return (
          <div>
            <ButtonDialog
              onClick={(e) => {
                this.onRemoveDeliveryRoute(rowData, rota.oid);
              }}
              button={{
                title: "Remover",
                // disabled: isDisable,
                className: "lognex-btn-danger-inverse",
              }}
              dialog={{
                header: "Confirmação",
                modal: true,
                message: "Deseja realmente remover essa delivery?",
              }}
            ></ButtonDialog>
          </div>
        );
      } else {
        return (
          <div>
            <button
              className="lognex-btn-danger-inverse"
              onClick={(e) => {
                this.onShowChangeCDtoRemoveRoute(rota, rowData);
              }}
            >
              <i className={"pi pi-trash"}></i>
            </button>
          </div>
        );
      }
    };


    let columns = [
      <Column key="selectorColumn" selectionMode="multiple" style={{ width: "3em" }} />,
      <Column expander={true} key={"routerTable"} style={{ width: "3em" }} />,
    ];
    columns = [
      ...columns,
      ...this.props.columns
        .filter((col) => col.field != "vehicle.nome")
        .map((col) => {
          if (col.field == "oid") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "50px" }} />;
          } else if (col.field == "pointStart.cd") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "45px" }} body={templateBodyCd} />;
          } else if (col.field == "distanceKm") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px" }} body={templateDistanceKm} />;
          } else if (col.field == "duration") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px" }} body={templateDuration} />;
          } else if (col.field == "routeColor") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "35px" }} body={templateRouteColor} />;
          } else if (col.field == "btnEmbarque") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "45px" }} body={templateGerarEmbarqueRoute} />;
          } else if (col.field == "btnRemoveRoute") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "45px" }} body={templateRouteRemove} />;
          } else if (col.field == "vehicle.name") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px" }} body={templateBodyVehicle} />;
          } else {
            return <Column {...col} key={col.field} style={{ fontSize: "10px" }} />;
          }

        }),
    ];

    Number.prototype.round = function (places) {
      return +(Math.round(this + "e+" + places) + "e-" + places);
    };

    const templateExpandedRow = (rowData, e) => {
      let columns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: "3em" }} />];
      columns = [
        ...columns,
        ...this.props.columnsShipment.map((col) => {
          if (col.field == "code") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "70px" }} />;
          }
          if (col.field == "pickup.virtualCD") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "50px" }} />;
          } else if (col.field == "distance") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "60px" }} />;
          } else if (col.field == "btnRemoveRoute") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "50px" }} body={templateRemoveDeliveryRoute} />;
          } else if (col.field == "btnViewMap") {
            return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "50px" }} body={templateBtnViewMap} />;
          } else {
            return <Column {...col} key={col.field} style={{ fontSize: "10px" }} />;
          }
        }),
      ];


      let columnsSteps = this.props.columnsStep.map((col) => {
        if (col.field == "code") {
          return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "80px" }} />;
        } else if (col.field == "type") {
          return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "70px" }} body={templateTypeStep} />;
        } else if (col.field == "btnViewMap") {
          return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "50px" }} body={templateBtnViewMapTypeView} />;
        }

        return <Column {...col} key={col.field} style={{ fontSize: "10px" }} />;
      })




      const filterCDSelected = this.props.filterCDSelected;
      const recordsBase = rowData.shipments; //
      const records = filterCDSelected && filterCDSelected.length > 0 ? [] : recordsBase;
      //rowData
      let volumes = [];
      let pesoTotal = 0;
      recordsBase.forEach((e) => {
        //validar o filtro de CD
        if (filterCDSelected && filterCDSelected.length > 0) {
          if (filterCDSelected.filter((or) => or.cd == e.pickup.virtualCD).length > 0) {
            records.push(e);
          }
        }

        pesoTotal += e.weight;
        e.dimensions.forEach((v) => {
          let encontrou = false;
          for (let index = 0; index < volumes.length; index++) {
            if (volumes[index].Name === v.name) {
              volumes[index].Value += v.value;
              encontrou = true;
              break;
            }
          }
          if (!encontrou) {
            //mostrar somente PALLET e Saco
            if (String(v.name).trim().toUpperCase() === "PALLET" || String(v.name).trim().toUpperCase() === "SACO") {
              volumes.push({ Name: v.name, Value: v.value });
            }
          }
        });
      });

      const rowClick = (e) => {
        e.originalEvent.preventDefault();
        e.originalEvent.stopPropagation();
        this.props.showBoxInfoDeliveryMap(null);
      };

      const valorFrete = rowData.infoRouteFrete && rowData.infoRouteFrete.custoSaco ? parseFloat(rowData.infoRouteFrete.custoSaco) : 0;
      const nameVeiculo = rowData.infoRouteFrete && rowData.infoRouteFrete.nome ? rowData.infoRouteFrete.nome : "";
      const capacidadePeso = rowData.infoRouteFrete && rowData.infoRouteFrete.capacidadePeso ? rowData.infoRouteFrete.capacidadePeso : 0;
      const capacidadePallets =
        rowData.infoRouteFrete && rowData.infoRouteFrete.capacidadePallets ? rowData.infoRouteFrete.capacidadePallets : 0;
      const percentOcupaPeso =
        rowData.infoRouteFrete && rowData.infoRouteFrete.percentOcupaPeso ? rowData.infoRouteFrete.percentOcupaPeso : 0;
      const percentOcupaPallets = rowData.infoRouteFrete && rowData.infoRouteFrete.percentOcupaPallets ? rowData.infoRouteFrete.percentOcupaPallets : 0;

      //STEPS

      return (
        <div className="content-info-route">
          <div className="info-veiculo-carga">
            <div
              className="content-vehicle"
              title="Alterar o tipo de veículo?"
              onClick={() => {
                this.onClickChangeVehicle(rowData);
              }}
            >
              <h3>{"Veículo"}</h3> <i className="pi pi-pencil"></i>
              <br />
              <div className="card-info">
                <div>
                  <p>{nameVeiculo}</p>
                  <p>
                    {capacidadePeso}
                    {" Kg"}{" "}
                  </p>
                  <p>
                    {capacidadePallets}
                    {" pallets"}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h3>{"Carga"}</h3>
              <br />
              <div className="card-info">
                <p>
                  {formatNumberDefault(parseFloat(pesoTotal).round(3))}
                  {" kg"}
                </p>
                {volumes.map((v, i) => {
                  return (
                    <p key={i}>
                      {formatNumberDefault(parseFloat(v.Value).round(3))} {v.Name}
                    </p>
                  );
                })}
              </div>
            </div>
            {/* percentual de Ocupação do veiculo */}
            <div>
              <h3>{"% Veículo X Carga"}</h3>
              <br />
              <div className="card-info">
                <p className={`${percentOcupaPeso > 100 ? "warning" : ""}`}>
                  {"Peso "}
                  {formatNumber(percentOcupaPeso)}
                  {"%"}
                </p>
                <p className={`${percentOcupaPallets > 100 ? "warning" : ""}`}>
                  {"Pallets "}
                  {formatNumber(percentOcupaPallets)}
                  {"%"}
                </p>
                <p>
                  {"Custo/Saco "}
                  {numberToCurrency(valorFrete, "R$")}
                </p>
              </div>
            </div>
          </div>

          <div className="info-veiculo-carga">
            <div className="content-btn-edit-route">
              <ButtonDialog
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.onClickQuebrarNovaRota(rowData.oid);
                }}
                button={{
                  title: "Gerar nova rota com as ordens selecionadas",
                  disabled:
                    this.props.selectedOrdersDivideRoute.length == 0 || this.props.selectedOrdersDivideRoute.length == records.length,
                  className: "lognex-btn-highlighted-inverse btn",
                  text: "Quebrar rota",
                  iconClassName: "pi pi-pencil",
                  style: { width: "90px", cursor: "pointer" },
                }}
                notShowIcon={true}
                dialog={{
                  header: "Confirmação",
                  modal: true,
                  message: "Deseja realmente gerar uma nova rota com as ordens selecionadas ?",
                }}
              ></ButtonDialog>

              {/* <button className="lognex-btn-highlighted-inverse btn" title={"Alterar o centro de distribuição da rota?"}>
                CD Origem
                <i className={"pi pi-pencil"}></i>
              </button>  */}

              {/* <MultiSelect
                optionLabel="cd"
                tooltip={"Filtrar por CD"}
                value={this.props.filterCDSelected}
                options={virtualCDRoute}
                placeholder={"Filtrar CD"}
                onChange={(e) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  console.log(e.value);
                  this.props.onSetSelectedCdFilter(e.value);
                }}
              /> */}
              <button onClick={(e) => { this.props.setTypeView(0); }} className="lognex-btn-highlighted-inverse btn" title={"Mostrar somente a sequência de entregas"}>
                Entregas
              </button>
              <button onClick={(e) => { this.props.setTypeView(1); }} className="lognex-btn-highlighted-inverse btn" title={"Mostrar sequência de coleta e entregas"}>
                Colet/Entreg
              </button>
              <h3>Total: {records.length}</h3>
            </div>
          </div>
          {/* Entregas */}
          {this.props.typeView == 0 &&
            <RouteRowTreeview
              columns={columns}
              records={records}
              rowClickHandler={rowClick}
              onSelectionChange={this.onSelectOrdersRoute}
              selectedOrdersDivideRoute={this.props.selectedOrdersDivideRoute}
            />
          }
          {/* Steps */}
          {this.props.typeView == 1 &&
            <RouteRowSteps columns={columnsSteps}
              records={rowData.steps}
              rowClick={rowClick} />
          }
        </div>
      );
    };

    return (
      <div className="route-content">
        {this.props.deliveryCDremove && (
          <ModalRemoveDelivery
            deliveryCDremove={this.props.deliveryCDremove}
            visible={this.props.deliveryCDremove != null}
            onHide={() => this.props.setDeliveryToRemoveChangeCD(null)}
            onConfirm={this.props.onRemoveDeliveryRoute}
            setSelectecVirtualCD={this.props.setSelectecVirtualCD}
            startLocation={this.props.startLocation}
          />
        )}

        {this.props.routeEditVehicle && (
          <ModalChangeVehicle
            vehicles={this.props.vehicles}
            visible={this.props.routeEditVehicle != null}
            onHide={() => this.props.setRouteChangeVehicle(null)}
            onConfirm={this.props.setChangeVehicleTypeRoute}
            vehicleSelected={this.props.vehicleSelected}
            routeEditVehicle={this.props.routeEditVehicle}
            setVehicleToChange={this.props.setVehicleToChange}
            columns={this.props.vehicleTypeColumns}
          />
        )}

        <div className="header-routes">
          <div><h3>{"Rotas"}</h3></div>
          <div style={{ display: "flex" }}>

            {this.props.listClientsDelivery.length > 0 &&
              <div className="btn-header-route">
                <button
                  className={"lognex-btn-highlighted"}
                  style={{ width: "150px", cursor: "pointer" }}
                  onClick={() => this.onClickOpenModalApplyOtmizeByClient(true)}
                >
                  {"Agrupar por clientes?"}
                </button>
                {/* <span style={{ color: "red" }}>{this.listClientsDelivery.length} clientes</span> */}
              </div>
            }
            <div className="btn-header-route">
              <ButtonDialog
                onClick={(e) => {
                  this.onRemoveAllRoutes();
                }}
                button={{
                  title: "Remover rotas",
                  disabled: this.props.records.length == 0 || !this.props.selectedRoutes || this.props.selectedRoutes.length == 0,
                  className: "lognex-btn-danger",
                }}
                dialog={{
                  header: "Confirmação",
                  modal: true,
                  message: "Deseja realmente remover as rotas selecionadas?",
                }}
              ></ButtonDialog>
            </div>
          </div>
          {/* <button className={"lognex-btn-highlighted"} title="Gerar embarque" style={{ width: "120px", cursor: "pointer" }} onClick={this.onClickGerarEmbarque}>
                Apli
              </button>  */}

        </div>
        {
          this.props.onModalGroupByClientIsVisible && (
            <ModalGroupByClients
              vehicles={this.props.vehicles}
              records={this.props.listClientsDelivery}
              visible={this.props.onModalGroupByClientIsVisible}
              onHide={() => this.props.setOpenModalGrupoByClients(false)}
              onConfirm={this.props.onConfirmOptmizeRouteByClient}
              columns={this.props.deliveryGroupByClientsColumns}
              setTypeSelectedVeicleGroupClient={this.props.setTypeSelectedVeicleGroupClient}
              setTypeSelectedCDGroupClient={this.props.setTypeSelectedCDGroupClient}
              onEnableVehicle={this.props.onEnableVehicle}
            />
          )
        }


        <TableRoutesList
          templateExpandedRow={templateExpandedRow}
          // rowToggleHandler={this.setExpandedRowsClick}
          rowExpandButtonOnClick={this.setExpandedRowsByRowClick}
          expandedRows={this.props.expandedRows}
          records={this.props.records}
          columns={columns}
          selectedRoutes={this.props.selectedRoutes}
          onSelectedEventsChange={this.onSelectedEventsChangeHandler}
        />
      </div >
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableRoutesListPageContainer);
