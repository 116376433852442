
import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../portalCarrier.actions';
import { Page } from "../../../../../components/page/Page";
import Toolbar from '../../../../../components/toolbar/Toolbar';
import { routes } from '../../../../../constants';
import { Column } from 'primereact/column';
import { TableRowTreeview } from './TableRowTreeview';
import { CollectionOrdersOpenList } from './CollectionOrdersOpenList';
import { numberToCurrency } from '../../../../../utils';
import storage from '../../../../../services/stoage.service'
import { AppService, COMPANY_CHANGE_EVENT_NAME } from '../../../../../services/app.service';
import { ORDER_PENDENCE_ACEITE } from '../../../constantes';
import moment from 'moment';

const mapStateToProps = state => {
  const records = state.crudPortalCarrierState.collectionOrdersPendeceAceite;
  let columns = [];
  const columnsRowTreeviewTemplate = state.crudPortalCarrierState.columnsRowTreeviewTemplate;
  const columnsRowTreeviewTransfTemplate = state.crudPortalCarrierState.columnsRowTreeviewTransfTemplate;

  const user = storage.getUser();

  if (!user.carrier) {

    columns = [...state.crudPortalCarrierState.columnsCollectionOrdersPendeceAceite, {
      field: "collectionOrder.carrier.name",
      header: "Transportadora",
      columnKey: "collectionOrder.carrier.name",
      sortable: false,
      filter: false
    }]
  } else {
    columns = [...state.crudPortalCarrierState.columnsCollectionOrdersPendeceAceite]
  }

  return {
    records,
    columns,
    //devera ser limpo a cada troca de tela
    first: state.crudPortalCarrierState.index,
    rows: state.crudPortalCarrierState.max,
    totalRecords: state.crudPortalCarrierState.count,
    sortField: state.crudPortalCarrierState.sortField,
    order: state.crudPortalCarrierState.sortOrder,

    expandedRows: state.crudPortalCarrierState.expandedRows,
    columnsRowTreeviewTemplate,
    openDialogIframeVisible: state.crudPortalCarrierState.openDialogIframeVisible,
    link: state.crudPortalCarrierState.link,
    carrier: state.crudPortalCarrierState.carrier,
    columnsRowTreeviewTransfTemplate,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => {
      if (paginationParams.index !== undefined) {
        dispatch(actions.setIndex(paginationParams.index));
      }
      if (paginationParams.page !== undefined) {
        dispatch(actions.setPage(paginationParams.page));
      }
      if (paginationParams.max !== undefined) {
        dispatch(actions.setMax(paginationParams.max));
      } 
    },
    cleanPaginate: () => {
      dispatch(actions.setIndex(0));
      dispatch(actions.setPage(0));
      dispatch(actions.setMax(50)); 
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },

    loadCollectionOrders: () => dispatch(actions.loadCollectionOrders()),
    setCurrentScreen: (currentScreen) => dispatch(actions.setCurrentScreen(currentScreen)),
    setExpandedRows: (expandedRows) => {
      dispatch(actions.setExpandedRows(expandedRows));
    },
    openDialogIframeCollectionOrder: (visible, link) => {
      dispatch(actions.openDialogIframeCollectionOrder({ visible, link }));
    },
    setRecordsCollectionOrders: (filters) => dispatch(actions.setRecordsCollectionOrders(filters)),
  }
}

class CollectionOrdersOpenPageContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogWidth: '100%',
      dialogHeight: '98%',
      windowHeight: 800,
    };
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.setCurrentScreen(ORDER_PENDENCE_ACEITE);
    this.props.loadCollectionOrders();
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.cleanPaginate();
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME) {
      this.props.setCurrentScreen(ORDER_PENDENCE_ACEITE);
      this.props.loadCollectionOrders();
    }
  };
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}`)
  }

  setExpandedRowsByRowClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else if (e.data && e.data.length == 1) {
      this.props.setExpandedRows([e.data[0]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  setExpandedRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  onRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
    this.props.loadCollectionOrders();
  }
  render() {

    const title = `Ordens pendentes de aceite`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onSearch={this.searchHandler} />
        </div>
      </div>
    );


    const numberTemplate = (rowData, column) => {
      try {
        const valor = rowData[column.field];
        let numero = "";
        if (valor) {
          numero = !valor ? 0.0 : parseFloat(valor).toFixed(3);
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {numero}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };


    const pesoTemplate = (rowData, column) => {
      try {
        const valor = rowData.collectionOrder.cargo.weight;
        let numero = "";
        if (valor) {
          numero = !valor ? 0.0 : parseFloat(valor).toFixed(3);
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {numero}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const dateTemplate = (rowData, column) => {
      try {
        const date = rowData[column.field];
        if (moment(date).isValid) {
          const formattedDate = moment(date).format("DD/MM/YYYY");
          return <div style={{ textAlign: "center", color: "black" }}>{formattedDate}</div>;
        } else {
          return <div></div>
        }
      } catch (error) {
        return <div></div>
      }
    };

    const valorCustoTemplate = (rowData, column) => {
      try {
        const valor = Number(rowData[column.field]);
        let formattedDate = "";
        if (valor) {
          formattedDate = numberToCurrency(valor, "R$");
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {formattedDate}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const btnAceiteTemplate = (rowData, column) => {
      try {

        return (
          <div style={{ textAlign: "center" }}>
            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.openDialogIframeCollectionOrder(true, rowData.collectionOrder.link);
                // 
                this.props.history.push(`${routes.PATH_CARRIER_PORTAL_COLLECTION_ORDER}`)
              }}

              title={"Abrir pagina para dar o aceite"}
            >
              <i className="pi pi-thumbs-up"></i>
            </button>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const templateExpandedRow = (rowData, e) => {
      const records = [];
      try {
        let sequencia = 0;
        rowData.destination.forEach((dest) => {
          sequencia++;
          records.push({
            index: sequencia,
            ...dest
          });
        });
      } catch (error) {
        console.log("Erro templateExpandedRow=", error);
      }
      let transferenceRecord = []
      let transferenceColumns = []
      if (rowData.ordersByTransfer && rowData.ordersByTransfer.length > 0) {
        transferenceRecord = rowData.ordersByTransfer;
        transferenceColumns = this.props.columnsRowTreeviewTransfTemplate.map((col) => {

          if (col.field === "type") {
            return <Column {...col} key={col.field} style={{ width: "130px" }} />;
          }
          if (col.field === "orderCodeTransfer") {
            return <Column {...col} key={col.field} style={{ width: "130px" }} />;
          }
          if (col.field === "code") {
            return <Column {...col} key={col.field} style={{ width: "130px" }} />;
          }
          if (col.field === "apportionmentValue") {
            return <Column {...col} key={col.field} style={{ width: "130px" }} body={valorCustoTemplate} />;
          }
          return <Column {...col} key={col.field} />;
        })
      }
      // OrdersByTransfer 

      let columnsTreeview = this.props.columnsRowTreeviewTemplate.map((col) => {
        if (col.field === "type") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} />;
        }
        if (col.field === "code") {
          return <Column {...col} key={col.field} style={{ width: "100px" }} />;
        }
        if (col.field === "situation") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} />;
        }
        if (col.field === "previsionDate") {
          return <Column {...col} key={col.field} style={{ width: "120px" }} />;
        }
        if (col.field === "qtdeSacos") {
          return <Column {...col} key={col.field} style={{ width: "70px" }} />;
        }
        if (col.field === "qtdePallets") {
          return <Column {...col} key={col.field} style={{ width: "70px" }} />;
        }
        if (col.field === "apportionmentValue") {
          return <Column {...col} key={col.field} style={{ width: "130px" }} body={valorCustoTemplate} />;
        }
        if (col.field === "cd") {
          return <Column {...col} key={col.field} style={{ width: "180px" }} />;
        }
        return <Column {...col} key={col.field} />;
      });

      return (
        <div>
          <h3>Ordem de Execução</h3>
          <TableRowTreeview records={records} columns={columnsTreeview} />
          {transferenceRecord.length > 0 &&
            <div>
              <h3>Pedidos de Transferência</h3>
              <TableRowTreeview records={transferenceRecord} columns={transferenceColumns} />
            </div>
          }
        </div>
      );
    };

    const columns = this.props.columns.map((col) => {
      if (col.field === "freightValue") {
        return <Column {...col} key={col.field} style={{ width: "130px" }} />;
      }
      if (col.field === "oid") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} />;
      }
      if (col.field === "totalValueProduct") {
        return <Column {...col} key={col.field} style={{ width: "130px" }} />;
      }

      if (col.field === "distance") {
        return <Column {...col} key={col.field} style={{ width: "110px" }} body={numberTemplate} />;
      }
      if (col.field === "darAceite") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} body={btnAceiteTemplate} />;
      }
      if (col.field === "collectionOrder.cargo.weight") {
        return <Column {...col} key={col.field} style={{ width: "110px" }} body={pesoTemplate} />;
      }
      if (col.field === "qtdeTotalSacos") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} />;
      }
      if (col.field === "qtdeTotalPallets") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} />;
      }
      if (col.field === "collectionOrder.pickupDate") {
        return <Column {...col} key={col.field} style={{ width: "110px" }} />;
      }
      if (col.field === "collectionOrder.vehicleType") {
        return <Column {...col} key={col.field} style={{ width: "130px" }} />;
      }
      if (col.field === "collectionOrder.origin.name") {
        return <Column {...col} key={col.field} style={{ width: "160px" }} />;
      }
      if (col.field === "collectionOrder.destination.name") {
        return <Column {...col} key={col.field} style={{ width: "160px" }} />;
      }


      return <Column {...col} key={col.field} />;
    });

    const content = (
      <div>
        <CollectionOrdersOpenList
          records={this.props.records}
          rowToggleHandler={this.setExpandedRowsClick}
          rowExpandButtonOnClick={this.setExpandedRowsByRowClick}
          expandedRows={this.props.expandedRows}
          templateExpandedRow={templateExpandedRow}
          onRowsClick={this.onRowsClick}
          onPage={this.onPage} 
          first={this.props.first}
          rows={this.props.rows}
          totalRecords={this.props.totalRecords}
          sortField={this.props.sortField}
          order={this.props.order}
          columns={columns} />
      </div>
    );

    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionOrdersOpenPageContainer);
