import React from "react";
import L, { Icon, map, marker, point } from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import leafletPip from "@mapbox/leaflet-pip";
import polyUtil from "@mapbox/polyline";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import { actions } from "../scriptwriter.actions";

import pin_green from "../../../assets/images/icons/pin_green.svg";
import pin_orange from "../../../assets/images/icons/orange.svg";
import pin_green_light from "../../../assets/images/icons/pin_green_light.svg";
import pin_red from "../../../assets/images/icons/pin_red.svg";
import pin_blue from "../../../assets/images/icons/pin_blue.svg";
import pin_ponto_apoio from "../../../assets/images/icons/ponto-apoio.svg";
// import pin_ponto_apoio_sel  from "../../../assets/images/icons/pin_ponto_apoio_sel.png";
import pin_home_origin from "../../../assets/images/icons/icon-cd.svg";
import pin_vehicle_origem from "../../../assets/images/icons/truck_origem.png";

import "./travel-map.scss";

import moment from "moment";
import { NOT_SCRIPTED, SCRIPTED } from "../contantes";
import { formatNumberDefault } from "../../../utils";

const mapStateToProps = (state) => {
  const selectedRoutes = state.crudScriptwriterState.selectedRoutes;

  return {
    selectedRoutes,
    defaultPosition: state.crudScriptwriterState.defaultPosition,
    routeColors: state.crudScriptwriterState.routeColors,
    lineStyle: state.crudScriptwriterState.lineStyle,
    unidadeRecords: state.crudScriptwriterState.unidadeRecords,
    orders: state.crudScriptwriterState.orders,
    // ordersNotScripted: state.crudScriptwriterState.ordersNotScripted,
    mostrarTodas: state.crudScriptwriterState.mostrarTodas,
    usersSelected: state.crudScriptwriterState.usersSelected,
    ordersNotScriptedSeletected: state.crudScriptwriterState.ordersNotScriptedSeletected,
    deliveryInfoBox: state.crudScriptwriterState.deliveryInfoBox,
    typeView: state.crudScriptwriterState.typeView,
    operationType: state.crudScriptwriterState.operationType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setTavelDeliveryDate: (travelData) => {
      dispatch(actions.setTavelDeliveryDate(travelData));
    },
    setOrdersNotScriptedSeletected: (orders) => dispatch(actions.setOrdersNotScriptedSeletected(orders)),
    showEditDelivery: (order) => dispatch(actions.showEditDelivery(order)),
    updatePostionPontoApoio:(updatePontoApoio)  => dispatch(actions.updatePostionPontoApoio(updatePontoApoio)),
  };
};

const htmlDecodeEdit = (input) => {
  var e = document.createElement("div");
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};
const BoxPopupEdit = (param) => {
  return (
    <div className="content-delivery-box">
      <div dangerouslySetInnerHTML={{ __html: htmlDecodeEdit(ReactDOMServer.renderToString(param.detail)) }}></div>

      <div>
        <button id="popup-button-id-order" data={JSON.stringify({ ...param })} className="btn-click-edit-delivery" type="button">
          Alterar dados da Ordem
        </button>
        <br></br>
        <button id="popup-button-id-order-select" data={JSON.stringify({ ...param })} className="btn-click-edit-delivery" type="button">
          Selecionar
        </button>
      </div>
    </div>
  );
};
class Map extends React.Component {
  constructor(props) {
    super(props);
    this.data = { bounds: L.latLngBounds(), markersPaths: [], layersCds: [], orderLayers: [], deliveryInfoBox: [] };
    // this.isClickCtr = false;
    this.markersGroupCds = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
    });
    this.markersGroupOrder = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: false, animate: true, spiderfyOnEveryZoom: true
    });
    this.state = {
      travelsRender: [],
    };
    //this.enableMouseMove = this.enableMouseMove.bind(this);
    this.rectangle = null;
    this.startLatlng = null;
  }
  componentDidMount() {
    //   this.isClickCtr = false;
    this.map = L.map("map", {
      center: this.props.defaultPosition,
      zoom: 5,
      zoomControl: true,
      layers: [
        L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    });
    this.map.on("click", (event) => {
      console.log(" event.latlng=", event.latlng);
    });
    this.markersGroupOrder.addTo(this.map);
  }

  checkOrderAllowed = (owner) => {
    for (let index = 0; index < this.props.usersSelected.length; index++) {
      if (this.props.usersSelected[index].user == String(owner.user).toUpperCase()) {
        return true;
      }
    }
    return false;
  };

  clearCds = () => {
    this.markersGroupCds.clearLayers();
  };

  removeCD = (latLng) => {
    let newListAux = [];
    for (var i = 0; i < this.data.layersCds.length; i++) {
      if (this.data.layersCds[i].lat == latLng.lat && this.data.layersCds[i].lng == latLng.lng) {
        // this.map.removeLayer(this.data.layersCds[i].layer);
        this.markersGroupCds.removeLayer(this.data.layersCds[i].layer);
      } else {
        newListAux.push(this.data.layersCds[i]);
      }
    }
    this.data.layersCds = newListAux;
  };

  setLayerCD = (marker) => {
    this.data.layersCds.push(marker);
  };

  getCoordenadasCDS = (cd) => {
    try {
      if (cd.address.georeference && cd.address.georeference.latitude) {
        return { lat: cd.address.georeference.latitude, lng: cd.address.georeference.longitude };
      }
    } catch (error) { }
    return null;
  };
  renderCdsOnMap = () => {
    this.clearCds();

    if (this.props.unidadeRecords && this.props.unidadeRecords.length > 0) {
      try {
        this.markersGroupCds.addTo(this.map);

        this.props.unidadeRecords.forEach((c) => {
          const coordenadasCds = this.getCoordenadasCDS(c);

          if (coordenadasCds != null) {
            const markerCD = L.marker([coordenadasCds.lat, coordenadasCds.lng], { opacity: 0.5 });
            markerCD.bindPopup(`Centro de Distribuição: <b>${c.label}</b> <br /> `);
            markerCD.setIcon(
              new Icon({
                iconUrl: pin_home_origin,
                iconRetinaUrl: pin_home_origin,
                iconSize: point(36, 22),
                iconAnchor: [15, 15],
              })
            );
            this.markersGroupCds.addLayer(markerCD);
            this.setLayerCD({ layer: markerCD, lat: coordenadasCds.lat, lng: coordenadasCds.lng });
          }
        });
      } catch (error) { }
    }
  };
  setNewLayerPath = (marker) => {
    this.data.markersPaths.push(marker);
  };

  setNewBounds = (coords) => {
    this.data.bounds.extend([coords[0], coords[coords.length - 1]]);
  };

  fitView = () => {
    if (this.data.bounds) {
      try {
        this.map.fitBounds(
          this.data.bounds,
          {
            paddingBottomRight: [10, 0],
            paddingTopLeft: [10, 0],
          },
          { duration: 3 }
        );
      } catch (e) {
        console.log("Erro fitView ", e);
      }
    }
  };

  clearMapPaths = () => {
    for (var i = 0; i < this.data.markersPaths.length; i++) {
      this.map.removeLayer(this.data.markersPaths[i]);
    }
    this.data.markersPaths = [];
  };

  clearOrderLayersMap = () => {
    for (var i = 0; i < this.data.orderLayers.length; i++) {
      this.map.removeLayer(this.data.orderLayers[i]);
    }
    this.data.orderLayers = [];
  };
  setOrderLayerLayer = (marker) => {
    this.data.orderLayers.push(marker);
  };

  addMarkerRouter = () => {
    //
    this.clearMapPaths();

    try {
      this.data.bounds = L.latLngBounds();
      this.props.selectedRoutes.map((route, index) => {
        const isAllowed = this.checkOrderAllowed(route.owner);
        if (isAllowed) {
          var latlngs = polyUtil.decode(route.geometry);
          this.setNewBounds(latlngs);

          var routeColor = route.routeColor; // this.props.routeColors[index % this.props.routeColors.length];

          var path = new L.Polyline(latlngs, {
            opacity: this.props.lineStyle.opacity,
            weight: this.props.lineStyle.weight,
            color: routeColor,
            offset: 10
          }).addTo(this.map);

          this.setNewLayerPath(path);
        }
      });

      if (this.data.markers.length > 0) {
        this.fitView();
      }
    } catch (error) { }
  };

  renderCDfrmOrder = (order, renderVehicle = false) => {
    try {
      const markerOrderOrigem = L.marker([order.pickup.location[1], order.pickup.location[0]]);
      markerOrderOrigem.bindPopup(`Centro de distribuição: <b>${order.pickup.virtualCD}</b>`);
      markerOrderOrigem.setZIndexOffset(8);

      markerOrderOrigem.setIcon(
        new Icon({
          iconUrl: renderVehicle ? pin_vehicle_origem : pin_home_origin,
          iconRetinaUrl: renderVehicle ? pin_vehicle_origem : pin_home_origin,
          iconSize: point(34, 34),
          iconAnchor: [15, 15],
        })
      );
      markerOrderOrigem.addTo(this.map);
      this.setOrderLayerLayer(markerOrderOrigem);
    } catch (error) {
      console.log("Erro:", error)
    }
  };

  checkDelivery = () => {
    const buttonEl = document.getElementById("popup-button-id-order");
    if (buttonEl) {
      let data = buttonEl.getAttribute("data");
      const orderData = JSON.parse(data);
      if (orderData) {
        this.props.showEditDelivery(orderData.order);
      }
    }
  };
  selectDelivery = () => {
    const buttonEl = document.getElementById("popup-button-id-order-select");
    if (buttonEl) {
      let data = buttonEl.getAttribute("data");
      const orderData = JSON.parse(data);
      if (orderData && orderData.order) {
        console.log(orderData.order)

        let isSelectedOrder = this.props.ordersNotScriptedSeletected.filter((o) => o.oid == orderData.order.oid).length > 0;
        //this.props.onSelectedEventsChange(e.value);
        if (!isSelectedOrder) {
          let listSelect = [...this.props.ordersNotScriptedSeletected];
          listSelect.push(orderData.order);
          this.props.setOrdersNotScriptedSeletected(listSelect);
        }

        //  this.props.showEditDelivery(orderData.order);
      }
    }
  };
  popupopenDelivery = () => {
    const buttonEl = document.getElementById("popup-button-id-order");
    if (buttonEl) {
      buttonEl.addEventListener("click", this.checkDelivery);
    }
    const buttonSelectOrder = document.getElementById("popup-button-id-order-select");
    if (buttonSelectOrder) {
      buttonSelectOrder.addEventListener("click", this.selectDelivery);
    }

  };

  popupcloseDelivery = () => {
    const buttonEl = document.getElementById("popup-button-id-order");
    if (buttonEl) {
      buttonEl.removeEventListener("click", this.checkDelivery);
    }
    const buttonSelectOrder = document.getElementById("popup-button-id-order-select");
    if (buttonSelectOrder) {
      buttonSelectOrder.removeEventListener("click", this.selectDelivery);
    }

  };

  //mostra order no mapa
  addOrderMap = () => {
    //
    this.clearOrderLayersMap();
    this.markersGroupOrder.clearLayers();
    try {
      this.props.orders.map((order, index) => {
        const orderAllowed = this.checkOrderAllowed(order.owner);
        if (orderAllowed) {
 
          if (order.type && order.type == "pontoApoio") { 
            const isSelected = this.props.ordersNotScriptedSeletected.filter((ord) => ord.code == order.code).length > 0;
           
            const markerOrder = L.marker([order.delivery.location[1], order.delivery.location[0]], {
              draggable: 'true',
              opacity: isSelected ?1:0.6,
            });
            markerOrder.setZIndexOffset(-1);
            let orderDetail = `Ponto Apoio:<b>${order.code}</b> `;
            markerOrder.bindPopup(orderDetail);


            const updatePostionPontoApoio = this.props.updatePostionPontoApoio
            markerOrder.on('dragend', function (event) {
              var position = markerOrder.getLatLng();
              markerOrder.setLatLng(position, { draggable: 'true'}).bindPopup(position).update();           
              // console.log(position).keyup();
              updatePostionPontoApoio({code:order.code,...position})
            });
            
  
            this.markersGroupOrder.addLayer(markerOrder); 
          } else {
            //mostrar todos os CDs caso nao tenha rota selecionada 
            if (this.props.selectedRoutes.length == 0) {
              this.renderCDfrmOrder(order);
            }

            if (order.status == NOT_SCRIPTED && this.props.mostrarTodas) {
              if (order.delivery.location[1] && order.delivery.location[0]) {
                const isSelected = this.props.ordersNotScriptedSeletected.filter((ord) => ord.oid == order.oid).length > 0;
                const markerOrder = L.marker([order.delivery.location[1], order.delivery.location[0]]);
                markerOrder.setZIndexOffset(-1);

                //showEditDelivery
                let orderDetail = `Order: <b>${order.code}</b> não roteirizada<br/> Ponto de entrega `;
                markerOrder.bindPopup(ReactDOMServer.renderToString(<BoxPopupEdit order={order} detail={orderDetail} />));

                markerOrder.setIcon(
                  new Icon({
                    iconUrl: isSelected ? pin_blue : pin_red,
                    iconRetinaUrl: isSelected ? pin_blue : pin_red,
                    iconSize: point(22, 24),
                    iconAnchor: [15, 15],
                  })
                );

                markerOrder.addEventListener("popupopen", this.popupopenDelivery).addEventListener("popupclose", this.popupcloseDelivery);
                // markerOrder.addTo(this.map);
                this.markersGroupOrder.addLayer(markerOrder);

                // this.setOrderLayerLayer(markerOrder);
              }
            } else if (order.status == SCRIPTED) {
              //verificar se essa order esta dentro da rota selecionada
              let enableOrder = false;// this.props.selectedRoutes.length == 0; //se nao tiver rota selecionada, deixar true, caso o contrario verificar se essa order esta na rota


              let color = "";
              let routeCD = "";
              let routeDocumentNumber="";
              this.props.selectedRoutes.forEach((route) => {
                route.codes.forEach((code) => {
                  if (code == order.code) {
                    color = route.routeColor;
                    enableOrder = true;
                    routeCD = String(route.pointStart.cd).trim().toUpperCase();
                    routeDocumentNumber = route.pointStart.documentNumber;
                  }
                });
              });
              if (order.delivery.location[1] && order.delivery.location[0]) {


                if (enableOrder) {
                  const markerOrder = L.marker([order.delivery.location[1], order.delivery.location[0]]);
                  markerOrder.setZIndexOffset(-1);

                  markerOrder.bindPopup(`Order: <b>${order.code}</b> Ponto de entrega `);

                  markerOrder.setOpacity(1);
                  markerOrder.setIcon(
                    new Icon({
                      iconUrl: pin_green,
                      iconRetinaUrl: pin_green,
                      iconSize: point(30, 30),
                      iconAnchor: [15, 15],
                    })
                  );
                  //caso uma rota esteja selecionada, mostra o CD de origem somente dessa rota
                  let renderVehicle = false;  
                  if(routeCD === String(order.pickup.virtualCD).toUpperCase() && String(routeDocumentNumber).toUpperCase() == String(order.pickup.documentNumber).toUpperCase()){
                    renderVehicle=true;
                  }
                  this.renderCDfrmOrder(order, renderVehicle);

                  markerOrder.addTo(this.map);

                  this.setOrderLayerLayer(markerOrder);

                } else {
                  if (orderAllowed) {
                    const markerOrder = L.marker([order.delivery.location[1], order.delivery.location[0]]);
                    markerOrder.setZIndexOffset(-4);
                    // markerOrder.bindPopup(`Order: <b>${order.code}</b> Ponto de entrega `);

                    markerOrder.setOpacity(0.4);
                    markerOrder.setIcon(
                      new Icon({
                        iconUrl: pin_green_light,
                        iconRetinaUrl: pin_green_light,
                        iconSize: point(18, 18),
                        iconAnchor: [15, 15],
                      })
                    );

                    markerOrder.addTo(this.map);

                    this.setOrderLayerLayer(markerOrder);
                  }
                }

              }
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  clearDeliveryInfoBoxMap = () => {
    for (var i = 0; i < this.data.deliveryInfoBox.length; i++) {
      this.map.removeLayer(this.data.deliveryInfoBox[i]);
    }
    this.data.deliveryInfoBox = [];
  };
  showDeliveryInfoBox = () => {
    try {


      // deliveryInfoBox
      this.clearDeliveryInfoBoxMap();
      if (this.props.deliveryInfoBox) {

        let circlePoint;
        const latlngsPickup = [this.props.deliveryInfoBox.pickup.location[1], this.props.deliveryInfoBox.pickup.location[0]];
        const latlngsDelivery = [this.props.deliveryInfoBox.delivery.location[1], this.props.deliveryInfoBox.delivery.location[0]];

        if (this.props.typeView == 0) {
          //
          var line = new L.Polyline([latlngsPickup, latlngsDelivery], {
            opacity: 1,
            weight: 3,
            color: "#9614A5",
            className: "line_dot_polyline",
          });
          // line.bindPopup(`Order: <b>${this.props.deliveryInfoBox.code}</b> não roteirizada<br/> Ponto de entrega `);
          line.addTo(this.map);
          this.data.deliveryInfoBox.push(line);

          // const circlePointOrig = L.circle(latlngsPickup, 2000, {
          //   fillOpacity: 0.05,
          //   color: "#9614A5",
          // });
          const circlePointOrig = L.circleMarker(latlngsPickup, {
            fillOpacity: 0.05,
            color: "#9614A5",
          });
          circlePointOrig.addTo(this.map);
          this.data.deliveryInfoBox.push(circlePointOrig);

          //info box
          circlePoint = L.circleMarker(latlngsDelivery, {
            fillOpacity: 0.05,
            color: "#9614A5",
          });

        } else if (this.props.typeView == 1) { //info box
          if (this.props.operationType && this.props.operationType == "delivery") {

            circlePoint = L.circleMarker(latlngsDelivery, {
              fillOpacity: 0.05,
              color: "#9614A5",
            });
          } else {
            circlePoint = L.circleMarker(latlngsPickup, {
              fillOpacity: 0.05,
              color: "#9614A5",
            });
          }

        }
        const peso = formatNumberDefault(parseFloat(this.props.deliveryInfoBox.weight).round(3));
        let volumes = [];
        this.props.deliveryInfoBox.dimensions.forEach((v) => {
          let encontrou = false;
          for (let index = 0; index < volumes.length; index++) {
            if (volumes[index].Name === v.name) {
              volumes[index].Value += v.value;
              encontrou = true;
              break;
            }
          }
          if (!encontrou) {
            volumes.push({ Name: v.name, Value: v.value });
          }
        });

        let boxInfo = `Order: <b>${this.props.deliveryInfoBox.code}</b></br>
          Coleta: <b>${this.props.deliveryInfoBox.pickup.description}</b></br>
          Entrega: <b>${this.props.deliveryInfoBox.delivery.description}</b></br>
          Peso: <b>${peso} Kg</b></br>
      `;
        let infoVolume = "";
        volumes.forEach((v) => {
          infoVolume += `Embalagem: <b>${formatNumberDefault(parseFloat(v.Value).round(3))} ${v.Name}</b></br>`;
        });
        boxInfo += infoVolume;
        circlePoint.bindPopup(boxInfo, { autoClose: false }).addTo(this.map).openPopup();
        circlePoint.addEventListener("popupclose", this.clearDeliveryInfoBoxMap);
        this.data.deliveryInfoBox.push(circlePoint);

        // let bounds =[latlngsPickup, latlngsDelivery];
        // this.map.fitBounds(bounds, {padding: [300, 300]});
      }

    } catch (error) {
      console.log(error)
    }
  };
  render() {
    this.addOrderMap();
    this.addMarkerRouter();
    // this.renderCdsOnMap();

    this.showDeliveryInfoBox();
    return <div id="map" className="mapa-route" style={{ height: "87vh", width: "100%", padding: 0, zIndex: 0 }}></div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
