import { types } from "./freightTable.actions";
import { Reducer } from "../../services/reducer.factory";
import * as utils from "../../utils";
const initialState = {
  currentClient: {
    razaoSocial: "",
  },
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true,
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true,
    },
    {
      field: "carrier.person.name",
      header: "Transportadora",
      columnKey: "carrier.person.name",
      sortable: true,
      filter: true,
    },
    {
      field: "initialValidity",
      header: "Início da vigência",
      columnKey: "initialValidity",
      sortable: true,
      filter: true,
    },
    {
      field: "finalValidity",
      header: "Final da vigência",
      columnKey: "finalValidity",
      sortable: true,
      filter: true,
    },
    {
      field: "active",
      header: "Ativo",
      columnKey: "active",
      sortable: true,
      filter: true,
    },
  ],
  selectedFreightTables: [],
  businessUnitsColumns: [
    {
      field: "tradeName",
      header: "Pessoa",
      columnKey: "tradeName",
      sortable: true,
      filter: true,
    },
    {
      field: "label",
      header: "Sigla",
      columnKey: "label",
      sortable: true,
      filter: true,
    },
    {
      field: "documentNumber",
      header: "CNPJ",
      columnKey: "documentNumber",
      sortable: true,
      filter: true,
    },
  ],
  clientsByComponentRestrictioncolumns: [
    {
      field: "razaoSocial",
      header: "Razão Social",
      columnKey: "razaoSocial",
      sortable: true,
      filter: true,
    },
    {
      field: "cnpj",
      header: "CNPJ",
      columnKey: "cnpj",
      sortable: true,
      filter: true,
    },
  ],
  locationsByComponentRestrictioncolumns: [
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true,
    },
  ],
  routesColumns: [
    
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: false,
      filter: false,
    },
    {
      field: "oidOrigin",
      header: "ID Origem",
      columnKey: "oidOrigin",
      sortable: false,
      filter: false,
    },
    {
      field: "originName",
      header: "Origem",
      columnKey: "originName",
      sortable: false,
      filter: false,
    },
    {
      field: "oidDestination",
      header: "ID Destino",
      columnKey: "oidDestination",
      sortable: false,
      filter: false,
    },
    {
      field: "destinationName",
      header: "Destino",
      columnKey: "destinationName",
      sortable: false,
      filter: false,
    },
  ],
  restrictionsColumns: [
    {
      field: "name",
      header: "Restrições",
      columnKey: "name",
      sortable: false,
      filter: false,
    },
  ],
  ignoreComponentsColumns: [
    {
      field: "name",
      header: "Componente",
      columnKey: "name",
      sortable: false,
      filter: false,
    },
  ],
  selectedRoutes: [] /* usado para manipulação de mais de uma rota do registro atual */,
  currentRoute: null /* usado para a seleção da rota no lookup */,
  selectedRoute: null /* usada para manipulação da rota do registro atual */,
  selectedRange: null /* usada para manipulação do range atualmente selecionado */,
  indexSelectedRange: 0,
  selectedSerie: null,
  currentComponent: null /* usado para guardar o componente que está sendo adicionado  pelo lookup de componentes */,
  disabled: true,
  currentRecord: {
    oid: "",
    active: false,
    businessUnits: [],
    carrier: null,
    initialValidity: "",
    finalValidity: "",
    name: "",
    modal: "ROAD",
    note: "",
    routes: [],
    restrictions: [],
    tags: [],
    currentClientName: null,
    generalFreightComponents: [],
    initialRangeValue: 0,
    finalRangeValue: 0,
    isRangeMaximum: false,
    isRangeMinimum: false,
    icmsHighlighted: false,
    isOnlyMatriz: false,
    applyToMatriz: false,
    ignoreICMS: false,
    cubingFactor: 0,
    disabled: true,
    labelIcms: "",
    onlyIgnoreListComponent:[],
  },
  currentBusinessUnit: null,
  selectedBusinessUnits: [],
  lookupRoute: {
    editDisabled: true,
    visible: false,
    modal: false,
    header: "Rotas",
  },
  lookupBusinessUnit: {
    visible: false,
    modal: false,
    header: "Unidades",
  },
  lookupBusinessUnit2: {
    visible: false,
    modal: false,
    header: "Unidades",
  },
  lookupCarrier: {
    visible: false,
    modal: false,
    header: "Transportadoras",
  },
  lookupComponent: {
    editDisabled: true,
    visible: false,
    modal: false,
    header: "Componentes de Frete",
  },
  dialogRoutes: {
    visible: false,
    modal: true,
    header: "Adicionar Rotas",
  },
  dialogDuplicateRoute: {
    visible: false,
    modal: true,
    header: "Duplicar Rota",
  },
  dialogAddBusinessUnits: {
    visible: false,
    modal: true,
    header: "Adicionar Unidades",
  },
  dialogAddComponentGeneralComponents: {
    visible: false,
    modal: true,
    header: "Adicionar componente",
  },
  panelByRoute: {
    dialog: {
      visible: false,
      modal: false,
      confirmDisabled: false,
      addDisabled: false,
    },
    panelRange: {
      dialogAddComponentSelectedSerie: {
        visible: false,
        modal: false,
        header: "Adicionar componente",
      },
      dialogEditSerie: {
        visible: false,
        modal: false,
        confirmDisabled: false,
        addDisabled: false,
      },
    },
  },
  dialogAddRestrictions: {
    visible: false,
    modal: false,
    header: "Adicionar Restrições",
    dialogComponentName: "",
    showlookupClient: false,
    showlookupLocation: false,
    showLookupUnit: false,
    componentsRestriction: [],
    currentComponentRestriction: null,
  },
  lookupClient: {
    searchText: "",
    razaoSocial: "",
    editDisabled: false,
    visible: false,
    modal: false,
    header: "Clientes",
    records: [],
    columns: [],
    totalRecords: 0,
    order: {},
    sortField: "",
    first: 0,
    rows: 0,
    selectedRecords: [],
    tableRecords: [], //setar os registros da planilha nesse local
    tableColumns: [
      {
        field: "razaoSocial",
        header: "Razão Social",
        columnKey: "razaoSocial",
        sortable: true,
        filter: true,
      },
      {
        field: "cnpj",
        header: "CNPJ",
        columnKey: "cnpj",
        sortable: true,
        filter: true,
      },
    ],
  },
  lookupLocation: {
    searchText: "",
    editDisabled: false,
    visible: false,
    modal: false,
    header: "Localizações",
    records: [],
    columns: [],
    totalRecords: 0,
    order: {},
    sortField: "",
    first: 0,
    rows: 0,
    selectedRecords: [],
    tableRecords: [],
    tableColumns: [
      {
        field: "name",
        header: "Nome",
        columnKey: "name",
        sortable: true,
        filter: true,
      },
    ],
  },
  lookupRestrictedUnit: {
    searchText: "",
    editDisabled: false,
    visible: false,
    modal: false,
    header: "Unidades",
    records: [],
    columns: [],
    totalRecords: 0,
    order: {},
    sortField: "",
    first: 0,
    rows: 0,
    selectedRecords: [],
    tableRecords: [],
    tableColumns: [
      {
        field: "sigla",
        header: "Sigla",
        columnKey: "sigla",
        sortable: false,
        filter: false,
      }, {
        field: "cnpj",
        header: "CNPJ",
        columnKey: "cnpj",
        sortable: false,
        filter: false,
      },
    ],
  },
  panelRestrictionByComponent: {
    restrictionsDisabled: true,
    editButtonDisabled: true,
    currentSelectedComponent: [],
  },
  panelClientsByComponentRestriction: {
    selectedComponents: [],
    records: [],
  },
  panelLocationsByComponentRestriction: {
    selectedComponents: [],
    records: [],
  },
  panelBusinessUnitByComponentRestriction: {
    selectedComponents: [],
    records: [],
  },
  currentDuplicateRoute: {
    name: "",
    lookupOrigin: null,
    lookupDestination: null,
    lookupRoute: null,
  },
  lookupOrigin: {
    editDisabled: true,
    visible: false,
    modal: false,
    header: "Origens",
  },
  lookupDestination: {
    editDisabled: true,
    visible: false,
    modal: false,
    header: "Destinos",
  },
  active: "",
  initialValidity: null,
  finalValidity: null,
  dialogStatusImportXlsRoutes: {
    visible: false,
    statusCode: 0,
    resultImportTableXls: null
  },
  compositionColumns: [
    {
      field: "label",
      header: "Componentes",
      columnKey: "label",
      sortable: false,
      filter: false,
    },
  ],
  listOperations: [
    { label: "Soma", value: "SUM" },
    { label: "Multiplicação", value: "MULTIPLY" }
  ],
  dialogStatusImportXlsTaxaMultValores: null,  
  auxiliarMultiEntries: [
    {
      countMultiEntries:0,
      idComponent: 0,
      currentIndex : 0,
    }
  ]

};

const reducer = new Reducer(initialState, types);

export function CRUDFreightTableReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_BUSINESSUNIT_VISIBLE:
      return setLookupBusinessUnitVisible(state, action);
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_LOOKUP_ROUTE_VISIBLE:
      return setLookupRouteVisible(state, action);
    case types.SET_LOOKUP_COMPONENT_VISIBLE:
      return setLookupComponentVisible(state, action);
    case types.REMOVE_SELECTED_BUSINESSUNITS:
      return removeSelectedBusinessUnits(state, action);
    case types.SET_SELECTED_BUSINESSUNITS:
      return setSelectedBusinessUnits(state, action);
    case types.SET_DIALOG_ADD_BUSINESSUNITS_VISIBLE:
      return setDialogAddBusinessUnitsVisible(state, action);
    case types.SET_CURRENT_BUSINESSUNIT:
      return setCurrentBusinessUnit(state, action);
    case types.ADD_BUSINESSUNIT:
      return addBusinessUnit(state, action);
    case types.SET_DIALOG_ADD_ROUTES_VISIBLE:
      return setDialogRoutesVisible(state, action);
    case types.SET_CURRENT_ROUTE:
      return setCurrentRoute(state, action);
    case types.ADD_ROUTE:
      return addRoute(state, action);
    case types.SET_SELECTED_ROUTE:
      return setSelectedRoute(state, action);
    case types.SET_SELECTED_ROUTES:
      return setSelectedRoutes(state, action);
    case types.REMOVE_SELECTED_ROUTES:
      return removeSelectedRoutes(state);
    case types.SET_DIALOG_RANGE_VISIBLE:
      return setDialogRangeVisible(state, action);
    case types.SET_DIALOG_EDIT_SERIE_VISIBLE:
      return setDialogEditSerieVisible(state, action);
    case types.ADD_RANGE:
      return addRange(state, action);
    case types.SET_SELECTED_RANGE:
      return setSelectedRange(state, action);
    case types.SET_SELECTED_SERIE:
      return setSelectedSerie(state, action);
    case types.CHANGE_COMPONENT_FROM_SELECTED_SERIE:
      return changeComponentFromSelectedSerie(state, action);
    case types.REMOVE_COMPONENT_FROM_SELECTED_SERIE:
      return removeComponentFromSelectedSerie(state, action);
    case types.CHANGE_VISIBILITY_DIALOG_ADD_COMPONENT_INTO_SELECTED_SERIE:
      return changeVisibilityDialogAddComponentSelectedSerie(state, action);
    case types.SET_CURRENT_COMPONENT:
      return setCurrentComponent(state, action);
    case types.ADD_COMPONENT_SELECTED_SERIE:
      return addComponentSelectedSerie(state, action);
    case types.ADD_COMPONENT_GENERAL_COMPONENTS:
      return addComponentGeneralComponents(state, action);
    case types.CHANGE_COMPONENT_FROM_GENERAL_COMPONENTS:
      return changeComponentFromGeneralComponents(state, action);
    case types.REMOVE_COMPONENT_FROM_GENERAL_COMPONENTS:
      return removeComponentFromGeneralComponents(state, action);
    case types.CHANGE_VISIBILITY_DIALOG_ADD_COMPONENT_INTO_GENERAL_COMPONENTS:
      return changeVisibilityDialogAddComponentGeneralComponents(state, action);
    case types.REMOVE_SELECTED_RANGE:
      return removeSelectedRange(state);
    case types.ADD_RESTRICTION_ON_RESTRICTION_TABLE:
      return addRestrictionOnRestrictionTable(state, action);
    case types.REMOVE_RESTRICTION_ON_RESTRICTION_TABLE:
      return removeRestrictionOnRestrictionTable(state, action);
    case types.DELETE_ADDED_CLIENTS:
      return deleteAddedClients(state, action);
    case types.DELETE_ADDED_LOCATIONS:
      return deleteAddedLocations(state, action);
    case types.SET_ADD_RESTRICTION_BUTTON_STATE:
      return setAddRestrictionButtonState(state, action);
    case types.SET_EDIT_RESTRICTION_BUTTON_STATE:
      return setEditRestrictionButtonState(state, action);
    case types.SET_DIALOG_ADD_RESTRICTIONS_VISIBLE:
      return setDialogAddRestrictionsVisible(state, action);
    case types.SET_CURRENT_SELECTED_COMPONENT:
      return setCurrentSelectedComponent(state, action);
    case types.SET_DIALOG_COMPONENT_NAME:
      return setdialogComponentName(state, action);
    case types.SET_DIALOG_COMPONENT_RESTRICTION_LOOKUP_CLIENT_VISIBLE:
      return setDialogComponentRestrictionLookupClientVisible(state, action);
    case types.SET_DIALOG_COMPONENT_RESTRICTION_LOOKUP_LOCATION_VISIBLE:
      return setDialogComponentRestrictionLookupLocationVisible(state, action);
    case types.LOOKUP_CLIENT_SET_VISIBLE:
      return lookupClientSetVisible(state, action);
    case types.SET_LOOKUP_CLIENT_SELECTED_RECORDS:
      return setlookupClientSelectedRecords(state, action);
    case types.LOOKUP_CLIENT_ADD_RECORDS:
      return lookupClientAddRecords(state, action);
    case types.LOOKUP_LOCATION_SET_VISIBLE:
      return lookupLocationSetVisible(state, action);
    case types.SET_LOOKUP_LOCATION_SELECTED_RECORDS:
      return setLookupLocationSelectedRecords(state, action);
    case types.LOOKUP_LOCATION_ADD_RECORDS:
      return lookupLocationAddRecords(state, action);
    case types.SET_CLIENTS_ON_CURRENT_RESTRICTION_COMPONENT:
      return setClientsOnCurrentRestrictionComponent(state, action);
    case types.SET_LOCATIONS_ON_CURRENT_RESTRICTION_COMPONENT:
      return setLocationsOnCurrentRestrictionComponent(state, action);
    case types.SET_BUSINESS_UNITS_ON_CURRENT_RESTRICTION_COMPONENT:
      return setBusinessUnitsOnCurrentRestrictionComponent(state, action);
    case types.SET_RESTRICTIONS_TO_DUPLICATE:
      return setRestrictionsToDuplicate(state, action);
    case types.DUPLICATE_CLIENTS_RESTRICTIONS:
      return duplicateClientsRestrictions(state, action);
    case types.SET_CURRENT_SELECTED_COMPONENT_ON_DROPDOWN:
      return setCurrentSelectedComponentOnDropdown(state, action);
    case types.DUPLICATE_LOCATIONS_RESTRICTIONS:
      return duplicateLocationsRestrictions(state, action);
    case types.REMOVE_SELECTED_CLIENTS:
      return removeSelectedClients(state, action);
    case types.REMOVE_SELECTED_CLIENTS_FROM_RESTRICTION_COMPONENT:
      return removeSelectedClientsFromRestrictionComponent(state, action);
    case types.REMOVE_SELECTED_LOCATIONS_FROM_RESTRICTION_COMPONENT:
      return removeSelectedLocationsFromRestrictionComponent(state, action);
    case types.REMOVE_SELECTED_BUSINESS_UNITS_FROM_RESTRICTION_COMPONENT:
      return removeSelectedBusinessUnitsFromRestrictionComponent(state, action);
    case types.REMOVE_SELECTED_LOCATIONS:
      return removeSelectedLocations(state, action);
    case types.SET_LOOKUPTABLE_CLIENTS:
      return setLookupTableClients(state, action);
    case types.SET_LOOKUPTABLE_LOCATIONS:
      return setLookupTableLocations(state, action);
    case types.SET_CURRENT_CLIENT_NAME:
      return setCurrentClientName(state, action);
    case types.SET_CURRENT_CLIENT:
      return setCurrentClient(state, action);
    case types.LOOKUPCLIENT_SET_SEARCH_TEXT:
      return lookupClientSetSearchText(state, action);
    case types.SET_DIALOG_DUPLICATE_ROUTE_VISIBLE:
      return setDialogDuplicateRouteVisible(state, action);
    case types.SET_DUPLICATE_ROUTE:
      return setDuplicateRoute(state, action);
    case types.SET_DUPLICATE_ROUTE_FIELD:
      return setDuplicateRouteFields(state, action);
    case types.SET_LOOKUP_ORIGIN_VISIBLE:
      return setLookupOriginVisible(state, action);
    case types.SET_LOOKUP_DESTINATION_VISIBLE:
      return setLookupDestinationVisible(state, action);
    case types.SET_SERIE:
      return setSerie(state, action);
    case types.REMOVE_SELECTED_SERIE:
      return removeSelectedSerie(state, action);
    case types.RESULT_IMPORT_CLIENT_XLSX:
      return resultImportClientXLSX(state, action);
    case types.RESULT_IMPORT_LOCATION_XLSX:
      return resultImportLocationXLSX(state, action);
    case types.SET_ACTIVE_FILTER:
      return setActiveFilter(state, action);
    case types.SET_INITIAL_VALIDITY_FILTER:
      return setInitialValidityFilter(state, action);
    case types.SET_FINAL_VALIDITY_FILTER:
      return setFinalValidityFilter(state, action);
    case types.SET_FIELD:
      return setField(state, action);
    case types.ADD_TAG:
      return addTag(state, action);
    case types.REMOVE_TAG:
      return removeTag(state, action);
    case types.SET_LOOKUP_RESTRICTED_UNIT_VISIBLE:
      return setLookupRestrictedUnitVisible(state, action)
    case types.SET_LOOKUP_BUSINESS_UNIT2:
      return setLookupRestrictedUnitVisible(state, action);
    case types.SET_BUSINESS_UNIT_RESTRICTED:
      return setTableRecordsBusinessUnitRestricted(state, action);
    case types.SET_SELECTED_RECORDS_UNIT_RESTRICTED:
      return setSelectedRecordsLookupUnitRestricted(state, action);
    case types.SET_DIALOG_COMPONENT_RESTRICTION_LOOKUP_UNIT_VISIBLE:
      return setDialogComponentRestrictionLookupUnitVisible(state, action);
    case types.DELETE_ADDED_BUSINESS_UNIT:
      return deleteAddedBusinessUnits(state, action);
    case types.SET_STATUS_DIALOG_IMPORT_XLS_ROUTE:
      return setDialogStatusImportXlsRoutes(state, action);
    case types.SET_VISIBLE_DIALOG_IMPORT_XLS_ROUTE:
      return setVisibleDialogStatusImportXlsRoutes(state, action);
    case types.SET_RESULT_IMPORT_TABLE_XLS:
      return showMessagesImportTable(state, action);
    case types.CHANGE_OPERATION:
      return changeOperation(state, action);
    case types.ADD_COMPOSITION:
      return addComposition(state, action);
    case types.REMOVE_COMPOSITION:
      return removeComposition(state, action);
    case types.ADD_ENTRIES:
      return addEntries(state, action);
    case types.REMOVE_ENTRIES:
      return removeEntries(state, action);
    case types.UPDATE_ENTRY:
      return updateEntry(state, action);
    case types.SHOW_DIALOG_STATUS_MSG_ERROR_IMPORT_TAXA_MULT_VAL:
      return showDialogStatusImportXlsTaxaMultValores(state, action);  
    case types.SET_ENTRIES_GENERAL_COMPONENT:
      return setEntriesGeneralComponent(state, action);
    case types.CLEAR_CURRENT_RECORD:
      return clearCurrentRecord(state, action);
    case types.SET_AUXILIAR_MULTIENTRIES:
      return setAuxiliarMultiEntries(state, action);   
    case types.ADD_IGNORE_COMPONENT:
      return addIgnoreComponentList(state, action); 
    case types.REMOVE_IGNORE_COMPONENT:
      return removeIgnoreComponentList(state, action);   
    default:
      return result;
  }
}

function setAuxiliarMultiEntries(state, action){
  let auxiliarMultiEntries = state.auxiliarMultiEntries;
  let encontrou = false;
  for(let i = 0; i < auxiliarMultiEntries.length; i++){
    if (auxiliarMultiEntries[i].idComponent == action.idComponent) {
      auxiliarMultiEntries[i].countMultiEntries = action.count;
      auxiliarMultiEntries[i].idComponent = action.idComponent;
      auxiliarMultiEntries[i].currentIndex = action.currentIndex;
      encontrou = true;
      break;
    }
  }
  if(!encontrou){
    const auxMultEntry = {}
    auxMultEntry.countMultiEntries = action.count;
    auxMultEntry.idComponent = action.idComponent;
    auxMultEntry.currentIndex = action.currentIndex;
    auxiliarMultiEntries.push(auxMultEntry);
  }

  return { ...state, auxiliarMultiEntries }
}

function clearCurrentRecord(state, action){
   let currentRecord = state.currentRecord;
   currentRecord.generalFreightComponents = [];
   return {...state, currentRecord }
}

function showDialogStatusImportXlsTaxaMultValores(state, action) {
  const dialogStatusImportXlsTaxaMultValores = action.dialogStatusImportXlsTaxaMultValores
  console.log("Dialog Status = ",dialogStatusImportXlsTaxaMultValores)
  return { ...state, dialogStatusImportXlsTaxaMultValores }
}
function removeIgnoreComponentList(state, action){
  let currentRecord = state.currentRecord;
  let value = action.value;
  let onlyIgnoreListComponent = currentRecord.onlyIgnoreListComponent || []
  let index = onlyIgnoreListComponent.indexOf(value)
  if (index > -1){
    onlyIgnoreListComponent.splice(index, 1);
  } 
  currentRecord = {...currentRecord, onlyIgnoreListComponent}
  return {...state, currentRecord}
}

function addIgnoreComponentList(state, action){
  let currentRecord = state.currentRecord;
  let value = String(action.value).trim();
  let onlyIgnoreListComponent = currentRecord.onlyIgnoreListComponent || []
  if (onlyIgnoreListComponent.length == 0) {
    onlyIgnoreListComponent.push(value);
  } else if (onlyIgnoreListComponent.filter((item) => item == value).length == 0) {
    onlyIgnoreListComponent.push(value);
  } 
  currentRecord = {...currentRecord, onlyIgnoreListComponent}
  return {...state, currentRecord}
}


function addCompositionGeneralComponent(state, action) {
  let currentRecord = state.currentRecord;
  let generalFreightComponents = currentRecord.generalFreightComponents;
  generalFreightComponents.length > 0 && generalFreightComponents.map((item) => {
    if (item.oid == action.component.oid) {
      item.composition = item.composition || []
      if (item.composition.filter((comp) => comp.name === action.description).length == 0) {
        item.composition.push(action.description);
      }
    }
  })
  currentRecord = { ...currentRecord, generalFreightComponents }
  return { ...state, currentRecord }
}

function addCompositionRouteComponent(state, action) {
  let selectedSerie = state.selectedSerie;
  let component = { ...action.component };
  if (action.field !== "oid" && selectedSerie.components) {
    if(!component.composition){
      component.composition=[];
    }
    if (component.composition.filter((comp) => comp.label === action.description.label).length == 0) {
      component.composition.push(action.description)    

      const components = [...selectedSerie.components]
      if (action.index > -1 && action.index < components.length) {
        components[action.index] = component;
      }

      selectedSerie.components = components;
   }
  }
  return Object.assign({}, state, { selectedSerie });
}

function addComposition(state, action) {
  switch (action.componentType) {
    case "generalComponent":
      return addCompositionGeneralComponent(state, action);
    case "routeComponent":
      return addCompositionRouteComponent(state, action);
  }
  return state;
}

function removeCompositionFromGeneralComponent(state, action) {
  let currentRecord = state.currentRecord;
  let generalFreightComponents = currentRecord.generalFreightComponents || [];
  generalFreightComponents = generalFreightComponents.map((generalComponent,index) => {
    if (index === action.index && generalComponent.oid === action.component.oid) {
      const component = { ...generalComponent }
      component.composition = component.composition || []
      component.composition = component.composition.filter(gpComposition => {
        const composition = action.composition.find(c => c.label === gpComposition.label);
        return composition != null ? false : true
      })
      return component;
    } else {
      return generalComponent;
    }
  })

  currentRecord = { ...currentRecord, generalFreightComponents }
  return { ...state, currentRecord }
}

function removeCompositionFromRouteComponent(state, action) {
  let selectedSerie = state.selectedSerie;
  let component = { ...action.component };
  if (action.field !== "oid" && selectedSerie.components) {
    component.composition = component.composition || []
    component.composition = component.composition.filter(gpComposition => {
      const composition = action.composition.find(c => c.label === gpComposition.label);
      return composition != null ? false : true
    })

    const components = [...selectedSerie.components]
    if (action.index > -1 && action.index < components.length) {
      components[action.index] = component;
    }

    selectedSerie.components = components;
  }
  return Object.assign({}, state, { selectedSerie });

}

function removeComposition(state, action) {
  switch (action.componentType) {
    case "generalComponent":
      return removeCompositionFromGeneralComponent(state, action);
    case "routeComponent":
      return removeCompositionFromRouteComponent(state, action);
  }
  return state;

}


function addEntries(state, action) {
  switch (action.componentType) {
    case "generalComponent":
      return addEntriesGeneralComponent(state, action);
    // case "routeComponent":
    //   return addEntriesRouteComponent(state, action);
  }
  return state;
}

function addEntriesGeneralComponent(state, action) {
  let currentRecord = state.currentRecord;
  let generalFreightComponents = currentRecord.generalFreightComponents;
  generalFreightComponents.length > 0 && generalFreightComponents.map((item) => {
    if (item.oid == action.component.oid) {
      item.entries = item.entries || [];
      let entries = action.entries || [];
      entries = entries.filter(currentEntry =>{
        let entry = item.entries.find( entry => entry.key === currentEntry.key);
        return entry == null;
      })
      item.entries = [...item.entries, ...entries]
     
    }
    return item;
  })
  currentRecord = { ...currentRecord, generalFreightComponents }
  return { ...state, currentRecord }
}

function removeEntries(state, action) {
  switch (action.componentType) {
    case "generalComponent":
      return removeEntriesGeneralComponent(state, action);
    // case "routeComponent":
    //   return removeEntriesRouteComponent(state, action);
  }
  return state;
}

function removeEntriesGeneralComponent(state, action) {
  let currentRecord = state.currentRecord;
  let generalFreightComponents = currentRecord.generalFreightComponents;
  generalFreightComponents.length > 0 && generalFreightComponents.map((item) => {
    if (item.oid == action.component.oid) {
      item.entries = item.entries || [];
      let entries = action.entries || [];
      entries = item.entries.filter(currentEntry =>{
        let entry = entries.find( entry => 
          {
            return entry.key === currentEntry.key;

          })
        return entry == null;
      })
      item.entries = [...entries]
     
    }
  })
  currentRecord = { ...currentRecord, generalFreightComponents }
  return { ...state, currentRecord }
}


function updateEntry(state, action) {
  switch (action.componentType) {
    case "generalComponent":
      return updateEntryGeneralComponent(state, action);
    // case "routeComponent":
    //   return updateEntryRouteComponent(state, action);
  }
  return state;
}

function updateEntryGeneralComponent(state, action) {
  let currentRecord = state.currentRecord;
  let generalFreightComponents = currentRecord.generalFreightComponents;
  generalFreightComponents.length > 0 && generalFreightComponents.map((item) => {
    if (item.oid == action.component.oid) {
      item.entries = item.entries || [];
      let entries = [...item.entries];
      if(action.entry != null && entries.length > action.indexEntry){
        entries[action.indexEntry] = action.entry;
        item.entries = entries;
      }
    }
    return item;
  })
  currentRecord = { ...currentRecord, generalFreightComponents }
  return { ...state, currentRecord }
}





function changeOperationGeneralComponent(state, action) {
  let currentRecord = state.currentRecord;
  let generalFreightComponents = currentRecord.generalFreightComponents;

  generalFreightComponents = generalFreightComponents.map((item) => {
    if (item.oid === action.component.oid) {
      item.operation = action.value;
    }
      return item;
  });

  currentRecord = { ...currentRecord, generalFreightComponents }

  return { ...state, currentRecord }
}
function changeOperationRouteComponent(state, action) {
  let selectedSerie = state.selectedSerie;
  let component = { ...action.component };
  if (action.field !== "oid" && selectedSerie.components) {
    component.operation = action.value
    const components = [...selectedSerie.components]
    if (action.index > -1 && action.index < components.length) {
      components[action.index] = component;
    }

    selectedSerie.components = components;
  }
  return Object.assign({}, state, { selectedSerie });
}

function changeOperation(state, action) {
  switch (action.componentType) {
    case "generalComponent":
      return changeOperationGeneralComponent(state, action);
    case "routeComponent":
      return changeOperationRouteComponent(state, action);
  }
  return state;
}

function setVisibleDialogStatusImportXlsRoutes(state, action) {
  let dialogStatusImportXlsRoutes = state.dialogStatusImportXlsRoutes;
  dialogStatusImportXlsRoutes.visible = action.visible;
  if (!dialogStatusImportXlsRoutes.visible) {
    dialogStatusImportXlsRoutes.statusCode = 0;
    dialogStatusImportXlsRoutes.resultImportTableXls = null;
  }
  return { ...state, dialogStatusImportXlsRoutes }
}
function showMessagesImportTable(state, action) {
  let dialogStatusImportXlsRoutes = state.dialogStatusImportXlsRoutes;
  dialogStatusImportXlsRoutes.resultImportTableXls = action.resultImportTableXls;
  return { ...state, dialogStatusImportXlsRoutes }
}
function setDialogStatusImportXlsRoutes(state, action) {
  let dialogStatusImportXlsRoutes = state.dialogStatusImportXlsRoutes;
  dialogStatusImportXlsRoutes.statusCode = action.statusCode;
  return { ...state, dialogStatusImportXlsRoutes }
}

function setSelectedRecordsLookupUnitRestricted(state, action) {
  let lookupRestrictedUnit = state.lookupRestrictedUnit;
  lookupRestrictedUnit.selectedRecords = action.records;
  return { ...state, lookupRestrictedUnit }
}

function setTableRecordsBusinessUnitRestricted(state, action) {
  let lookupRestrictedUnit = state.lookupRestrictedUnit;
  lookupRestrictedUnit.tableRecords = action.businessUnits;
  return { ...state, lookupRestrictedUnit }
}

function addTag(state, action) {
  let current = state.currentRecord;
  if (current.tags.indexOf(action.value) == -1) {
    let tags = current.tags;
    tags.push(action.value);
    const currentRecord = { ...current, tags };
    return { ...state, currentRecord };
  }
  return state;
}

function removeTag(state, action) {
  let current = state.currentRecord;
  if (current.tags.indexOf(action.value) != -1) {
    let tags = current.tags.filter((tag) => tag != action.value);
    const currentRecord = { ...current, tags }
    current.tags.splice(current.tags.indexOf(action.value), 1);
    return { ...state, currentRecord };
  }
  return state;
}

function setField(state, action) {
  const { field, value } = action;
  let current = state.currentRecord;
  if (field === "isOnlyMatriz" && value) {
    current.applyToMatriz = value;
  }
  return { ...state, current };
}

function setInitialValidityFilter(state, action) {
  let initialValidity = state.initialValidity;
  initialValidity = action.value;
  return { ...state, initialValidity };
}

function setFinalValidityFilter(state, action) {
  let finalValidity = state.finalValidity;
  finalValidity = action.value;
  return { ...state, finalValidity };
}

function setActiveFilter(state, action) {
  let active = state.active;
  active = action.value;
  return { ...state, active };
}

function setCurrentComponent(state, action) {
  const currentComponent = action.currentComponent;
  return Object.assign({}, state, {
    currentComponent,
  });
}
function setLookupBusinessUnitVisible(state, action) {
  let lookupBusinessUnit = state.lookupBusinessUnit;
  lookupBusinessUnit = Object.assign({}, lookupBusinessUnit, {
    visible: action.visible,
  });
  return Object.assign({}, state, { lookupBusinessUnit });
}
function setLookupCarrierVisible(state, action) {
  let lookupCarrier = state.lookupCarrier;
  lookupCarrier = Object.assign({}, lookupCarrier, { visible: action.visible });
  return Object.assign({}, state, { lookupCarrier });
}

function setSelectedBusinessUnits(state, action) {
  let selectedBusinessUnits = action.selectedBusinessUnits;
  return Object.assign({}, state, { selectedBusinessUnits });
}

function removeSelectedBusinessUnits(state, action) {
  let businessUnits = state.currentRecord.businessUnits;
  let selectedBusinessUnits = state.selectedBusinessUnits;
  businessUnits = businessUnits.filter((businessUnit) => {
    const existe = selectedBusinessUnits.filter((selectedBusinessUnit) => {
      return selectedBusinessUnit.oid === businessUnit.oid;
    });
    return existe.length > 0 ? false : true;
  });

  let currentRecord = Object.assign({}, state.currentRecord, { businessUnits });
  return Object.assign({}, state, { currentRecord, selectedBusinessUnits: [] });
}

function setDialogAddBusinessUnitsVisible(state, action) {
  let dialogAddBusinessUnits = state.dialogAddBusinessUnits;
  dialogAddBusinessUnits = Object.assign({}, dialogAddBusinessUnits, {
    visible: action.visible,
  });
  return Object.assign({}, state, { dialogAddBusinessUnits });
}

function addBusinessUnit(state, action) {
  const businessUnit = state.currentBusinessUnit;
  let currentRecord = state.currentRecord;
  let businessUnits = currentRecord.businessUnits || [];
  const alreadyAdded = businessUnits.find((current) => {
    return businessUnit.oid === current.oid;
  });
  if (!alreadyAdded) {
    businessUnits = [...businessUnits, state.currentBusinessUnit];
    currentRecord = Object.assign({}, currentRecord, { businessUnits });
    return Object.assign({}, state, { currentRecord });
  }
  return state;
}

function setCurrentBusinessUnit(state, action) {
  const currentBusinessUnit = action.currentBusinessUnit;
  return Object.assign({}, state, { currentBusinessUnit });
}

function setCurrentRoute(state, action) {
  const currentRoute = action.route;
  return Object.assign({}, state, { currentRoute });
}
function setSelectedRoute(state, action) {
  let result = state;
  const selectedRoute = action.route;
  const ranges =
    selectedRoute && selectedRoute.ranges ? selectedRoute.ranges : [];
  result = Object.assign({}, state, { selectedRoute });
  return setSelectedRange(result, { index: ranges.length > 0 ? 0 : -1 });
}
function setSelectedRoutes(state, action) {
  const selectedRoutes = action.routes || [];
  return Object.assign({}, state, { selectedRoutes });
}
function addRoute(state, action) {
  const route = action.route;
  const isRoutesWithSameContent = (route1, route2) => {
    if (
      route1.oidOrigin === route2.oidOrigin &&
      route1.oidDestination === route2.oidDestination
    ) {
      return true;
    }
    return false;
  };
  const alreadyAdded =
    state.currentRecord.routes.filter((currentRoute) => {
      if (route.oid) {
        return route === currentRoute.oid;
      } else {
        return isRoutesWithSameContent(route, currentRoute);
      }
    }).length > 0;
  if (!alreadyAdded) {
    let routes = state.currentRecord.routes;
    const newRoute = {
      ...route,
      //* ranges: [] I was always removing the ranges, changed that responsability to the functions that this method
    };
    routes = [...routes, newRoute];
    let currentRecord = Object.assign({}, state.currentRecord, { routes });
    return Object.assign({}, state, { currentRecord });
  }

  return state;
}

function addRestrictionOnRestrictionTable(state, action) {
  let currentRecord = state.currentRecord;
  if (currentRecord) {
    const component = action.component;
    if (component.restrictedByClient || component.restrictedByLocalization || component.restrictedByBusinessUnit) {
      const alreadyAdded =
        currentRecord.restrictions.filter((current) => {
          return component.oid === current.oid;
        }).length > 0;
      if (!alreadyAdded) {
        let restrictions = currentRecord.restrictions;
        const newRestriction = {
          ...component,
          restrictions: [],
        };
        restrictions = [...restrictions, newRestriction];
        currentRecord = Object.assign({}, currentRecord, { restrictions });
        return Object.assign({}, state, { currentRecord });
      }
    }
  }
  return state;
}

function removeRestrictionOnRestrictionTable(state, action) {
  let currentRecord = state.currentRecord;
  let restrictions = currentRecord.restrictions;
  let selectedRange = state.selectedRange;

  if (currentRecord && restrictions) {
    const component = action.component;
    //* Verifica se o componente com restrição não esta em nenhuma faixa por rota
    if (selectedRange) {
      if (selectedRange.series && selectedRange.series.length > 0) {
        for (let i = 0; i < selectedRange.series.length; i++) {
          const serie = selectedRange.series[i];
          if (serie.components && serie.components.length > 0) {
            for (let x = 0; x < serie.components.length; x++) {
              const serieComponent = serie.components[x];
              if (serieComponent.oid === component.oid) {
                currentRecord = Object.assign({}, currentRecord, {
                  restrictions,
                });
                return Object.assign({}, state, { currentRecord });
              }
            }
          }
        }
      }
    }
    //* Verifica se o componente com restrição nao esta adicionado como componente geral
    if (currentRecord.generalFreightComponents.length > 0) {
      for (let y = 0; y < currentRecord.generalFreightComponents.length; y++) {
        const generalComponent = currentRecord.generalFreightComponents[y];
        if (generalComponent.oid == component.oid)
          currentRecord = Object.assign({}, currentRecord, { restrictions });
        return Object.assign({}, state, { currentRecord });
      }
    }
    //* Caso não se confirme nenhuma das situações acima exclui o componente
    if (component.restrictedByClient || component.restrictedByLocalization || component.restrictedByBusinessUnit) {
      restrictions = restrictions.filter(
        (restriction) => restriction.oid !== component.oid
      );
    }
  }
  currentRecord = Object.assign({}, currentRecord, { restrictions });
  return Object.assign({}, state, { currentRecord });
}

function deleteAddedClients(state, action) {
  let panelRestrictionByComponent = state.panelRestrictionByComponent;
  let currentSelectedComponent = panelRestrictionByComponent;
  const component = action.component;
  if (component.restrictedByClient) {
    delete currentSelectedComponent.clients;
  }
  panelRestrictionByComponent = Object.assign({}, panelRestrictionByComponent, {
    currentSelectedComponent,
  });
  return Object.assign({}, state, { panelRestrictionByComponent });
}

function deleteAddedLocations(state, action) {
  let panelRestrictionByComponent = state.panelRestrictionByComponent;
  let currentSelectedComponent = panelRestrictionByComponent;
  const component = action.component;
  if (component.restrictedByLocalization) {
    delete currentSelectedComponent.locations;
  }
  panelRestrictionByComponent = Object.assign({}, panelRestrictionByComponent, {
    currentSelectedComponent,
  });
  return Object.assign({}, state, { panelRestrictionByComponent });
}

function deleteAddedBusinessUnits(state, action) {
  let panelRestrictionByComponent = state.panelRestrictionByComponent;
  let currentSelectedComponent = panelRestrictionByComponent;
  const component = action.component;
  if (component.restrictedByBusinessUnit) {
    delete currentSelectedComponent.businessUnit
  }
  panelRestrictionByComponent = { ...panelRestrictionByComponent, currentSelectedComponent }
  return { ...state, panelRestrictionByComponent }

}

function setAddRestrictionButtonState(state, action) {
  let panelRestrictionByComponent = state.panelRestrictionByComponent;
  let currentSelectedComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  let disabled = true;
  if (currentSelectedComponent) {
    disabled = false;
  }
  panelRestrictionByComponent = Object.assign({}, panelRestrictionByComponent, {
    restrictionsDisabled: disabled,
  });
  return Object.assign({}, state, { panelRestrictionByComponent });
}

function setEditRestrictionButtonState(state, action) {
  let panelRestrictionByComponent = state.panelRestrictionByComponent;
  let restrictions = state.currentRecord.restrictions;
  let currentSelectedComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  let disabled = true;
  if (currentSelectedComponent) {
    restrictions.forEach((restriction) => {
      if (currentSelectedComponent.oid === restriction.oid) {
        if (restriction.restrictedByClient) {
          if (restriction.clients) {
            disabled = false;
          }
        } else if (restriction.restrictedByLocalization) {
          if (restriction.locations) {
            disabled = false;
          }
        } else if (restriction.restrictedByBusinessUnit) {
          if (restriction.units) {
            disabled = false;
          }
        }
      }
    });
  }
  panelRestrictionByComponent = Object.assign({}, panelRestrictionByComponent, {
    editButtonDisabled: disabled,
  });
  return Object.assign({}, state, { panelRestrictionByComponent });
}

function setDialogAddRestrictionsVisible(state, action) {
  let dialogAddRestrictions = state.dialogAddRestrictions;
  dialogAddRestrictions = Object.assign({}, dialogAddRestrictions, {
    visible: action.visible,
  });
  return Object.assign({}, state, { dialogAddRestrictions });
}

function setCurrentSelectedComponent(state, action) {
  let panelRestrictionByComponent = state.panelRestrictionByComponent;
  panelRestrictionByComponent = Object.assign({}, panelRestrictionByComponent, {
    currentSelectedComponent: action.component,
  });
  return Object.assign({}, state, { panelRestrictionByComponent });
}

function setdialogComponentName(state, action) {
  let dialogAddRestrictions = state.dialogAddRestrictions;
  if (action.component) {
    dialogAddRestrictions = Object.assign({}, dialogAddRestrictions, {
      dialogComponentName: action.component.name,
    });
    return Object.assign({}, state, { dialogAddRestrictions });
  }
  return state;
}

function setDialogComponentRestrictionLookupClientVisible(state, action) {
  let dialogAddRestrictions = state.dialogAddRestrictions;
  if (action.component) {
    const showlookupClient = action.component.restrictedByClient;
    dialogAddRestrictions = Object.assign({}, dialogAddRestrictions, {
      showlookupClient: showlookupClient,
    });
    return Object.assign({}, state, { dialogAddRestrictions });
  }
  return state;
}

function setDialogComponentRestrictionLookupLocationVisible(state, action) {
  let dialogAddRestrictions = state.dialogAddRestrictions;
  if (action.component) {
    const showlookupLocation = action.component.restrictedByLocalization;
    dialogAddRestrictions = Object.assign({}, dialogAddRestrictions, {
      showlookupLocation: showlookupLocation,
    });
    return Object.assign({}, state, { dialogAddRestrictions });
  }
  return state;
}

function setDialogComponentRestrictionLookupUnitVisible(state, action) {
  let dialogAddRestrictions = state.dialogAddRestrictions;
  if (action.component) {
    const showLookupUnit = action.component.restrictedByBusinessUnit;
    dialogAddRestrictions = { ...dialogAddRestrictions, showLookupUnit: showLookupUnit };
    return { ...state, dialogAddRestrictions };
  }
  return state;
}

function lookupClientSetVisible(state, action) {
  let lookupClient = state.lookupClient;
  lookupClient = Object.assign({}, lookupClient, { visible: action.visible });
  return Object.assign({}, state, { lookupClient });
}

function setlookupClientSelectedRecords(state, action) {
  let lookupClient = state.lookupClient;
  lookupClient = Object.assign({}, lookupClient, {
    selectedRecords: action.selectedRecords,
  });
  return Object.assign({}, state, { lookupClient });
}

function lookupClientAddRecords(state, action) {
  let lookupClient = state.lookupClient;
  let newRecords = action.records;
  const oldRecords = lookupClient.tableRecords;

  if (newRecords) {
    newRecords = newRecords.filter(
      (newRecord) =>
        oldRecords.filter((oldRecord) => oldRecord.oid === newRecord.oid) <= 0
    );

    const tableRecords = [...oldRecords, ...newRecords];
    lookupClient = Object.assign({}, lookupClient, { tableRecords });
    return Object.assign({}, state, { lookupClient });
  } else {
    const tableRecords = [];
    lookupClient = Object.assign({}, lookupClient, { tableRecords });
    return Object.assign({}, state, { lookupClient });
  }
}

function lookupLocationSetVisible(state, action) {
  let lookupLocation = state.lookupLocation;
  lookupLocation = Object.assign({}, lookupLocation, {
    visible: action.visible,
  });
  return Object.assign({}, state, { lookupLocation });
}

function setLookupLocationSelectedRecords(state, action) {
  let lookupLocation = state.lookupLocation;
  lookupLocation = Object.assign({}, lookupLocation, {
    selectedRecords: action.selectedRecords,
  });
  return Object.assign({}, state, { lookupLocation });
}

function lookupLocationAddRecords(state, action) {
  let lookupLocation = state.lookupLocation;
  let newRecords = action.records;
  const oldRecords = lookupLocation.tableRecords || [];

  if (newRecords) {
    newRecords = newRecords.filter(
      (newRecord) =>
        oldRecords.filter((oldRecord) => oldRecord.oid === newRecord.oid) <= 0
    );

    const tableRecords = [...oldRecords, ...newRecords];
    lookupLocation = Object.assign({}, lookupLocation, { tableRecords });
    return Object.assign({}, state, { lookupLocation });
  } else {
    const tableRecords = [];
    lookupLocation = Object.assign({}, lookupLocation, { tableRecords });
    return Object.assign({}, state, { lookupLocation });
  }
}

function setClientsOnCurrentRestrictionComponent(state, action) {
  let currentRecord = state.currentRecord;
  let restrictions = currentRecord.restrictions;
  let clients = state.lookupClient.tableRecords;
  let currentSelectedComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  if (currentSelectedComponent.restrictedByClient) {
    restrictions = restrictions.map((restriction) => {
      if (restriction.oid === currentSelectedComponent.oid) {
        if (restriction.clients) {
          let oldClients = currentSelectedComponent.clients;
          let newClients = clients.filter(
            (newClient) =>
              oldClients.filter(
                (oldClient) => oldClient.oid === newClient.oid
              ) <= 0
          );

          clients = [...oldClients, ...newClients];
          restriction.clients = clients;
        } else {
          restriction.clients = clients;
        }
      }
      return restriction;
    });

    currentRecord = Object.assign({}, currentRecord, { restrictions });
    return Object.assign({}, state, { currentRecord });
  }
  return state;
}

function setBusinessUnitsOnCurrentRestrictionComponent(state, action) {
  let currentRecord = state.currentRecord;
  let restrictions = currentRecord.restrictions;
  let units = state.lookupRestrictedUnit.tableRecords;
  let currentSelectedComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  if (currentSelectedComponent.restrictedByBusinessUnit) {
    restrictions = restrictions.map((restriction) => {
      if (restriction.oid === currentSelectedComponent.oid) {
        if (restriction.units) {
          let oldUnits = currentSelectedComponent.units;
          let newUnits = units.filter(
            (newUnit) =>
              oldUnits.filter(
                (oldUnit) => oldUnit.oid === newUnit.oid
              ) <= 0
          );

          units = [...oldUnits, ...newUnits];
          restriction.units = units;
        } else {
          restriction.units = units;
        }
      }
      return restriction;
    });

    currentRecord = Object.assign({}, currentRecord, { restrictions });
    return Object.assign({}, state, { currentRecord });
  }
  return state;
}

function setLocationsOnCurrentRestrictionComponent(state, action) {
  let currentRecord = state.currentRecord;
  let restrictions = currentRecord.restrictions;
  let locations = state.lookupLocation.tableRecords;
  let currentSelectedComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  if (currentSelectedComponent.restrictedByLocalization) {
    restrictions = restrictions.map((restriction) => {
      if (restriction.oid === currentSelectedComponent.oid) {
        if (restriction.locations) {
          let oldLocations = restriction.locations;
          let newLocations = locations.filter(
            (newLocation) =>
              oldLocations.filter(
                (oldLocation) => oldLocation.oid === newLocation.oid
              ) <= 0
          );

          locations = [...oldLocations, ...newLocations];
          restriction.locations = locations;
        } else {
          restriction.locations = locations;
        }
      }
      return restriction;
    });
    currentRecord = Object.assign({}, currentRecord, { restrictions });
    return Object.assign({}, state, { currentRecord });
  }
  return state;
}

function setRestrictionsToDuplicate(state, action) {
  const currentRecord = state.currentRecord;
  let restrictions = currentRecord.restrictions;
  let dialogAddRestrictions = state.dialogAddRestrictions;
  let componentsRestriction = state.dialogAddRestrictions.componentsRestriction;
  let currentComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  let listRestrictions = restrictions.filter(
    (restriction) =>
      currentComponent.oid !== restriction.oid &&
      currentComponent.restrictedByClient === restriction.restrictedByClient
  );
  componentsRestriction = listRestrictions.map((restriction) => {
    if (restriction.clients) {
      let component = {
        label: restriction.name,
        value: restriction.oid,
      };
      return component;
    } else {
      if (restriction.locations) {
        let component = {
          label: restriction.name,
          value: restriction.oid,
        };
        return component;
      }
    }
    return componentsRestriction;
  });

  dialogAddRestrictions = Object.assign({}, dialogAddRestrictions, {
    componentsRestriction,
  });
  return Object.assign({}, state, { dialogAddRestrictions });
}

function duplicateClientsRestrictions(state, action) {
  const currentRecord = state.currentRecord;
  const currentComponentId = action.component;
  let components = currentRecord.restrictions;
  let tableRecords = state.lookupClient.tableRecords;
  let lookupClient = state.lookupClient;

  components = components.filter(
    (component) => component.oid === currentComponentId
  );
  components.forEach((component) => {
    if (component.clients) {
      component.clients.forEach((client) => {
        tableRecords = [...tableRecords, client];
      });
    } else {
      return state;
    }
  });

  lookupClient = Object.assign({}, lookupClient, { tableRecords });
  return Object.assign({}, state, { lookupClient });
}

function duplicateLocationsRestrictions(state, action) {
  const currentRecord = state.currentRecord;
  const currentComponentId = action.component;
  let components = currentRecord.restrictions;
  let tableRecords = state.lookupClient.tableRecords;
  let lookupLocation = state.lookupLocation;

  components = components.filter(
    (component) => component.oid === currentComponentId
  );
  components.forEach((component) => {
    if (component.locations) {
      component.locations.forEach((location) => {
        tableRecords = [...tableRecords, location];
      });
    } else {
      return state;
    }
  });

  lookupLocation = Object.assign({}, lookupLocation, { tableRecords });
  return Object.assign({}, state, { lookupLocation });
}

function removeSelectedClients(state, action) {
  let lookupClient = state.lookupClient;
  let tableRecords = lookupClient.tableRecords;
  let currentSelectedRecords = lookupClient.selectedRecords;

  let newTableRecords = tableRecords.filter(
    (record) =>
      currentSelectedRecords.filter(
        (currentRecord) => record.oid === currentRecord.oid
      ) <= 0
  );

  lookupClient = Object.assign({}, lookupClient, {
    tableRecords: newTableRecords,
  });
  return Object.assign({}, state, { lookupClient });
}

function removeSelectedClientsFromRestrictionComponent(state, action) {
  let lookupClient = state.lookupClient;
  let currentRecord = state.currentRecord;
  let restrictions = currentRecord.restrictions;
  let currentSelectedClients = lookupClient.selectedRecords;
  let currentSelectedComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  restrictions = restrictions.map((restriction) => {
    if (restriction.oid === currentSelectedComponent.oid) {
      if (restriction.clients) {
        restriction.clients = restriction.clients.filter(
          (client) =>
            currentSelectedClients.filter(
              (currentClient) => client.oid === currentClient.oid
            ) <= 0
        );
      }
    }
    return restriction;
  });

  currentRecord = Object.assign({}, currentRecord, { restrictions });
  return Object.assign({}, state, { currentRecord });
}

function removeSelectedLocationsFromRestrictionComponent(state, action) {
  let lookupLocation = state.lookupLocation;
  let currentRecord = state.currentRecord;
  let restrictions = currentRecord.restrictions;
  let currentSelectedLocations = lookupLocation.selectedRecords;
  let currentSelectedComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  restrictions = restrictions.map((restriction) => {
    if (restriction.oid === currentSelectedComponent.oid) {
      if (restriction.locations) {
        restriction.locations = restriction.locations.filter(
          (location) =>
            currentSelectedLocations.filter(
              (currentLocation) => location.oid === currentLocation.oid
            ) <= 0
        );
      }
    }
    return restriction;
  });

  currentRecord = Object.assign({}, currentRecord, { restrictions });
  return Object.assign({}, state, { currentRecord });
}
function removeSelectedBusinessUnitsFromRestrictionComponent(state, action) {
  let lookupRestrictedUnit = state.lookupRestrictedUnit;
  let currentRecord = state.currentRecord;
  let restrictions = currentRecord.restrictions;
  let currentSelectedBusinessUnits = lookupRestrictedUnit.selectedRecords;
  let currentSelectedComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  restrictions = restrictions.map((restriction) => {
    if (restriction.oid === currentSelectedComponent.oid) {
      if (restriction.units) {
        restriction.units = restriction.units.filter(
          (unit) =>
            currentSelectedBusinessUnits.filter(
              (currentUnit) => unit.oid === currentUnit.oid
            ) <= 0
        );
      }
    }
    return restriction;
  });

  currentRecord = Object.assign({}, currentRecord, { restrictions });
  return Object.assign({}, state, { currentRecord });
}

function removeSelectedLocations(state, action) {
  let lookupLocation = state.lookupLocation;
  let tableRecords = lookupLocation.tableRecords;
  let currentSelectedRecords = lookupLocation.selectedRecords;
  let newTableRecords = tableRecords.filter(
    (record) =>
      currentSelectedRecords.filter(
        (currentRecord) => record.oid === currentRecord.oid
      ) <= 0
  );

  lookupLocation = Object.assign({}, lookupLocation, {
    tableRecords: newTableRecords,
  });
  return Object.assign({}, state, { lookupLocation });
}

function setLookupTableClients(state, action) {
  let currentRecord = state.currentRecord;
  let restrictions = currentRecord.restrictions;
  let lookupClient = state.lookupClient;
  let tableRecords = lookupClient.tableRecords;
  let currentSelectedComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  restrictions.forEach((restriction) => {
    if (restriction.oid === currentSelectedComponent.oid) {
      if (restriction.clients) {
        restriction.clients.forEach((client) => {
          tableRecords = [...tableRecords, client];
        });
      }
    }
  });
  lookupClient = Object.assign({}, lookupClient, { tableRecords });
  return Object.assign({}, state, { lookupClient });
}

function setLookupTableLocations(state, action) {
  let currentRecord = state.currentRecord;
  let restrictions = currentRecord.restrictions;
  let lookupLocation = state.lookupLocation;
  let tableRecords = lookupLocation.tableRecords;
  let currentSelectedComponent =
    state.panelRestrictionByComponent.currentSelectedComponent;

  restrictions.forEach((restriction) => {
    if (restriction.oid === currentSelectedComponent.oid) {
      if (restriction.locations) {
        restriction.locations.forEach((location) => {
          tableRecords = [...tableRecords, location];
        });
      }
    }
  });
  lookupLocation = Object.assign({}, lookupLocation, { tableRecords });
  return Object.assign({}, state, { lookupLocation });
}

function setCurrentClientName(state, action) {
  let currentClient = state.currentClient;
  let razaoSocial = currentClient.razaoSocial;

  razaoSocial = action.name;

  currentClient = Object.assign({}, currentClient, { razaoSocial });
  return Object.assign({}, state, { currentClient });
}

function lookupClientSetSearchText(state, action) {
  let lookupClient = state.lookupClient;
  let searchText = action.SearchText;
  lookupClient = Object.assign({}, lookupClient, { searchText });
  return Object.assign({}, state, { lookupClient });
}

function setCurrentClient(state, action) {
  // TODO implementar este metodo
}

function setCurrentSelectedComponentOnDropdown(state, action) {
  let dialogAddRestrictions = state.dialogAddRestrictions;

  let value = "";
  if (action.value) {
    value = action.value;
  }

  dialogAddRestrictions = Object.assign({}, dialogAddRestrictions, {
    currentComponentRestriction: value,
  });
  return Object.assign({}, state, { dialogAddRestrictions });
}

function removeSelectedRoutes(state) {
  const selectedRoutes = state.selectedRoutes;
  if (selectedRoutes && selectedRoutes.length > 0) {
    const currentRecord = Object.assign({}, state.currentRecord);
    let routes = currentRecord.routes; 
    routes = routes.filter((current) => {
      let routeInSelectedRoutes = selectedRoutes.find((selected) => 
      selected.oidCarrier == current.oidCarrier
      && selected.oidDestination == current.oidDestination
      && selected.oidOrigin == current.oidOrigin
      && selected.originName == current.originName) != null;
      return !routeInSelectedRoutes;
    }); 
    currentRecord.routes = routes;
    const result = Object.assign({}, state, {
      currentRecord,
      selectedRoutes: [],
    }); 
   return setSelectedRoute(result, { route: null });
  }
  return state;
}

function removeSelectedRange(state) {
  const selectedRoute = state.selectedRoute;
  const selectedRange = state.selectedRange;
  if (selectedRoute && selectedRange) {
    let ranges = selectedRoute.ranges || [];
    ranges = ranges.filter(
      (range) => range.rangeType !== selectedRange.rangeType
    );
    selectedRoute.ranges = ranges;
    return setSelectedRoute(state, { route: selectedRoute });
  }
  return state;
}

function setLookupRouteVisible(state, action) {
  let lookupRoute = state.lookupRoute;
  lookupRoute = Object.assign({}, lookupRoute, { visible: action.visible });
  return Object.assign({}, state, { lookupRoute });
}
function setLookupComponentVisible(state, action) {
  let lookupComponent = state.lookupComponent;
  lookupComponent = Object.assign({}, lookupComponent, {
    visible: action.visible,
  });
  return Object.assign({}, state, { lookupComponent });
}

function setDialogRoutesVisible(state, action) {
  let dialogRoutes = state.dialogRoutes;
  dialogRoutes = Object.assign({}, dialogRoutes, { visible: action.visible });
  return Object.assign({}, state, { dialogRoutes });
}

function setDialogRangeVisible(state, action) {
  let panelByRoute = Object.assign({}, state.panelByRoute);
  panelByRoute.dialog.visible = action.visible;
  return Object.assign({}, state, { panelByRoute });
}

function addRange(state, action) {
  let selectedRoute = state.selectedRoute;
  let ranges = selectedRoute.ranges || [];
  let newRange = action.rangeData;
  let range = ranges.find((r) => r.rangeType === newRange.rangeType);
  if (range) {
    //merge series
    let newSeries = newRange.series || [];
    let oldSeries = range.series || [];
    //remove as series não mais existentes
    oldSeries = oldSeries
      .filter((old) => {
        const serie = newSeries.find(
          (serie) => serie.start === old.start && serie.end === old.end
        );
        return serie != null;
      }) //faz o merge das séries já existentes
      .map((old) => {
        const serie = newSeries.find(
          (serie) => serie.start === old.start && serie.end === old.end
        );
        if (serie) {
          return Object.assign(old, serie);
        }
        return old;
      });
    //encontra as novas séries
    newSeries = newSeries.filter((newSerie) => {
      const serie = oldSeries.find(
        (serie) => serie.start === newSerie.start && serie.end === newSerie.end
      );
      return serie == null;
    });
    oldSeries = [...oldSeries, ...newSeries].sort((s1, s2) => {
      if (s1.start === s2.start) {
        return 0;
      } else if (s1.start > s2.start) {
        return 1;
      } else {
        return -1;
      }
    });
    range.series = oldSeries;
  } else {
    ranges = [...ranges, { ...action.rangeData }];
  }

  selectedRoute.ranges = ranges;
  return Object.assign({}, state, { selectedRoute });
}

function setSelectedRange(state, action) {
  let result = state;
  let selectedRoute = state.selectedRoute || {};
  let ranges = selectedRoute.ranges || [];
  let selectedRange = null;
  let selectedSerie = null;
  if (action.index > -1 && action.index < ranges.length) {
    selectedRange = ranges[action.index];
    selectedSerie =
      selectedRange.series && selectedRange.series.length > 0
        ? selectedRange.series[0]
        : null;
  }
  result = Object.assign({}, state, {
    selectedRange,
    indexSelectedRange: action.index,
  });
  return setSelectedSerie(result, { serie: selectedSerie });
}

function setSelectedSerie(state, action) {
  return Object.assign({}, state, { selectedSerie: action.serie });
}

function addComponentSelectedSerie(state, action) {
  let selectedSerie = state.selectedSerie;
  if (selectedSerie) {
    const component = action.component;
    const alreadyAdded =
      component &&
      selectedSerie.components &&
      selectedSerie.components.filter((current) => {
        return component.oid === current.oid && !current.useTextValue;
      }).length > 0;
    if (!alreadyAdded) {
      let components = selectedSerie.components || [];
      components = [...components, action.component];
      selectedSerie.components = components;
      return Object.assign({}, state, { selectedSerie });
    }
  }
  return state;
}
function addComponentGeneralComponents(state, action) {
  let currentRecord = state.currentRecord;
  if (currentRecord) {
    const component = action.component;
    const alreadyAdded =
      component &&
      currentRecord.generalFreightComponents &&
      currentRecord.generalFreightComponents.filter((current) => {
        return component.oid === current.oid;
      }).length > 0;
    if (!alreadyAdded) {
      let components = currentRecord.generalFreightComponents || [];
      components = [...components, component];
      currentRecord.generalFreightComponents = components;
      return Object.assign({}, state, { currentRecord });
    }
  }
  return state;
}

function changeComponentFromSelectedSerie(state, action) {
  let selectedSerie = state.selectedSerie;
  let component = { ...action.component };
  if (action.field !== "oid" && selectedSerie.components) {
    component[action.field] = action.value;
    const components = [...selectedSerie.components]
    if (action.index > -1 && action.index < components.length) {
      components[action.index] = component;
    }

    selectedSerie.components = components;
  }
  return Object.assign({}, state, { selectedSerie });
}
function changeComponentFromGeneralComponents(state, action) {
  let currentRecord = state.currentRecord;
  let component = { ...action.component };
  if (action.field !== "oid" && currentRecord.generalFreightComponents) {
    component[action.field] = action.value;
    currentRecord.generalFreightComponents = currentRecord.generalFreightComponents.map(
      (current) => {
        if (current.oid === component.oid) {
          return component;
        }
        return current;
      }
    );
  }
  return Object.assign({}, state, { currentRecord });
}
function removeComponentFromSelectedSerie(state, action) {
  let selectedSerie = state.selectedSerie;
  let component = action.component;
  selectedSerie.components = selectedSerie.components.filter(
    (current) => current !== component
  );

  return Object.assign({}, state, { selectedSerie });
}
function removeComponentFromGeneralComponents(state, action) {
  let currentRecord = state.currentRecord;
  let component = action.component;
  currentRecord.generalFreightComponents = currentRecord.generalFreightComponents.filter(
    (current) => current !== component
  );

  return Object.assign({}, state, { currentRecord });
}

function changeVisibilityDialogAddComponentSelectedSerie(state, action) {
  let panelByRoute = state.panelByRoute;
  let panelRange = panelByRoute.panelRange;
  let dialogAddComponentSelectedSerie =
    panelRange.dialogAddComponentSelectedSerie;
  dialogAddComponentSelectedSerie = Object.assign(
    {},
    dialogAddComponentSelectedSerie,
    { visible: action.visible }
  );
  panelRange = Object.assign({}, panelRange, {
    dialogAddComponentSelectedSerie,
  });
  panelByRoute = Object.assign({}, panelByRoute, { panelRange });
  return Object.assign({}, state, { panelByRoute });
}
function changeVisibilityDialogAddComponentGeneralComponents(state, action) {
  let dialogAddComponentGeneralComponents =
    state.dialogAddComponentGeneralComponents;
  dialogAddComponentGeneralComponents = Object.assign(
    {},
    dialogAddComponentGeneralComponents,
    { visible: action.visible }
  );
  return Object.assign({}, state, { dialogAddComponentGeneralComponents });
}

function setDialogDuplicateRouteVisible(state, action) {
  let dialogDuplicateRoute = state.dialogDuplicateRoute;
  dialogDuplicateRoute = Object.assign({}, dialogDuplicateRoute, {
    visible: action.visible,
  });
  return Object.assign({}, state, { dialogDuplicateRoute });
}

function setDuplicateRoute(state, action) {
  const route = action.route;

  if (route) {
    const currentDuplicateRoute = {
      origin: { oid: route.oidOrigin, description: route.originName },
      destination: {
        oid: route.oidDestination,
        description: route.destinationName,
      },
      carrier: {
        oid: route.oidCarrier,
        name: route.carrierName,
        cnpj: route.carrierNumber,
      },
      company: {
        oid: route.oidCompany,
        name: route.companyName,
        cnpj: route.companyNumber,
      },
      ranges: utils.deepCopy(route.ranges),
      name: route.name,
    };

    return Object.assign({}, state, { currentDuplicateRoute });
  }
  return state;
}

function setDuplicateRouteFields(state, action) {
  const { field, value } = action;
  if (field) {
    let currentDuplicateRoute = state.currentDuplicateRoute;
    let stateField = currentDuplicateRoute[field];
    typeof stateField === "string"
      ? (stateField = value)
      : (stateField = Object.assign({}, {}, value));
    currentDuplicateRoute[field] = stateField;

    return Object.assign({}, state, { currentDuplicateRoute });
  }
  return state;
}

function setLookupOriginVisible(state, action) {
  let lookupOrigin = state.lookupOrigin;
  lookupOrigin = Object.assign({}, lookupOrigin, { visible: action.visible });
  return Object.assign({}, state, { lookupOrigin });
}

function setLookupDestinationVisible(state, action) {
  let lookupDestination = state.lookupDestination;
  lookupDestination = Object.assign({}, lookupDestination, {
    visible: action.visible,
  });
  return Object.assign({}, state, { lookupDestination });
}
function setDialogEditSerieVisible(state, action) {
  let panelByRoute = Object.assign({}, state.panelByRoute);
  let panelRange = panelByRoute.panelRange;
  let dialogEditSerie = panelRange.dialogEditSerie;
  dialogEditSerie = Object.assign({}, dialogEditSerie, {
    visible: action.visible,
  });
  panelRange = Object.assign({}, panelRange, { dialogEditSerie });
  panelByRoute = Object.assign({}, panelByRoute, { panelRange });
  return Object.assign({}, state, { panelByRoute });
}

//*This method sets a new value to a serie within the selectedRoute -> selectedRange -> selectedSerie
function setSerie(state, action) {
  let selectedRoute = Object.assign({}, state.selectedRoute);
  let selectedRange = Object.assign({}, state.selectedRange);
  let selectedSerie = Object.assign({}, state.selectedSerie);

  const series = selectedRange.series.map((serie) => {
    if (
      serie.start === selectedSerie.start &&
      serie.end === selectedSerie.end
    ) {
      return action.serie;
    }
    return serie;
  });

  const ranges = selectedRoute.ranges.map((range) => {
    if ((range.rangeType = selectedRange.rangeType)) {
      range.series = series;
    }
    return range;
  });

  selectedRoute = Object.assign(selectedRoute, { ranges });

  return setSelectedRoute(state, { route: selectedRoute });
}
//*This method removes a serie within the selectedRoute -> selectedRange -> selectedSerie
function removeSelectedSerie(state, action) {
  const selectedRoute = state.selectedRoute;
  const selectedRange = state.selectedRange;
  const selectedSerie = state.selectedSerie;

  if (selectedRoute && selectedRange && selectedSerie) {
    let series = selectedRange.series || [];
    series = series.filter(
      (serie) =>
        !(
          serie.start === selectedSerie.start && serie.end === selectedSerie.end
        )
    );

    if (series.length <= 0) {
      return removeSelectedRange(state, action);
    }
    let ranges = selectedRoute.ranges.map((range) => {
      if (range.rangeType === selectedRange.rangeType) {
        range.series = series;
      }
      return range;
    });

    selectedRoute.ranges = ranges;
    return setSelectedRoute(state, { route: selectedRoute });
  }
  return state;
}

function resultImportClientXLSX(state, action) {
  let lookupClient = { ...state.lookupClient, tableRecords: action.data || [] };
  return { ...state, lookupClient };
}
function resultImportLocationXLSX(state, action) {
  let lookupLocation = {
    ...state.lookupLocation,
    tableRecords: action.data || [],
  };
  return { ...state, lookupLocation };
}

function setLookupBusinessUnit2(state, action) {
  let lookupBusinessUnit2 = state.lookupBusinessUnit2;
  lookupBusinessUnit2.visible = action.visible;
  return { ...state, lookupBusinessUnit2 }
}

function setLookupRestrictedUnitVisible(state, action) {
  let lookupRestrictedUnit = state.lookupRestrictedUnit;
  lookupRestrictedUnit.visible = action.visible;
  return { ...state, lookupRestrictedUnit }
}

function setEntriesGeneralComponent(state, action) {
  let currentRecord = state.currentRecord;
  let generalFreightComponents = currentRecord.generalFreightComponents;
  generalFreightComponents.length > 0 && generalFreightComponents.map((item) => {
    if (item.oid == action.componentOid) {
      item.entries = action.entries;
    }
    return item;
  })
  currentRecord = { ...currentRecord, generalFreightComponents }
  return { ...state, currentRecord }
}
