import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { UnidadeList } from "./UnidadeList";
import { actions } from "../unidade.actions";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'

const mapStateToProps = state => {
  const currentMenu = state.menuState.currentRecord;
  const userConfigurations = state.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
  return {
    columns: state.crudUnidadeState.columns,
    records: state.crudUnidadeState.records,
    first: state.crudUnidadeState.index,
    rows: state.crudUnidadeState.max,
    totalRecords: state.crudUnidadeState.count,
    sortField: state.crudUnidadeState.sortField,
    order: state.crudUnidadeState.sortOrder,
    booleanFilter: state.crudUnidadeState.booleanFilter,
    booleanTypes: [
      { label: 'TODOS', value: '' },
      { label: 'SIM', value: true },
      { label: 'NÃO', value: false }
    ],
    menuItems: state.menuState.items,
    currentMenu,
    isFavorite,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.applyFilter(filterParams));
    },
    changeBooleanFilter: (e) => {
      const target = e.target;

      dispatch(actions.setBooleanFilter(target.name, target.value));
      let filters = [];
      if (target.value || target.value === false) {
        filters = [{
          campo: "active",
          valor: target.value,
          tipoFiltro: 'IGUAL_A'
        }];
      }
      dispatch(actions.applyFilter(filters));

    },
    load: () => dispatch(actions.load()),
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
    }
  };
};

class UnidadeListPageContainer extends React.Component {
  newHandler = () => {
    this.props.history.push(`${routes.PATH_UNIDADE}/novo`);
  };

  componentWillReceiveProps(newProps) {
    if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }

    if ((!this.props.currentMenu && newProps.currentMenu) ||
      (this.props.currentMenu !== newProps.currentMenu)) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu)
    }
  }

  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath)
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  }

  onPage = e => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    });
  };
  onSort = e => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  };
  onFilter = e => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      if(key == "nomePessoa"){
        filters.push({
          campo: "pessoa.nome",
          valor: e.filters[key].value,
          tipoFiltro: "CONTEM"
        });
      }else if(key == "nomeEmpresa"){
        filters.push({
          campo: "empresa.nomeEmpresa",
          valor: e.filters[key].value,
          tipoFiltro: "CONTEM"
        });      
      }else{
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: "CONTEM"
        });
      }
    });
    this.props.applyFilter(filters);
  };

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_UNIDADE}/${oid}`)
    }
  }

  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu)
    }
  }

  render() {
    const isFavorite = this.props.isFavorite;
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Unidades</h1>

          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
            title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
        </div>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );

    const booleanFilter = (field) => {
      return (<Dropdown style={{ width: '100%' }} name={field}
        value={this.props.booleanFilter[field]} options={this.props.booleanTypes} onChange={(e) => this.props.changeBooleanFilter(e)} />)
    }



    const booleanFieldsTemplate = (rowData, column) => {
      const result = rowData[column.field];
      if (result) {
        return <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}><span className="pi pi-check-circle" style={{ 'fontSize': '1.8rem' }} /> </div>;
      } else {
        return <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}><span className="pi pi-times-circle" style={{ 'fontSize': '1.8rem' }} /></div>;
      }
    };

    const columns = this.props.columns.map(col => {
      if ('ativo' === col.field) {
        return <Column {...col} key={col.field} body={booleanFieldsTemplate} filterElement={booleanFilter(col.field)} />;
      }
      return <Column {...col} key={col.field} />;
    });

    const content = (
      <UnidadeList
        {...this.props}
        onRowClick={this.onRowClick}
        onFilter={this.onFilter}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={columns}
      />
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnidadeListPageContainer);
