import { types } from "./patiomanager.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";

const initialState = {
  currentBusinessUnit: null,
  selectedCarriers: [],
  dateSelected: null,
  intervaloMin: 15,
  //default values
  docaWorkDayTime: { 
    timeNextOperation:15,//sempre de 15 em 15 minutos
    "dayStartSchedule" : 1,//dias
    docaRangeTime: [
      {
        start: "08:00",
        end: "18:00",
      },
    ],
    holidays:[],
    docaWorkDays: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false,
    },
  },
  schedulingRecords: [], //registros de agendamentos
  scheduleEdit: null,
  visibleEditSchedule: false,
  horaSelected: null,
  dockSelected: null,
  isLoadingShedulesHours: false,
  scheduleLimite:0
};

 
const reducer = new Reducer(initialState, types);

export function CRUDDockmanagerReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_CURRENT_BUSINESSUNIT:
      return setCurrentBusinessUnit(state, action);
    case types.SET_SELECTED_CARRIERS:
      return setSelectedCarriers(state, action);
    case types.SET_SELECTED_DATE:
      return setSelectedDate(state, action);
    case types.SET_SCHEDULING_RECORDS:
      return setSchedulingRecords(state, action);
    case types.SET_VISIBLE_MODAL_EDIT_SCHEDULE:
      return setVisibleEditSchedule(state, action);
    case types.SET_SELECTED_ECHEDULE_EDIT:
      return setEditSelectedSchedule(state, action);
    case types.SET_SELECTED_DOCK_EDIT:
      return setEditDockSelected(state, action);
    case types.SET_SELECTED_HORA_EDIT:
      return setEditHoraSelected(state, action);
    case types.ENABLE_LOAD_HOURS_SCHEDULE_DOCK:
      return setLoaderHoursScheduleDoca(state, action);
    case types.SET_SCHEDULE_LIMITE:
      return setScheduleLimites(state, action);
  }
  return result;
}

 
function setScheduleLimites(state, action) {
  let scheduleLimite = action.scheduleLimite;
  return Object.assign({}, state, { scheduleLimite });
}
function setLoaderHoursScheduleDoca(state, action) {
  let isLoadingShedulesHours = action.isLoadingShedulesHours;
  return Object.assign({}, state, { isLoadingShedulesHours });
}

function setEditHoraSelected(state, action) {
  let horaSelected = action.horaSelected; 
  return Object.assign({}, state, { horaSelected });
}
function setEditDockSelected(state, action) {
  let dockSelected = action.dockSelected;
  return Object.assign({}, state, { dockSelected: { ...dockSelected } });
}
const getRandomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return "#" + randomColor;
  //color:this.getRandomColor(),
};

function setEditSelectedSchedule(state, action) {
  let scheduleEdit = action.scheduleEdit ? { ...action.scheduleEdit } : null;
  return Object.assign({}, state, { scheduleEdit });
}

function setVisibleEditSchedule(state, action) {
  return Object.assign({}, state, { visibleEditSchedule: action.visible });
}
function setSchedulingRecords(state, action) {
  let schedulingRecords = action.schedulingRecords;
  return Object.assign({}, state, { schedulingRecords });
}
function setCurrentBusinessUnit(state, action) {
  const currentBusinessUnit = action.currentBusinessUnit;
  let  docaWorkDayTime;
  if (currentBusinessUnit && currentBusinessUnit.docaWorkDayTime) {
    docaWorkDayTime = currentBusinessUnit.docaWorkDayTime;
    if (!docaWorkDayTime) {
      docaWorkDayTime = state.docaWorkDayTime;
    }
  } else {
    docaWorkDayTime = state.docaWorkDayTime;
  }
  let selectedCarriers = [];
  if (currentBusinessUnit && currentBusinessUnit.carrierConfigurations) {
    selectedCarriers = currentBusinessUnit.carrierConfigurations;
  }
  return Object.assign({}, state, { currentBusinessUnit, docaWorkDayTime, selectedCarriers });
}

function checkDay(state) {
  const wednesday = [];

  if (!state.docaWorkDayTime.docaWorkDays.sunday) wednesday.push(0);
  if (!state.docaWorkDayTime.docaWorkDays.monday) wednesday.push(1);
  if (!state.docaWorkDayTime.docaWorkDays.tuesday) wednesday.push(2);
  if (!state.docaWorkDayTime.docaWorkDays.wednesday) wednesday.push(3);
  if (!state.docaWorkDayTime.docaWorkDays.thursday) wednesday.push(4);
  if (!state.docaWorkDayTime.docaWorkDays.friday) wednesday.push(5);
  if (!state.docaWorkDayTime.docaWorkDays.saturday) wednesday.push(6);

  if (wednesday.indexOf(moment().weekday()) == -1) {
    let dateSelected = moment().toDate();
    state = Object.assign({}, state, { dateSelected });
  }
  return state;
}

function setSelectedCarriers(state, action) {
  const selectedCarriers = action.selectedCarriers;
  // moment().toDate()
  if (!state.dateSelected) {
    state = checkDay(state);
  } 
  return Object.assign({}, state, { selectedCarriers });
}

function setSelectedDate(state, action) {
  const dateSelected = action.dateSelected; //).startOf("day").toDate();
  return Object.assign({}, state, { dateSelected });
}
