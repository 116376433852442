
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import { connect } from "react-redux";
import { actions as actionsCollectionOrder } from "../../../../collectionOrderPortalCarrier/collectionOrderPortalCarrier.actions";
import { actions } from "../../../portalCarrier.actions";
import "./componetes.scss"
import { FileUploudScreen } from "../../../../../components/file-upload-screen/FileUploadScreen";
import FileUploaderButtonPreview from "../../../../../components/file-uploader-button-preview/FileUploaderButtonPreview";
import { CARGO_IN_TRANSITO, CARGO_SHIP_DELIVERED } from "../../../constantes";
import { makeMessages, messageActions } from "../../../../../store/message";
import storage from '../../../../../services/stoage.service';
import { InputSwitch } from "primereact/inputswitch";

const mapStateToProps = store => {
  const fileImg = store.crudPortalCarrierState.fileImg;
  const linkCanhoto = store.crudPortalCarrierState.linkCanhoto;
  const cargoShipStatusInTransito = store.crudPortalCarrierState.cargoShipStatusInTransito;
  const cargoShipStatusDelivereds = store.crudPortalCarrierState.cargoShipStatusDelivereds;
  const expandedRows = store.crudPortalCarrierState.expandedRows;

  const user = storage.getUser();
  let isPermitEditarEmbarque = false;
  if (user.carrier) {
    isPermitEditarEmbarque = true;
  }
  return {
    fileImg,
    linkCanhoto,
    currentOrderEdit: store.crudPortalCarrierState.currentOrderEdit,
    observation: store.crudPortalCarrierState.observation,
    deliveryDate: store.crudPortalCarrierState.deliveryDate,
    showModalUploadImg: store.crudPortalCarrierState.showModalUploadImg,
    cargoShipStatusInTransito,
    cargoShipStatusDelivereds,
    expandedRows,
    currentScreen: store.crudPortalCarrierState.currentScreen,
    isPermitEditarEmbarque
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCodeOrder: order => dispatch(actions.setCodeOrder(order)),
    setShowModalUploadImg: visible => dispatch(actions.setShowModalUploadImg(visible)),
    changeHandlerImage: (fileImg) => dispatch(actions.changeHandlerImage(fileImg)),
    onChangeDeliveryDate: (deliveryDate) => dispatch(actions.onChangeDeliveryDate(deliveryDate)),
    onChangeObs: (obs) => dispatch(actions.onChangeObs(obs)),
    sendReceipt: (data, calbak) => dispatch(actionsCollectionOrder.sendReceipt(data, calbak)),
    updatePedidoInfo: (dados) => dispatch(actions.updatePedidoInfo(dados)),
    getAllCargoShipmentInTransito: () => dispatch(actions.getAllCargoShipmentInTransito()),

    getCargoShipmentDelivered: () => {
      dispatch(actions.loadCargoShipmentDelivered())
    },
    showMessageErro: (msg) => {
      dispatch(messageActions.messageShowMessages(makeMessages([msg], "error"))
      );
    }
  }
}


class UpdateDataEntregaPortalCarrier extends React.Component {
  constructor(props) {
    super(props);

    let entregaNormal = this.props.currentOrderEdit.deliverySuccess
    let entregaCancelada = this.props.currentOrderEdit.deliverySuccess != null ? !this.props.currentOrderEdit.deliverySuccess : false
    if (!entregaNormal && !entregaCancelada) {
      entregaNormal = true//default
    }

    this.state = {
      entregaNormal: entregaNormal,
      entregaCancelada: entregaCancelada
    }
  }

  checkIfAllDeliveryWithDate = () => {
    if (this.props.expandedRows && Array(this.props.expandedRows).length > 0) {
      let rowSeletect = this.props.expandedRows[0];
      if (this.props.currentScreen == CARGO_IN_TRANSITO) {

        let cargoShipStatusInTransito = this.props.cargoShipStatusInTransito;
        let indice = -1;
        for (let index = 0; index < cargoShipStatusInTransito.length; index++) {
          if (cargoShipStatusInTransito[index].oid == rowSeletect.oid) {
            indice = index;
          }
        }

        if (indice > 0) {
          let countDeliveryDate = 0;
          for (let index = 0; index < cargoShipStatusInTransito[indice].orders.length; index++) {
            if (cargoShipStatusInTransito[indice].orders[index].occurrence &&
              cargoShipStatusInTransito[indice].orders[index].occurrence.dateOccurrence) {
              countDeliveryDate++;
            }
          }
          if (countDeliveryDate == cargoShipStatusInTransito[indice].orders.length) {

            this.props.getAllCargoShipmentInTransito();
          }
        }
      } else if (this.props.currentScreen == CARGO_SHIP_DELIVERED) {

        let cargoShipStatusDelivereds = this.props.cargoShipStatusDelivereds;
        let indice = -1;
        for (let index = 0; index < cargoShipStatusDelivereds.length; index++) {
          if (cargoShipStatusDelivereds[index].oid == rowSeletect.oid) {
            indice = index;
          }
        }

        if (indice > 0) {
          let countDeliveryDate = 0;
          for (let index = 0; index < cargoShipStatusDelivereds[indice].orders.length; index++) {
            if (cargoShipStatusDelivereds[indice].orders[index].occurrence &&
              cargoShipStatusDelivereds[indice].orders[index].occurrence.dateOccurrence) {
              countDeliveryDate++;
            }
          }
          if (countDeliveryDate == cargoShipStatusDelivereds[indice].orders.length) {

            this.props.getCargoShipmentDelivered();
          }
        }

      }
    }
  }

  onSave = (e) => {
    //validar o usuario se é transp  
    if (this.props.isPermitEditarEmbarque) {

      let file = this.props.fileImg;
      const setShowModalUploadImg = this.props.setShowModalUploadImg;
      const updatePedidoInfo = this.props.updatePedidoInfo;

      const tipFile = typeof fileImg;

      if (file && tipFile !== "string") {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let fileBase64 = "";
        const extension = file.name.split('.').pop();
        reader.onloadend = () => {
          fileBase64 = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "");

          const data = {
            "link": this.props.linkCanhoto,
            "code": this.props.currentOrderEdit.code,
            "deliveryDate": this.props.currentOrderEdit.deliveryDate,
            "observation": this.props.currentOrderEdit.observation,
            "extension": extension,
            "deliverySuccess": this.state.entregaNormal,
            "file": fileBase64,
            "type": file.type
          }
          this.props.sendReceipt(data, (sucess) => {
            if (sucess) {
              setShowModalUploadImg(false);
              updatePedidoInfo(data);
              setTimeout(() => { this.checkIfAllDeliveryWithDate(); }, 1000);
            }
          });
        }
      }
      else {
        const data = {
          "link": this.props.linkCanhoto,
          "code": this.props.currentOrderEdit.code,
          "deliveryDate": this.props.currentOrderEdit.deliveryDate,
          "observation": this.props.currentOrderEdit.observation,
          "deliverySuccess": this.state.entregaNormal,
        }

        this.props.sendReceipt(data, (sucess) => {
          if (sucess) {
            setShowModalUploadImg(false);
            updatePedidoInfo(data);
            setTimeout(() => { this.checkIfAllDeliveryWithDate(); }, 1000);
          }
        });
      }
    } else {
      this.props.showMessageErro("Somente a transportadora pode alterar o status do embarque")
    }
  }
  onUploadImage = (e) => {
    if (e.target && e.target.files.length > 0) {
      this.props.changeHandlerImage(e.target.files[0])
    }
  }

  showModalUploadImg = (visible) => {
    this.props.setShowModalUploadImg(visible);
    if (!visible) {
      this.props.setCodeOrder(null);
    }
  }

  onChangeDeliveryDate = (event) => {
    this.props.onChangeDeliveryDate(event.target.value)
  }
  onChangeObs = (event) => {
    this.props.onChangeObs(event.target.value)
  }

  onChangeEntregaNormal = (value) => {
    this.setState({ entregaNormal: value });
    this.setState({ entregaCancelada: !value });
    if (this.props.observation && String(this.props.observation).trim() == "Entrega Recusada") {
      this.props.onChangeObs("")
    }
  }
  onChangeEntregaCancelada = (value) => {
    this.setState({ entregaCancelada: value });
    this.setState({ entregaNormal: !value });

    const observation = this.props.observation && String(this.props.observation).trim() != "" ? this.props.observation : "Entrega Recusada"
    this.props.onChangeObs(observation)
  }

  render() {

    const deliveryDate = this.props.deliveryDate// ? this.props.currentOrderEdit.deliveryDate : "";
    const observation = this.props.observation// ? this.props.currentOrderEdit.observation : "";
    const btnSaveEnabled = this.state.entregaNormal || this.state.entregaCancelada;


    return (

      <FileUploudScreen
        visible={this.props.showModalUploadImg}
        onHide={(e) => this.showModalUploadImg(false)}>
        <div className="p-grid">
          <div className="p-col-12 content-check">

            <div className="align-inline-up-canhoto" onClick={(e) => { this.onChangeEntregaNormal(true); }}>
              <InputSwitch
                checked={this.state.entregaNormal}
                onChange={(e) => { this.onChangeEntregaNormal(true); }}
                key={"canhoto-success"}
                id="canhoto-success"
              />
              <div className="space-10px"></div>
              <label htmlFor="canhoto-success">Entrega realizada</label>
            </div>


            <div className="align-inline-up-canhoto" onClick={(e) => { this.onChangeEntregaCancelada(true) }} >
              <InputSwitch
                checked={this.state.entregaCancelada}
                onChange={(e) => { this.onChangeEntregaCancelada(true); }}
                key={"canhoto-cancelada"}
                id="canhoto-cancelada"
              />
              <div className="space-10px"></div>
              <label htmlFor="canhoto-cancelada">Entrega Recusada</label>
            </div>
          </div>
        </div>

        <div className="p-grid">
          {this.state.entregaNormal &&
            <div className="p-col-3">
              <div className="content-upload">
                <FileUploaderButtonPreview
                  title="Enviar Comprovante de Entrega"
                  onUpload={(e) => this.onUploadImage(e)}
                  fileImg={this.props.fileImg}
                  label="Comprovante"
                  accept="image/png, image/jpeg, image/jpg"
                >
                </FileUploaderButtonPreview>
              </div>
            </div>
          }
          <div className={this.state.entregaNormal ? 'p-col-9 form-field-container' : 'p-col-12 form-field-container'}>

            <div className="p-col-12 form-field-container">
              <label>Data da entrega</label>
              <Calendar
                showIcon={true}
                name="deliveryDate"
                value={deliveryDate}
                hourFormat="24"
                showTime={true}
                onChange={this.onChangeDeliveryDate}
                disabled={false}
              />
            </div>

            <div className="p-col-12 form-field-container">
              <label>Observação</label>
              <InputTextarea
                rows={10}
                cols={30}
                name="observation"
                value={observation}
                onChange={this.onChangeObs}
              />
            </div>
          </div>

          <div className="p-col-12 lxActionButtons">
            <div>
              <button
                disabled={!btnSaveEnabled}
                title="Enviar Comprovante"
                onClick={this.onSave}
                style={{ marginRight: ".25em" }}
                className="lognex-btn-highlighted">
                <i className="pi pi-save"></i>
              </button>
              Salvar
            </div>
          </div>
        </div>

      </FileUploudScreen>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDataEntregaPortalCarrier);
