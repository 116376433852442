export const routes = {
    PATH_TESTE: '/teste',
    PATH_PESSOA: '/system/setup/persons',
    PATH_EMPRESA: '/system/setup/companies',
    PATH_USUARIO: '/system/setup/users',
    PATH_PERFIL: '/system/setup/profiles',
    PATH_MODULO: '/system/setup/modules',
    PATH_ACAO: '/system/setup/actions',
    PATH_PERMISSAO: '/system/setup/permissions',
    PATH_UNIDADE: '/system/setup/units',
    PATH_CLIENT: '/system/setup/clients',
    PATH_MENU: '/system/setup/menus',
    PATH_ZIPCODERANGE: '/planning/setup/zipcoderanges',
    PATH_CARRIER: '/planning/setup/carriers',
    PATH_REGION: '/planning/setup/regions',
    PATH_ROUTE: '/planning/setup/routes',
    PATH_SEGMENT: '/system/setup/segments',
    PATH_FREIGHT_COMPONENT: '/planning/setup/freightcomponents',
    PATH_PALLETSTANDARD: '/planning/setup/palletstandards',
    PATH_PALLET: '/planning/setup/pallets',
    PATH_PACKING: '/planning/setup/packings',
    PATH_PRODUCT: '/planning/setup/products',
    PATH_MODAL: '/planning/setup/modals',
    PATH_VEHICLE: '/planning/setup/vehicles',
    PATH_FREIGHT_TABLE: '/planning/setup/freighttables',
    PATH_VEHICLE_GROUP: '/planning/setup/vehiclegroups',
    PATH_VEHICLE_TYPE: '/planning/setup/vehicletypes',
    PATH_IMPORT_FILES: '/planning/documents/imports',
    PATH_AUDIT: "/financial/audit",
    PATH_DASHBOARD: '',
    PATH_FREIGHT_CALCULATION: '/planning/freightcalculator',
    PATH_TRACKING: '/delivery_management/shipment_tracking',
    PATH_OCCURRENCETYPE: '/delivery_management/ocurrency_types',
    PATH_DELIVERY_DEADLINE: '/delivery_management/delivery_deadline',
    PATH_HOLIDAY: '/delivery_management/holiday',
    PATH_ACCOUNT_CONFIGURATION: '/account_configuration',
    PATH_ORDER_LOG: '/orders/log',
    PATH_ORDER_MANAGEMENT: '/planning/orders/management',
    PATH_USER_DOCUMENT_TYPE: '/planning/userdocumenttype',
    PATH_PACKING_CONVERTER: '/planning/setup/packing-converter',
    PATH_CARGO_SHIPMENT: '/planning/orders/management/cargo/shipment',
    PATH_DRIVER: '/planning/driver',
    PATH_LOG_NFE: '/planning/logs/nfes',
    PATH_HISTORY_LOG: '/historylog/log',
    PATH_ORDER_DELIVERY: '/planning/orders/delivery',
    PATH_TOWER_EVENT: '/lognex/tower/events',
    PATH_TOWER_TRAVEL: '/lognex/tower/travel',
    PATH_TOWER_EQUIPMENT: '/lognex/tower/equipment',
    PATH_TOWER_EQUIPMENT_TYPE: '/lognex/tower/equipment-type',
    PATH_TOWER_TRAVEL_VISUALIZE_MAP: '/lognex/tower/travel-visualize-map',
    PATH_TOWER_DASHBOARD: '/lognex/tower/dashboard',
    PATH_TOWER_OBSERVATION_TYPE: '/lognex/tower/observationtype',
    PATH_AUDIT_RECORDS: '/financial/audit/records',
    PATH_DOCK_MANAGER: '/lognex/dockmanager',
    PATH_PATIO_MANAGER: '/lognex/patioManager',
    PATH_PATIO_MANAGER_DOCK: '/lognex/patioManager/dock',    
    PATH_TRANSFER_ROUTE: '/transferroute',
    PATH_SCRIPTWRITER: '/lognex/scriptwriter',
    PATH_PENDENCE_DOC: '/planning/pendence',
    PATH_POLICES: '/system/setup/polices',
    PATH_POLICES_GROUP: '/system/setup/policesgroup',
    PATH_POLICES_RESOURCES: '/system/setup/policesresources',
    PATH_CFOP:'/financial/audit/cfop',
    PATH_CARGOSHIPMENT_REPORT: '/planning/orders/report',
    PATH_CARGOSHIPMENT_REPORT_KM: '/planning/orders/report-km',
    PATH_CAMPANHA_ANYMARKET: '/planning/setup/campanha-anymarket',
    PATH_CARRIER_PORTAL: '/transporter/portal',
    PATH_CARRIER_PORTAL_COLLECTION_ORDER: '/transporter/portal/collection-order',
}