import { Column } from "primereact/column";
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { connect } from "react-redux";
import { ButtonDialog } from '../../../components/button-dialog/ButtonDialog';
import { Page } from "../../../components/page/Page";
import { Step } from '../../../components/step/Step';
import Toolbar from '../../../components/toolbar/Toolbar';
import { Wizard } from '../../../components/wizard/Wizard';
import { routes } from '../../../constants';
import { AppService, BUSINESS_UNIT_CHANGE_EVENT_NAME, COMPANY_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { formatNumber, numberToCurrency } from '../../../utils';
import { actions as businessUnitsGroupActions } from '../../businessUnitGroupFilter/businessUnitGroupFilter.actions';
import { actions as carrierActions } from "../../carrier/carrier.actions";
import { actions as locationsActions } from "../../location/location.actions";
import { actions as unidadeActions } from '../../unidade/unidade.actions';
import { actions as accountConfigurationActions } from "../../accountConfiguration/accountConfiguration.actions";

import { Filter } from '../components/Filter';
import { actions } from '../order-management.actions';
import './OrderManagementCard.scss';

function hasPencency(records, type) {
    records = records || [];
    records = records.filter(record => {
        if (!record || !record.pendencies || record.pendencies.length === 0) {
            return false;
        }
        return record.pendencies.find(pendency => pendency.type === type && pendency.status === 'OPEN') != null;
    })
    return records && records.length > 0
}

function getIDs(data = []) {
    data = data || []
    return data.map(item => item.oid);
}
function getIDShipments(data = []) {
    data = data || []
    return data.map(item => {
        return { id: item.oid, urgent: item.urgent, ag: item.ag,zorc:item.zorc }
    });
}
function getPendencesIDs(data, type, status) {
    let shipmentsWithPendency = data.filter(shipment => {
        const pendencies = shipment.pendencies || [];
        let pendencie = pendencies.find(pendency => pendency.type === type && pendency.status === status);
        return pendencie != null;
    })
    return getIDs(shipmentsWithPendency);
}


function mapStateToProps(state) {
    const { crudBusinessUnitGroupState, crudUnidadeState } = state;
    const orders = state.orderManagementState.orders;
    const shipments = state.orderManagementState.shipments;
    const countOrders = state.orderManagementState.countOrders
    const countCargoShipmentOrders = state.orderManagementState.countCargoShipmentOrders
    const filterFields = { ...state.orderManagementState.toolbarFilters, businessUnitGroup: crudBusinessUnitGroupState.currentRecord, dropdownBusinessUnitsOptions: crudUnidadeState.records }



    const lookupCarrierBase = {
        records: state.crudCarrierState.records,
        columns: state.orderManagementState.toolbarFilters.lookupCarrier.visible
            ? state.crudCarrierState.columns
                .filter(col => col.field === 'oid' || col.field === 'tradeName' || col.field === 'name')
                .map(col => <Column key={col.field} {...col} />)
            : [<Column key={"columnKeyCarrier"} />],
        first: state.crudCarrierState.index,
        rows: state.crudCarrierState.max,
        totalRecords: state.crudCarrierState.count,
        sortField: state.crudCarrierState.sortField,
        order: state.crudCarrierState.sortOrder,
        editDisabled: true,
    }
    const lookupCarrier = { ...state.orderManagementState.toolbarFilters.lookupCarrier, ...lookupCarrierBase }

    const lookupOriginBase = {
        records: state.crudLocationState.records,
        columns: state.orderManagementState.toolbarFilters.lookupOrigin.visible
            ? state.crudLocationState.columns
                .filter(col => col.field === 'oid' || col.field === 'description')
                .map(col => <Column key={col.field} {...col} />)
            : [<Column key={"columnKeyOrigin"} />],
        first: state.crudLocationState.index,
        rows: state.crudLocationState.max,
        totalRecords: state.crudLocationState.count,
        sortField: state.crudLocationState.sortField,
        order: state.crudLocationState.sortOrder,
        editDisabled: true,
    }
    const lookupOrigin = { ...state.orderManagementState.toolbarFilters.lookupOrigin, ...lookupOriginBase }

    const lookupDestinationBase = {
        records: state.crudLocationState.records,
        columns: state.orderManagementState.toolbarFilters.lookupDestination.visible
            ? state.crudLocationState.columns
                .filter(col => col.field === 'oid' || col.field === 'description')
                .map(col => <Column key={col.field} {...col} />)
            : [<Column key={"columnKeyDestination"} />],
        first: state.crudLocationState.index,
        rows: state.crudLocationState.max,
        totalRecords: state.crudLocationState.count,
        sortField: state.crudLocationState.sortField,
        order: state.crudLocationState.sortOrder,
        editDisabled: true,
    }
    const lookupDestination = { ...state.orderManagementState.toolbarFilters.lookupDestination, ...lookupDestinationBase }


    const hasMoreOrders = state.orderManagementState.pagination.count != orders.length;
    const hasMoreShipments = state.orderManagementState.pagination.count != shipments.length;
    let selectedShipments = state.orderManagementState.selectedShipments;
    const pendenceEmail = hasPencency(selectedShipments, 'EMAIL');
    const pendenceQuote = hasPencency(selectedShipments, 'QUOTE');
    const pendenceConfirmeShipmentWebhook = hasPencency(selectedShipments, "CONFIRM_SHIPMENT_WEBHOOK");

    const businessUnitGroupRecords = crudBusinessUnitGroupState.records;
    const businessUnitGroupColumns = crudBusinessUnitGroupState.columns;
    const businessUnitGroupIndex = crudBusinessUnitGroupState.index;
    const businessUnitGroupMax = crudBusinessUnitGroupState.max;
    const businessUnitGroupCount = crudBusinessUnitGroupState.count;
    const businessUnitGroupSortField = crudBusinessUnitGroupState.sortField;
    const businessUnitGroup = crudBusinessUnitGroupState.currentRecord;

    const businessUnitColumns = crudUnidadeState.columns;
    const businessUnitRecords = crudUnidadeState.records;
    const listGrouper = state.orderManagementState.toolbarFilters.listGrouper || null;
    const selectedBusinessUnits = state.orderManagementState.toolbarFilters.selectedBusinessUnits || [];
    const selectedBusinessUnitGroup = state.orderManagementState.toolbarFilters.selectedBusinessUnitGroup || null;
    const selectedMainBusinessUnit = state.orderManagementState.toolbarFilters.selectedMainBusinessUnit || null;
    const selectedBusinessUnitGroupLabel = state.orderManagementState.toolbarFilters.selectedBusinessUnitGroupLabel || "";

    let isDisableBtnConfirm = false;
    try {
        selectedShipments = selectedShipments.filter((emb) => emb.situation !== "INVALID")
        const totalEmbarqWhitShippment = selectedShipments.filter((emb) => emb.returned).length
        isDisableBtnConfirm = selectedShipments.length == 0 || totalEmbarqWhitShippment > 0
    } catch (error) {
    }

    const { cargoManager = { permiteEmbarqueSemCotacao: false } } = state.crudAccountConfigurationState.currentRecord;
 


    return {
        selectedOrders: state.orderManagementState.selectedOrders,
        currentView: state.orderManagementState.currentView,
        pendenceEmail,
        pendenceQuote,
        pendenceConfirmeShipmentWebhook,
        selectedShipments,
        orders,
        shipments,
        countOrders,
        filterFields,
        lookupCarrier,
        lookupOrigin,
        lookupDestination,
        countCargoShipmentOrders,
        hasMoreOrders,
        hasMoreShipments,
        businessUnitGroupRecords,
        businessUnitGroupColumns,
        businessUnitGroupIndex,
        businessUnitGroupMax,
        businessUnitGroupCount,
        businessUnitGroupSortField,
        listGrouper,
        selectedBusinessUnits,
        selectedBusinessUnitGroup,
        businessUnitColumns,
        businessUnitRecords,
        selectedMainBusinessUnit,
        selectedBusinessUnitGroupLabel,
        businessUnitGroup,
        isDisableBtnConfirm,
        cargoManager
    }

}

function mapDispatchToProps(dispatch) {
    return {
        setCurrentView: (view) => dispatch(actions.setCurrentView(view)),

        loadOrders: (scriptStatus, readyStock, replace) => dispatch(actions.loadOrders(scriptStatus, readyStock, replace)),
        loadShipmentCargo: (status, replace) => dispatch(actions.loadShipmentCargo(status, replace)),

        loadCountOrders: () => dispatch(actions.loadCountOrders()),
        loadCountCargoShipmentOrders: () => dispatch(actions.loadCountCargoShipmentOrders()),

        setNumberFilter: (field, value) => dispatch(actions.setNumberFilter(field, value)),
        setGrouperFilter: (field, value) => dispatch(actions.setGroupFilter(field, value)),
        initialDateChangeHandler: e => dispatch(actions.setInitialDateFilter(e.value)),
        finalDateChangeHandler: e => dispatch(actions.setFinalDateFilter(e.value)),

        lookupCarrierSetVisible: visible => dispatch(actions.setLookupCarrierVisible(visible)),
        lookupCarrierPaginate: paginationParams => dispatch(carrierActions.paginate(paginationParams)),
        lookupCarrierSortOrder: sortParams => dispatch(carrierActions.sortOrder(sortParams)),
        lookupCarrierLoad: () => dispatch(carrierActions.load()),
        setCarrierFilter: carrier => dispatch(actions.setCarrierFilter(carrier)),
        lookupCarrierApplyFilter: filterParams => {
            dispatch(carrierActions.setType(null));
            dispatch(carrierActions.applyFilter(filterParams));
        },

        lookupOriginLoad: () => dispatch(locationsActions.load()),
        lookupOriginSetVisible: visible => dispatch(actions.setLookupOriginVisible(visible)),
        setOriginFilter: origin => dispatch(actions.setOriginFilter(origin)),
        lookupOriginApplyFilter: filterParams => {
            dispatch(locationsActions.setType(null));
            dispatch(locationsActions.applyFilter(filterParams));
        },
        lookupOriginPaginate: paginationParams => dispatch(locationsActions.paginate(paginationParams)),
        lookupOriginSortOrder: sortParams => dispatch(locationsActions.sortOrder(sortParams)),

        lookupDestinationLoad: () => dispatch(locationsActions.load()),
        lookupDestinationSetVisible: visible => dispatch(actions.setLookupDestinationVisible(visible)),
        setDestinationFilter: destination => dispatch(actions.setDestinationFilter(destination)),
        lookupDestinationApplyFilter: filterParams => {
            dispatch(locationsActions.setType(null));
            dispatch(locationsActions.applyFilter(filterParams));
        },
        lookupDestinationPaginate: paginationParams => dispatch(locationsActions.paginate(paginationParams)),
        lookupDestinationSortOrder: sortParams => dispatch(locationsActions.sortOrder(sortParams)),

        applyFilter: ({ orderFilters = [], shipmentFilters = [] }) => {
            dispatch(actions.setType(null));
            dispatch(actions.applyOrderFilters(orderFilters));
            dispatch(actions.applyShipmentFilters(shipmentFilters));
            dispatch(actions.loadCountOrders());
            dispatch(actions.loadCountCargoShipmentOrders());
        },
        setOrder: order => {
            dispatch(actions.setOrder(order))
        },
        setOrders: orders => {
            dispatch(actions.setOrders(orders))
        },
        addOrders: orders => {
            dispatch(actions.addOrders(orders))
        },
        setSelectedOrders: orders => dispatch(actions.setSelectedOrders(orders)),
        scriptingOrders: (orders, mainBusinessUnitGroup) => dispatch(actions.scriptingOrders(orders, mainBusinessUnitGroup)),

        cancelOrders: (oids) => (dispatch(actions.cancelOrders(oids))),
        cancelScriptingOrders: (oids) => (dispatch(actions.cancelScriptingOrders(oids))),

        setShipment: shipment => {
            dispatch(actions.setShipment(shipment))
        },
        setShipments: shipments => {
            dispatch(actions.setShipments(shipments))
        },
        setSelectedShipments: shipments => dispatch(actions.setSelectedShipments(shipments)),
        confirmCollectionOrder: shipments => dispatch(actions.confirmCollectionOrder(shipments)),
        resendEmailCarrier: (oids) => dispatch(actions.resendEmailCarrier(oids)),
        regenerateQuote: (oids) => dispatch(actions.regenerateQuote(oids)),
        resendConfirmShipment: (oids) => dispatch(actions.resendConfirmShipmentWebhook(oids)),
        onShowGroupModal: () => {
            dispatch(businessUnitsGroupActions.setRecord(null));
            dispatch(unidadeActions.load());
            dispatch(actions.setVisibleModalGrouperFilter(true));
        },
        loadBusinessUnits: (filters) => {
            dispatch(unidadeActions.applyFilter(filters));
            dispatch(unidadeActions.load());
        },
        onHideGroupModal: () => {
            dispatch(actions.setVisibleModalGrouperFilter(false));
        },
        onShowSearchBusinessUnitModal: (visible) => {
            if (visible) {
                dispatch(businessUnitsGroupActions.load());
            }
            dispatch(actions.setVisibleModalSearchBusinessUnitFilter(visible));
        },
        setSelectedRecord: (record) => dispatch(actions.setSelectedRecord(record)),
        setUnitsInListGrouper: (units) => dispatch(actions.setSelectedUnitsListGrouper(units)),
        setBusinessUnitGroup: (businessUnitGroup) => {
            if (businessUnitGroup) {
                dispatch(actions.setBusinessUnitFilter(null))
            }
            dispatch(businessUnitsGroupActions.setRecord(businessUnitGroup))
        },
        setSelectedBusinessUnitGroup: (selectedBusinessUnitGroup) => {
            dispatch(actions.setSelectedBusinessUnit(selectedBusinessUnitGroup));
        },
        setSelectedBusinessUnitsGrouper: (record) => {
            dispatch(actions.setSelectedBusinessUnitsGrouper(record))
        },
        setSelectedBusinessUnits: (records) => {
            dispatch(actions.setSelectedBusinessUnits(records))
        },
        setSelectMainBusinessUnit: (record) => {
            dispatch(actions.setSelectMainBusinessUnit(record))
        },
        createBusinessUnitGroup: (newGroup) => {
            if (newGroup) {
                dispatch(actions.setBusinessUnitFilter(null))
            }
            dispatch(businessUnitsGroupActions.setRecord(newGroup))
            dispatch(businessUnitsGroupActions.save())
        },
        setSelectedBusinessUnitGroupLabel: (value) => {
            dispatch(actions.setSelectedBusinessUnitGroupLabel(value));
        },
        applyBusinessUnitGroupFilter: (filters) => {
            dispatch(businessUnitsGroupActions.setType(null));
            dispatch(businessUnitsGroupActions.applyFilter(filters));
        },
        setBusinessUnitGroupFilter: (businessUnitGroup) => {
            dispatch(actions.setBusinessUnitGroupFilter(businessUnitGroup))
        },
        setBusinessUnitFilter: (businessUnit) => {
            dispatch(actions.setBusinessUnitFilter(businessUnit))
            if (businessUnit) {
                dispatch(businessUnitsGroupActions.setRecord(null))
            }
        },
        clearRecords: () => {
            const data = {
                records: [],
                index: 0,
                size: 0,
                count: 50
            }
            dispatch(actions.fetchShipmentsSuccess(data))
            dispatch(actions.fetchOrdersSuccess(data))
        },
        businessUnitGroupPaginate: paginationParams => dispatch(businessUnitsGroupActions.paginate(paginationParams)),
        businessUnitGroupOnSortOrder: sortParams => dispatch(businessUnitsGroupActions.sortOrder(sortParams)),
        cancelCargoShipment: ids => dispatch(actions.cancelCargoShipment(ids)),
        backCargoShipment: ids => dispatch(actions.backCargoShipment(ids)),
        changeAutoComplete: value => dispatch(actions.changeAutoComplete(value)),
        searchLabel: value => dispatch(actions.searchLabel(value)),
        loadLabels: (filters) => dispatch(actions.loadLabels(filters)),
        setLabel: (value) => dispatch(actions.setLabel(value)),
        setLabelList: (labelList) => dispatch(actions.setLabelList(labelList)),
        setStatusInTransitShipments: ids => dispatch(actions.setStatusInTransitShipments(ids)),

        onSelectUrgent: value => dispatch(actions.onSelectUrgent(value)),
        onSelectAG: value => dispatch(actions.onSelectAG(value)),
        onSelectZorc: value => dispatch(actions.onSelectZorc(value)),
        

        accountConfigurationLoad: () => dispatch(accountConfigurationActions.loadAccountConfigurationsByCompany()),
 
        createCargoShipmentWithoutQuote: (ordersId) =>dispatch(actions.createCargoShipmentWithoutQuote(ordersId)),
    }
}

class OrderManagementListPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showOrderDialog: false,
            currentOrder: null,
            currentStepIndex: 0,
        }
    }


    componentWillReceiveProps(newProps) {
        if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
            const menuPath = this.props.history.location.pathname;
            this.props.setCurrentMenu(menuPath)
        }

        if ((!this.props.currentMenu && newProps.currentMenu) ||
            (this.props.currentMenu !== newProps.currentMenu)) {
            const oidMenu = newProps.currentMenu.oid;
            this.props.userLastAccess(oidMenu)
        }
    }



    componentDidMount() {
        AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
        AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
        // const filtersParams = this.getFilters();
        // this.props.applyFilter(filtersParams);
        this.toggleSelect(this.props.currentView)
        // this.loadOrders(this.props.currentView);
        // this.loadShipmentCargo(this.props.currentView);
        this.props.accountConfigurationLoad();
        this.props.loadBusinessUnits([]);
    }

    loadOrders = (currentView) => {
        switch (currentView) {
            case 'ordersWithoutStock':
                this.props.loadOrders('NOT_SCRIPTED', false, true);
                break;
            case 'ordersWithStock':
                this.props.loadOrders('NOT_SCRIPTED', true, true);
                break;
            case 'ordersInScripting':
                this.props.loadOrders('SCRIPTING', true, true);
                break;
        }
    }

    loadShipmentCargo = (currentView, replace) => {
        switch (currentView) {
            case 'shipments':
                this.props.loadShipmentCargo('NEW', replace);
                break;
            case 'shipmentsToPickupProcess':
                this.props.loadShipmentCargo('CONFIRMED', replace);
                break;
            case 'shipmentsInPickupProcess':
                this.props.loadShipmentCargo('APROVED', replace);
                break;
            case 'shipmentsInDeliveryProcess':
                this.props.loadShipmentCargo('TRAVELING', replace);
                break;
            case 'shipmentsWithDeliveryProcessEnd':
                this.props.loadShipmentCargo('DELIVERED', replace);
                break;
        }
    }

    onReceiveAppNotifications = (eventName, params) => {
        if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
            if (eventName === COMPANY_CHANGE_EVENT_NAME) {
                this.props.setBusinessUnitFilter(null);
                this.props.loadBusinessUnits([]);
            }
            const filtersParams = this.getFilters();
            // this.props.applyFilter(filtersParams);
            this.props.accountConfigurationLoad();
            this.props.clearRecords();
            //this.loadOrders(this.props.currentView);
            //this.loadShipmentCargo(this.props.currentView);

        }
    }

    onSelectOrder = (event) => {
        const selected = event.selected;
        const order = event.order;
        let orders = [...this.props.selectedOrders] || [];
        const previousSelected = orders.find(element => element.oid === order.oid) != null;
        if (selected && !previousSelected) {
            orders.push(order);
        }
        if (!selected) {
            orders = orders.filter(element => element.oid !== order.oid);
        }
        this.props.setSelectedOrders(orders);

    }

    onClickOrder = (event) => {
        const currentOrder = event.order;
        if (currentOrder) {
            this.props.setOrder(currentOrder);
            this.props.history.push(`${routes.PATH_ORDER_MANAGEMENT}/${currentOrder.oid}`)
        }
    }

    onCancelOrders = (event) => {
        const ids = getIDs(this.props.selectedOrders);
        this.props.cancelOrders(ids)
    }

    onScriptingOrderHandler = (event) => {
        this.props.scriptingOrders(this.props.selectedOrders, this.props.businessUnitGroup.mainBusinessUnit);
    }

    onCancelScriptinsOrders = (event) => {
        const ids = getIDs(this.props.selectedOrders);
        this.props.cancelScriptingOrders(ids)
    }

    onSetCurrentView = (view) => {
        this.props.setCurrentView(view)
    }

    toggleSelect = (currentView) => {
        const elCurrentView = document.getElementById(currentView);
        const currentSelected = document.querySelector('.selected');
        let result = false;
        if (elCurrentView != currentSelected) {
            currentSelected && currentSelected.classList.toggle('selected');
            result = elCurrentView.classList.toggle('selected');
        }
        return result
    }

    onSelectPanel = (event) => {
        const result = this.toggleSelect(event.currentTarget.id)
        if (result) {
            this.onSetCurrentView(event.currentTarget.id)
            this.loadOrders(event.currentTarget.id)
            this.loadShipmentCargo(event.currentTarget.id);
        }
    }

    onSetNumberFilter = (e) => {
        const field = e.target.name
        const value = e.target.value
        this.props.setNumberFilter(field, value)
    }

    onInitialDateChangeHandler = (e) => {
        this.props.initialDateChangeHandler(e)
    }

    onFinalDateChangeHandler = (e) => {
        this.props.finalDateChangeHandler(e)
    }

    lookupCarrierOnPage = e => {
        this.props.lookupCarrierPaginate({
            max: e.rows,
            index: e.first
        });
    };
    lookupCarrierOnSort = e => {
        this.props.lookupCarrierSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField,
        });
    };
    lookupCarrierOnFilter = e => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: "CONTEM"
            });
        });
        this.props.lookupCarrierApplyFilter(filters);
    };
    lookupCarrierOnClick = e => {
        this.props.lookupCarrierLoad();
        this.props.lookupCarrierSetVisible(true);
    };
    lookupCarrierOnHide = e => {
        this.props.lookupCarrierSetVisible(false);
    };
    lookupCarrierOnConfirm = e => {
        this.props.lookupCarrierApplyFilter([]);
        this.props.setCarrierFilter(e.selection);
        this.props.lookupCarrierSetVisible(false);
    };
    lookupCarrierOnCancel = e => {
        this.props.setCarrierFilter(null);
        this.props.lookupCarrierSetVisible(false);
    };

    lookupCarrierOnEdit = () => { }

    lookupCarrierOnComplete = e => { }

    lookupCarrierOnInputFilter = e => {
        const value = e.value;
        const filters = []
        if (typeof value === "string") {
            filters.push(
                {
                    campo: "tradeName",
                    valor: value,
                    tipoFiltro: "CONTEM"
                }
            )
            this.props.setCarrierFilter({ tradeName: value });
        } else {
            this.props.setCarrierFilter(value);
        }
        this.props.lookupCarrierApplyFilter(filters);
    };

    getCarrierFilter = () => {
        const { documentNumber } = this.props.filterFields.carrier || ""
        return documentNumber
    }

    // LOOKUP ORIGIN
    lookupOriginOnPage = e => {
        this.props.lookupOriginPaginate({
            max: e.rows,
            index: e.first
        });
    };
    lookupOriginOnSort = e => {
        this.props.lookupOriginSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField,
        });
    };
    lookupOriginOnFilter = e => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: "CONTEM"
            });
        });
        this.props.lookupOriginApplyFilter(filters);
    };
    lookupOriginOnClick = e => {
        this.props.lookupOriginLoad();
        this.props.lookupOriginSetVisible(true);
    };
    lookupOriginOnHide = e => {
        this.props.lookupOriginSetVisible(false);
    };
    lookupOriginOnConfirm = e => {
        this.props.lookupOriginApplyFilter([]);
        this.props.setOriginFilter(e.selection);
        this.props.lookupOriginSetVisible(false);
    };
    lookupOriginOnCancel = e => {
        this.props.setOriginFilter(null);
        this.props.lookupOriginSetVisible(false);
    };
    lookupOriginOnEdit = () => { }
    lookupOriginOnComplete = e => { }
    lookupOriginOnInputFilter = e => {
        const value = e.value;
        const filters = []
        if (typeof value === "string") {
            filters.push(
                {
                    campo: "description",
                    valor: value,
                    tipoFiltro: "CONTEM"
                }
            )
            this.props.setOriginFilter({ description: value });
        } else {
            this.props.setOriginFilter(value);
        }
        this.props.lookupOriginApplyFilter(filters);
    };
    getOriginFilter = () => {
        const { oid } = this.props.filterFields.origin || {}
        return oid
    }


    // LOOKUP DESTINATION
    lookupDestinationOnPage = e => {
        this.props.lookupDestinationPaginate({
            max: e.rows,
            index: e.first
        });
    };
    lookupDestinationOnSort = e => {
        this.props.lookupDestinationSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField,
        });
    };
    lookupDestinationOnFilter = e => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: "CONTEM"
            });
        });
        this.props.lookupDestinationApplyFilter(filters);
    };
    lookupDestinationOnInputFilter = e => {
        const value = e.value;
        const filters = []
        if (typeof value === "string") {
            filters.push(
                {
                    campo: "description",
                    valor: value,
                    tipoFiltro: "CONTEM"
                }
            )
            this.props.setDestinationFilter({ description: value });
        } else {
            this.props.setDestinationFilter(value);
        }
        this.props.lookupDestinationApplyFilter(filters);
    };
    lookupDestinationOnClick = e => {
        this.props.lookupDestinationLoad();
        this.props.lookupDestinationSetVisible(true);
    };
    lookupDestinationOnHide = e => {
        this.props.lookupDestinationSetVisible(false);
    };
    lookupDestinationOnConfirm = e => {
        this.props.lookupDestinationApplyFilter([]);
        this.props.setDestinationFilter(e.selection);
        this.props.lookupDestinationSetVisible(false);
    };
    lookupDestinationOnCancel = e => {
        this.props.setDestinationFilter(null);
        this.props.lookupDestinationSetVisible(false);
    };
    lookupDestinationOnEdit = () => { }
    lookupDestinationOnComplete = e => { }
    getDestinationFilter = () => {
        const { oid } = this.props.filterFields.destination || {}
        return oid
    }

    getFilters = () => {

        let orderFilters = [];
        let shipmentFilters = [];
        const normalizeDate = date => {
            return date && typeof date === "object" ? date : null;
        }
        const initialDateFilter = normalizeDate(this.props.filterFields.initialDate)
        const finalDateFilter = normalizeDate(this.props.filterFields.finalDate)
        const number = this.props.filterFields.number && this.props.filterFields.number !== ""
            ? this.props.filterFields.number
            : null
        const carrierOptions = this.getCarrierFilter() || ""
        const originOptions = this.getOriginFilter() || ""
        const destinationOptions = this.getDestinationFilter() || "";
        const groupOption = this.props.filterFields.businessUnitGroup || null;
        const currentBusinessUnit = this.props.filterFields.currentBusinessUnit || null;
        const labels = []
        this.props.filterFields.label && this.props.filterFields.label.forEach((item) => {
            labels.push(item.name)
        });

        const baseFilters = []
        if (initialDateFilter && finalDateFilter) {
            baseFilters.push({
                campo: "createdAt",
                campoComplementar: "createdAt",
                valor: initialDateFilter,
                valorComplementar: finalDateFilter,
                tipoFiltro: "ENTRE"
            });
        }
        if (number) {
            let num = number.split(";");
            if (num.length == 1) {
                baseFilters.push({
                    campo: "number",
                    valor: number,
                    tipoFiltro: "IGUAL_A"
                });
            } else {
                baseFilters.push({
                    campo: "number",
                    valor: num,
                    tipoFiltro: "ESTA_CONTIDO_EM"
                });
            }


        }
        if (carrierOptions != "") {
            baseFilters.push({
                campo: "carrierNumber",
                valor: carrierOptions,
                tipoFiltro: "IGUAL_A"
            });
        }
        if (originOptions != "") {
            baseFilters.push({
                campo: "origin.locationID",
                valor: originOptions,
                tipoFiltro: "IGUAL_A"
            });
        }
        if (destinationOptions != "") {
            baseFilters.push({
                campo: "destination.locationID",
                valor: destinationOptions,
                tipoFiltro: "IGUAL_A"
            });
        }

        if (labels.length > 0) {
            baseFilters.push({
                campo: "label",
                valor: labels,
                tipoFiltro: "ESTA_CONTIDO_EM"
            });
        }

        {/** filtros para o AGRUPADOR */ }
        orderFilters = [...baseFilters]
        shipmentFilters = [...baseFilters]

        if (groupOption && groupOption.oid) {
            orderFilters.push(
                {
                    campo: "businessUnit.documentNumber",
                    valor: groupOption.businessUnits.map(unit => unit.documentNumber),
                    tipoFiltro: "ESTA_CONTIDO_EM"
                }
            );
            orderFilters.push(
                {
                    campo: "businessUnit.label",
                    valor: groupOption.businessUnits.map(unit => unit.code),
                    tipoFiltro: "ESTA_CONTIDO_EM"
                }
            );
            shipmentFilters.push(
                {
                    campo: "businessUnit.documentNumber",
                    valor: groupOption.mainBusinessUnit.documentNumber,
                    tipoFiltro: "IGUAL_A"
                }
            );
            shipmentFilters.push(
                {
                    campo: "businessUnit.label",
                    valor: groupOption.mainBusinessUnit.label,
                    tipoFiltro: "IGUAL_A"
                }
            );
        } else if (currentBusinessUnit && currentBusinessUnit.documentNumber) {
            orderFilters.push(
                {
                    campo: "businessUnit.documentNumber",
                    valor: [currentBusinessUnit.documentNumber],
                    tipoFiltro: "ESTA_CONTIDO_EM"
                }
            );
            orderFilters.push(
                {
                    campo: "businessUnit.label",
                    valor: currentBusinessUnit.code,
                    tipoFiltro: "IGUAL_A"
                }
            );
            shipmentFilters.push(
                {
                    campo: "businessUnit.documentNumber",
                    valor: currentBusinessUnit.documentNumber,
                    tipoFiltro: "IGUAL_A"
                }
            );
            shipmentFilters.push(
                {
                    campo: "businessUnit.label",
                    valor: currentBusinessUnit.label,
                    tipoFiltro: "IGUAL_A"
                }
            );
        }
        return { orderFilters, shipmentFilters }
    }

    onApplyFilter = () => {
        const filtersParams = this.getFilters();
        this.props.applyFilter(filtersParams);
        this.loadOrders(this.props.currentView);
        this.loadShipmentCargo(this.props.currentView);
    }

    onSelectAllOrders = (event) => {
        if (event.target.checked) {
            this.props.setSelectedOrders(this.props.orders);
        } else {
            this.props.setSelectedOrders([]);

        }
    }

    onLoadPlussOrdersItems = () => {
        // this.loadOrders(this.props.currentView);
        switch (this.props.currentView) {
            case 'ordersWithoutStock':
                this.props.loadOrders('NOT_SCRIPTED', false, false);
                break;
            case 'ordersWithStock':
                this.props.loadOrders('NOT_SCRIPTED', true, false);
                break;
            case 'ordersInScripting':
                this.props.loadOrders('SCRIPTING', true, false);
                break;
        }
    }

    onLoadPlussCargoShipmentItems = () => {
        this.loadShipmentCargo(this.props.currentView, false);
    }

    onSelectShipment = (event) => {
        const selected = event.selected;
        const shipment = event.shipment;
        let shipments = [...this.props.selectedShipments] || [];
        const previousSelectedShipment = shipments.find(elem => elem.oid === shipment.oid) != null;
        if (selected && !previousSelectedShipment) {
            shipments.push(shipment);
        }
        if (!selected) {
            shipments = shipments.filter(element => element.oid !== shipment.oid);
        }
        this.props.setSelectedShipments(shipments);
    }


    onSelectAllShipments = (event) => {
        if (event.target.checked) {
            this.props.setSelectedShipments(this.props.shipments);
        } else {
            this.props.setSelectedShipments([]);

        }
    }

    onClickShipment = (event) => {
        const currentShipment = event.shipment;
        if (currentShipment) {
            this.props.setShipment(currentShipment);
            this.props.history.push(`${routes.PATH_CARGO_SHIPMENT}/${currentShipment.oid}`)
        }
    }

    onConfirmCollectionOrder = () => {
        let shipments = getIDShipments(this.props.selectedShipments);
        this.props.confirmCollectionOrder(shipments)
    }



    onClickResolvePendenceEmail = (event) => {
        const ids = getPendencesIDs(this.props.selectedShipments, "EMAIL", "OPEN")
        this.props.resendEmailCarrier(ids);
    }


    onClickResolvePendenceQuote = (event) => {
        const ids = getPendencesIDs(this.props.selectedShipments, "QUOTE", "OPEN")
        this.props.regenerateQuote(ids);
    }

    onClickResolvePendenceConfirmShipment = (event) => {
        const ids = getPendencesIDs(this.props.selectedShipments, "CONFIRM_SHIPMENT_WEBHOOK", "OPEN")
        this.props.resendConfirmShipment(ids)
    }

    onClickSearchGrouper = (e) => {
        this.props.onShowSearchBusinessUnitModal(true);
    }

    onBusinessUnitsHide = (e) => {
        this.props.onShowSearchBusinessUnitModal(false);
    }

    onClickAddGrouper = (e) => {
        this.props.onShowGroupModal();
    }

    onGroupHide = (e) => {
        this.props.onHideGroupModal();
        return this.setState({ ...this.state, currentStepIndex: 0 });
    }


    onNextGrouper = (e) => {
        return this.setState({ ...this.state, currentStepIndex: 1 });
    }

    onPreviousGrouper = (e) => {
        return this.setState({ ...this.state, currentStepIndex: 0 });
    }

    onCancelGrouper = (e) => {
        return this.setState({ ...this.state, currentStepIndex: 0 });
    }


    onConfirmGroup = (e) => {
        let businessUnits = this.props.selectedBusinessUnits || [];
        businessUnits = businessUnits.map(unit => {
            return {
                id: unit.oid,
                documentNumber: unit.cnpj,
                tradeName: unit.tradeName,
                label: unit.codigo
            }
        })
        const mainBusinessUnit = {
            id: this.props.selectedMainBusinessUnit.oid,
            documentNumber: this.props.selectedMainBusinessUnit.cnpj,
            tradeName: this.props.selectedMainBusinessUnit.nomePessoa,
            label: this.props.selectedMainBusinessUnit.codigo
        }
        const label = this.props.selectedBusinessUnitGroupLabel;
        this.props.createBusinessUnitGroup({
            businessUnits,
            mainBusinessUnit,
            label
        })
        this.props.setSelectMainBusinessUnit(null);
        this.props.setSelectedBusinessUnits([]);
        this.props.setSelectedBusinessUnitGroupLabel("");
        this.props.onHideGroupModal();
        this.setState({ ...this.state, currentStepIndex: 0 });
    }

    setSelectedRecord = (event) => {
        const data = event.data;
        this.props.setSelectedRecord(data);
    }
    onSelectBusinessUnitsChange = (event) => {
        event.originalEvent.preventDefault();
        event.originalEvent.stopPropagation();
        this.props.setSelectedBusinessUnits(event.value)
    }

    onSelectionBusinessUnitGroupChange = (event) => {
        this.props.setSelectedBusinessUnitGroup(event.value);
    }

    onConfirmSelectedBusinessUnitGroup = () => {
        const businessUnitGroup = this.props.selectedBusinessUnitGroup || null;
        this.props.setBusinessUnitGroup(businessUnitGroup);
        this.props.setSelectedBusinessUnitGroup(null);
        this.props.onShowSearchBusinessUnitModal(false);
    }

    onSelectMainBusinessUnitsChange = (event) => {
        this.props.setSelectMainBusinessUnit(event.value);
    }

    onChangeBusinessUnitLabel = (event) => {
        const target = event.target;
        const value = target.value;
        this.props.setSelectedBusinessUnitGroupLabel(value);
    }

    onCompleteBusinessUnitGroup = (event) => { }

    onInputFilterBusinessUnitGroup = (event) => {
        const value = event.value;
        let filters = [];
        if (typeof value === "string") {
            filters.push({
                campo: "label",
                valor: value,
                tipoFiltro: "CONTEM"
            });
            this.props.setBusinessUnitGroup({ label: value });
        } else {
            this.props.setBusinessUnitGroup(value);
        }
        this.props.applyBusinessUnitGroupFilter(filters);
    }

    onDropdownBusinessUnitChange = (event) => {
        const businessUnit = event.value;
        console.log("businessUnit=", businessUnit)
        this.props.setBusinessUnitFilter(businessUnit)
    }

    businessUnitGroupOnPage = e => {
        this.props.businessUnitGroupPaginate({
            max: e.rows,
            index: e.first
        });
    };
    businessUnitGroupOnOnSort = e => {
        this.props.businessUnitGroupOnSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField,
        });
    };

    onCancelCargoShipment = (e) => {
        const ids = getIDs(this.props.selectedShipments);
        this.props.cancelCargoShipment(ids);
    }
    onBackCargoShipment = (e) => {
        const ids = getIDs(this.props.selectedShipments);
        this.props.backCargoShipment(ids);
    }
    onSetStatusInTransitShipments = (e) => {
        const ids = getIDs(this.props.selectedShipments);
        this.props.setStatusInTransitShipments(ids);
    }

    onLabelChangeHandler = (e) => {
        const value = e.value;
        if (typeof value === "string") {
            this.props.setLabel(this.props.filterFields.label || []);
        } else {
            this.props.setLabel(value);
        }
    }

    onCompleteMethodLabel = (e) => {
        const value = e.query;
        if (value.trim().length) {
            setTimeout(() => {
                let filters = [];
                if (typeof value === "string") {
                    filters.push({
                        campo: "label",
                        valor: value,
                        tipoFiltro: "CONTEM"
                    });
                }

                this.props.loadLabels(filters)
            }, 200)
        }
    }

    createCargoShipmentWithoutQuote = (event) => {
        const ids = getIDs(this.props.selectedOrders);
        this.props.createCargoShipmentWithoutQuote(ids)
    }

    render() {
        const lookupCarrier = {
            ...this.props.lookupCarrier,
            lookupCarrierOnComplete: this.lookupCarrierOnComplete,
            lookupCarrierOnInputFilter: this.lookupCarrierOnInputFilter,
            lookupCarrierOnEdit: this.lookupCarrierOnEdit,
            lookupCarrierOnClick: this.lookupCarrierOnClick,
            lookupCarrierOnConfirm: this.lookupCarrierOnConfirm,
            lookupCarrierOnCancel: this.lookupCarrierOnCancel,
            lookupCarrierOnPage: this.lookupCarrierOnPage,
            lookupCarrierOnFilter: this.lookupCarrierOnFilter,
            lookupCarrierOnSort: this.lookupCarrierOnSort,
            lookupCarrierOnHide: this.lookupCarrierOnHide,
        }

        const lookupOrigin = {
            ...this.props.lookupOrigin,
            lookupOriginOnComplete: this.lookupOriginOnComplete,
            lookupOriginOnInputFilter: this.lookupOriginOnInputFilter,
            lookupOriginOnEdit: this.lookupOriginOnEdit,
            lookupOriginOnClick: this.lookupOriginOnClick,
            lookupOriginOnConfirm: this.lookupOriginOnConfirm,
            lookupOriginOnCancel: this.lookupOriginOnCancel,
            lookupOriginOnPage: this.lookupOriginOnPage,
            lookupOriginOnFilter: this.lookupOriginOnFilter,
            lookupOriginOnSort: this.lookupOriginOnSort,
            lookupOriginOnHide: this.lookupOriginOnHide,
        }

        const lookupDestination = {
            ...this.props.lookupDestination,
            lookupDestinationOnComplete: this.lookupDestinationOnComplete,
            lookupDestinationOnInputFilter: this.lookupDestinationOnInputFilter,
            lookupDestinationOnEdit: this.lookupDestinationOnEdit,
            lookupDestinationOnClick: this.lookupDestinationOnClick,
            lookupDestinationOnConfirm: this.lookupDestinationOnConfirm,
            lookupDestinationOnCancel: this.lookupDestinationOnCancel,
            lookupDestinationOnPage: this.lookupDestinationOnPage,
            lookupDestinationOnFilter: this.lookupDestinationOnFilter,
            lookupDestinationOnSort: this.lookupDestinationOnSort,
            lookupDestinationOnHide: this.lookupDestinationOnHide,
        }
        const actions = {
            numberChangeHandler: this.onSetNumberFilter,
            initialDateChangeHandler: this.onInitialDateChangeHandler,
            finalDateChangeHandler: this.onFinalDateChangeHandler,
            applyFilter: this.onApplyFilter,
            clickSearchHandler: this.onClickSearchGrouper,
            clickAddHandler: this.onClickAddGrouper,
            onComplete: this.onCompleteBusinessUnitGroup,
            onInputFilter: this.onInputFilterBusinessUnitGroup,
            onDropdownBusinessUnitChange: this.onDropdownBusinessUnitChange,
            labelChangeHandler: this.onLabelChangeHandler,
            completeMethodLabel: this.onCompleteMethodLabel,
        }

        const header = (
            <div>
                <h1>Gerenciamento de Pedidos</h1>

                <hr className="lxSplitter"></hr>
                <div className="p-grid">
                    <div className="p-col-10">
                    </div>
                </div>
            </div>
        );

        const view = this.props.currentView
        let content = (<div></div>)
        let toolbarItems = null;
        if (view === 'ordersWithoutStock') {
            let items = this.props.orders.map(order => {
                return {
                    order,
                    statusColor: '#cccccc',
                    showSelect: false,
                    selected: false
                }
            })
            content = (<div>
                <OrderCardList
                    items={items}
                    hasMoreOrders={this.props.hasMoreOrders}
                    onSelectOrder={this.onSelectOrder}
                    onClickOrder={this.onClickOrder}
                    loadPlussOrdersItems={this.onLoadPlussOrdersItems}
                />
            </div>)
        }
        if (view === 'ordersWithStock') {
            let items = this.props.orders.map(order => {
                return {
                    order,
                    statusColor: '#6EFF86',
                    showSelect: true,
                }
            })

            let buttonCancelOrder = null;
            buttonCancelOrder = (
                <button
                    className="button-action"
                    onClick={this.onCancelOrders}
                    disabled={this.props.selectedOrders.length === 0}
                    title="Cancelar Pedido(s)"
                    style={{ marginRight: ".25em" }}
                >
                    <i className="pi pi-trash"></i> Cancelar Pedido(s)
                </button>
            );

            toolbarItems = (
                <div className="p-toolbar-group-left">
                    <label className="selector-label"><input className="selector" type="checkbox" onClick={this.onSelectAllOrders} checked={this.props.selectedOrders.length === this.props.orders.length} />Selecionar Todos</label>
                    <button
                        className="button-action"
                        onClick={this.onScriptingOrderHandler}
                        disabled={this.props.selectedOrders.length === 0}
                        title="Roteirizar"
                        style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-globe"></i> Roteirizar
                    </button>

                    {buttonCancelOrder}
                    {this.props.cargoManager.permiteEmbarqueSemCotacao &&
                        <button
                            className="button-action"
                            onClick={()=>{
                                this.createCargoShipmentWithoutQuote();
                            }}
                            disabled={this.props.selectedOrders.length === 0}
                            title=" Gerar embarque"
                            style={{ marginRight: ".25em" }}
                        >
                            <i className="pi pi-check"></i> Gerar embarque
                        </button>
                    }

                </div>
            )

            content = (<div>

                <OrderCardList
                    items={items}
                    hasMoreOrders={this.props.hasMoreOrders}
                    selections={this.props.selectedOrders}
                    onSelectOrder={this.onSelectOrder}
                    onClickOrder={this.onClickOrder}
                    loadPlussOrdersItems={this.onLoadPlussOrdersItems}
                />
            </div>)
        }
        if (view === 'ordersInScripting') {
            let items = this.props.orders.map(order => {
                return {
                    order,
                    statusColor: '#6EFF86',
                    showSelect: true,
                }
            })

            toolbarItems = (
                <div className="p-toolbar-group-left">
                    <label className="selector-label"><input className="selector" type="checkbox" onClick={this.onSelectAllOrders} checked={this.props.selectedOrders.length === this.props.orders.length} />Selecionar Todos</label>
                    <button
                        className="button-action"
                        onClick={this.onCancelScriptinsOrders}
                        disabled={this.props.selectedOrders.length === 0}
                        title="Cancelar Roteirização"
                        style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-times-circle"></i> Cancelar Roteirização
                    </button>
                </div>
            )


            content = (<div>

                <OrderCardList
                    items={items}
                    hasMoreOrders={this.props.hasMoreOrders}
                    selections={this.props.selectedOrders}
                    onSelectOrder={this.onSelectOrder}
                    onClickOrder={this.onClickOrder}
                    loadPlussOrdersItems={this.onLoadPlussOrdersItems}
                />
            </div>)
        }
        if (view === 'shipments') {
            let items = this.props.shipments.map(shipment => {
                let color = 'yellow';
                if (shipment.situation == 'INVALID') {
                    color = '#EE0000'
                }
                return {
                    shipment,
                    statusColor: color,
                    showSelect: shipment.situation != "INVALID",
                    selected: false
                }
            })

            let buttonPendenceEmail = null;
            buttonPendenceEmail = (
                <button
                    className="button-action"
                    title="Pendência de Email"
                    style={{ marginRight: ".25em" }}
                    onClick={this.onClickResolvePendenceEmail}
                    disabled={!this.props.pendenceEmail}
                >
                    <i className="pi pi-envelope"></i> Reenviar Email
                </button>
            );

            let buttonPendenceQuote = null;
            buttonPendenceQuote = (
                <button
                    className="button-action"
                    title="Pendência de Cotação"
                    style={{ marginRight: ".25em" }}
                    onClick={this.onClickResolvePendenceQuote}
                    disabled={!this.props.pendenceQuote}
                >
                    <i className="pi pi-dollar"></i> Regerar Cotação
                </button>
            );




            toolbarItems = (
                <React.Fragment>

                    <div className="p-toolbar-group-left">
                        <label className="selector-label"><input className="selector" type="checkbox" onClick={this.onSelectAllShipments} checked={this.props.selectedShipments.length === this.props.shipments.length} />Selecionar Todos</label>
                        <button
                            className="button-action"
                            onClick={this.onConfirmCollectionOrder}
                            disabled={this.props.isDisableBtnConfirm}
                            title="Roteirizar"
                            style={{ marginRight: ".25em" }}
                        >
                            <i className="pi pi-check"></i> Confirmar
                        </button>

                        {buttonPendenceEmail}

                        {buttonPendenceQuote}


                    </div>
                    <div className="p-toolbar-group-right">
                        <button
                            className="button-action"
                            onClick={() => { }}
                            title="Roteirizar"
                            style={{ marginRight: ".25em" }}
                        >
                            <i className="pi pi-sort-alt"></i>Ordenar
                        </button>
                    </div>
                </React.Fragment>

            )
            content = (<div>

                <ShipmentCardList
                    items={items}
                    selections={this.props.selectedShipments}
                    hasMoreShipments={this.props.hasMoreShipments}
                    onSelectShipment={this.onSelectShipment}
                    onClickShipment={this.onClickShipment}
                    loadPlussCargoShipmentItems={this.onLoadPlussCargoShipmentItems}
                    onSelectUrgent={this.props.onSelectUrgent}
                    onSelectAG={this.props.onSelectAG}
                    onSelectZorc={this.props.onSelectZorc}
                />
            </div>)

        }
        if (view === 'shipmentsToPickupProcess') {
            let items = this.props.shipments.map(shipment => {
                let color = 'yellow'
                if (shipment.situation == 'INVALID') {
                    color = '#EE0000'
                }
                return {
                    shipment,
                    statusColor: color,
                    showSelect: true,
                    selected: false
                }
            })
            toolbarItems = (
                <React.Fragment>
                    <div className="p-toolbar-group-left">
                        <label className="selector-label"><input className="selector" type="checkbox" onClick={this.onSelectAllShipments} checked={this.props.selectedShipments.length === this.props.shipments.length} />Selecionar Todos</label>
                        <ButtonDialog
                            onClick={this.onCancelCargoShipment}
                            button={{
                                className: "lognex-btn-danger",
                                title: "Cancelar embarque(s)",
                                text: "Cancelar embarque(s)",
                                iconClassName: "pi pi-times-circle",
                                disabled: this.props.selectedShipments.length === 0,
                            }}
                            dialog={{
                                header: 'Confirmação',
                                modal: false,
                                message: "Ao remover o embarque, todos os pedidos serão removidos do roteirizador e será enviado e-mail de cancelamento ao transportador, Deseja realmente remover o(s) Embarque(s)?",
                            }}
                        >
                        </ButtonDialog>

                        <button
                            className="button-action"
                            onClick={this.onBackCargoShipment}
                            disabled={this.props.selectedShipments.length === 0}
                            title=" Voltar para embarque(s)"
                            style={{ marginRight: ".25em" }}
                        >
                            <i className="pi pi-undo"></i> Voltar para embarque(s)
                        </button>



                    </div>
                    <div className="p-toolbar-group-right">
                        <button
                            className="button-action"
                            onClick={() => { }}
                            title="Roteirizar"
                            style={{ marginRight: ".25em" }}
                        >
                            <i className="pi pi-sort-alt"></i>Ordenar
                        </button>
                    </div>
                </React.Fragment>
            )
            content = (<div>

                <ShipmentCardList
                    items={items}
                    selections={this.props.selectedShipments}
                    hasMoreShipments={this.props.hasMoreShipments}
                    onSelectShipment={this.onSelectShipment}
                    onClickShipment={this.onClickShipment}
                    loadPlussCargoShipmentItems={this.onLoadPlussCargoShipmentItems}
                    onSelectUrgent={this.props.onSelectUrgent}
                    onSelectAG={this.props.onSelectAG}
                    onSelectZorc={this.props.onSelectZorc}
                />
            </div>)
        }
        if (view === 'shipmentsInPickupProcess') {
            let items = this.props.shipments.map(shipment => {
                let color = 'yellow'
                if (shipment.situation == 'INVALID') {
                    color = '#EE0000'
                }
                return {
                    shipment,
                    statusColor: color,
                    showSelect: shipment.situation != "INVALID",
                    selected: false
                }
            })

            let toolbarShipmentsInPickupProcess = (
                <div className="p-toolbar-group-left">
                    <label className="selector-label"><input className="selector" type="checkbox" onClick={this.onSelectAllShipments} checked={this.props.selectedShipments.length === this.props.shipments.length} />Selecionar Todos</label>


                    <button
                        className="button-action"
                        title="Pendência de Reenvio ao ERP"
                        style={{ marginRight: ".25em" }}
                        onClick={this.onClickResolvePendenceConfirmShipment}
                        disabled={!this.props.pendenceConfirmeShipmentWebhook}
                    >
                        <i className="pi pi-reply"></i> Reenviar ao ERP
                    </button>

                    <ButtonDialog
                        onClick={this.onBackCargoShipment}
                        button={{
                            className: "button-action",
                            title: "Voltar para embarque(s)",
                            text: "Voltar para embarque(s)",
                            iconClassName: "pi pi-undo",
                            disabled: this.props.selectedShipments.length === 0,
                        }}
                        dialog={{
                            header: 'Confirmação',
                            modal: true,
                            message: 'Atenção, ao retornar o embarque ao status de "Embarque" o mesmo deverá ser excluído obrigatoriamente do ERP e do TMS. Deseja confirmar o retorno para o status de "Embarque"?',
                        }}
                    >
                    </ButtonDialog>

                    <ButtonDialog
                        onClick={this.onSetStatusInTransitShipments}
                        button={{
                            className: "button-action",
                            title: "Alterar embarque(s) para 'Em Trânsito'",
                            text: "Em Trânsito",
                            iconClassName: "pi pi-arrow-right",
                            disabled: this.props.selectedShipments.length === 0,
                        }}
                        dialog={{
                            header: 'Confirmação',
                            modal: true,
                            message: 'Atenção, ao alterar o embarque para o status de "Em Trânsito" será enviado um email para o transportador, mesmo que não possua todas as NFes. Deseja continuar ?',
                        }}
                    >
                    </ButtonDialog>


                    {/* <button
                        className="button-action"
                        onClick={this.onBackCargoShipment}
                        disabled={this.props.selectedShipments.length === 0}
                        title=" Voltar para embarque(s)"
                        style={{ marginRight: ".25em" }}
                        >
                        <i className="pi pi-undo"></i> Voltar para embarque(s)
                    </button> */}
                </div>

            );





            toolbarItems = (
                <React.Fragment>

                    {toolbarShipmentsInPickupProcess}

                    <div className="p-toolbar-group-right">
                        <button
                            className="button-action"
                            onClick={() => { }}
                            title="Roteirizar"
                            style={{ marginRight: ".25em" }}
                        >
                            <i className="pi pi-sort-alt"></i>Ordenar
                        </button>
                    </div>
                </React.Fragment>
            )
            content = (<div>

                <ShipmentCardList
                    items={items}
                    selections={this.props.selectedShipments}
                    hasMoreShipments={this.props.hasMoreShipments}
                    onSelectShipment={this.onSelectShipment}
                    onClickShipment={this.onClickShipment}
                    loadPlussCargoShipmentItems={this.onLoadPlussCargoShipmentItems}
                    onSelectUrgent={this.props.onSelectUrgent}
                    onSelectAG={this.props.onSelectAG}
                    onSelectZorc={this.props.onSelectZorc}
                />
            </div>)
        }
        if (view === 'shipmentsInDeliveryProcess') {
            let items = this.props.shipments.map(shipment => {
                let color = '#7fffd4';
                if (shipment.situation == 'INVALID') {
                    color = '#EE0000'
                }
                return {
                    shipment,
                    statusColor: color,
                    showSelect: false,
                    selected: false
                }
            })
            toolbarItems = (
                <div className="p-toolbar-group-right">
                    <button
                        className="button-action"
                        onClick={() => { }}
                        title="Roteirizar"
                        style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-sort-alt"></i>Ordenar
                    </button>
                </div>
            )
            content = (<div>

                <ShipmentCardList
                    items={items}
                    hasMoreShipments={this.props.hasMoreShipments}
                    onSelectShipment={this.onSelectShipment}
                    onClickShipment={this.onClickShipment}
                    loadPlussCargoShipmentItems={this.onLoadPlussCargoShipmentItems}
                    onSelectUrgent={this.props.onSelectUrgent}
                    onSelectAG={this.props.onSelectAG}
                    onSelectZorc={this.props.onSelectZorc}
                />
            </div>)
        }
        if (view === 'shipmentsWithDeliveryProcessEnd') {
            let items = this.props.shipments.map(shipment => {
                let color = '#6EFF86'
                if (shipment.situation == 'INVALID') {
                    color = '#EE0000'
                }
                return {
                    shipment,
                    statusColor: color,
                    showSelect: false,
                    selected: false
                }
            })
            toolbarItems = (
                <div className="p-toolbar-group-right">
                    <button
                        className="button-action"
                        onClick={() => { }}
                        title="Roteirizar"
                        style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-sort-alt"></i>Ordenar
                    </button>
                </div>
            )
            content = (<div>

                <ShipmentCardList
                    items={items}
                    hasMoreShipments={this.props.hasMoreShipments}
                    onSelectShipment={this.onSelectShipment}
                    onClickShipment={this.onClickShipment}
                    loadPlussCargoShipmentItems={this.onLoadPlussCargoShipmentItems}
                    onSelectUrgent={this.props.onSelectUrgent}
                    onSelectAG={this.props.onSelectAG}
                    onSelectZorc={this.props.onSelectZorc}
                />
            </div>)
        }

        const outOfStock = this.props.countOrders && this.props.countOrders.outOfStock ? this.props.countOrders.outOfStock : 0
        const withStock = this.props.countOrders && this.props.countOrders.withStock ? this.props.countOrders.withStock : 0
        const inScripting = this.props.countOrders && this.props.countOrders.inScripting ? this.props.countOrders.inScripting : 0

        const shipmentOrderNew = this.props.countCargoShipmentOrders && this.props.countCargoShipmentOrders.new ? this.props.countCargoShipmentOrders.new : 0
        const shipmentOrderConfirmed = this.props.countCargoShipmentOrders && this.props.countCargoShipmentOrders.confirmed ? this.props.countCargoShipmentOrders.confirmed : 0
        const shipmentOrderApproved = this.props.countCargoShipmentOrders && this.props.countCargoShipmentOrders.approved ? this.props.countCargoShipmentOrders.approved : 0
        const shipmentOrderTraveling = this.props.countCargoShipmentOrders && this.props.countCargoShipmentOrders.traveling ? this.props.countCargoShipmentOrders.traveling : 0
        const shipmentOrderDelivered = this.props.countCargoShipmentOrders && this.props.countCargoShipmentOrders.delivered ? this.props.countCargoShipmentOrders.delivered : 0
        const dashboard = (
            <div className="dashboard-container">
                <div className="dashboard-panel" onClick={this.onSelectPanel} id="ordersWithoutStock">
                    <p className="dashboard-panel__title">Pedidos sem estoque</p>
                    <p className="dashboard-panel__value">{outOfStock}</p>
                </div>

                <div className="dashboard-panel" onClick={this.onSelectPanel} id="ordersWithStock">
                    <p className="dashboard-panel__title">Pedidos com estoque</p>
                    <p className="dashboard-panel__value">{withStock}</p>
                </div>
                <div className="dashboard-panel" onClick={this.onSelectPanel} id="ordersInScripting">
                    <p className="dashboard-panel__title">Em roteirização</p>
                    <p className="dashboard-panel__value">{inScripting} </p>
                </div>

                <div className="dashboard-panel" onClick={this.onSelectPanel} id="shipments">
                    <p className="dashboard-panel__title">Embarques</p>
                    <p className="dashboard-panel__value">{shipmentOrderNew}</p>
                </div>

                <div className="dashboard-panel" onClick={this.onSelectPanel} id="shipmentsToPickupProcess">
                    <p className="dashboard-panel__title">Ordem de coleta</p>
                    <p className="dashboard-panel__value">{shipmentOrderConfirmed}</p>
                </div>

                <div className="dashboard-panel" onClick={this.onSelectPanel} id="shipmentsInPickupProcess">
                    <p className="dashboard-panel__title">Para coletar</p>
                    <p className="dashboard-panel__value">{shipmentOrderApproved}</p>
                </div>

                <div className="dashboard-panel" onClick={this.onSelectPanel} id="shipmentsInDeliveryProcess">
                    <p className="dashboard-panel__title">Em trânsito</p>
                    <p className="dashboard-panel__value">{shipmentOrderTraveling}</p>
                </div>
                <div className="dashboard-panel" onClick={this.onSelectPanel} id="shipmentsWithDeliveryProcessEnd">
                    <p className="dashboard-panel__title">Entregues</p>
                    <p className="dashboard-panel__value">{shipmentOrderDelivered}</p>
                </div>

            </div>
        )


        {/* ADD */ }
        let columnsBusinessUnitsGrouper = this.props.businessUnitColumns
            .map(col => {
                col.filter = false;
                col.sortable = false;
                return col;
            })
            .filter(col => col.field === "oid" || col.field === "tradeName" || col.field === "label")
            .map(col => {
                if (col.field === "oid") {
                    return (<Column {...col} key={col.field} field={col.field} header="Código" />)
                }
                if (col.field === "tradeName") {
                    return (<Column {...col} key={col.field} field={col.field} header="Unidade" />)
                }
                if (col.field === "label") {
                    return (<Column {...col} key={col.field} field={col.field} header="label" />)
                }
                return (<Column {...col} key={col.field} field={col.field} header={col.field} />)
            })
        const selectorUnitsColumns = <Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />
        const selectorMainUnitColumns = <Column key="selectorColumn" selectionMode="single" style={{ width: '3em' }} />

        columnsBusinessUnitsGrouper = this.state.currentStepIndex === 0
            ? [selectorUnitsColumns, ...columnsBusinessUnitsGrouper]
            : [selectorMainUnitColumns, ...columnsBusinessUnitsGrouper];


        const currentStepIndex = this.state.currentStepIndex;
        const contentStep1 = (
            <div className="p-col-12">
                <label>
                    Selecione as Unidades
                </label>
                <DataTable
                    className="lxUnitsTable"
                    selectionMode="multiple"
                    onFilter={() => { }}
                    onSort={() => { }}
                    onPage={() => { }}
                    value={this.props.businessUnitRecords}
                    lazy={true}
                    // totalRecords={props.totalRecords}
                    // sortOrder={props.order}
                    // sortField={props.sortField}
                    // paginator={true}
                    // first={props.first}
                    // rows={props.rows}
                    selection={this.props.selectedBusinessUnits}
                    responsive={true}
                    emptyMessage="Não foram encontrados registros"
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    onSelectionChange={this.onSelectBusinessUnitsChange}
                >
                    {columnsBusinessUnitsGrouper}
                </DataTable>
            </div>
        )

        const contentStep2 = (
            <React.Fragment>
                <div className="p-col-12 form-field-container">
                    <label>label</label>
                    <InputText value={this.props.selectedBusinessUnitGroupLabel} onChange={this.onChangeBusinessUnitLabel} name="businessUnitLabel" />
                </div>
                <div className="p-col-12">
                    <label>
                        Selecione a unidade principal
                    </label>
                    <DataTable
                        className="lxUnitsTable"
                        selectionMode="multiple"
                        onFilter={() => { }}
                        onSort={() => { }}
                        onPage={() => { }}
                        value={this.props.selectedBusinessUnits}
                        lazy={true}
                        // totalRecords={props.totalRecords}
                        // sortOrder={props.order}
                        // sortField={props.sortField}
                        // paginator={true}
                        // first={props.first}
                        // rows={props.rows}
                        selection={this.props.selectedMainBusinessUnit}
                        responsive={true}
                        emptyMessage="Não foram encontrados registros"
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        onSelectionChange={this.onSelectMainBusinessUnitsChange}
                    >
                        {columnsBusinessUnitsGrouper}
                    </DataTable>
                </div>
            </React.Fragment>
        )

        const grouper = (
            <Wizard
                header="Agrupador"
                selectionMode="multiple"
                visible={this.props.filterFields.modalGrouperVisible}
                modal={true}
                currentIndex={this.state.currentStepIndex}
                onHide={this.onGroupHide}
            >

                <Step
                    content={contentStep1}
                    onClickNext={this.onNextGrouper}
                ></Step>
                <Step
                    content={contentStep2}
                    onClickPrevious={this.onPreviousGrouper}
                    onClickConfirm={this.onConfirmGroup}
                    disableConfirm={(!this.props.selectedMainBusinessUnit) || (this.props.selectedBusinessUnitGroupLabel && this.props.selectedBusinessUnitGroupLabel.trim() === "")}
                    onClickCancel={this.onCancelGrouper}
                ></Step>

            </Wizard>
        )


        {/* SEARCH */ }
        let columnsBusinessUnits = this.props.businessUnitGroupColumns
            .filter(col => col.field != 'oid')
            .map(col => {
                if (col.field === "label") {
                    return (<Column {...col} key={col.field} field={col.field} header="Grupo" />)
                }
                if (col.field === "mainBusinessUnit") {
                    return (<Column {...col} key={col.field} field={col.field} header="Principal" body={(rowData) => (<span>{rowData.mainBusinessUnit.label}</span>)} />)
                }
                if (col.field === "businessUnits") {
                    return (<Column {...col} key={col.field} field={col.field} header="Unidades" body={(rowData) => (<span> {rowData.businessUnits.map(u => u.label).join(', ')}</span>)} />)
                }
                return (<Column {...col} key={col.field} field={col.field} header={col.field} />)
            })
        const selectorBusinessUnitColumns = <Column key="selectorColumn" selectionMode="single" style={{ width: '3em' }} />
        columnsBusinessUnits = [selectorBusinessUnitColumns, ...columnsBusinessUnits]


        const contentBusinessUnits = (
            <div className="p-col-12">
                <DataTable
                    className="lxUnitsTable"
                    selectionMode="single"
                    onPage={this.businessUnitGroupOnPage}
                    value={this.props.businessUnitGroupRecords}
                    lazy={true}
                    // sortOrder={this.businessUnitGroupOnOnSort}
                    sortField={this.props.businessUnitGroupSortField}
                    paginator={true}
                    first={this.props.businessUnitGroupIndex}
                    rows={this.props.businessUnitGroupMax}
                    totalRecords={this.props.businessUnitGroupCount}
                    selection={this.props.selectedBusinessUnitGroup}
                    responsive={true}
                    emptyMessage="Não foram encontrados registros"
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    onSelectionChange={this.onSelectionBusinessUnitGroupChange}
                    scrollable={true}
                    scrollHeight={"540px"}
                >
                    {columnsBusinessUnits}
                </DataTable>
            </div>
        )

        const footerBusinessUnit = (
            <div className="footer_businessUnit" style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                    <button
                        className="lognex-btn-highlighted"
                        title="Confirmar"
                        onClick={this.onConfirmSelectedBusinessUnitGroup}
                    >
                        <i className="pi pi-check"></i>
                    </button>
                </div>
                <div >
                    <button className="lognex-btn-danger"
                        title="Cancelar"
                        onClick={this.onBusinessUnitsHide}
                    >
                        <i className="pi pi-times"></i>
                    </button>
                </div>
            </div>
        )

        const searchBusinessUnits = (
            <Dialog
                className="lxStepDialog"
                selectionMode="single"
                header="Unidades"
                visible={this.props.filterFields.modalSearchBusinessUnitVisible}
                modal={true}
                footer={footerBusinessUnit}
                onHide={this.onBusinessUnitsHide}
            >
                {contentBusinessUnits}

            </Dialog>
        )
        const contentWrapper = (
            <div className="order-page">
                <Filter
                    filterFields={this.props.filterFields}
                    actions={actions}
                    lookupCarrier={lookupCarrier}
                    lookupOrigin={lookupOrigin}
                    lookupDestination={lookupDestination}
                    records={this.props.businessUnitGroupRecords}
                />
                {searchBusinessUnits}

                {grouper}

                {dashboard}
                <div>
                    <Toolbar >
                        {toolbarItems}
                    </Toolbar >
                </div>
                <div className="order-card-list-container">
                    {content}
                </div>
            </div >
        )
        return <Page header={header} content={contentWrapper} />;

    }
}

function OrderCardList(props) {

    const selections = props.selections || [];
    const orders = props.items.map((item, index) => {
        const selected = selections.find(element => element.oid === item.order.oid) != null;
        return (
            <OrderCard
                order={item.order}
                statusColor={item.statusColor}
                key={index}
                selected={selected}
                showSelect={item.showSelect}
                onSelectOrder={props.onSelectOrder}
                onClickOrder={props.onClickOrder}
            />
        )
    })


    return (
        <div className="order-card-list p-grid">

            {orders}

            <div className="btn-footer">
                <button
                    className="button-action"
                    onClick={props.loadPlussOrdersItems}
                    title="Carregar"
                    style={{ marginRight: ".25em" }}
                    disabled={!props.hasMoreOrders}
                >
                    <i className="pi pi-plus"></i>Carregar mais
                </button>
            </div>
        </div>

    )
}

function OrderCard(props) {
    const { order, statusColor } = props;
    const onSelectOrder = (event) => {
        event.stopPropagation();
        console.log(event);
        props.onSelectOrder({
            order: props.order,
            selected: event.currentTarget.checked
        })
    }
    const onClickOrder = (event) => {
        props.onClickOrder({
            order: props.order,
        })
    }
    let selectComponent = null;
    if (props.showSelect) {
        selectComponent = (<input type="checkbox" onClick={onSelectOrder} checked={props.selected} />)
    }
    const title = `Pedido nr ${props.order.oid}`
    return (
        <div className="order-card p-col-3" onClick={onClickOrder} title={title}>
            <div className="order-card__status" style={{ backgroundColor: statusColor }}></div>
            <div className="order-card__content">
                {selectComponent}
                <div>
                    <p className="order-number">{order && order.code ? order.code : " "}</p>
                    <p className="order-card-label">Número</p>
                </div>
                <div>
                    <p className="order-client">{order && order.receiver ? order.receiver.name.toUpperCase() : " "}</p>
                    <p className="order-card-label">Cliente</p>
                </div>
                <div>
                    <p className="order-date">{order && order.createdAt ? order.createdAt.format('DD/MM/YYYY') : "Não informada"}</p>
                    <p className="order-card-label">Data</p>
                </div>
                <div>
                    <p className="order-date">{order && order.deliveryForecast ? order.deliveryForecast.format('DD/MM/YYYY') : "Não informada"}</p>
                    <p className="order-card-label">Previsão de entrega</p>
                </div>
            </div>
        </div>
    )
}

function ShipmentCardList(props) {
    const selections = props.selections || [];
    const shipments = props.items.map((item, index) => {
        const selected = selections.find(element => element.oid === item.shipment.oid) != null;
        return (
            <ShipmentCard
                shipment={item.shipment}
                statusColor={item.statusColor}
                key={index}
                selected={selected}
                showSelect={item.showSelect}
                onSelectShipment={props.onSelectShipment}
                onClickShipment={props.onClickShipment}
                onSelectUrgent={props.onSelectUrgent}
                onSelectAG={props.onSelectAG}
                onSelectZorc={props.onSelectZorc}
            />
        )
    })
    return (
        <div className="order-card-list p-grid">
            {shipments}

            <div className="btn-footer">
                <button
                    className="button-action"
                    onClick={props.loadPlussCargoShipmentItems}
                    title="Carregar"
                    style={{ marginRight: ".25em" }}
                    disabled={!props.hasMoreShipments}
                >
                    <i className="pi pi-plus"></i>Carregar mais
                </button>
            </div>
        </div>
    )
}

function ShipmentCard(props) {
    const { shipment, statusColor } = props;

    let iconPendenceEmail = false
    let iconPendenceQuote = false
    let iconPendenceConfirmShipment = false

    if (shipment.pendencies && shipment.pendencies.length > 0) {
        shipment.pendencies.forEach((pendency) => {
            if (pendency.type === 'EMAIL' && pendency.status === 'OPEN') {
                iconPendenceEmail = true;
            }
            if (pendency.type === 'QUOTE' && pendency.status === 'OPEN') {
                iconPendenceQuote = true;
            }
            if (pendency.type === 'CONFIRM_SHIPMENT_WEBHOOK' && pendency.status === 'OPEN') {
                iconPendenceConfirmShipment = true;
            }
        })
    }


    const onSelectShipment = (event) => {
        event.stopPropagation();
        props.onSelectShipment({
            shipment: props.shipment,
            selected: event.currentTarget.checked
        })
    }
    const onClickShipment = (event) => {
        props.onClickShipment({
            shipment: props.shipment,
        })
    }
    let selectComponent = null;
    if (props.showSelect) {
        selectComponent = (<input type="checkbox" onClick={onSelectShipment} checked={props.selected} />)
    }

    let totalWeight = 0;
    //percorrer as orders e somar o peso
    props.shipment.orders.forEach(order => {
        totalWeight = totalWeight + parseFloat(order.cargo.summary.weight)
    });
    let totalWeightVehicle = (totalWeight * 100) / props.shipment.vehicle.vehicleCapacity;
    totalWeightVehicle = isFinite(totalWeightVehicle) ? totalWeightVehicle : 0;
    const shipmentNumber = shipment.code && shipment.code.join(", ")
    const enableChangeUrgent = props.selected && shipment.status == "NEW"
    const title = shipment.situation && shipment.situation === 'INVALID' ? `Embarque nr ${props.shipment.oid} inválido por motivo de pedido(s) adionado(s) após roteirização` : `Embarque nr ${props.shipment.oid}`
    return (
        <div className="shipment-card p-col-3" onClick={onClickShipment} title={title}>
            <div className="shipment-card__status" style={{ backgroundColor: statusColor }}></div>
            <div className="shipment-card__content">
                <div className="icons-pendencies">
                    {iconPendenceEmail ? <i className="pi pi-envelope icons-danger" title="Possui pendência de email"></i> : ""}
                    {iconPendenceQuote ? <i className="pi pi-dollar icons-danger" title="Possui pendência de cotação"></i> : ""}
                    {iconPendenceConfirmShipment ? <i className="pi pi-reply icons-danger" title="Possui pendência de envio ao ERP"></i> : ""}
                </div>
                {selectComponent}
                <div>
                    <p className="shipment-number" title={shipmentNumber}>{shipmentNumber}</p>
                    <p className="shipment-card-label">Número</p>
                </div>
                <div>
                    <p className="shipment-route">{shipment.origin.name.toUpperCase()} / {shipment.destination.name.toUpperCase()}</p>
                    <p className="shipment-card-label">Rota</p>
                </div>
                <div>
                    <p className="shipment-route">{shipment && shipment.carrier.name ? shipment.carrier.name.toLowerCase() : "Não informada"}</p>
                    <p className="shipment-card-label">Transportadora</p>
                </div>

                <div className="card-panel-group">
                    <div className="card-panel-group__item">
                        <p className="shipment-card-value">{shipment.quote && numberToCurrency(shipment.quote.totalValue, 'REAL')}</p>
                        <p className="shipment-card-label">Valor de frete</p>
                    </div>
                    <div className="card-panel-group__item">
                        <p className="shipment-card-value">{shipment && shipment.distance ? formatNumber(shipment.distance) : 0.0}</p>
                        <p className="shipment-card-label">Distância (Km)</p>
                    </div>
                    <div className="card-panel-group__item">
                        <p className="shipment-card-value">{shipment && shipment.costByPacking.packingName ? shipment.costByPacking.packingName : "Não informado"}</p>
                        <p className="shipment-card-label">Embalagem</p>
                    </div>
                    <div className="card-panel-group__item">
                        <p className="shipment-card-value">{shipment && shipment.costByPacking.value ? numberToCurrency(shipment.costByPacking.value, 'REAL') : 0.0}</p>
                        <p className="shipment-card-label">Custo/Embalagem</p>
                    </div>
                    <div className="card-panel-group__item">
                        <p className="shipment-card-value">{shipment && shipment.vehicle.vehicleType ? shipment.vehicle.vehicleType.name : "Não informado"}</p>
                        <p className="shipment-card-label">Tipo veículo</p>
                    </div>
                    <div className="card-panel-group__item">
                        <p className="shipment-card-value">{shipment && shipment.vehicle.plate ? shipment.vehicle.plate : "Não informada"}</p>
                        <p className="shipment-card-label">Placa</p>
                    </div>

                    <div className="card-panel-group__item">
                        <p className="shipment-date">{shipment && shipment.createdAt ? shipment.createdAt.format('DD/MM/YYYY') : "Não informado"}</p>
                        <p className="shipment-card-label">Data</p>
                    </div>
                    <div className="card-panel-group__item">
                        <p className="shipment-date">{shipment && shipment.deliveryForecast ? shipment.deliveryForecast.format('DD/MM/YYYY') : "Não informada"}</p>
                        <p className="shipment-card-label">Previsão de entrega</p>
                    </div>
                    <div className="card-panel-group__item">
                        <p className="shipment-date">{totalWeightVehicle ? formatNumber(totalWeightVehicle) + "%" : "Não informado"}</p>
                        <p className="shipment-card-label">Ocupação </p>
                    </div>

                    <div className="card-panel-group__urgent">
                        <div className="item">
                            <h3 style={{ marginLeft: "5px" }}>{"Urgente"}</h3>
                            <InputSwitch checked={shipment.urgent} onChange={(e) => {
                                if (enableChangeUrgent) {
                                    e.originalEvent.stopPropagation();
                                    e.originalEvent.preventDefault();
                                    props.onSelectUrgent({
                                        shipment: props.shipment,
                                        selected: e.value
                                    })
                                }
                            }} />
                        </div>
                        <div className="item">
                            <h3 style={{ marginLeft: "5px" }}>{"AG"}</h3>
                            <InputSwitch checked={shipment.ag} onChange={(e) => {
                                if (enableChangeUrgent) {
                                    e.originalEvent.stopPropagation();
                                    e.originalEvent.preventDefault();
                                    props.onSelectAG({
                                        shipment: props.shipment,
                                        selected: e.value
                                    })
                                }
                            }} />
                        </div>
                        <div className="item">
                            <h3 style={{ marginLeft: "5px" }}>{"ZORD"}</h3>
                            <InputSwitch checked={shipment.zorc} onChange={(e) => {
                                if (enableChangeUrgent) {
                                    e.originalEvent.stopPropagation();
                                    e.originalEvent.preventDefault();
                                    props.onSelectZorc({
                                        shipment: props.shipment,
                                        selected: e.value
                                    })
                                }
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderManagementListPageContainer);