import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';
import { InputSwitch } from 'primereact/inputswitch';
import AuthenticationService from '../../../services/authentication.service';
import { InputCnpjCpf } from '../../../components/input-cnpj-cpf/InputCnpjCpf';
import { InputCep } from '../../../components/input-cep/InputCep';
import { InputTextarea } from 'primereact/inputtextarea';


export const UnidadeForm = (props) => {
    const record = props.record;
    const changeHandler = props.changeHandler;
    const latitude = record.address && record.address.georeference && record.address.georeference.latitude ? record.address.georeference.latitude : ""
    const longitude = record.address && record.address.georeference && record.address.georeference.longitude ? record.address.georeference.longitude : ""

    const customFields = [];
    if (record && record.customFields) {
        const keys = Object.keys(record.customFields);
        keys.forEach((key, index) => {
            const nameField = "customFields." + key
            customFields.push(
                <div className="p-col-4 form-field-container" key={index}>
                    <label>{key}</label>
                    <InputText value={record.customFields[key]} onChange={changeHandler} name={nameField} />
                </div>
            )
        })
    }

    return (
        <div className="pessoa-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-3">
                            <div className="form-field-container">
                                <label>Id</label>
                                <InputText value={record.oid} onChange={changeHandler} disabled={true} />
                            </div>
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Empresa</label>
                            <LookupField
                                value={record.companyName}
                                placeholder="Empresa"
                                name="companyName"
                                field="tradeName"
                                onComplete={props.lookupEmpresaOnComplete}
                                onInputFilter={props.lookupEmpresaOnInputFilter}
                                editDisabled={props.lookupEmpresa.editDisabled}
                                onEditField={props.lookupEmpresaOnEdit}
                                onClick={props.lookupEmpresaOnClick}

                                visible={props.lookupEmpresa.visible}
                                modal={props.lookupEmpresa.modal}
                                header={props.lookupEmpresa.header}
                                onHide={props.lookupEmpresaOnHide}
                                onConfirm={props.lookupEmpresaOnConfirm}
                                onCancel={props.lookupEmpresaOnCancel}
                                records={props.lookupEmpresa.records}
                                columns={props.lookupEmpresa.columns}
                                totalRecords={props.lookupEmpresa.totalRecords}
                                sortOrder={props.lookupEmpresa.order}
                                sortField={props.lookupEmpresa.sortField}
                                first={props.lookupEmpresa.first}
                                rows={props.lookupEmpresa.rows}
                                onPage={props.lookupEmpresaOnPage}
                                onFilter={props.lookupEmpresaOnFilter}
                                onSort={props.lookupEmpresaOnOnSort}

                            />

                        </div>

                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Nome/Razão Social</label>
                            <InputText value={record.name} onChange={changeHandler} name="name" />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Nome Fantasia</label>
                            <InputText value={record.tradeName} onChange={changeHandler} name="tradeName" />
                        </div>

                        <div className="p-col-4 form-field-container">
                            <label className="lxRequiredField">CPF/CNPJ</label>
                            <InputCnpjCpf value={record.documentNumber} onChange={changeHandler} name="documentNumber" />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label className="lxRequiredField">Código</label>
                            <InputText value={record.code} onChange={changeHandler} name="code" />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Sigla</label>
                            <InputText value={record.label} onChange={changeHandler} name="label" />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Endereço</label>
                            <InputText value={record.address.street} onChange={changeHandler} name="address.street" />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Complemento</label>
                            <InputText value={record.address.complement} onChange={changeHandler} name="address.complement" />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Número</label>
                            <InputText value={record.address.number} onChange={changeHandler} name="address.number" />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Bairro</label>
                            <InputText value={record.address.district} onChange={changeHandler} name="address.district" />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>CEP</label>
                            <InputCep value={record.address.zipCode} onChange={changeHandler} name="address.zipCode" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Cidade</label>
                            <InputText value={record.address.city} onChange={changeHandler} name="address.city" />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Email</label>
                            <InputText value={record.email} onChange={changeHandler} name="email" />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Site</label>
                            <InputText value={record.site} onChange={changeHandler} name="site" />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Contato</label>
                            <InputTextarea value={record.contact} onChange={changeHandler} name="contact" />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <div className="p-grid" style={{ width: "100%" }}>
                                {customFields}
                            </div>
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Latitude</label>
                            <InputText value={latitude} onChange={changeHandler} name="address.georeference.latitude" />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Longitude</label>
                            <InputText value={longitude} onChange={changeHandler} name="address.georeference.longitude" />
                        </div>

                    </div>
                </div>
            </div>
        </div>


    )
};