
import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../freightTable.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { FreightTableForm } from './FreightTableForm';
import { actions as unidadeActions } from '../../unidade/unidade.actions';
import { actions as carrierActions } from '../../carrier/carrier.actions';
import { actions as routeActions } from '../../route/route.actions';
import { actions as componentActions } from '../../freightComponent/freightComponent.actions';
import { actions as restrictionActions } from '../freightTable.actions'
import { messageActions, makeMessages } from '../../../store/message/';
import * as utils from './utils';
import * as generalUtils from '../../../utils/index';

import * as i18n from '../../../i18n';
import { HistoryLogListPage } from '../../history-log-component';
import { LxDialogStatusImportXlsRoutes } from './components/LxDialogStatusImportRouteXls';
import { LxDialogStatusImportTaxaMutCompXls } from './components/LxDialogStatusImportTaxaMutCompXls';

const mapStateToProps = state => {
  const record = state.crudFreightTableState.currentRecord;

  const isTransfer = (record.tags.indexOf("TRANSFERÊNCIA") > -1) ? true : false;
  const chilledProduct = (record.tags.indexOf("REFRIGERADO") > -1) ? true : false;

  const lookupBusinessUnitBase = {
    currentBusinessUnit: state.crudFreightTableState.currentBusinessUnit,
    records: state.crudUnidadeState.records,
    columns: state.crudUnidadeState.columns.map(col => {
      col.sortable = false;
      col.filter = false;
      return col;
    })
      .filter(col => col.field === 'label' || col.field === 'tradeName' || col.field === 'documentNumber')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudUnidadeState.index,
    rows: state.crudUnidadeState.max,
    totalRecords: state.crudUnidadeState.count,
    sortField: state.crudUnidadeState.sortField,
    order: state.crudUnidadeState.sortOrder,
    editDisabled: !record.oidUnidade
  }
  let lookupBusinessUnit = Object.assign({}, state.crudFreightTableState.lookupBusinessUnit, lookupBusinessUnitBase);
  let lookupBusinessUnit2 = Object.assign({}, state.crudFreightTableState.lookupBusinessUnit2, lookupBusinessUnitBase);
  let lookupRestrictedUnit = Object.assign({}, state.crudFreightTableState.lookupRestrictedUnit, lookupBusinessUnitBase);
  // console.log("lookupRestrictedUnit",lookupRestrictedUnit)
  const lookupCarrierBase = {
    records: state.crudCarrierState.records,
    columns: state.crudCarrierState.columns
      .filter(col => col.field === 'oid' || col.field === 'name' || col.field === 'tradeName'  || col.field === 'documentNumber')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    editDisabled: !record.oidCarrier
  }
  let lookupCarrier = Object.assign({}, state.crudFreightTableState.lookupCarrier, lookupCarrierBase);

  const lookupRouteBase = {
    records: state.crudRouteState.records,
    columns: state.crudRouteState.columns
      .filter(col => col.field === 'oid' || col.field === 'name')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudRouteState.index,
    rows: state.crudRouteState.max,
    totalRecords: state.crudRouteState.count,
    sortField: state.crudRouteState.sortField,
    order: state.crudRouteState.sortOrder,
    editDisabled: !record.oidRoute
  }
  let lookupRoute = Object.assign({}, state.crudFreightTableState.lookupRoute, lookupRouteBase);

  const lookupComponentBase = {
    records: state.crudFreightComponentState.records,
    columns: state.crudFreightComponentState.columns
      .filter(col => col.field === 'oid' || col.field === 'name')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudFreightComponentState.index,
    rows: state.crudFreightComponentState.max,
    totalRecords: state.crudFreightComponentState.count,
    sortField: state.crudFreightComponentState.sortField,
    order: state.crudFreightComponentState.sortOrder,
    editDisabled: !record.oid
  }
  let lookupComponent = Object.assign({}, state.crudFreightTableState.lookupComponent, lookupComponentBase);

  const lookupClientsBase = {
    record: state.crudFreightTableState.currentClient,
    records: state.crudClientState.records,
    columns: state.crudClientState.columns
      .filter(col => col.field === 'razaoSocial')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudClientState.index,
    rows: state.crudClientState.max,
    totalRecords: state.crudClientState.count,
    sortField: state.crudClientState.sortField,
    order: state.crudClientState.sortOrder,
  }
  let lookupClient = Object.assign({}, state.crudFreightTableState.lookupClient, lookupClientsBase);

  const lookupLocationBase = {
    records: state.crudLocationState.records,
    columns: state.crudLocationState.columns
      .filter(col => col.field === 'description')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
  }
  let lookupLocation = Object.assign({}, state.crudFreightTableState.lookupLocation, lookupLocationBase);


  const dialogRoutes = state.crudFreightTableState.dialogRoutes;
  const dialogDuplicateRoute = state.crudFreightTableState.dialogDuplicateRoute;

  // currentOperation && listComposition
  let listComposition = [];
  let currentOperation = null;
  //  if (record.generalFreightComponents.length > 0){

  //      //operation
  //      record.generalFreightComponents.forEach((item) => {
  //          if (item && item.operation !== ""){
  //              const resultOperation= {
  //                  label: item.operation
  //              }
  //              currentOperation = resultOperation;
  //          }

  //          // listComponent
  //          if (item && item.composition && item.composition.length > 0){                
  //              listComposition = item.composition;
  //          }

  //      });

  //  } else {
  //      currentOperation = state.crudFreightTableState.currentOperation;
  //      listComposition = state.crudFreightTableState.listComposition;
  //  }

  const compositionColumns = state.crudFreightTableState.compositionColumns;
  const listOperations = state.crudFreightTableState.listOperations || [];
  const showModalInsertComponent = state.crudFreightTableState.showModalInsertComponent;
  const descriptionComponent = state.crudFreightTableState.descriptionComponent;
  const selectedRow = state.crudFreightTableState.selectedRow || [];

  return {
    record,
    isTransfer,
    chilledProduct,
    lookupBusinessUnit,
    lookupCarrier,
    lookupRoute,
    lookupComponent,
    lookupClient,
    lookupLocation,
    lookupBusinessUnit2,
    lookupRestrictedUnit,

    //componente Valor Calculado
    compositionColumns,
    listComposition,
    currentOperation,
    listOperations,
    showModalInsertComponent,
    descriptionComponent,
    selectedRow,

    // ignore Component
    ignoreComponentsColumns: state.crudFreightTableState.ignoreComponentsColumns || [],
    onlyIgnoreListComponent: state.crudFreightTableState.currentRecord.onlyIgnoreListComponent || [],


    // isVisibleHistorylog: state.crudFreightTableState.visibleHistorylog,

    businessUnitsColumns: state.crudFreightTableState.businessUnitsColumns,
    businessUnitsRecords: state.crudFreightTableState.currentRecord.businessUnits,
    selectedBusinessUnits: state.crudFreightTableState.selectedBusinessUnits,
    businessUnitsFirst: 1,
    businessUnitsRows: state.crudFreightTableState.currentRecord.businessUnits.length,
    businessUnitsTotalRecords: state.crudFreightTableState.currentRecord.businessUnits.length,
    businessUnitsSortField: '',
    businessUnitsOrder: 1,
    dialogAddBusinessUnits: state.crudFreightTableState.dialogAddBusinessUnits,

    clientsByComponentRestrictioncolumns: state.crudFreightTableState.clientsByComponentRestrictioncolumns,
    locationsByComponentRestrictioncolumns: state.crudFreightTableState.locationsByComponentRestrictioncolumns,
    unitByComponentRestrictioncolumns: state.crudUnidadeState.columns,
    routeColumns: state.crudFreightTableState.routesColumns,
    restrictionColumns: state.crudFreightTableState.restrictionsColumns,
    dialogAddRestrictionsOpen: state.crudFreightTableState.dialogAddRestrictionsOnOpen,
    dialogRestrictions: state.crudFreightTableState.dialogAddRestrictions,
    dialogAddRestrictionsClose: state.crudFreightTableState.dialogAddRestrictionsOnClose,
    dialogComponentName: state.crudFreightTableState.dialogAddRestrictions.dialogComponentName,

    dialogRoutes,
    dialogDuplicateRoute,
    currentRoute: state.crudFreightTableState.currentRoute,
    currentComponent: state.crudFreightTableState.currentComponent,
    selectedRoute: state.crudFreightTableState.selectedRoute,
    selectedRoutes: state.crudFreightTableState.selectedRoutes,
    currentRanges: state.crudFreightTableState.selectedRoute ? state.crudFreightTableState.selectedRoute.ranges : [],
    selectedRange: state.crudFreightTableState.selectedRange,
    dialogRangesProps: state.crudFreightTableState.panelByRoute.dialog,
    dialogEditRangeProps: state.crudFreightTableState.panelByRoute.dialogEdit,
    indexSelectedRange: state.crudFreightTableState.indexSelectedRange,
    selectedSerie: state.crudFreightTableState.selectedSerie,
    dialogAddComponentSelectedSerie: state.crudFreightTableState.panelByRoute.panelRange.dialogAddComponentSelectedSerie,
    dialogAddComponentGeneralComponents: state.crudFreightTableState.dialogAddComponentGeneralComponents,
    generalComponents: record ? record.generalFreightComponents : [],
    restrictionsDisabled: state.crudFreightTableState.panelRestrictionByComponent.restrictionsDisabled,
    currentSelectedComponent: state.crudFreightTableState.panelRestrictionByComponent.currentSelectedComponent,
    panelClientsByComponentRestriction: state.crudFreightTableState.panelClientsByComponentRestriction,
    panelLocationsByComponentRestriction: state.crudFreightTableState.panelLocationsByComponentRestriction,
    panelRestrictionByComponent: state.crudFreightTableState.panelRestrictionByComponent,
    currentDuplicateRoute: state.crudFreightTableState.currentDuplicateRoute,
    lookupOrigin: state.crudFreightTableState.lookupOrigin,
    lookupDestination: state.crudFreightTableState.lookupDestination,
    dialogEditSerie: state.crudFreightTableState.panelByRoute.panelRange.dialogEditSerie,
    dialogStatusImportXlsRoutes: state.crudFreightTableState.dialogStatusImportXlsRoutes,
    dialogStatusImportXlsTaxaMultValores: state.crudFreightTableState.dialogStatusImportXlsTaxaMultValores,
    auxiliarMultiEntries: state.crudFreightTableState.auxiliarMultiEntries,
  }
}


const mapDispatchToProps = dispatch => {
  const dispatchDefaultActions = utils.defaultActionsDispatchToProps({ dispatch, actions, messageActions, makeMessages })
  const dispatchBusinessUnit = utils.businessUnitDispatchToProps({ dispatch, actions, unidadeActions });
  const dispatchCarrier = utils.carrierDispatchToProps({ dispatch, actions, carrierActions });
  const dispatchRoutes = utils.routeDispatchToProps({ dispatch, actions, routeActions });
  const dispatchComponents = utils.componentDispatchToProps({ dispatch, actions, componentActions });
  const dispatchRestrictions = utils.restrictionDispatchToProps({ dispatch, actions, restrictionActions });




  return {
    /*BusinessUnit */
    ...dispatchBusinessUnit,
    /*Carrier */
    ...dispatchCarrier,
    /*Route */
    ...dispatchRoutes,
    /*Restriction*/
    ...dispatchRestrictions,
    /* Component */
    ...dispatchComponents,
    /*default actions */
    ...dispatchDefaultActions,

    //componente Valor Calculado
    changeOperation: (component, name, value, index, componentType) => dispatch(actions.changeOperation(component, name, value, index, componentType)),
    addComposition: (composition, component, index,componentType) => dispatch(actions.addComposition(composition, component, index, componentType)),
    removeComposition: (component, composition, index,componentType) => dispatch(actions.removeComposition(component, composition, index, componentType)),
    
    addEntries: (component, entries, index,componentType) => dispatch(actions.addEntries(component, entries, index, componentType)),
    removeEntries: (component, entries, index,componentType) => dispatch(actions.removeEntries( component, entries, index, componentType)),
    updateEntry: (component, entry, index, indexEntry, componentType) => dispatch(actions.updateEntry(component,entry, index, indexEntry, componentType)),
    sendInportTaxaComponetXLSX: (component,index,xls) => dispatch(actions.sendInportTaxaComponetXLSX(component,index,xls)),
    showDialogStatusImportXlsTaxaMultValores: (obj) => dispatch(actions.showDialogStatusImportXlsTaxaMultValores(obj)),
    saveEntrie:(componentId,entrie) => dispatch(actions.saveEntrie(componentId,entrie)),
    onLoadMultiEntry: (filtersMultiEntries) => dispatch(actions.loadMultiEntry(filtersMultiEntries)),
  }
}

class FreightTableFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isVisibleHistorylog: false
    }
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_FREIGHT_TABLE}/${this.props.record.oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_FREIGHT_TABLE}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_FREIGHT_TABLE}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;
    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
    this.props.setSelectedRoute(null)
    this.props.setSelectedRoutes([])
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_FREIGHT_TABLE}`)
  }

  lookupBusinessUnitOnPage = (e) => {
    this.props.lookupBusinessUnitPaginate({
      max: e.rows,
      index: e.first
    })
  }

  lookupBusinessUnitOnSort = (e) => {
    this.props.lookupBusinessUnitSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  lookupBusinessUnitOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupBusinessUnitApplyFilter(filters);
  }

  lookupBusinessUnitOnClick = (e) => {
    this.props.lookupBusinessUnitLoad();
    this.props.lookupBusinessUnitSetVisible(true);
  }

  lookupBusinessUnitOnHide = (e) => {
    this.props.lookupBusinessUnitSetVisible(false);
  }

  lookupBusinessUnitOnConfirm = (e) => {
    this.props.setCurrentBusinessUnit(e.selection);
    this.props.lookupBusinessUnitSetVisible(false);
  }

  lookupBusinessUnitOnCancel = (e) => {
    this.props.setCurrentBusinessUnit(null);
    this.props.lookupBusinessUnitSetVisible(false);
  }

  lookupBusinessUnitOnEdit = () => {
    if (this.props.record.oidBusinessUnit) {
      this.props.history.push(`${routes.PATH_BUSINESSUNIT}/${this.props.record.oidBusinessUnit}`);
    }
  }

  lookupBusinessUnitOnComplete = e => { }

  lookupBusinessUnitOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      }];
      this.props.setCurrentBusinessUnit({ tradeName: value });
      this.props.lookupBusinessUnitApplyFilter(filters);
    } else {
      this.props.setCurrentBusinessUnit(value);
    }
  }

  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first
    });
  }

  lookupCarrierOnSort = (e) => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }

  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupCarrierApplyFilter(filters)
  }

  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  }

  lookupCarrierOnHide = (e) => {
    this.props.lookupCarrierSetVisible(false);
  }

  lookupCarrierOnConfirm = (e) => {
    console.log("e.selection=",e.selection)
    this.props.setCarrier(e.selection);
    this.props.lookupCarrierSetVisible(false);
  }

  lookupCarrierOnCancel = (e) => {
    this.props.setCarrier(null);
    this.props.lookupCarrierSetVisible(false);
  }

  lookupCarrierOnEdit = () => {
    if (this.props.record.oidCarrier) {
      this.props.history.push(`${routes.PATH_CARRIER}/${this.props.record.oidCarrier}`);
    }
  }

  lookupCarrierOnComplete = e => { };

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      }];
      this.props.setCarrier({person:{  name: value}  });
      this.props.lookupCarrierApplyFilter(filters);
    } else {
      this.props.setCarrier(value);
    }
  }

  businessUnitsOnSelectionChange = (e) => {
    this.props.setSelectedBusinessUnits(e.value);
  }

  onRemoveSelectedBusinessUnits = (e) => {
    this.props.removeSelectedBusinessUnits();
  }

  dialogAddBusinessUnitsOnOpen = (e) => {
    this.props.setCurrentBusinessUnit(null);
    this.props.setDialogAddBusinessUnitsVisible(true);
  }

  dialogAddBusinessUnitsOnClose = (e) => {
    this.props.setDialogAddBusinessUnitsVisible(false);
  }

  dialogAddBusinessUnitsOnConfirm = (e) => {
    this.props.setDialogAddBusinessUnitsVisible(false);
    this.props.addBusinessUnit();
  }

  dialogAddBusinessUnitsOnAdd = (e) => {
    this.props.addBusinessUnit();
  }

  dialogAddRouteOnOpen = (e) => {
    this.props.setCurrentRoute(null);
    this.props.setDialogRouteVisible(true);
  }

  dialogRouteOnConfirme = (e) => {
    let newRoute = this.props.currentRoute
    newRoute.range = []
    this.props.addRoute(newRoute);
    this.props.setCurrentRoute(null);
    this.props.setDialogRouteVisible(false);
  }

  dialogRouteOnAdd = (e) => {
    let newRoute = this.props.currentRoute
    newRoute.range = []
    this.props.addRoute(newRoute);
    this.props.setCurrentRoute(null);
  }

  dialogRouteOnHide = (e) => {
    this.props.setCurrentRoute(null);
    this.props.setDialogRouteVisible(false);
  }

  dialogDuplicateRouteOnConfirm = (e) => {
    if (this.props.currentDuplicateRoute) {
      const { ranges, route } = this.props.currentDuplicateRoute
      let duplicatedRoute = {}
      if (route && route.oid && route.oid > 0) {
        duplicatedRoute = {
          ...route,
          ranges,
        }
      } else {
        const { name, origin, destination, carrier, company, cnpj } = this.props.currentDuplicateRoute

        duplicatedRoute = {
          active: true,
          name,
          ranges,
          cnpj,
          carrierName: carrier.name,
          carrierNumber: carrier.cnpj,
          companyName: company.name,
          companyNumber: company.cnpj,
          destinationName: destination.description,
          oidCarrier: carrier.oid,
          oidCompany: company.oid,
          oidDestination: destination.oid,
          oidOrigin: origin.oid,
          originName: origin.description,
        }
      }
      this.props.addRoute(duplicatedRoute);
    }
    this.props.setDialogDuplicateRouteVisible(false);
  }

  dialogDuplicateRouteOnHide = (e) => {
    this.props.setDialogDuplicateRouteVisible(false);
  }

  dialogDuplicateRouteOnOpen = (e) => {
    this.props.setDuplicateRoute(this.props.selectedRoute)
    this.props.setDialogDuplicateRouteVisible(true);
  }

  onRemoveSelectedRoutes = (e) => {
    this.props.removeSelectedRoutes();
  }

  lookupRouteOnClick = (e) => {
    this.props.lookupRouteClearFilter()
    this.props.lookupRouteLoad();
    this.props.lookupRouteSetVisible(true);
  }

  lookupRouteOnHide = (e) => {
    this.props.lookupRouteSetVisible(false);
  }

  lookupRouteOnConfirm = (e) => {
    this.props.setCurrentRoute(e.selection);
    this.props.lookupRouteSetVisible(false);
    this.props.lookupRouteClearFilter()
  }

  lookupRouteOnCancel = (e) => {
    this.props.setCurrentRoute(null);
    this.props.lookupRouteSetVisible(false);
    this.props.lookupRouteClearFilter()
  }

  lookupRouteOnComplete = e => { };

  lookupRouteOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      }];
      this.props.setCurrentRoute({ name: value });
      this.props.lookupRouteApplyFilter(filters);
    } else {
      this.props.setCurrentRoute(value);
    }
  }

  lookupRouteOnPage = (e) => {
    this.props.lookupRoutePaginate({
      max: e.rows,
      index: e.first
    });
  }

  lookupRouteOnSort = (e) => {
    this.props.lookupRouteSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }

  lookupRouteOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupRouteApplyFilter(filters);
  }

  dialogAddRangeOnOpen = () => {
    this.props.setDialogRangeVisible(true);
  }

  dialogAddRangeOnConfirm = (data) => {
    this.props.addRange(data)
    this.props.setDialogRangeVisible(false);
  }

  dialogAddRangeOnAdd = (data) => {
    this.props.addRange(data);
  }

  dialogAddRangeOnCancel = () => {
    this.props.setDialogRangeVisible(false);
  }

  dialogEditSerieOnOpen = () => {
    this.props.setDialogEditSerieVisible(true);
  }

  dialogEditSerieOnConfirm = ({ start, end }) => {
    this.props.setSerie({ start, end });
    this.props.setDialogEditSerieVisible(false);
  }

  dialogEditSerieOnCancel = () => {
    this.props.setDialogEditSerieVisible(false);
  }

  onRemoveSelectedSerie = () => {
    this.props.removeSelectedSerie();
  }

  onRemoveSelectedRange = () => {
    const rangeType = this.props.selectedRange ? this.props.selectedRange.rangeType : null;
    if (rangeType) {
      this.props.removeSelectedRange();
    }
  }

  onSelectionRangeChange = (event) => {
    this.props.setSelectedRange(event.index || 0);
  }

  onSelectedSerieChange = (event) => {
    this.props.setSelectedSerie(event.value);
  }

  onRemoveComponentFromSelectedSerie = (component) => {
    this.props.removeComponentFromSelectedSerie(component);
  }

  onChangeComponentFromSelectedSerie = (component, field, value, index) => {
    this.props.changeComponentFromSelectedSerie(component, field, value, index);
  }

  showDialogAddComponentIntoSelectedSerie = () => {
    this.props.setVisibleDialogAddComponentIntoSelectedSerie(true);
  }

  /* General Components */
  showDialogAddComponentIntoGeneralComponents = () => {
    this.props.setVisibleDialogAddComponentIntoGeneralComponents(true);
  }

  onRemoveComponentFromGeneralComponents = (component) => {
    this.props.removeComponentFromGeneralComponents(component);
  }

  onChangeComponentFromGeneralComponents = (component, field, value) => {
    this.props.changeComponentFromGeneralComponents(component, field, value);
  }

  dialogAddComponentGeneralComponentsOnConfirm = (e) => {
    this.props.addComponentGeneralComponents(this.props.currentComponent);
    this.props.setCurrentComponent(null);
    this.props.setVisibleDialogAddComponentIntoGeneralComponents(false);
  }

  dialogAddComponentGeneralComponentsOnHide = (e) => {
    this.props.setCurrentComponent(null);
    this.props.setVisibleDialogAddComponentIntoGeneralComponents(false);
  }

  dialogAddComponentGeneralComponentsOnAdd = (e) => {
    this.props.addComponentGeneralComponents(this.props.currentComponent);
    this.props.setCurrentComponent(null);

  }

  /* Component */
  dialogAddComponentSelectedSerieOnConfirm = (e) => {
    this.props.addComponentSelectedSerie(this.props.currentComponent);
    this.props.addRestrictionOnRestrictionTable(this.props.currentComponent)
    this.props.setCurrentComponent(null);
    this.props.setVisibleDialogAddComponentIntoSelectedSerie(false);
  }

  dialogAddComponentSelectedSerieOnHide = (e) => {
    this.props.setCurrentComponent(null);
    this.props.setVisibleDialogAddComponentIntoSelectedSerie(false);
  }

  dialogAddComponentSelectedSerieOnAdd = (e) => {
    this.props.addComponentSelectedSerie(this.props.currentComponent);
    this.props.addRestrictionOnRestrictionTable(this.props.currentComponent);
    this.props.setCurrentComponent(null);
  }

  lookupComponentOnClick = (e) => {
    this.props.lookupComponentClearFilter();
    this.props.lookupComponentLoad();
    this.props.lookupComponentSetVisible(true);
  }

  lookupComponentOnHide = (e) => {
    this.props.lookupComponentSetVisible(false);
  }

  lookupComponentOnConfirm = (e) => {
    this.props.setCurrentComponent(e.selection);
    this.props.lookupComponentSetVisible(false);
    this.props.lookupComponentClearFilter();
  }

  lookupComponentOnCancel = (e) => {
    this.props.setCurrentComponent(null);
    this.props.lookupComponentSetVisible(false);
    this.props.lookupComponentClearFilter();
  }

  lookupComponentOnComplete = e => { };

  lookupComponentOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      }];
      this.props.setCurrentComponent({ name: value });
      this.props.lookupComponentApplyFilter(filters);
    } else {
      this.props.setCurrentComponent(value);
    }
  }

  lookupClientOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [{
        campo: "razaoSocial",
        valor: value,
        tipoFiltro: "CONTEM"
      }];
      this.props.lookupClientSetSearchText(value);
      this.props.lookupClientApplyFilter(filters);
    } else {
      this.props.setCurrentClient(value);
    }
  }

  lookupClientOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupClientApplyFilter(filters);
  }

  lookupLocationOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupLocationApplyFilter(filters);
  }

  lookupLocationOnPage = (e) => {
    this.props.lookupLocationPaginate({
      max: e.rows,
      index: e.first
    });
  }

  lookupOriginOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "description",
        valor: e.value,
        tipoFiltro: 'CONTEM'
      }];
      this.props.setDuplicateRouteField('origin', { description: e.value });
      this.props.lookupLocationApplyFilter(filters);
    } else {
      this.props.setDuplicateRouteField('origin', { oid: e.value.oid, description: e.value.description });
    }
  }

  lookupOriginOnComplete = (e) => { }

  lookupDestinationOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "description",
        valor: e.value,
        tipoFiltro: 'CONTEM'
      }];
      this.props.setDuplicateRouteField('destination', { description: e.value });
      this.props.lookupLocationApplyFilter(filters);
    } else {
      this.props.setDuplicateRouteField('destination', { oid: e.value.oid, description: e.value.description });
    }
  }

  lookupDestinationOnComplete = (e) => { }

  lookupComponentOnPage = (e) => {
    this.props.lookupComponentPaginate({
      max: e.rows,
      index: e.first
    });
  }

  lookupComponentOnSort = (e) => {
    this.props.lookupComponentSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }

  lookupComponentOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupComponentApplyFilter(filters);
  }

  /* Fim Component */

  onChangeDuplicateRouteName = (e) => {
    this.props.setDuplicateRouteField("name", e.target.value);
  }
  lookupOriginOnConfirm = (e) => {
    this.props.lookupOriginOnConfirm(e);
  }

  lookupOriginOnConfirm = (e) => {
    if (e.selection) {
      const { oid, description } = e.selection;
      this.props.setDuplicateRouteField("origin", { oid, description });
      this.props.setLookupOriginVisible(false);
    }
  }

  lookupDestinationOnConfirm = (e) => {
    if (e.selection) {
      const { oid, description } = e.selection;
      this.props.setDuplicateRouteField("destination", { oid, description });
      this.props.setLookupDestinationVisible(false);
    }
  }

  lookupOriginOnHide = (e) => {
    this.props.setLookupOriginVisible(false);
  }

  lookupDestinationOnHide = (e) => {
    this.props.setLookupDestinationVisible(false);
  }

  lookupOriginOnOpen = (e) => {
    this.props.loadLocations();
    this.props.setLookupOriginVisible(true);
  }

  lookupDestinationOnOpen = (e) => {
    this.props.loadLocations();
    this.props.setLookupDestinationVisible(true);
  }

  lookupRouteDuplicateOnConfirm = (e) => {
    this.props.setDuplicateRouteField('route', e.selection);
    this.props.lookupRouteSetVisible(false);
    this.props.lookupRouteClearFilter();
  }

  lookupRouteDuplicateOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      }];
      this.props.setDuplicateRouteField('route', { name: value });
      this.props.lookupRouteApplyFilter(filters);
    } else {
      this.props.setDuplicateRouteField('route', value);
    }
  }

  verifyDuplicatedRoute = (newRoute, routes) => {
    if (newRoute && routes && routes.length > 0) {
      for (let index = 0; index < routes.length; index++) {
        const route = routes[index];
        if ((newRoute.name === route.name) && (newRoute.oidOrigin === route.oidOrigin || newRoute.oidDestination === route.oidDestination)) {
          return true
        }
      }
    }
    return false
  }

  duplicateConfirmDisable = () => {
    const selectedRoute = this.props.selectedRoute;
    const routes = this.props.record.routes;
    const currentDuplicateRoute = this.props.currentDuplicateRoute;
    if (currentDuplicateRoute && selectedRoute) {
      const { name, origin, destination, route } = currentDuplicateRoute
      if (route && route.oid && !generalUtils.verifyIdExistsInObjectList(route.oid, routes) && !this.verifyDuplicatedRoute(route, routes)) {
        return false
      }
      if ((name && origin && destination && origin.oid && destination.oid)) {
        if ((name !== selectedRoute.name) && (origin.oid !== selectedRoute.oidOrigin || destination.oid !== selectedRoute.oidDestination)) {
          return false
        }
      }
    }
    return true;
  }

  showImportFileXLSX = (e, type) => {
    const xls = e.target.files;
    this.props.importFilesXLSX(xls, type);
  }


  lookupRestrictedUnitOnSelectUnits = (event) => {

    this.props.setSelectedRecordsLookupUnitRestricted(event.value)
  }

  lookupUnitRestrictedOnClick = () => {
    this.props.lookupBusinessUnitLoad();
    this.props.setVisibleLookupRestrictedUnit(true)
  }

  lookupUnitRestrictedOnHide = () => {
    this.props.setVisibleLookupRestrictedUnit(false);
  }

  lookupUnitRestrictedOnCancel = () => {
    this.props.setVisibleLookupRestrictedUnit(false);
  }

  lookupUnitRestrictedOnConfirm = (event) => {
    this.props.setBusinessUnitRestrictedOnConfirm(event.selection)
  }

  lookupUnitRestrictedOnFilter = (e) => {
    this.lookupBusinessUnitOnFilter(e)
  }

  onClickOpenHistoryLog = () => {
    this.setState({
      isVisibleHistorylog: true
    })
  }
  onHideHistorylog = () => {
    this.setState({
      isVisibleHistorylog: false
    })
  }

  //Criar Faixa de CEP e criar rotas apartir de dados XLS
  importInforTabelaFreteXLSX = (event) => {
    event.preventDefault();
    this.props.sendInforTabelaFreteXLSX(event.target.files)
    event.target.value = null
  }
  onCloseDialogImportXls = () => {
    this.props.setVisibleDialogStatusImportXlsRoutes(false);
  }

  onChangeOperationHandlerGeneralComponent = (component, name, value, index) => {
    const componentType = 'generalComponent';
    this.props.changeOperation(component, name, value, index, componentType);
  }

  onChangeOperationHandlerRouteComponent = (component, name, value, index) => {
    const componentType = 'routeComponent';
    this.props.changeOperation(component, name, value, index, componentType);
  }

  onRemoveCompositionRouteComponent = (component, description, index) => {
    const componentType = 'routeComponent';
    this.props.removeComposition(component, description, index, componentType);
  }
  onRemoveCompositionGeneralComponent = (component, description, index) => {
    const componentType = 'generalComponent';
    this.props.removeComposition(component, description, index, componentType);
  }

  onAddCompositionGeneralComponent = (component, description, index) => {
    const componentType = 'generalComponent';
    if (description && description.trim().length > 0) {
      const composition = {
        label: description,
      }
      this.props.addComposition(component, composition, index, componentType);
    }
  }
  onAddEntriesGeneralComponent = (component, entries, index) => {
    const componentType = 'generalComponent';
    if (entries && entries.length > 0) {     
      this.props.addEntries(component, entries, index, componentType);
      this.props.saveMultiEntries(component, entries);
    }
  }

  onRemoveEntriesGeneralComponent = (component, entries, index) => {
    const componentType = 'generalComponent';
    if (entries && entries.length > 0) {     
      this.props.removeEntries(component, entries, index, componentType);
      this.props.removeMultiEntry(component, entries)
    }
  }
  onUpdateEntryGeneralComponent = (component, entry, index, indexEntry) => {
    const componentType = 'generalComponent';
    if (entry ) {     
      this.props.updateEntry(component, entry, index, indexEntry, componentType);
      this.props.saveMultiEntries(component, [entry]);
    }
  }

  onLoadMultiEntryGeneralComponent = (filters) => {
    this.props.onLoadMultiEntry(filters);
  }

  onAddCompositionRouteComponent = (component, description, index) => {
    const componentType = 'routeComponent';
    if (description && description.trim().length > 0) {
      const composition = {
        label: description,
      }
      this.props.addComposition(component, composition, index, componentType);
    }
  }


  render() {
    let businessUnitsColumns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />];
    businessUnitsColumns = [...businessUnitsColumns, ...this.props.businessUnitsColumns.map(col => {
      return (<Column {...col} key={col.field} />)
    })];

    let routeColumns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />];
    routeColumns = [...routeColumns, ...this.props.routeColumns.map(col => {
      
      if(col.field =="oidOrigin"){
        return (<Column {...col} key={col.field} style={{ width: '7em' }}/>)
      }

      if(col.field =="oidDestination"){
        return (<Column {...col} key={col.field} style={{ width: '7em' }}/>)
      }
      return (<Column {...col} key={col.field} />)
    })];

    let restrictionColumns = [<Column key="selectorColumn" selectionMode="single" style={{ width: '3em' }} />];
    restrictionColumns = [...restrictionColumns, ...this.props.restrictionColumns.map(col => {
      return (<Column {...col} key={col.field} />)
    })];

    let clientsByComponentRestrictioncolumns = [<Column style={{ width: '3em' }} />];
    clientsByComponentRestrictioncolumns = [...clientsByComponentRestrictioncolumns, ...this.props.clientsByComponentRestrictioncolumns.map(col => {
      return (<Column {...col} key={col.field} />)
    })];

    let locationsByComponentRestrictioncolumns = [<Column style={{ width: '3em' }} />];
    locationsByComponentRestrictioncolumns = [...locationsByComponentRestrictioncolumns, ...this.props.locationsByComponentRestrictioncolumns.map(col => {
      return (<Column {...col} key={col.field} />)
    })];

    let unitsByComponentRestrictioncolumns = [<Column style={{ width: '3em' }} key={"selection"} />];
    unitsByComponentRestrictioncolumns = [...unitsByComponentRestrictioncolumns, ...this.props.unitByComponentRestrictioncolumns.filter(col => col.field == 'sigla' || col.field == 'nomePessoa').map(col => {
      return (<Column {...col} key={col.field} />)
    })];

    let columnsComposition = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />]
    columnsComposition = [...columnsComposition, ...this.props.compositionColumns.map(col => {
      return (<Column {...col} key={col.field} />)
    })];



    const businessUnitsParams = {
      lookupBusinessUnit: this.props.lookupBusinessUnit,
      lookupBusinessUnitOnHide: this.lookupBusinessUnitOnHide,
      lookupBusinessUnitOnConfirm: this.lookupBusinessUnitOnConfirm,
      lookupBusinessUnitOnCancel: this.lookupBusinessUnitOnCancel,
      lookupBusinessUnitOnPage: this.lookupBusinessUnitOnPage,
      lookupBusinessUnitOnSort: this.lookupBusinessUnitOnSort,
      lookupBusinessUnitOnFilter: this.lookupBusinessUnitOnFilter,
      lookupBusinessUnitOnClick: this.lookupBusinessUnitOnClick,
      lookupBusinessUnitOnEdit: this.lookupBusinessUnitOnEdit,
      lookupBusinessUnitOnComplete: this.lookupBusinessUnitOnComplete,
      lookupBusinessUnitOnInputFilter: this.lookupBusinessUnitOnInputFilter,
      businessUnitsColumns: businessUnitsColumns,
      selectedBusinessUnits: this.props.selectedBusinessUnits,
      businessUnitsRecords: this.props.businessUnitsRecords,
      businessUnitsFirst: this.props.businessUnitsFirst,
      businessUnitsRows: this.props.businessUnitsRows,
      businessUnitsTotalRecords: this.props.businessUnitsTotalRecords,
      businessUnitsSortField: this.props.businessUnitsSortField,
      businessUnitsOrder: this.props.businessUnitsOrder,
      onFilterBusinessUnits: this.onFilterBusinessUnits,
      onPageBusinessUnits: this.onPageBusinessUnits,
      onSortBusinessUnits: this.onSortBusinessUnits,
      businessUnitsOnSelectionChange: this.businessUnitsOnSelectionChange,
      onRemoveSelectedBusinessUnits: this.onRemoveSelectedBusinessUnits,

      dialogAddBusinessUnits: this.props.dialogAddBusinessUnits,
      dialogAddBusinessUnitsOnOpen: this.dialogAddBusinessUnitsOnOpen,
      dialogAddBusinessUnitsOnClose: this.dialogAddBusinessUnitsOnClose,
      dialogAddBusinessUnitsOnConfirm: this.dialogAddBusinessUnitsOnConfirm,
      dialogAddBusinessUnitsOnAdd: this.dialogAddBusinessUnitsOnAdd,
      disabledButtonBusinessUnits: {
        add: this.props.record.isOnlyMatriz,
        remove: this.props.record.isOnlyMatriz
      }
    }

    const carrierParams = {
      lookupCarrier: this.props.lookupCarrier,
      lookupCarrierOnHide: this.lookupCarrierOnHide,
      lookupCarrierOnConfirm: this.lookupCarrierOnConfirm,
      lookupCarrierOnCancel: this.lookupCarrierOnCancel,
      lookupCarrierOnPage: this.lookupCarrierOnPage,
      lookupCarrierOnSort: this.lookupCarrierOnSort,
      lookupCarrierOnFilter: this.lookupCarrierOnFilter,
      lookupCarrierOnClick: this.lookupCarrierOnClick,
      lookupCarrierOnEdit: this.lookupCarrierOnEdit,
      lookupCarrierOnComplete: this.lookupCarrierOnComplete,
      lookupCarrierOnInputFilter: this.lookupCarrierOnInputFilter
    }

    const currentDuplicateRoute = this.props.currentDuplicateRoute ? this.props.currentDuplicateRoute : {}

    const freighTableFormParams = {
      mainForm: {
        clearCurrentRecord: this.props.clearCurrentRecord,
        changeHandler: this.props.changeHandler,
        transferChangeHandler: this.props.transferChangeHandler,
        chilledProductChangeHandler: this.props.chilledProductChangeHandler,
        transfer: this.props.isTransfer,
        chilledProduct: this.props.chilledProduct,
        record: this.props.record,
        ...businessUnitsParams,
        ...carrierParams
      },
      dialogRoutes: {
        dialog: {
          header: "Rotas",
          visible: this.props.dialogRoutes.visible,
          modal: this.props.dialogRoutes.modal,
          confirmButtonDisabled: false,
          addButtonDisabled: false,
          actions: {
            onHide: this.dialogRouteOnHide,
            onConfirm: this.dialogRouteOnConfirme,
            onAdd: this.dialogRouteOnAdd,
          }
        },
        lookup: {
          title: "Rota",
          editDisabled: this.props.lookupRoute.editDisabled,
          visible: this.props.lookupRoute.visible,
          modal: false,
          header: this.props.lookupRoute.header,
          records: this.props.lookupRoute.records,
          columns: this.props.lookupRoute.columns,
          order: this.props.lookupRoute.order,
          sortField: this.props.lookupRoute.sortField,
          first: this.props.lookupRoute.first,
          rows: this.props.lookupRoute.rows,
          totalRecords: this.props.lookupRoute.totalRecords,
          value: this.props.currentRoute,
          placeholder: "Rota",
          name: "record.currentRoute",
          field: "name",
          actions: {
            onInputFilter: this.lookupRouteOnInputFilter,
            onClick: this.lookupRouteOnClick,
            onHide: this.lookupRouteOnHide,
            onConfirm: this.lookupRouteOnConfirm,
            onCancel: this.lookupRouteOnCancel,
            onPage: this.lookupRouteOnPage,
            onFilter: this.lookupRouteOnFilter,
            onSort: this.lookupRouteOnSort
          }
        },
      },
      dialogDuplicateRoute: {
        dialog: {
          header: this.props.dialogDuplicateRoute.header,
          visible: this.props.dialogDuplicateRoute.visible,
          modal: this.props.dialogDuplicateRoute.modal,
          addButtonDisabled: false,
          confirmDisabled: this.duplicateConfirmDisable(),
          actions: {
            onHide: this.dialogDuplicateRouteOnHide,
            onConfirm: this.dialogDuplicateRouteOnConfirm,
          }
        },
        duplicateRoute:
        {
          ...currentDuplicateRoute,
          actions: {
            onChangeDuplicateRouteName: this.onChangeDuplicateRouteName
          }
        },
        lookupOrigin: {
          ...this.props.lookupOrigin,
          actions: {
            onConfirm: this.lookupOriginOnConfirm,
            onCancel: this.lookupOriginOnHide,
            onHide: this.lookupOriginOnHide,
            onOpen: this.lookupOriginOnOpen,
            onInputFilter: this.lookupOriginOnInputFilter,
            onComplete: this.lookupOriginOnComplete,
          }
        },
        lookupDestination: {
          ...this.props.lookupDestination,
          actions: {
            onConfirm: this.lookupDestinationOnConfirm,
            onCancel: this.lookupDestinationOnHide,
            onHide: this.lookupDestinationOnHide,
            onOpen: this.lookupDestinationOnOpen,
            onInputFilter: this.lookupDestinationOnInputFilter,
            onComplete: this.lookupDestinationOnComplete,
          }
        },
        lookupLocation: {
          ...this.props.lookupLocation,
          searchText: "",
          lookupLocationOnHide: this.props.lookupLocationOnCancel,
          lookupLocationOnCancel: this.props.lookupLocationOnCancel,
          lookupLocationOnClick: this.props.lookupLocationLoad,
          lookupLocationOnFilter: this.lookupLocationOnFilter,
          lookupLocationOnPage: this.lookupLocationOnPage,
        },
        lookupRoute: {
          records: this.props.lookupRoute.records,
          columns: this.props.lookupRoute.columns,
          visible: this.props.lookupRoute.visible,
          modal: false,
          header: this.props.lookupRoute.header,
          title: "Rota",
          editDisabled: this.props.lookupRoute.editDisabled,

          order: this.props.lookupRoute.order,
          sortField: this.props.lookupRoute.sortField,
          first: this.props.lookupRoute.first,
          rows: this.props.lookupRoute.rows,
          totalRecords: this.props.lookupRoute.totalRecords,
          value: this.props.currentRoute,
          actions: {
            onInputFilter: this.lookupRouteDuplicateOnInputFilter,
            onClick: this.lookupRouteOnClick,
            onHide: this.lookupRouteOnHide,
            onConfirm: this.lookupRouteDuplicateOnConfirm,
            onCancel: this.lookupRouteOnCancel,
            onPage: this.lookupRouteOnPage,
            onFilter: this.lookupRouteOnFilter,
            onSort: this.lookupRouteOnSort
          }
        },
      },
      generalComponents: {
        componentsParams: this.props.generalComponents ?
          this.props.generalComponents.map(component => {
            return {
              component,
              listOperations: this.props.listOperations,
              auxiliarMultiEntry: this.props.auxiliarMultiEntries.filter(comp => comp.idComponent === component.oid)[0],
              actions: {
                onRemove: this.onRemoveComponentFromGeneralComponents,
                onChange: this.onChangeComponentFromGeneralComponents,
                onChangeOperationHandler: this.onChangeOperationHandlerGeneralComponent,
                onRemoveItem: this.onRemoveCompositionGeneralComponent,
                onAddComposition: this.onAddCompositionGeneralComponent,
                onAddEntries: this.onAddEntriesGeneralComponent,
                onRemoveEntries: this.onRemoveEntriesGeneralComponent,
                onUpdateEntry: this.onUpdateEntryGeneralComponent,
                onLoadMultiEntry: this.onLoadMultiEntryGeneralComponent,
                sendInportTaxaComponetXLSX:this.props.sendInportTaxaComponetXLSX,
                saveEntrie : this.props.saveEntrie,
              },
              calculatedComponent: {
                columnsComposition,
                actions: {}
              }
            }
          }) : [],
        toolbar: {
          hint: {
            add: "Adicionar Componentes",
          },
          onAdd: this.showDialogAddComponentIntoGeneralComponents,
          header: ""
        },
        dialogAddComponentGeneralComponents: {
          dialog: {
            header: "Adicionar Componente",
            visible: this.props.dialogAddComponentGeneralComponents.visible,
            modal: this.props.dialogAddComponentGeneralComponents.modal,
            confirmButtonDisabled: false,
            addButtonDisabled: false,
            actions: {
              onHide: this.dialogAddComponentGeneralComponentsOnHide,
              onConfirm: this.dialogAddComponentGeneralComponentsOnConfirm,
              onAdd: this.dialogAddComponentGeneralComponentsOnAdd,
            }
          },
          lookup: {
            title: "Componentes de Frete",
            editDisabled: this.props.lookupComponent.editDisabled,
            visible: this.props.lookupComponent.visible,
            modal: false,
            header: this.props.lookupComponent.header,
            records: this.props.lookupComponent.records,
            columns: this.props.lookupComponent.columns,
            order: this.props.lookupComponent.order,
            sortField: this.props.lookupComponent.sortField,
            first: this.props.lookupComponent.first,
            rows: this.props.lookupComponent.rows,
            totalRecords: this.props.lookupComponent.totalRecords,
            value: this.props.currentComponent,
            placeholder: "Digite o nome do componente",
            name: "name",
            field: "name",
            actions: {
              onInputFilter: this.lookupComponentOnInputFilter,
              onClick: this.lookupComponentOnClick,
              onHide: this.lookupComponentOnHide,
              onConfirm: this.lookupComponentOnConfirm,
              onCancel: this.lookupComponentOnCancel,
              onPage: this.lookupComponentOnPage,
              onFilter: this.lookupComponentOnFilter,
              onSort: this.lookupComponentOnSort
            }
          },
        },
      },
      ignoreComponents:{
        ignoreComponentsColumns: this.props.ignoreComponentsColumns,
        onlyIgnoreListComponent: this.props.onlyIgnoreListComponent,
        addIgnoreComponentList: this.props.addIgnoreComponentList,
        removeIgnoreComponentList: this.props.removeIgnoreComponentList,
      },
      panelRoutes: {
        dataTable: {
          selectedRoutes: this.props.selectedRoutes,
          routeColumns,
          routesRecords: this.props.record.routes,
          routesOrder: 0,
          routesSortField: "",
          rows: 5,
          actions: {
            onSelectionChangeRoutes: this.props.onSelectionChangeRoutes,
            onFilterRoutes: () => { console.log('OnFilterRoutes') },
            onSortRoutes: () => { console.log('onSortRoutes') }
          }
        },
        toolbar: {
          hint: {
            add: "Adicionar rota",
            remove: "Remover rota",
            duplicate: "Duplicar rota",
          },
          disabled: {
            duplicate: !(this.props.selectedRoutes && this.props.selectedRoutes.length > 0),
          },
          onAdd: this.dialogAddRouteOnOpen,
          onRemove: this.onRemoveSelectedRoutes,
          onDuplicate: this.dialogDuplicateRouteOnOpen,
        }
      },
      panelComponentsByRote: {
        table: {
          selection: this.props.selectedSerie,
          ranges: this.props.currentRanges,
          columns: [(<Column key="selectorColumn" selectionMode="single" style={{ width: '3em' }} />), (<Column key="start" field="start" header="Valor inicial" />), (<Column key="start" field="end" header="Valor final" />)],
          actions: {
            onChangeSelection: this.onSelectedSerieChange
          }
        },
        indexSelectedRange: this.props.indexSelectedRange,
        selectedRange: this.props.selectedRange,
        onSelectionRangeChange: this.onSelectionRangeChange,
        toolbar: {
          hint: {
            add: "Adicionar faixas",
            remove: "Remover faixas"
          },
          onAdd: this.dialogAddRangeOnOpen,
          onRemove: this.onRemoveSelectedRange,
        },
        panelSeries: {
          toolbar: {
            hint: {
              edit: "Editar série",
              remove: "Remover série"
            },
            disabled: {
              edit: !this.props.selectedSerie,
              remove: !this.props.selectedSerie,
            },
            onEdit: this.dialogEditSerieOnOpen,
            onRemove: this.onRemoveSelectedSerie,
          },
          dialogEditSerie: {
            start: this.props.selectedSerie ? this.props.selectedSerie.start : 0,
            end: this.props.selectedSerie ? this.props.selectedSerie.end : 0,
            header: "Editar série",
            ...this.props.dialogEditSerie,
            actions: {
              onHide: this.dialogEditSerieOnCancel,
              onConfirm: this.dialogEditSerieOnConfirm,
            }
          }
        },
        panelComponents: {
          toolbar: {
            hint: {
              add: "Adicionar Componentes",
            },
            onAdd: this.showDialogAddComponentIntoSelectedSerie,
            header: "Componentes por faixa"
          },
          componentsParams: this.props.selectedSerie && this.props.selectedSerie.components ?
            this.props.selectedSerie.components.map(component => {
              return {
                component,
                listOperations: this.props.listOperations,
                actions: {
                  onRemove: this.onRemoveComponentFromSelectedSerie,
                  onChange: this.onChangeComponentFromSelectedSerie,
                  // adicionar as ações do (novo) componente por valor calculado aqui
                  onChangeOperationHandler: this.onChangeOperationHandlerRouteComponent,
                  onRemoveItem: this.onRemoveCompositionRouteComponent,
                  onAddComposition: this.onAddCompositionRouteComponent,
                },
                calculatedComponent: {
                  columnsComposition,
                  actions: {}
                }
              }
            }) : [],
          dialogAddComponentSelectedSerie: {
            dialog: {
              header: "Adicionar Componente",
              visible: this.props.dialogAddComponentSelectedSerie.visible,
              modal: this.props.dialogAddComponentSelectedSerie.modal,
              confirmButtonDisabled: false,
              addButtonDisabled: false,
              actions: {
                onHide: this.dialogAddComponentSelectedSerieOnHide,
                onConfirm: this.dialogAddComponentSelectedSerieOnConfirm,
                onAdd: this.dialogAddComponentSelectedSerieOnAdd,
              }
            },
            lookup: {
              title: "Componentes de Frete",
              editDisabled: this.props.lookupComponent.editDisabled,
              visible: this.props.lookupComponent.visible,
              modal: false,
              header: this.props.lookupComponent.header,
              records: this.props.lookupComponent.records,
              columns: this.props.lookupComponent.columns,
              order: this.props.lookupComponent.order,
              sortField: this.props.lookupComponent.sortField,
              first: this.props.lookupComponent.first,
              rows: this.props.lookupComponent.rows,
              totalRecords: this.props.lookupComponent.totalRecords,
              value: this.props.currentComponent,
              placeholder: "Digite o nome do componente",
              name: "name",
              field: "name",
              actions: {
                onInputFilter: this.lookupComponentOnInputFilter,
                onClick: this.lookupComponentOnClick,
                onHide: this.lookupComponentOnHide,
                onConfirm: this.lookupComponentOnConfirm,
                onCancel: this.lookupComponentOnCancel,
                onPage: this.lookupComponentOnPage,
                onFilter: this.lookupComponentOnFilter,
                onSort: this.lookupComponentOnSort
              }
            },
          },

        },
        dialog: {
          header: "Faixas",
          visible: this.props.dialogRangesProps.visible,
          modal: this.props.dialogRangesProps.modal,
          confirmDisabled: this.props.dialogRangesProps.confirmDisabled,
          addDisabled: this.props.dialogRangesProps.addDisabled,
          actions: {
            onHide: () => {
              this.props.setDialogRangeVisible(false)
            },
            onConfirm: this.dialogAddRangeOnConfirm,
            onAdd: this.dialogAddRangeOnAdd,
            onCancel: this.dialogAddRangeOnCancel

          },
          select: {
            currentType: this.props.selectedRange ? this.props.selectedRange.rangeType : null,
            series: this.props.selectedRange ? this.props.selectedRange.series : [],
            types: [
              { label: i18n.getValue('WEIGHT'), value: 'WEIGHT' },
              { label: i18n.getValue("EXCESS_WEIGHT"), value: 'EXCESS_WEIGHT' },
              { label: i18n.getValue("PRODUCT_VALUE"), value: 'VALUE' },
              { label: i18n.getValue("CUBAGE"), value: 'CUBAGE' },
              { label: i18n.getValue("EXCESS_CUBAGE"), value: 'EXCESS_CUBAGE' },
              { label: i18n.getValue("QUANTITY_ITEMS"), value: 'QUANTITY_ITEMS' },
              { label: i18n.getValue("COST_FREIGHT"), value: 'COST_FREIGHT' },
              { label: i18n.getValue('KILOMETER'), value: 'KILOMETER' },
              { label: i18n.getValue("EXCESS_KILOMETER"), value: 'EXCESS_KILOMETER' },
            ],
          },
          table: {
            columns: [(<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />), (<Column key="start" field="start" header="Valor inicial" />), (<Column key="start" field="end" header="Valor final" />)]
          }
        },
      },
      panelRestrictionByComponent: {
        dataTable: {
          currentSelectedComponent: this.props.currentSelectedComponent,
          restrictionColumns,
          restrictionsRecords: this.props.record.restrictions,
          restrictionsOrder: 0,
          restrictionsSortField: "",
          rows: 5,
          actions: {
            onSelectionChangeRestrictionsComponents: this.props.onSelectionChangeRestrictionsComponents,
            onFilterRestrictions: () => { console.log('onFilterRestrictions') },
            onSortRestrictions: () => { console.log('onSortRestrictions') },
          }
        },
        dialogAddRestrictionsOpen: this.props.dialogAddRestrictionsOnOpen,
        dialogEditRestrictionsOpen: this.props.dialogEditRestrictionsOpen,
        disabled: this.props.restrictionsDisabled,
        editButtonDisabled: this.props.panelRestrictionByComponent.editButtonDisabled,
      },
      panelClientsByComponentRestriction: {
        showPanelClientsByComponentRestriction: this.props.currentSelectedComponent && this.props.currentSelectedComponent.clients ? true : false,
        dataTable: {
          selectedComponents: [],
          clientsByComponentRestrictioncolumns,
          records: this.props.currentSelectedComponent && this.props.currentSelectedComponent.clients ? this.props.currentSelectedComponent.clients : [],
          Order: 0,
          SortField: "",
          rows: 5,
          actions: {
            onSelectionChangeClients: () => { console.log('onSelectionChangeClients') },
            onFilterClients: () => { console.log('onFilterClients') },
            onSortClients: () => { console.log('onSortClients') },
          }
        },
      },
      panelLocationsByComponentRestriction: {
        showPanelLocationsByComponentRestriction: this.props.currentSelectedComponent && this.props.currentSelectedComponent.locations && this.props.currentSelectedComponent.locations ? true : false,
        dataTable: {
          selectedComponents: [],
          locationsByComponentRestrictioncolumns,
          records: this.props.currentSelectedComponent && this.props.currentSelectedComponent.locations ? this.props.currentSelectedComponent.locations : [],
          Order: 0,
          SortField: "",
          rows: 5,
          actions: {
            onSelectionChangeLocations: () => { console.log('onSelectionChangeLocations') },
            onFilterLocations: () => { console.log('onFilterLocations') },
            onSortLocations: () => { console.log('onSortLocations') },
          }
        },
      },
      panelBusinessUnitByComponentRestriction: {
        showPanelUnitByComponentRestriction: this.props.currentSelectedComponent && this.props.currentSelectedComponent.units && this.props.currentSelectedComponent.units ? true : false,
        dataTable: {
          selectedComponents: [],
          unitsByComponentRestrictioncolumns,
          records: this.props.currentSelectedComponent && this.props.currentSelectedComponent.units ? this.props.currentSelectedComponent.units : [],
          Order: 0,
          SortField: "",
          rows: 5,
          actions: {
            onSelectionChangeUnits: () => { console.log('onSelectionChangeUnits') },
            onFilterUnits: () => { console.log('onFilterUnits') },
            onSortUnits: () => { console.log('onSortUnits') },
          }
        },
      },
      /* Dialog que abre quando tentar adicionar clientes ou localizações para os componentes com restrição */
      dialogAddRestrictions: {
        visible: this.props.dialogRestrictions.visible,
        actions: {
          dialogAddRestrictionsClose: this.props.dialogAddRestrictionsOnClose,
          dialogAddRestrictionsOnConfirm: this.props.dialogAddRestrictionsOnConfirm,
          componentsRestrictionOnChange: this.props.componentsRestrictionOnChange,
          showImportFileXLSX: this.showImportFileXLSX,
        },
        dialogComponentName: this.props.dialogComponentName,
        showlookupClient: this.props.dialogRestrictions.showlookupClient,
        showlookupLocation: this.props.dialogRestrictions.showlookupLocation,
        showLookupUnit: this.props.dialogRestrictions.showLookupUnit,

        componentsRestriction: this.props.dialogRestrictions.componentsRestriction,
        currentComponentRestriction: this.props.dialogRestrictions.currentComponentRestriction,
      },
      lookupClient: {
        records: this.props.lookupClient.records,
        columns: this.props.lookupClient.columns,
        currentRecord: this.props.lookupClient.record,
        visible: this.props.lookupClient.visible,
        modal: this.props.lookupClient.modal,
        tableRecords: this.props.lookupClient.tableRecords,
        tableColumns: this.props.lookupClient.tableColumns,
        selectedRecords: this.props.lookupClient.selectedRecords,
        searchText: this.props.lookupClient.searchText,
        lookupClientOnClick: this.props.lookupClientLoad,
        lookupClientOnSelectClient: this.props.lookupClientOnSelectClient,
        lookupClientOnConfirm: this.props.lookupClientOnConfirm,
        lookupClientOnCancel: this.props.lookupClientOnCancel,
        lookupClientOnHide: this.props.lookupClientOnCancel,
        lookupClientOnRemoveSelected: this.props.lookupClientOnRemoveSelected,
        lookupClientOnFilter: this.lookupClientOnFilter,
        // Metodo abaixo chamando quando lookup esta fechado
        lookupClientOnInputFilter: this.lookupClientOnInputFilter,
        lookupClientOnPage: () => { console.log('lookupClientOnPage') },
        lookupClientOnOnSort: () => { console.log('lookupClientOnOnSort') },
        lookupClientOnComplete: () => { console.log('lookupClientOnComplete') },
        lookupClientOnEdit: () => { console.log('lookupClientOnEdit') },
      },
      lookupLocation: {
        records: this.props.lookupLocation.records,
        columns: this.props.lookupLocation.columns,
        visible: this.props.lookupLocation.visible,
        modal: this.props.lookupLocation.modal,
        header: this.props.lookupLocation.header,
        tableColumns: this.props.lookupLocation.tableColumns,
        selectedRecords: this.props.lookupLocation.selectedRecords,
        tableRecords: this.props.lookupLocation.tableRecords,
        searchText: "",
        lookupLocationOnHide: this.props.lookupLocationOnCancel,
        lookupLocationOnCancel: this.props.lookupLocationOnCancel,
        lookupLocationOnClick: this.props.lookupLocationLoad,
        lookupLocationOnSelectLocation: this.props.lookupLocationOnSelectLocation,
        lookupLocationOnConfirm: this.props.lookupLocationOnConfirm,
        lookupLocationOnRemoveSelected: this.props.lookupLocationOnRemoveSelected,
        lookupLocationOnFilter: this.lookupLocationOnFilter
      },
      lookupUnitRestricted: {
        records: this.props.lookupRestrictedUnit.records,
        columns: this.props.lookupRestrictedUnit.columns,
        visible: this.props.lookupRestrictedUnit.visible,
        modal: this.props.lookupRestrictedUnit.modal,
        header: this.props.lookupRestrictedUnit.header,
        tableColumns: this.props.lookupRestrictedUnit.tableColumns,
        tableRecords: this.props.lookupRestrictedUnit.tableRecords,
        selectedRecords: this.props.lookupRestrictedUnit.selectedRecords,
        searchText: "",
        lookupUnitRestrictedOnHide: this.lookupUnitRestrictedOnHide,
        lookupUnitRestrictedOnCancel: this.lookupUnitRestrictedOnCancel,
        lookupUnitRestrictedOnClick: this.lookupUnitRestrictedOnClick,
        lookupUnitRestrictedOnSelectUnit: this.lookupRestrictedUnitOnSelectUnits,
        lookupUnitRestrictedOnConfirm: this.lookupUnitRestrictedOnConfirm,
        lookupUnitRestrictedOnRemoveSelected: this.lookupUnitRestrictedOnRemoveSelected,
        lookupUnitRestrictedOnFilter: this.lookupUnitRestrictedOnFilter,
      }
    }

    const title = `Tabela de Frete ${this.props.record.oid || 'novo'}`
    const filePath = '/resources/templates/tabela_frete_template.xlsx'
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler}
            saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}
            onImportXLSX={(this.props.record.carrier && this.props.record.carrier.oid) ? this.importInforTabelaFreteXLSX : null}
            XLSXTemplatePath={filePath}
            onShowHistoryLog={this.props.record.oid ? this.onClickOpenHistoryLog : null}>
          </Toolbar>
        </div>
      </div>
    );

    const content = (
      <>
        <FreightTableForm
          {...freighTableFormParams}
        />
        {this.state.isVisibleHistorylog && this.props.record.oid &&
          <HistoryLogListPage docID={this.props.record.oid} resourceType={"lxFreightTable"} onHide={this.onHideHistorylog} />
        }
        <LxDialogStatusImportXlsRoutes dialog={this.props.dialogStatusImportXlsRoutes} onCloseDialog={this.onCloseDialogImportXls} />
        {this.props.dialogStatusImportXlsTaxaMultValores &&
          <LxDialogStatusImportTaxaMutCompXls dialog={this.props.dialogStatusImportXlsTaxaMultValores} onCloseDialog={()=>this.props.showDialogStatusImportXlsTaxaMultValores(null)} />
        }
      </>
    );

    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FreightTableFormPageContainer);