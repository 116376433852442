import axios from 'axios';
import storage from './stoage.service';
import store from '../store';
import history from '../history';
import { LensTwoTone } from '@material-ui/icons';
import moment from 'moment';

axios.defaults.timeout = 60 * 1000 * 5; //timeout in miliseconds

const getHeaders = () => {
    const appState = store.getState().appState;
    const user = storage.getUser();
    
    let groupname=""
    if(user && user.groups && user.groups.length>0){
        groupname =user.groups[0].name 
        groupname +=":"+user.company
    } 
   
    let companyId = "-1";
    if(appState.currentCompany){
        if(appState.currentCompany.CNPJ){
            companyId = appState.currentCompany.CNPJ
        }else if(appState.currentCompany.documentNumber){
            companyId = appState.currentCompany.documentNumber
        }
    } 
    let unitId = "0";
    if(appState.currentUnit){
        if(appState.currentUnit.cnpj){
            unitId = appState.currentUnit.cnpj
        }else if(appState.currentUnit.documentNumber){
            unitId = appState.currentUnit.documentNumber
        }
    } 
    var timeZone =  Intl.DateTimeFormat().resolvedOptions().timeZone 
    const headers = {
        "Content-type": "application/json",
        "accept": "application/json",
        "companyId":companyId, 
        "unitId": unitId,
        "user" : appState.currentUser,
        "appid": storage.getResponseID() || "-1",
        "groupname": groupname,
        "timezone":timeZone,//usado em relatorios, para quem usa o TMS em fuso horario diferentes
    }
    const token = storage.getToken();
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }


    return headers;
}


const get = (path, costumHeaders = {}) => {
    return axios.get(path, { headers: Object.assign(getHeaders(), costumHeaders) })
        .then(proccessSuccess)
        .catch(proccessError)
}
function sleep(time){
    return new Promise((resolve,reject)=>{
        window.setTimeout(()=>{
            resolve()
        },time)
    })
}

const post = async(path, data, costumHeaders = {},customOptions = {}) => {
    let headers = {...getHeaders(), ...costumHeaders}
    let colectOrdem = path.includes("portal/collectionorder");
    let isTracecode = path.includes("/portal/rastreardelivery/tracecode")
    if(path != '/api/v1/lognex/login' &&  !colectOrdem && !isTracecode){
        while(!headers.companyId || headers.companyId.length!=14){
            await sleep(500); 
            headers = {...getHeaders(), ...costumHeaders}  
        }
    } 
    const options = {...customOptions,headers}
    return axios.post(path, data, options)
        .then(proccessSuccess)
        .catch(proccessError)
}

const put = (path, data) => {
    return axios.put(path, data, { headers: getHeaders() })
        .then(proccessSuccess)
        .catch(proccessError)
}

const remove = (path, data) => {
    return axios.delete(path, { headers: getHeaders() })
        .then(proccessSuccess)
        .catch(proccessError)
}

const uploadFiles = (path,files,headers={},body={})=>{
    if(files){
        headers = Object.assign({}, getHeaders(),headers,{"Content-type": "multipart/form-data"})
        const formData = new FormData();
        for (let index = 0; index < files.length;index++){
            formData.append(`files[${index}]`,files[index]);
        }
        formData.append("body",body);
        return axios.post(path,formData,{headers,timeout:60*5*1000})
    }
    return Promise.reject({errorMessage:"No Files to send"})
    

}

function proccessSuccess(response) {
    storage.setResponseID(response.headers['appid'])
    return Promise.resolve(response);
}

function proccessError(err) {
    if(err && err.response && err.response.headers){
        storage.setResponseID( err.response.headers['appid'] )
    }
   if(err && err.response && err.response.status === 401){
       storage.clear();
       history.push('/login')

   }
    return Promise.reject(err);
}

export default {
    get,
    post,
    put,
    remove,
    uploadFiles,
    getHeaders
}