import React from 'react';
import { Column } from 'primereact/column';
import { connect } from 'react-redux';
import { Page } from '../../../../components/page/Page';
import Toolbar from '../../../../components/toolbar/Toolbar';
import { actions } from '../cargo-shipment.actions';
import { routes } from '../../../../constants';
import { actions as carrierActions } from '../../../carrier/carrier.actions';
import { actions as freightCalculationActions } from '../../../freightCalculation/freightCalculation.actions';
import { actions as driverActions } from '../../../driver/driver.actions';
import { actions as vehicleTypeActions } from '../../../vehicleType/vehicleType.actions';
import * as utils from '../../../../utils';
import * as IconFactory from '../../../../services/icons.factory'
import moment from "moment";

import { CargoShipmentForm } from './CargoShipmentForm';
import { HistoryLogListPage } from '../../../history-log-component';

const kgTemplate = (rowData, column) => {
    return (
        <span style={{ display: "inline-block", width: "100%", textAlign: "right" }}>{utils.formatNumber(rowData.cargo.summary.weight, 3)} KG</span>
    )
}
const valueTemplate = (rowData, column) => {
    return (
        <span style={{ display: "inline-block", width: "100%", textAlign: "right" }}>{utils.formatNumber(rowData.cargo.summary.value)}</span>
    )
}
const valueColTemplate = (rowData, column) => {
    return (
        <span style={{ display: "inline-block", width: "100%", textAlign: "right" }}>{utils.formatNumber(rowData[column.field])}</span>
    )
}
const shipmentCodeTemplate = (rowData, column) => {
    return (
        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>{rowData.customFields.shipmentCode || ""}</span>
    )
}
const dateTimeTemplate = (rowData, column) => {
    const date = rowData[column.field];
    let formattedDate = moment(date).utc().format("DD/MM/YYYY HH:mm");
    return <span style={{ textAlign: "center" }}>{formattedDate}</span>;
};
const bodyIsAgTemplate = (rowData, column) => {
    let isAg = ""
    if (rowData[column.field]) {
        isAg = "AG"
    }

    return <span style={{ textAlign: "center" }}>{isAg}</span>;
};

const bodyNroNfeTemplate = (rowData, column) => {
    let nroNfe = rowData[column.field]

    return <span style={{ textAlign: "center" }}>{nroNfe}</span>;
};


function mapStateToProps(state) {

    const { crudCargoShipmentState, crudCarrierState, crudDriverState, crudVehicleTypeState, freightCalculationState } = state;
    const record = crudCargoShipmentState.currentRecord;
    const orderColumns = crudCargoShipmentState.orderColumns;
    const occurrenceColumns = crudCargoShipmentState.occurrenceColumns;
    const lookupCarrierBase = {
        records: crudCarrierState.records,
        columns: crudCarrierState.columns
            .filter(col => col.field === 'oid' || col.field === 'name' || col.field === 'tradeName')
            .map(col => {
                col = { ...col, sortable: false }
                return (<Column key={col.field} {...col} />)
            }),
        first: crudCarrierState.index,
        rows: crudCarrierState.max,
        totalRecords: crudCarrierState.count,
        sortField: crudCarrierState.sortField,
        order: crudCarrierState.sortOrder,
        editDisabled: !record.carrier
    }
    let lookupCarrier = { ...state.crudCargoShipmentState.lookupCarrier, ...lookupCarrierBase };


    const lookupDriverBase = {
        records: crudDriverState.records,
        columns: crudDriverState.columns
            .filter(col => col.field === 'oid' || col.field === 'name')
            .map(col => {
                col = { ...col, sortable: false }
                return (<Column key={col.field} {...col} />)
            }),
        first: crudDriverState.index,
        rows: crudDriverState.max,
        totalRecords: crudDriverState.count,
        sortField: crudDriverState.sortField,
        order: crudDriverState.sortOrder,
        editDisabled: !record.driver
    }

    let lookupDriver = { ...state.crudCargoShipmentState.lookupDriver, ...lookupDriverBase }


    const lookupVehicleTypeBase = {
        records: crudVehicleTypeState.records,
        columns: crudVehicleTypeState.columns
            .filter(col => col.field === 'oid' || col.field === 'name' || col.field === 'code')
            .map(col => {
                col = { ...col, sortable: false }
                return (<Column key={col.field} {...col} />)
            }),
        first: crudVehicleTypeState.index,
        rows: crudVehicleTypeState.max,
        totalRecords: crudVehicleTypeState.count,
        sortField: crudVehicleTypeState.sortField,
        order: crudVehicleTypeState.sortOrder,
        editDisabled: !record.vehicleType
    }

    let lookupVehicleType = { ...state.crudCargoShipmentState.lookupVehicleType, ...lookupVehicleTypeBase }


    const lookupOrderBase = {
        isMultiple: true,
        records: crudCargoShipmentState.orders,
        columns: orderColumns
            .map(col => {
                col = { ...col, sortable: false }
                if (col.field === "cargo.summary.weight") {
                    return (<Column key={col.field} {...col} body={kgTemplate} />)
                }
                if (col.field === "cargo.summary.value") {
                    return (<Column key={col.field} {...col} body={valueTemplate} />)
                }
                return (<Column key={col.field} {...col} />)
            }),
    }

    let lookupOrder = { ...state.crudCargoShipmentState.lookupOrder, ...lookupOrderBase }

    const status = state.crudCargoShipmentState.currentRecord.status === "NEW" && state.crudCargoShipmentState.currentRecord.situation != "INVALID";
    const disableLookupVehicle = status;
    const disableLookupCarrier = status;
    const disableLookupDriver = status;
    let disableAddToolbarButton = status;
    let disableRemoveToolbarButton = status;
    const disabledForm = status;

    const quotesColumns = freightCalculationState.tableQuotes.columns
    const quotesRecords = state.freightCalculationState.tableQuotes.records.map(record => {
        const data = { ...record }
        data.carrierName = record && record.carrier ? record.carrier.name : "";
        return data
    })

    //libera o botao remover delivery quando for AG e o o embarque ja tiver sido aceito 
    if ((state.crudCargoShipmentState.currentRecord.status == "APROVED" || state.crudCargoShipmentState.currentRecord.status == "TRAVELING" 
    || state.crudCargoShipmentState.currentRecord.status == "DELIVERED")
        && state.crudCargoShipmentState.currentRecord.situation != "INVALID"
        && crudCargoShipmentState.selectedOrders) {
        const orderSelected = crudCargoShipmentState.selectedOrders[0]
        if (orderSelected && orderSelected.ag == true) {
            disableRemoveToolbarButton = true;
        }
    }
    let isRemoveEmbarque=false 
    if (record.status === "NEW" || record.status === "APROVED"||  record.status === "TRAVELING"  || record.status == "DELIVERED"){ 
        isRemoveEmbarque=true 
    } 

    return {
        selectedOrders: crudCargoShipmentState.selectedOrders,
        record,
        lookupCarrier,
        lookupDriver,
        lookupVehicleType,
        lookupOrder,
        orderColumns,
        occurrenceColumns,
        disabledForm,
        disableLookupVehicle,
        disableLookupCarrier,
        disableLookupDriver,
        disableAddToolbarButton,
        disableRemoveToolbarButton,
        isRemoveEmbarque,
        rows: state.crudCargoShipmentState.max,
        first: state.crudCargoShipmentState.first,
        totalRecords: state.crudCargoShipmentState.count,
        sortField: state.crudCargoShipmentState.sortField,
        sortOrder: state.crudCargoShipmentState.sortOrder,
        totalWeight: state.crudCargoShipmentState.cards.totalWeight ? state.crudCargoShipmentState.cards.totalWeight : 0,
        totalValue: state.crudCargoShipmentState.cards.totalValue ? state.crudCargoShipmentState.cards.totalValue : 0,
        totalFreight: state.crudCargoShipmentState.cards.totalFreight ? state.crudCargoShipmentState.cards.totalFreight : 0,
        totalWeightVehicle: state.crudCargoShipmentState.cards.totalWeightVehicle ? state.crudCargoShipmentState.cards.totalWeightVehicle : 0,
        freightQuotesRecords: state.crudCargoShipmentState.freightQuotesRecords,
        quotesColumns,
        quotesRecords,
        deliveryStoColumns: crudCargoShipmentState.deliveryStoColumns,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
        sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
        loadCargoShipmentById: (oid) => dispatch(actions.loadCargoShipmentById(oid)),
        saveHandler: event => {
            dispatch(actions.save());
        },
        removeHandler: event => {
            dispatch(actions.remove());
        },
        setCarrier: (carrier) => {
            const { name, documentNumber, branches } = carrier;
            const data = {
                name,
                cnpj: documentNumber,
                code: carrier.code || "",
                email: carrier.email || "",
                ownCarrier: carrier.ownCarrier || false
            }
            dispatch(actions.setField("carrier", data));
        },
        lookupCarrierApplyFilter: (filters) => {
            dispatch(carrierActions.setType(null));
            dispatch(carrierActions.applyFilter(filters))
        },
        lookupCarrierSetVisible: (visible) => {
            dispatch(actions.lookupCarrierSetVisible(visible));
        },
        lookupCarrierSortOrder: (params) => {
            dispatch(carrierActions.sortOrder(params));
        },
        lookupCarrierPaginate: (params) => {
            dispatch(carrierActions.paginate(params));
        },
        /* DRIVER */

        setDriver: (driver) => {
            const { documentNumber, name } = driver
            const driverSelected = {
                "documentNumber": documentNumber,
                "name": name
            }
            dispatch(actions.setField("driver", driverSelected));
        },
        lookupDriverApplyFilter: (filters) => {
            dispatch(driverActions.setType(null));
            dispatch(driverActions.applyFilter(filters))
        },
        lookupDriverSetVisible: (visible) => {
            dispatch(actions.lookupDriverSetVisible(visible));
        },
        lookupDriverSortOrder: (params) => {
            dispatch(driverActions.sortOrder(params));
        },
        lookupDriverPaginate: (params) => {
            dispatch(driverActions.paginate(params));
        },

        /* VEHICLE TYPE */
        setVehicleType: (vehicleType) => {
            const { name } = vehicleType
            const vehicleSelected = {
                "name": name
            }
            dispatch(actions.setField("vehicle.vehicleType", vehicleSelected));
        },
        lookupVehicleTypeApplyFilter: (filters) => {
            dispatch(vehicleTypeActions.setType(null));
            dispatch(vehicleTypeActions.applyFilter(filters))
        },
        lookupVehicleTypeSetVisible: (visible) => {
            dispatch(actions.lookupVehicleTypeSetVisible(visible));
        },
        lookupVehicleTypeSortOrder: (params) => {
            dispatch(vehicleTypeActions.sortOrder(params));
        },
        lookupVehicleTypePaginate: (params) => {
            dispatch(vehicleTypeActions.paginate(params));
        },
        setField: (field, value) => {
            dispatch(actions.setField(field, value));
        },
        setSelectedOrders: (orders) => {
            dispatch(actions.setSelectedOrders(orders));
        },
        onRemoveSelectedOrder: () => {
            dispatch(actions.removeSelectedOrders());
            dispatch(actions.calcCards());
        },

        /* LOOKUP ORDER */
        setLookupOrderVisible: (visible) => {
            dispatch(actions.setLookupOrderVisible(visible))
        },
        loadOrders: () => {
            dispatch(actions.loadOrders());
        },
        apllyOrderFilters: (filters) => {
            dispatch(actions.setOrderFilters(filters));
        },
        addOrders: (orders) => {
            dispatch(actions.addOrders(orders));
        },

        calcCards: () => {
            dispatch(actions.calcCards());
        },

        loadQuotes: (params) => {
            dispatch(freightCalculationActions.apiCalculateFreight(params));
        },

        clearfreightCalculationQuotes: () => {
            dispatch(freightCalculationActions.clearQuotes([]));
        }

    }
}

class CargoShipmentFormPageContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isVisibleHistorylog: false,
            isVisibleDeliveryReceipt: false,
            isVisibleModalFreightQuote: false,
            spin: false,
            currentImagePath: '',
            countRotation: 0,
        }
    }

    componentDidMount() {
        const oid = this.props.match.params.id;
        if (oid && parseInt(oid)) {
            this.props.loadCargoShipmentById(oid);
        }
        this.props.loadOrders();
        this.props.calcCards();
    }

    searchHandler = () => {
        this.props.history.push(`${routes.PATH_ORDER_MANAGEMENT}`)
    }

    lookupCarrierOnInputFilter = (e) => {
        const value = e.value;
        let filters = []
        if (typeof value === 'string') {
            filters.push({
                campo: "name",
                valor: value,
                tipoFiltro: 'CONTEM'
            });
            this.props.setCarrier({ name: value });
        } else {
            this.props.setCarrier(value);
        }
        this.props.lookupCarrierApplyFilter(filters);
    }
    lookupCarrierOnClick = () => {
        const filters = [];
        this.props.lookupCarrierPaginate({
            max: 15,
            index: 0
        });
        this.props.lookupCarrierApplyFilter(filters);
        this.props.lookupCarrierSetVisible(true);

    }
    lookupCarrierOnHide = () => {
        this.props.lookupCarrierSetVisible(false);
    }
    lookupCarrierOnConfirm = (event) => {
        this.props.setCarrier(event.selection);
        this.props.lookupCarrierSetVisible(false);
    }
    lookupCarrierOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'
            });
        });
        this.props.lookupCarrierApplyFilter(filters);
    }
    lookupCarrierOnSort = (e) => {
        this.props.lookupCarrierSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        });
    }
    lookupCarrierOnPage = (e) => {
        this.props.lookupCarrierPaginate({
            max: e.rows,
            index: e.first
        });
    }

    /* DRIVER */
    lookupDriverOnInputFilter = (e) => {
        const value = e.value;
        let filters = []
        if (typeof value === 'string') {
            filters.push({
                campo: "name",
                valor: value,
                tipoFiltro: 'CONTEM'
            });
            this.props.setDriver({ name: value });
        } else {
            this.props.setDriver(value);
        }
        this.props.lookupDriverApplyFilter(filters);
    }
    lookupDriverOnClick = () => {
        const filters = [];
        this.props.lookupDriverPaginate({
            max: 15,
            index: 0
        });
        this.props.lookupDriverApplyFilter(filters);
        this.props.lookupDriverSetVisible(true);

    }
    lookupDriverOnHide = () => {
        this.props.lookupDriverSetVisible(false);
    }
    lookupDriverOnConfirm = (event) => {
        this.props.setDriver(event.selection);
        this.props.lookupDriverSetVisible(false);
    }
    lookupDriverOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'
            });
        });
        this.props.lookupDriverApplyFilter(filters);
    }
    lookupDriverOnSort = (e) => {
        this.props.lookupDriverSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        });
    }
    lookupDriverOnPage = (e) => {
        this.props.lookupDriverPaginate({
            max: e.rows,
            index: e.first
        });
    }

    /** VEHICLE TYPE */
    lookupVehicleTypeOnInputFilter = (e) => {
        const value = e.value;
        let filters = []
        if (typeof value === 'string') {
            filters.push({
                campo: "name",
                valor: value,
                tipoFiltro: 'CONTEM'
            });
            this.props.setVehicleType({ name: value });
        } else {
            this.props.setVehicleType(value);
        }
        this.props.lookupVehicleTypeApplyFilter(filters);
    }
    lookupVehicleTypeOnClick = () => {
        const filters = [];
        this.props.lookupVehicleTypePaginate({
            max: 15,
            index: 0
        });
        this.props.lookupVehicleTypeApplyFilter(filters);
        this.props.lookupVehicleTypeSetVisible(true);

    }
    lookupVehicleTypeOnHide = () => {
        this.props.lookupVehicleTypeSetVisible(false);
    }
    lookupVehicleTypeOnConfirm = (event) => {
        this.props.setVehicleType(event.selection);
        this.props.lookupVehicleTypeSetVisible(false);
    }
    lookupVehicleTypeOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'
            });
        });
        this.props.lookupVehicleTypeApplyFilter(filters);
    }
    lookupVehicleTypeOnSort = (e) => {
        this.props.lookupVehicleTypeSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        });
    }
    lookupVehicleTypeOnPage = (e) => {
        this.props.lookupVehicleTypePaginate({
            max: e.rows,
            index: e.first
        });
    }


    onChangeHandler = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.setField(name, value);
    }

    ordersOnSelectionChange = (event) => {
        this.props.setSelectedOrders(event.value);
    }


    /* LOOKUP ORDER */

    lookupOrderOnHide = () => {
        this.props.setLookupOrderVisible(false);
        this.props.apllyOrderFilters([]);
    }

    lookupOrderOnConfirm = (event) => {
        this.props.addOrders(event.selection);
        this.props.setLookupOrderVisible(false);
        this.props.apllyOrderFilters([]);
        this.props.calcCards();

    }
    dialogAddOrderShow = () => {
        this.props.apllyOrderFilters([]);
        this.props.loadOrders();
        this.props.setLookupOrderVisible(true);
    }

    occurrencesOnPage = e => {
        this.props.paginate({
            max: e.rows,
            index: e.first
        });
    };
    occurrencesOnSort = e => {
        this.props.sortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        });
    };

    onClickOpenHistoryLog = () => {
        this.setState({
            isVisibleHistorylog: true
        })
    }
    onHideHistorylog = () => {
        this.setState({
            isVisibleHistorylog: false
        })
    }

    showDeliveryReceiptOnClick = (visible) => {
        this.setState({
            isVisibleDeliveryReceipt: visible
        });
    }

    onShowModalFreightQuoteOnClick = (visible) => {
        this.setState({
            isVisibleModalFreightQuote: visible
        });
    }

    onShowFreightQuote = () => {
        this.onLoadQuotes();
        this.onShowModalFreightQuoteOnClick(true);
    }

    changeTransiction = () => {
        let count = this.state.countRotation;
        let cssClassName = 'rotate-image';
        if (count == 0) {
            cssClassName = cssClassName + ' spin';
            count++;
        } else if (count == 1) {
            cssClassName = cssClassName + ' spin180';
            count++;
        } else if (count == 2) {
            cssClassName = cssClassName + ' spin360';
            count = 0;
        }

        this.setState({ countRotation: count })

        this.setState((prevState) => {
            const spin = !prevState.spin;
            return {
                spin,
                image: (<img className={cssClassName} src={prevState.currentImagePath} />)
            }
        })
    }

    setCurrentImage = (image) => {
        const path = `${image}`//data:image/jpeg;base64,
        const spin = !!this.state.spin;
        image = (<img className={utils.classNames({ 'rotate-image': true, 'spin': spin })} src={path} />)
        this.setState({
            spin,
            currentImagePath: path,
            image
        });
    }

    onLoadQuotes = () => {
        let weight = 0;
        let value = 0;
        this.props.record.orders.forEach((item) => {
            weight += item.cargo.summary.weight;
            value += item.cargo.summary.value;
        });

        let zipCodeDestination = this.props.record.destination.address.zipcode || "";
        zipCodeDestination = zipCodeDestination.replace("-", "");
        let zipCodeOrigin = this.props.record.origin.address.zipcode || ""
        zipCodeOrigin = zipCodeOrigin.replace("-", "");


        const body = {
            currentLocationTypeFilter: "",
            freightCalculationParams: {
                zipCodeDestination: parseInt(zipCodeDestination),
                zipCodeOrigin: parseInt(zipCodeOrigin),
                weight: parseFloat(weight),
                value: parseFloat(value),
                distance: parseFloat(this.props.record.distance),
                vehicleType: this.props.record.vehicle.vehicleType,
                clientCNPJ: null,
                deliveryNumbers: this.props.record.deliveryNumbers,
                shipper: this.props.record.shipper.documentNumber || "",
            },

        }
        this.props.loadQuotes(body);
    }


    render() {
        const title = `Embarque ${this.props.record.oid || 'novo'}`
        const header = (
            <div>
                <h1>{title}</h1>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar
                        onSave={this.props.saveHandler}
                        saveButtonStyle='highlighted'
                        onRemove={this.props.record.status && this.props.isRemoveEmbarque ? this.props.removeHandler : ""}
                        removeButtonStyle='danger'
                        onSearch={this.searchHandler}
                        onShowHistoryLog={this.props.record.oid ? this.onClickOpenHistoryLog : null}
                        onShowFreightQuote={this.props.record.oid ? this.onShowFreightQuote : null}
                        messageDelete="Atenção, o embarque deverá ser removido do ERP antes da remoção do embarque no TMS! Deseja continuar?"
                        counter={this.props.record.code && this.props.record.code.length > 0}
                    >

                    </Toolbar>
                </div>
            </div>
        );

        let orderColumns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />];
        orderColumns = [...orderColumns, ...this.props.orderColumns.map(col => {

            if (col.field === "cargo.summary.weight") {
                return (<Column key={col.field} {...col} body={kgTemplate} />)
            }
            if (col.field === "cargo.summary.value") {
                return (<Column key={col.field} {...col} body={valueTemplate} />)
            }
            if (col.field === "customFields.shipmentCode") {
                return (<Column key={col.field} {...col} body={shipmentCodeTemplate} />)
            }
            if (col.field === "apportionmentValue") {
                return (<Column key={col.field} {...col} body={valueColTemplate} />)
            }

            if (col.field === "deliveryForecast") {
                return (<Column key={col.field} {...col} body={dateTimeTemplate} />)
            }
            if (col.field === "ag") {
                return (<Column key={col.field} {...col} style={{ width: "60px" }} body={bodyIsAgTemplate} />)
            }
            if (col.field === "nfeNumber") {
                return (<Column key={col.field} {...col} style={{ width: "70px" }} body={bodyNroNfeTemplate} />)
            }
            return (<Column {...col} key={col.field} />)
        })];

        const lookupVehicleType = {
            fields: {
                editDisabled: true,
                ...this.props.lookupVehicleType,
            },
            actions: {
                onComplete: () => { },
                onInputFilter: this.lookupVehicleTypeOnInputFilter,
                onEdit: () => { },
                onClick: this.lookupVehicleTypeOnClick,
                onHide: this.lookupVehicleTypeOnHide,
                onConfirm: this.lookupVehicleTypeOnConfirm,
                onCancel: this.lookupVehicleTypeOnHide,
                onPage: this.lookupVehicleTypeOnPage,
                onFilter: this.lookupVehicleTypeOnFilter,
                onSort: this.lookupVehicleTypeOnSort,
                disabled: !this.props.disableLookupVehicle
            }
        }
        const lookupCarrier = {
            fields: {
                editDisabled: true,
                ...this.props.lookupCarrier,
            },
            actions: {
                onComplete: () => { },
                onInputFilter: this.lookupCarrierOnInputFilter,
                onEdit: () => { },
                onClick: this.lookupCarrierOnClick,
                onHide: this.lookupCarrierOnHide,
                onConfirm: this.lookupCarrierOnConfirm,
                onCancel: this.lookupCarrierOnHide,
                onPage: this.lookupCarrierOnPage,
                onFilter: this.lookupCarrierOnFilter,
                onSort: this.lookupCarrierOnSort,
                disabled: !this.props.disableLookupCarrier
            }
        }
        const lookupDriver = {
            fields: {
                editDisabled: true,
                ...this.props.lookupDriver,
            },
            actions: {
                onComplete: () => { },
                onInputFilter: this.lookupDriverOnInputFilter,
                onEdit: () => { },
                onClick: this.lookupDriverOnClick,
                onHide: this.lookupDriverOnHide,
                onConfirm: this.lookupDriverOnConfirm,
                onCancel: this.lookupDriverOnHide,
                onPage: this.lookupDriverOnPage,
                onFilter: this.lookupDriverOnFilter,
                onSort: this.lookupDriverOnSort,
                disabled: !this.props.disableLookupDriver
            }
        }

        const orders = {
            toolbar: {
                hint: {
                    add: "Adicionar pedido",
                    remove: "Remover pedido",
                },
                header: "Pedidos",
                onAdd: this.dialogAddOrderShow,
                onRemove: this.props.onRemoveSelectedOrder,
                disabledButtonOrders: {
                    add: !this.props.disableAddToolbarButton,
                    remove: !this.props.disableRemoveToolbarButton
                }
            },
            table: {
                dataTable: {
                    selection: this.props.selectedOrders ? this.props.selectedOrders : [],
                    value: this.props.record && this.props.record.orders ? this.props.record.orders : [],
                    sortOrder: 0,
                    sortField: "",
                    paginator: true,
                    selectionMode: "multiple",
                    rows: this.props.tableOrders && this.props.tableOrders.rows ? this.props.tableOrders.rows : 5,
                    onSelectionChange: this.ordersOnSelectionChange ? this.ordersOnSelectionChange : () => { console.log("datatable.ordersOnSelectionChange must be implemented") },
                    onFilter: this.ordersOnFilter ? this.ordersOnFilter : () => { console.log("ordersOnFilter must be implemented") },
                    onSort: this.ordersOnSort ? this.ordersOnSort : () => { console.log("ordersOnSort must be implemented") },
                    emptyMessage: "Não foram encontrados registros",
                    rowsPerPageOptions: [5, 10, 20, 50]
                },
                columns: [...orderColumns]
            }
        }
        const lookupOrder = {
            fields: {
                ...this.props.lookupOrder
            },
            actions: {
                onHide: this.lookupOrderOnHide,
                onConfirm: this.lookupOrderOnConfirm,
                onCancel: this.lookupOrderOnHide,
                onPage: this.lookupOrderOnPage,
                onFilter: this.lookupOrderOnFilter,
                onSort: this.lookupOrderOnSort,
            }
        }

        const dateTemplate = (rowData, column) => {
            const date = rowData[column.field];
            let formattedDate = moment(date).utc().format("DD/MM/YYYY");
            return <div style={{ textAlign: "center" }}>{formattedDate}</div>;
        };




        const imageTemplate = (rowData, column) => {
            const image = rowData[column.field];
            if ((rowData.code === 1 || rowData.code === 9999) && image) {
                return <div className="p-col-12 lxActionButtons" style={{ textAlign: "center" }}>
                    <button
                        className="lognex-btn-highlighted"
                        onClick={(e) => {
                            this.setCurrentImage(image)
                            this.showDeliveryReceiptOnClick(true);
                        }}
                        title={"Ver comprovante de entrega"}
                    >
                        <i className="pi pi-image"></i>
                    </button>
                </div>
            } else {
                return ""
            }
        }

        let occurrenceColumns = [<Column key="selectorColumn" style={{ textAlign: "center", width: '3em' }} />];
        occurrenceColumns = [...occurrenceColumns, ...this.props.occurrenceColumns.map(col => {
            if (col.field === "dateOccurrence") {
                return (<Column key={col.field} {...col} body={dateTemplate} />)
            }
            if (col.field === "image") {
                return (<Column key={col.field} {...col} body={imageTemplate} />)
            }
            return (<Column style={{ textAlign: "center" }} {...col} key={col.field} />)
        })];

        const valueTemplateDeliverySto = (rowData, column) => {
            return (
                <span style={{ display: "inline-block", width: "100%", textAlign: "right" }}>{utils.formatNumber(rowData.cargo.summary.value)}</span>
            )
        }

        let deliveryStoColumns = this.props.deliveryStoColumns.map(col => {
            if (col.field === "deliveryForecast") {
                return (<Column key={col.field} {...col} body={dateTimeTemplate} />)
            }
            if (col.field === "cargo.summary.value") {
                return (<Column key={col.field} {...col} body={valueTemplateDeliverySto} />)
            }
            if (col.field === "apportionmentValue") {
                return (<Column key={col.field} {...col} body={valueColTemplate} />)
            }
            return (<Column style={{ textAlign: "center" }} {...col} key={col.field} />)
        });

        const occurrences = {
            columns: [...occurrenceColumns],
            records: this.props.record.occurrences,
        }
        const deliverysSto = {
            columns: deliveryStoColumns,
            records: this.props.record.ordersByTransfer || [],
        }

        const modalViewDeliveryReceipt = {
            title: "Comprovante de Entrega",
            visible: this.state.isVisibleDeliveryReceipt,
            image: this.state.image,
            onHide: () => this.showDeliveryReceiptOnClick(false),
        }

        const currencyTemplate = (rowData, column) => {
            return (
                <div style={{ textAlign: 'center' }}>{utils.numberToCurrency(rowData[column.field], 'REAL')}</div>
            )
        }
        const percentTemplate = (rowData, column) => {
            return (
                <div style={{ textAlign: 'center' }}> {utils.formatNumber(rowData[column.field])}</div>
            )
        }
        const tagsTemplate = (rowData, column) => {
            const tags = rowData[column.field] && rowData[column.field].length > 0 ? rowData[column.field].join(', ') : ""
            return (
                <div style={{ textAlign: 'center' }}> {tags}</div>
            )
        }

        const deadlineTemplate = (rowData, column) => {
            return (
                <div style={{ textAlign: 'center' }}> {utils.formatNumber(rowData[column.field])}<span title="dias">{' d'}</span></div>
            )
        }
        const workingDaysTemplate = (rowData, column) => {
            const result = rowData[column.field];
            return <div style={{ textAlign: 'center' }}>{result === true ? 'Úteis' : result === false ? 'Todos' : ''}</div>;
        };

        const applyColumnsCustomBodies = (columns) => {
            return columns.map(col => {
                if (col.columnKey === 'freightValue' || col.columnKey === 'icmsValue' || col.columnKey === 'totalFreightValue') {
                    col.body = currencyTemplate
                } else if (col.columnKey === 'carrierDeliveryDeadline' || col.columnKey === 'clientDeliveryDeadline') {
                    col.body = deadlineTemplate
                } else if (col.columnKey === 'nfePercent') {
                    col.body = percentTemplate
                } else if (col.columnKey === 'tags') {
                    col.body = tagsTemplate
                } else if (col.columnKey === 'onlyWorkingDays') {
                    col.body = workingDaysTemplate
                }
                return col
            })
        }

        const headerTemplate = (iconName, col, label = false) => {
            return (
                <div className="iconTemplate">
                    <span>
                        <img src={IconFactory.getIcon(iconName)}
                            title={col.header} alt={col.header} />
                    </span>
                    <span>
                        {label ? col.header : ""}
                    </span>
                </div>)
        }

        const applyColumnsCustomHeaderBodies = (columns) => {
            return columns.map(col => {
                if (col.columnKey === 'carrierDeliveryDeadline') {
                    col.headerBody = headerTemplate("clock_truck", col)
                } else if (col.columnKey === 'clientDeliveryDeadline') {
                    col.headerBody = headerTemplate("clock_person", col)
                } else if (col.columnKey === 'tags') {
                    col.headerBody = headerTemplate("mark", col)
                } else if (col.columnKey === 'onlyWorkingDays') {
                    col.headerBody = headerTemplate("calendar", col)
                }
                return col
            })
        }

        let columns = applyColumnsCustomBodies(this.props.quotesColumns)
        columns = applyColumnsCustomHeaderBodies(this.props.quotesColumns)

        const modalFreightQuote = {
            title: "Cálculo de Frete",
            visible: this.state.isVisibleModalFreightQuote,
            columns: columns,
            value: this.props.quotesRecords,
            onHide: () => {
                this.props.clearfreightCalculationQuotes();
                this.onShowModalFreightQuoteOnClick(false)
            },
        }

        const content = (<div>
            <CargoShipmentForm
                weight={this.props.totalWeight}
                value={this.props.totalValue}
                freightValue={this.props.totalFreight}
                totalWeightVehicle={this.props.totalWeightVehicle}
                record={this.props.record}
                changeHandler={this.onChangeHandler}
                lookupVehicleType={lookupVehicleType}
                lookupDriver={lookupDriver}
                lookupCarrier={lookupCarrier}
                lookupOrder={lookupOrder}
                orders={orders}
                occurrences={occurrences}
                modal={modalViewDeliveryReceipt}
                changeTransiction={this.changeTransiction}
                disabledForm={this.props.disabledForm}
                modalFreightQuote={modalFreightQuote}
                deliverysSto={deliverysSto}
            />

            {this.state.isVisibleHistorylog &&
                <HistoryLogListPage docID={this.props.record.oid} resourceType={"cargo_shipment"} onHide={this.onHideHistorylog} />
            }

        </div>)
        return (<Page header={header} content={content} />)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CargoShipmentFormPageContainer)


